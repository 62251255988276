<template>
    <div>

        <TableWithoutRecords v-if="!isLoading && !hasOrders"/>

        <div
            class="bg-little-gray p-4 mb-4"
            v-for="(order, orderIndex) in orders"
            v-bind:key="'order_' + orderIndex"
        >

            <h5 class="font-weight-600 text-dark mb-3">
                <i class="fad fa-car-mechanic mr-2 fa-fw"/><strong>#{{ order.orderNumber }}</strong>, {{
                    order.address
                }}
                <BLink :to="{name: 'order_show', params: {id: order.id}}" target="_blank" class="text-info">
                    <i class="fad fa-external-link fa-fw ml-3"/>
                </BLink>
            </h5>

            <div class="bg-white p-2 border-radius-sm d-flex mb-2">

                <div class="mr-4">
                    <strong>Datum a čas:</strong>
                    <span v-if="order.scheduledStartTime">&nbsp;{{
                            {
                                startTime: order.scheduledStartTime,
                                endTime: order.scheduledEndTime
                            }|prettyDateRange(false, true)
                        }}</span>
                    <span v-else>&nbsp;Nenaplánováno</span>
                </div>

                <div class="mr-4">
                    <strong>Technik:</strong>
                    <span v-if="order.worker">&nbsp;{{ order.worker }}</span>
                    <span v-else>&nbsp;Nepřiřazen</span>
                </div>

                <div class="mr-4">
                    <strong>Stav:</strong>
                    <span v-if="order.status">&nbsp;{{ orderStatus.getItem(order.status).text }}</span>
                    <span v-else>&nbsp;Neznámý</span>
                </div>

                <div>
                    <strong>Role subjektu:</strong>
                    <span v-if="subject === order.customer && order.payer === 1">
                        &nbsp;Zákazník, hradící zakázku
                    </span>
                    <span v-else-if="subject === order.customer && order.payer === 2">
                        &nbsp;Zákazník, nehradící zakázku
                    </span>
                    <span v-else-if="subject === order.propertyOwner && order.payer === 2">
                        &nbsp;Vlastník nemovitosti, hradící zakázku
                    </span>
                    <span v-else-if="subject === order.propertyOwner && order.payer === 1">
                        &nbsp;Vlastník nemovitosti, hradící zakázku
                    </span>
                    <span v-else>
                        &nbsp;Neznámý
                    </span>
                </div>

            </div>

            <table class="table table-borderless mb-0">
                <thead>
                <tr class="border-bottom">
                    <th class="align-middle w-20">
                        Typ opravy
                    </th>
                    <th class="align-middle">
                        Název spotřebiče
                    </th>
                    <th class="align-middle w-15">
                        Sériové číslo
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr :class="{'border-bottom': repairIndex + 1 !== order.repairs.length}"
                    v-for="(repair, repairIndex) in order.repairs"
                    v-bind:key="'order_' + orderIndex + '_' + repairIndex"
                >
                    <td class="align-middle">
                        {{ repair.repairType }}
                    </td>
                    <td class="align-middle">
                        {{ repair.applianceName }}
                    </td>
                    <td class="align-middle">
                        {{ repair.applianceSerialNumber }}
                    </td>
                </tr>
                <tr class="border-top" v-if="order.repairs.length === 0">
                    <td colspan="6">
                        <TableWithoutRecords>
                            V této zakázce nejsou evidovány žádné opravy.
                        </TableWithoutRecords>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Commodity from "@/Model/Enum/commodity"
import ServiceContractLevel from "@/Model/Enum/service_contract_level"
import ServiceOrderStatus from "@/Model/Enum/service_order_status"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        subject: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            commodity: Commodity,
            serviceContractLevel: ServiceContractLevel,
            orderStatus: ServiceOrderStatus,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'subject/orders/isLoading',
            hasOrders: 'subject/orders/hasRecords',
            orders: 'subject/orders/getRecords',
        }),
    },

    created() {
        this.loadSubjectOrders(this.subject)
    },

    methods: {

        loadSubjectOrders(id) {
            this.$store.dispatch("subject/orders/loadOrders", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
