import axios from "axios"
import * as https from "https"
import router from "@/router"
import store from "@/store"
import {apiBaseUrl} from "@/environment"
import {appLocalStorage} from "@/Model/Common"

/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const config = {
    baseURL: apiBaseUrl,
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    })
}

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different config
 * Only this client will be used rather than axios in the application
 **/
const httpClient = axios.create(config)

/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
 * In future, interceptors can be created into separate files and consumed into multiple http clients
 * @param {*} config
 */
const authInterceptor = config => {
    /** add auth token */
    return config
}

const loggerInterceptor = config => {
    /** Add logging here */
    return config
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor)
httpClient.interceptors.request.use(loggerInterceptor)

/** Adding the response interceptors */
httpClient.interceptors.response.use(
    response => {
        return response
    },
    error => {

        if (!error.response) {
            return Promise.reject("Network error")
        } else {

            const originalRequest = error.config

            try {

                let errorLog = appLocalStorage.getItem("apiErrorLog")
                let errorMessage = {
                    time: new Date().toLocaleString(),
                    code: error.response.status,
                    url: error.response.config.url,
                    data: error.response.data,
                    message: error.toString(),
                }

                if (!Array.isArray(errorLog)) {
                    errorLog = []
                }

                errorLog.push(errorMessage)
                appLocalStorage.setItem("apiErrorLog", errorLog)
            } catch {
                console.log("Error during save the api error log.")
            }

            if (!originalRequest.url.includes('auth/in')) {
                if (error.response.status === 401 && !originalRequest._retry) {
                    alert("WARNING: The token for login has expired. Please log out and re-log into the application.")
                    originalRequest._retry = true
                    store.dispatch("auth/logout")
                    return router.push("/in")
                }
            }

            return Promise.reject(error)
        }
    }
)

export {httpClient}
