<template>
    <div class="container-fluid p-4">
        <RouterView v-if="!isLoading && hasSupplyPoint"/>

        <div v-if="isLoading === true" class="p-5 text-center">
            <i class="fad fa-spinner fa-fw fa-spin text-primary mr-4"/> Nahrávám odběrné místo
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    computed: {
        ...mapGetters({
            isLoading: "worker/supplyPoint/detail/isLoading",
            hasSupplyPoint: "worker/supplyPoint/detail/hasRecord",
            supplyPoint: "worker/supplyPoint/detail/getRecord",
        }),
    },

    mounted() {
        this.loadOrder(this.$route.params.id)
    },

    methods: {

        loadOrder(id) {
            this.$store.dispatch("worker/supplyPoint/detail/getDetailOfSupplyPoint", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
