<template>
    <div>

        <TableWithoutRecords v-if="records.length === 0"/>

        <div class="bg-little-gray mb-5 p-3 border-radius-sm" v-else>
            <div
                v-for="(record, recordIndex) in records"
                v-bind:key="'issuedMaterial_' + record.id"
                v-bind:class="(records.length - 1) !== recordIndex ? 'border-bottom': ''"
                class="p-2"
            >
                <div class="font-weight-600" v-if="record.appliance">
                    <i class="fad fa-satellite fa-fw mr-2"/>
                    {{ record.appliance.name }} <span v-if="record.applianceSerialNumber">,
                    SN: {{ record.applianceSerialNumber }}</span>
                </div>
                <div class="font-weight-600">
                    <i class="fad fa-calendar-day fa-fw mr-2"/>
                    {{ record.createdAt|prettyDateTime }}<span v-if="record.createdBy">,
                    {{ record.createdBy.name }}</span>:
                </div>
                {{ record.message }}
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        supplyPoint: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isLoading: 'supplyPoint/notes/isLoading',
            hasRecords: 'supplyPoint/notes/hasNotes',
            records: 'supplyPoint/notes/getNotes',
        }),
    },

    mounted() {
        this.loadRecords()
    },

    methods: {

        loadRecords() {
            this.$store.dispatch("supplyPoint/notes/clearAll")
            this.$store.dispatch("supplyPoint/notes/getTechnicalNotes", this.supplyPoint)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
