<template>
    <div>

        <div class="mb-4">
            <div class="d-flex justify-content-between text-align-center">
                <BButtonGroup>
                    <BButton variant="primary" @click="filterActive = !filterActive">
                        <i class="fad fa-filter mr-2"/> <span class="font-weight-500">Filtr</span>
                    </BButton>
                    <BButton
                        variant="danger"
                        @click="resetFilters"
                        v-if="isFilterUsed"
                    >
                        <i class="far fa-times"/>
                    </BButton>
                </BButtonGroup>
            </div>

            <BCollapse :visible="filterActive || isFilterUsed" class="mt-3 bg-little-gray p-3">
                <BRow class="mb-3">
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Název spotřebiče:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-name"
                        >
                            <BFormInput
                                id="filter-name"
                                size="sm"
                                v-model="filterRequest.filter.name"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Výrobní číslo:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-serialNumber"
                        >
                            <BFormInput
                                id="filter-serialNumber"
                                size="sm"
                                v-model="filterRequest.filter.serialNumber"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                </BRow>
                <BRow>
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Druh spotřebiče:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-applianceType"
                        >
                            <BFormSelect
                                v-model="filterRequest.filter.applianceType"
                                name="type"
                                id="filter-applianceType"
                                @change="filterUpdated"
                                size="sm"
                                text-field="name"
                                value-field="id"
                            >
                                <template #first>
                                    <BFormSelectOption :value="null">-- Vybrat --</BFormSelectOption>
                                </template>

                                <BFormSelectOption
                                    v-for="(applianceType, applianceTypeIndex) in applianceTypes"
                                    :key="'applianceType_' + applianceTypeIndex"
                                    :value="applianceType.id"
                                >
                                    {{ commodity.getItem(applianceType.commodity).text }} - {{ applianceType.name }}
                                </BFormSelectOption>

                            </BFormSelect>
                        </BFormGroup>
                    </BCol>
                    <BCol/>
                </BRow>
            </BCollapse>

        </div>

        <BTable
            ref="table"
            :fields="fields"
            :busy="isLoading"
            :current-page.sync="filterRequest.currentPage"
            :per-page.sync="filterRequest.perPage"
            :sort-by.sync="filterRequest.sortBy"
            :sort-desc.sync="filterRequest.sortDesc"
            :items="myProvider"
            tbody-tr-class="border-bottom"
            thead-tr-class="border-bottom"
            hover
            sort-icon-left
            borderless
            class="mb-0"
            no-sort-reset
            @context-changed="gridUpdated"
            show-empty
        >
            <template v-slot:table-busy>
                <TableLoading/>
            </template>
            <template v-slot:empty>
                <TableWithoutRecords/>
            </template>
            <template v-slot:cell(action)="data">
                <RouterLink
                    :to="{ name: 'appliance_show', params: { appliance_id: data.item.id }}"
                    class="btn btn-sm btn-primary"
                >
                    <i class="fad fa-eye fw"/>
                </RouterLink>
            </template>
        </BTable>
        <div class="mt-3">
            <strong>Celkem záznamů:</strong> {{ totalRecords }}
        </div>

        <TablePaginator :total-records="totalRecords" :filter-request="filterRequest" hide-go-to-end-buttons/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import SimpleFormRequest from "../../../NewModel/Common/SimpleFormRequest"
import Commodity from "@/Model/Enum/commodity"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TablePaginator from "@/Module/Common/UI/Table/TablePaginator.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TablePaginator,
        TableWithoutRecords,
    },

    data() {
        return {
            commodity: Commodity,
            filterRequest: this.buildFilterRequest(),
            filterActive: false,
            fields: [
                {
                    key: 'name',
                    label: 'Spotřebič',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle w-20'
                },
                {
                    key: 'applianceType',
                    label: 'Druh spotřebiče',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle w-15'
                },
                {
                    key: 'serialNumber',
                    label: 'Výrobní číslo',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle w-20'
                },
                {
                    key: 'address',
                    label: 'Adresa',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle'
                },
                {
                    key: 'action',
                    label: 'Akce',
                    thClass: 'font-weight-700',
                    class: 'text-right align-middle w-5'
                }
            ],
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isLoading: 'appliance/isLoading',
            totalRecords: 'appliance/getTotalAppliances',
            applianceTypes: 'appliance/types/getTypes',
        }),
        gridName: () => '_applianceGridFilter',
        isFilterUsed() {
            return this.filterRequest.filter.name !== ''
                || this.filterRequest.filter.applianceType !== null
                || this.filterRequest.filter.serialNumber !== ''
        },
    },

    created() {
        if (this.$cookies.isKey(this.gridName) && this.user.filterSave) {
            this.filterRequest = SimpleFormRequest.restore(this.$cookies.get(this.gridName))
        }
    },

    mounted() {
        this.filterUpdated()
        this.loadApplianceTypes()
    },

    methods: {

        loadApplianceTypes() {
            this.$store.dispatch("appliance/types/findAllActiveTypes")
        },

        buildFilterRequest() {
            return new SimpleFormRequest({
                name: '',
                applianceType: null,
                serialNumber: '',
            }, "ean", 10, false)
        },

        gridUpdated(ctx) {
            if (ctx) {
                this.filterRequest.sortBy = ctx.sortBy
                this.filterRequest.sortDesc = ctx.sortDesc
                this.saveFilter()
            }
        },

        saveFilter() {
            if (this.user.filterSave) {
                this.$cookies.set(this.gridName, this.filterRequest.store())
            }
        },

        filterUpdated(resetPage = false) {
            if (resetPage) {
                this.filterRequest.currentPage = 1
            }
            this.saveFilter()
            this.$refs.table.refresh()
        },

        myProvider(ctx, callback) {
            let items = []
            this.$store.dispatch("appliance/clearAll")
            this.$store.dispatch("appliance/getFilteredRecords", this.filterRequest)
                .then((response) => {
                    callback(response)
                })
                .catch(() => {
                    callback([])
                })
            return items || []
        },

        resetFilters() {
            this.filterActive = false
            this.filterRequest = this.buildFilterRequest()
            this.filterUpdated()
        },

    },

}
</script>
