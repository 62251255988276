import _ from "lodash"

const ServiceOrderStatusList = [
    {
        id: 0,
        text: "Koncept",
        enableForm: true,
        enableFinish: false,
        workerStatusEnabled: false,
        enableCancel: true,
        refreshCalendar: false,
        cancelled: false,
        enableInterruption: false,
        enableCreateChild: false,
        enableRestoring: false
    },
    {
        id: 1,
        text: "Naplánováno",
        enableForm: true,
        enableFinish: false,
        workerStatusEnabled: true,
        enableCancel: true,
        refreshCalendar: true,
        cancelled: false,
        enableInterruption: true,
        enableCreateChild: true,
        enableRestoring: false
    },
    {
        id: 2,
        text: "K dořešení",
        enableForm: true,
        enableFinish: false,
        workerStatusEnabled: false,
        enableCancel: true,
        refreshCalendar: false,
        cancelled: false,
        enableInterruption: false,
        enableCreateChild: true,
        enableRestoring: false
    },
    {
        id: 3,
        text: "Realizováno",
        enableForm: false,
        enableFinish: true,
        workerStatusEnabled: false,
        enableCancel: true,
        refreshCalendar: false,
        cancelled: false,
        enableInterruption: false,
        enableCreateChild: true,
        enableRestoring: true
    },
    {
        id: 5,
        text: "Uzavřeno",
        enableForm: false,
        enableFinish: false,
        workerStatusEnabled: false,
        enableCancel: false,
        refreshCalendar: false,
        cancelled: false,
        enableInterruption: false,
        enableCreateChild: true,
        enableRestoring: true
    },
    {
        id: 7,
        text: "Zrušeno",
        enableForm: false,
        enableFinish: false,
        workerStatusEnabled: false,
        enableCancel: false,
        refreshCalendar: false,
        cancelled: true,
        enableInterruption: false,
        enableCreateChild: false,
        enableRestoring: false
    },
    {
        id: 9,
        text: "Odstraněno",
        enableForm: false,
        enableFinish: false,
        workerStatusEnabled: false,
        enableCancel: false,
        refreshCalendar: false,
        cancelled: false,
        enableInterruption: false,
        enableCreateChild: false,
        enableRestoring: false
    },
]

export default class ServiceOrderStatus {

    constructor(id) {
        this.selected = id !== null ? id : null
        this.items = {...ServiceOrderStatusList}
    }

    toString() {
        let o = _.find(this.items, {id: this.selected})
        return o === undefined ? "N/A" : o.text
    }

    static print(id) {
        let o = _.find({...ServiceOrderStatusList}, {id: parseInt(id)})
        return o === undefined ? "N/A" : o.text
    }

    static list() {
        return {...ServiceOrderStatusList}
    }

    static arrayList() {
        return ServiceOrderStatusList
    }

    static getItem(id) {
        let o = _.find({...ServiceOrderStatusList}, {id: parseInt(id)})
        return o === undefined ? null : o
    }

}
