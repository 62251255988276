<template>
    <div>
        <BButton v-b-modal.appliance-window class="px-4" variant="primary">
            <i class="fad fa-add fa-fw mr-2"/>Přidat nový spotřebič
        </BButton>

        <BModal
            id="appliance-window"
            header-bg-variant="primary"
            header-text-variant="white"
            footer-bg-variant="light"
            footer-text-variant="dark"
            size="xl"
            body-class="p-4"
            footer-class="d-flex justify-content-between align-items-center"
            no-stacking
        >
            <template #modal-header>
                <div class="p-3">
                    <h4 class="display-4 mb-0">
                        <i class="fa-light fa-layer-plus fa-fw mr-3"/> Přidat spotřebič do montážního listu
                    </h4>
                </div>
            </template>

            <template #modal-footer="{cancel}">
                <BButton variant="dark" @click="cancel()">
                    <i class="fad fa-times fa-fw mr-2"/>Zavřít okno
                </BButton>
                <BButton variant="primary" @click="onSubmit()" :disabled="!isCreateEnabled">
                    <i class="fad fa-check fa-fw mr-2"/>Vytvořit
                </BButton>
            </template>

            <BForm ref="applianceForm" @submit="onSubmit">

                <BFormGroup
                    id="input-group-0"
                    label-for="input-0"
                    label-class="font-weight-600"
                    class="mb-4"
                >
                    <template #label>
                        Spotřebič na odběrném místě:
                        <span class="text-danger font-weight-900 ml-2">*</span>
                    </template>
                    <template #description v-if="createAppliance === true">
                        <em class="text-danger">
                            <strong>UPOZORNĚNÍ:</strong> Na odběrném místě bude vytvořen zcela nový spotřebič!</em>
                    </template>

                    <BInputGroup class="mb-2">
                        <BFormSelect v-model="form.appliance" id="input-0">
                            <template #first>
                                <BFormSelectOption :value="null" disabled>-- Vybrat --</BFormSelectOption>
                            </template>
                            <BFormSelectOption
                                :value="availableAppliance.id"
                                v-for="availableAppliance in availableAppliances"
                                :key="availableAppliance.id"
                            >
                                #{{ String(availableAppliance.id).slice(-5) }} - {{ availableAppliance.name }}
                            </BFormSelectOption>
                        </BFormSelect>
                        <BInputGroupAppend is-text>
                            <BFormCheckbox
                                v-model="createAppliance"
                                :checked="true"
                                :unchecked-value="false"
                                class="mr-2"
                            >
                                Spotřebič neexistuje
                            </BFormCheckbox>
                        </BInputGroupAppend>
                    </BInputGroup>

                </BFormGroup>

                <BFormGroup
                    id="input-group-3"
                    label-for="input-3"
                    label-class="font-weight-600"
                    class="mb-4"
                    v-if="createAppliance === true"
                >
                    <template #label>
                        Druh spotřebiče:
                        <span class="text-danger font-weight-900 ml-2">*</span>
                    </template>
                    <BFormSelect
                        v-model="form.applianceType"
                        id="input-3"
                        :options="availableApplianceTypes"
                        value-field="id"
                        text-field="name"
                    >
                        <template #first>
                            <BFormSelectOption :value="null" disabled>-- Vybrat --</BFormSelectOption>
                        </template>
                    </BFormSelect>
                </BFormGroup>

                <BFormGroup
                    id="input-group-1"
                    label-for="input-1"
                    label-class="font-weight-600"
                >
                    <template #label>
                        Sériové číslo:
                        <span class="text-danger font-weight-900 ml-2">*</span>
                    </template>
                    <BFormInput
                        id="input-1"
                        v-model="form.serialNumber"
                        placeholder="Zadejte sériové číslo spotřebiče."
                        required
                    />
                </BFormGroup>

                <BFormGroup
                    id="input-group-2"
                    label="Poznámka:"
                    label-for="input-2"
                    label-class="font-weight-600"
                >
                    <BFormTextarea
                        id="input-2"
                        v-model="form.note"
                        placeholder="Pokud chcete, můžete zde zadat poznámku k spotřebiči"
                    />
                </BFormGroup>
            </BForm>

        </BModal>

    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    props: {
        applianceMode: {
            type: String,
            required: false,
            default: "create",
        },
    },

    computed: {
        ...mapGetters({
            availableAppliances_isLoading: "assemblySheet/availableAppliances/isLoading",
            hasAvailableAppliances: "assemblySheet/availableAppliances/hasRecords",
            availableAppliances: "assemblySheet/availableAppliances/getRecords",
            availableApplianceTypes_isLoading: "assemblySheet/availableApplianceTypes/isLoading",
            hasAvailableApplianceTypes: "assemblySheet/availableApplianceTypes/hasRecords",
            availableApplianceTypes: "assemblySheet/availableApplianceTypes/getRecords",
        }),
        isCreateEnabled() {
            return (this.createAppliance === true && this.form.applianceType !== null) || this.form.appliance !== null
        },
    },

    data() {
        return {
            createAppliance: false,
            form: {
                appliance: null,
                applianceType: null,
                serialNumber: "",
                note: "",
            },
        }
    },

    mounted() {
        this.$store.dispatch("assemblySheet/availableAppliances/findAvailableAppliances", this.$route.params.id)
        this.$store.dispatch("assemblySheet/availableApplianceTypes/findAvailableApplianceTypes", this.$route.params.id)
    },

    methods: {

        onSubmit() {

            if (!this.$refs.applianceForm.checkValidity()) {
                this.warningMessage("Validace formuláře nebyla úspěšná. Byla všechna požadovaná pole vyplněna?")
                return
            }

            this.$store.dispatch("assemblySheet/appliances/createAppliance", {
                id: this.$route.params.id,
                formData: this.form
            })
                .then((result) => {
                    this.$emit("created", result)
                    this.$bvModal.hide("appliance-window")
                })
        },

    },

}
</script>
