<template>
    <div class="container">

        <Header icon="fad fa-plus-circle" title="Vytvořit model spotřebiče">
            <template #buttons>
                <BackLinkButton to="/setup/boilers/models"/>
            </template>
        </Header>

        <BoilerModelForm
            :request="request"
            :is-processing="isCreating"
            v-on:submitted="onSubmitted"
        />

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceModelRequest from "../../../NewModel/Appliance/Model/Request/ApplianceModelRequest"
import BackLinkButton from "../../Common/UI/Button/BackLinkButton.vue"
import BoilerModelForm from "./Component/ApplianceModelForm.vue"
import Header from "../../Common/UI/Header.vue"

export default {

    components: {
        BackLinkButton,
        BoilerModelForm,
        Header,
    },

    computed: {
        ...mapGetters({
            isCreating: 'appliance/models/isCreating',
        }),
    },

    data() {
        return {
            request: new ApplianceModelRequest(),
        }
    },

    methods: {

        /**
         * @param request : ApplianceModelRequest
         */
        onSubmitted(request) {
            this.$store.dispatch("appliance/models/createModel", request)
                .then((record) => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'setup_boiler_model_edit', params: {id: record.id}})
                })
                .catch((err) => {
                    this.warningMessage(err.response.message)
                })
        },

    },

}
</script>
