<template>
    <div>

        <Header :title="boilerModel.fullName">
            <template #buttons>
                <PrimaryLinkButton
                    icon="fad fa-pencil"
                    text="Upravit"
                    :to="{name: 'setup_boiler_model_edit', params: {id: boilerModel.id}}"
                />
                <BackLinkButton to="/setup/boilers/models" class="ml-3"/>
            </template>
        </Header>

        <div class="bg-little-gray p-4 mb-4">
            <ApplianceModelInfo :entity="boilerModel"/>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-4">
            <h4 class="font-weight-700 text-dark mb-0">
                <i class="fad fa-wrench fa-fw mr-3"/>Provedení
            </h4>
        </div>

        <ApplianceModelTypes :entity="boilerModel" v-if="boilerModel.id !== null"/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceModelInfo from "./Component/ApplianceModelInfo.vue"
import ApplianceModelTypes from "./Component/ApplianceModelTypes.vue"
import BackLinkButton from "../../Common/UI/Button/BackLinkButton.vue"
import Header from "../../Common/UI/Header.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        ApplianceModelInfo,
        ApplianceModelTypes,
        BackLinkButton,
        Header,
        PrimaryLinkButton,
    },

    computed: {
        ...mapGetters({
            boilerModel: 'appliance/models/getModel',
        }),
    },

}
</script>
