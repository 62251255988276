<template>
    <div>

        <div class="bg-white p-4 border-radius-sm">
            <div class="d-flex justify-content-end align-items-center mb-4">
                <div class="d-flex align-items-center">
                    <PrimaryLinkButton
                        :to="'/worker/orders/' + assemblySheet.order.id"
                        icon="fad fa-chevron-left"
                        text="Zpět do zakázky"
                    />
                </div>
            </div>
            <AssemblySheetHeaderFragment :assembly-sheet="assemblySheet"/>
            <AssemblySheetTransportFragment :assembly-sheet="assemblySheet"/>
            <AssemblySheetServiceOperationsFragment :assembly-sheet="assemblySheet"/>
            <AssemblySheetOtherMaterialsFragment
                :assembly-sheet="assemblySheet"
                v-if="assemblySheet.materials.length > 0"
                class="mb-4"
            />
            <AssemblySheetOtherItemsFragment
                :assembly-sheet="assemblySheet"
                v-if="assemblySheet.items.length > 0"
                class="mb-4"
            />
            <AssemblySheetPricingFragment :assembly-sheet="assemblySheet"/>
            <AssemblySheetCustomerFragment :assembly-sheet="assemblySheet"/>
            <div class="d-flex justify-content-end align-items-center">
                <div class="d-flex align-items-center">
                    <PrimaryLinkButton
                        :to="'/worker/orders/' + assemblySheet.order.id"
                        icon="fad fa-chevron-left"
                        text="Zpět do zakázky"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import AssemblySheetCustomerFragment from "./Component/AssemblySheetCustomerFragment.vue"
import AssemblySheetHeaderFragment from "./Component/AssemblySheetHeaderFragment.vue"
import AssemblySheetOtherItemsFragment from "./Component/AssemblySheetOtherItemsFragment.vue"
import AssemblySheetOtherMaterialsFragment from "./Component/AssemblySheetOtherMaterialsFragment.vue"
import AssemblySheetPricingFragment from "./Component/AssemblySheetPricingFragment.vue"
import AssemblySheetServiceOperationsFragment from "./Component/AssemblySheetServiceOperationsFragment.vue"
import AssemblySheetTransportFragment from "./Component/AssemblySheetTransportFragment.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        AssemblySheetCustomerFragment,
        AssemblySheetHeaderFragment,
        AssemblySheetOtherItemsFragment,
        AssemblySheetOtherMaterialsFragment,
        AssemblySheetPricingFragment,
        AssemblySheetServiceOperationsFragment,
        AssemblySheetTransportFragment,
        PrimaryLinkButton,
    },

    computed: {
        ...mapGetters({
            assemblySheet: "assemblySheet/process/getRecord",
        }),
    },

}
</script>
