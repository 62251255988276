export default class AddressParcelRequest {

    constructor() {
        this.siteCity = ""
        this.siteRegister = ""
        this.siteNumber = ""
        this.gpsLat = 50.226725
        this.gpsLng = 12.798262
    }

    toForm() {
        return this
    }

}
