import Vue from 'vue'
import Vuex from 'vuex'
import AddressStore from "./NewModel/Address/AddressStore"
import AssemblySheetStore from "./NewModel/AssemblySheet/AssemblySheetStore"
import IncidentStore from "./NewModel/Incident/IncidentStore"
import MaterialStore from "./NewModel/Material/MaterialStore"
import OrderStore from "./NewModel/Order/OrderStore"
import ProfileStore from "./NewModel/Profile/ProfileStore"
import SupplyPointStore from "./NewModel/SupplyPoint/SupplyPointStore"
import WorkerStore from "./NewModel/Worker/WorkerStore"
import TrackingStore from "./NewModel/Tracking/TrackingStore"
import UserStore from "./NewModel/User/UserStore"
import ApplianceStore from "./NewModel/Appliance/ApplianceStore"
import RepairTypeStore from "./NewModel/RepairType/RepairTypeStore"
import AresStore from "./NewModel/Ares/AresStore"
import AttachmentStore from "./NewModel/Attachment/AttachmentStore"
import AuthStore from "./NewModel/Auth/AuthStore"
import DiscountStore from "./NewModel/Discount/DiscountStore"
import PlanningStore from "./NewModel/Planning/PlanningStore"
import DashboardStore from "./NewModel/Dashboard/DashboardStore"
import IssueStore from "./NewModel/Issue/IssueStore"
import SubjectStore from "./NewModel/Subject/SubjectStore"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        address: AddressStore,
        appliance: ApplianceStore,
        ares: AresStore,
        assemblySheet: AssemblySheetStore,
        attachment: AttachmentStore,
        auth: AuthStore,
        dashboard: DashboardStore,
        discount: DiscountStore,
        incident: IncidentStore,
        issue: IssueStore,
        material: MaterialStore,
        order: OrderStore,
        planning: PlanningStore,
        profile: ProfileStore,
        repairType: RepairTypeStore,
        subject: SubjectStore,
        supplyPoint: SupplyPointStore,
        tracking: TrackingStore,
        user: UserStore,
        worker: WorkerStore,
    }
})
