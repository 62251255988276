<template>
  <b-input-group size="lg">
    <template #prepend>
      <b-input-group-text><i class="fad fa-font-case"></i></b-input-group-text>
    </template>
    <b-form-input v-model="searchText" v-on:keyup="typed" />
  </b-input-group>
</template>

<script>
export default {

  data() {
    return {
      searchText: ""
    }
  },

  methods: {

    typed() {
      this.$emit('typed', this.searchText);
    }

  }
}
</script>
