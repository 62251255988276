import authHeader from "../../../Model/Common/AuthHeader"
import {httpClient} from "../../../Model/Common"

const ApplianceManufacturerService = {

    findAllActiveManufacturers() {
        return httpClient.get("v3/manufacturers", {
            headers: authHeader()
        })
    },

    /**
     * @param request : SimpleFormRequest
     */
    filterManufacturers(request) {
        return httpClient.post("v3/manufacturers/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getManufacturer(id) {
        return httpClient.get("v3/manufacturers/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param request : SubjectContactRequest
     */
    createManufacturer(request) {
        return httpClient.post("v3/manufacturers", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @param request : SubjectContactRequest
     */
    updateManufacturer(id, request) {
        return httpClient.put("v3/manufacturers/" + id, request.toForm(), {
            headers: authHeader()
        })
    },

}

export default ApplianceManufacturerService
