<template>
    <tr class="border-top">
        <template v-if="material.action === 'edit' || material.action === 'create'">
            <td class="align-middle">
                <BFormInput
                    type="text"
                    size="sm"
                    v-model="form.itemName"
                    :disabled="isProcessing"
                    required
                />
            </td>
            <td class="align-middle text-center">
                <BFormSelect v-model="form.assemblySheetAppliance">
                    <BFormSelectOption :value="null" disabled>-- Vyberte --</BFormSelectOption>
                    <BFormSelectOption
                        v-for="(appliance, applianceIndex) in assemblySheet.appliances"
                        :key="applianceIndex + appliance.id"
                        :value="appliance.id"
                    >
                        #{{ String(appliance.appliance.id).slice(-5) }}
                        <span v-if="appliance.appliance.name"> - {{ appliance.appliance.name }}</span>
                    </BFormSelectOption>
                </BFormSelect>
            </td>
            <td class="align-middle text-center">
                <BFormInput
                    class="text-center"
                    min="1"
                    type="number"
                    v-model.number="form.quantity"
                    size="sm"
                    :disabled="isProcessing"
                    required
                />
            </td>
            <td class="align-middle text-right">
                <BInputGroup append="Kč" size="sm">
                    <BFormInput
                        class="text-right"
                        min="0"
                        step="0.1"
                        type="number"
                        v-model.number="form.pricePerItem"
                        :disabled="isProcessing"
                        required
                    />
                </BInputGroup>
            </td>
            <td class="align-middle text-right">
                <BInputGroup append="%" size="sm">
                    <BFormInput
                        class="text-right"
                        min="0" max="100"
                        step="0.1"
                        type="number"
                        v-model.number="form.discount"
                        :disabled="isProcessing"
                        required
                    />
                </BInputGroup>
            </td>
            <td class="align-middle text-right">
                <BButton variant="danger" @click="cancelRow()">
                    <i class="fa fa-times fa-fw"/>
                </BButton>
                <BButton variant="success" @click="saveRow()" class="ml-3" :disabled="isProcessing">
                    <i class="fad fa-spinner-third fa-spin fa-fw" v-if="isProcessing"/>
                    <i class="fad fa-check fa-fw" v-else/>
                </BButton>
            </td>
        </template>
        <template v-else>
            <td class="align-middle">
                {{ material.itemName }}
            </td>
            <td class="align-middle text-center">
                <span v-if="material.appliance">#{{ String(material.appliance).slice(-5) }}</span>
            </td>
            <td class="align-middle text-center">
                {{ material.quantity }}
            </td>
            <td class="align-middle text-right">
                {{ material.pricePerItem|moneyFormat("CZK_LONG") }}
            </td>
            <td class="align-middle text-right">
                {{ material.discount }} %
            </td>
            <td class="align-middle text-right">
                <BButton variant="danger" @click="deleteRow()">
                    <i class="fad fa-trash fa-fw"/>
                </BButton>
                <BButton variant="primary" @click="openEditFormMode()" class="ml-3">
                    <i class="fad fa-edit fa-fw"/>
                </BButton>
            </td>
        </template>
    </tr>
</template>

<script>
import {mapGetters} from "vuex"
import AssemblySheetEntity from "../../../../../NewModel/AssemblySheet/Entity/AssemblySheetEntity"
import AssemblySheetMaterialEntity
    from "../../../../../NewModel/AssemblySheet/Entity/AssemblySheetMaterialEntity"

export default {

    props: {
        assemblySheet: {
            type: AssemblySheetEntity,
            required: true,
        },
        material: {
            type: AssemblySheetMaterialEntity,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isProcessing: "assemblySheet/materials/isProcessing",
        }),
    },

    data() {
        return {
            form: {
                assemblySheetAppliance: null,
                appliance: null,
                material: null,
                itemName: "",
                pricePerItem: 0,
                discount: 0,
                quantity: 1,
            }
        }
    },

    mounted() {
        if (this.material.action === "create") {
            this.openCreateFormMode()
        }
    },

    methods: {

        openFormMode(mode) {
            this.material.action = mode
        },

        openCreateFormMode() {
            this.openFormMode("create")
        },

        fillFormFromEntity(material) {
            this.form.assemblySheetAppliance = material.assemblySheetAppliance
            this.form.appliance = material.appliance
            this.form.material = null
            this.form.itemName = material.itemName
            this.form.pricePerItem = material.pricePerItem
            this.form.quantity = material.quantity
            this.form.discount = material.discount
        },

        openEditFormMode() {
            this.openFormMode("edit")
            this.fillFormFromEntity(this.material)
        },

        cancelRow() {
            if (this.material.action === "edit") {
                this.fillFormFromEntity(this.material)
                this.material.action = null
            } else {
                this.$emit("cancelled", {
                    index: this.index
                })
            }
        },

        deleteRow() {
            let self = this
            this.$bvModal.msgBoxConfirm("Opravdu chcete odstranit položku " + this.material.itemName + "?", {
                title: "Potvrzení",
                okVariant: "primary",
                cancelVariant: "default",
                centered: true,
                okTitle: "Ano",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("assemblySheet/materials/deleteMaterial", {
                            assemblySheetId: this.assemblySheet.id,
                            materialId: this.material.id,
                        })
                            .then(() => {
                                self.$emit("cancelled", {
                                    index: this.index
                                })
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

        saveRow() {
            try {
                this.checkForm()
                if (this.material.action === "edit") {
                    this.$store.dispatch("assemblySheet/materials/updateMaterial", {
                        assemblySheetId: this.assemblySheet.id,
                        materialId: this.material.id,
                        formData: {
                            assemblySheetAppliance: this.form.assemblySheetAppliance,
                            material: null,
                            eanCode: "",
                            itemName: this.form.itemName,
                            pricePerItem: parseFloat(this.form.pricePerItem),
                            discount: parseInt(this.form.discount),
                            quantity: parseFloat(this.form.quantity),
                        }
                    })
                        .then((response) => {
                            this.material.action = null
                            this.$emit("updated", {
                                materialId: this.material.id,
                                index: this.index,
                                entity: response
                            })
                        })
                } else if (this.material.action === "create") {
                    this.$store.dispatch("assemblySheet/materials/createMaterial", {
                        assemblySheetId: this.assemblySheet.id,
                        formData: {
                            assemblySheetAppliance: this.form.assemblySheetAppliance,
                            material: null,
                            eanCode: "",
                            itemName: this.form.itemName,
                            pricePerItem: parseFloat(this.form.pricePerItem),
                            discount: parseInt(this.form.discount),
                            quantity: parseFloat(this.form.quantity),
                        }
                    })
                        .then((response) => {
                            this.material.action = null
                            this.$emit("created", {
                                materialId: this.material.id,
                                index: this.index,
                                entity: response
                            })
                        })
                }

            } catch (err) {
                this.warningMessage(err)
                return false
            }
        },

        checkForm() {
            if (this.form.discount < 0 || this.form.discount > 100) {
                throw "Sleva musí být v rozmezí 0 až 100 %!"
            }
            if (this.form.quantity < 1) {
                throw "Počet musí být větší než 0!"
            }
        },

    },

}
</script>
