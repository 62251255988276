export default class ApplianceModelTypeRequest {

    constructor() {
        this.name = ""
        this.manufacturer = null
        this.model = null
        this.applianceType = null
        this.description = ""
        this.active = true
        this.power = 0.0
        this.fuel = 0
        this._new = false
        this._editable = false
        this._id = null
    }

    /**
     * @param response : ApplianceModelTypeEntity
     * @param manufacturer : Number
     * @param model : Number
     * @returns ApplianceModelTypeRequest
     */
    static fromEntity(response, manufacturer, model) {

        let req = new this
        req.name = response.name
        req.manufacturer = manufacturer
        req.model = model
        req.applianceType = response.applianceType.id
        req.description = response.description
        req.active = response.active
        req.power = response.power
        req.fuel = response.fuel || 0
        req._id = response.id
        return req
    }

    toForm() {
        return {
            name: this.name,
            manufacturer: this.manufacturer,
            model: this.model,
            applianceType: this.applianceType,
            description: this.description,
            active: this.active,
            power: this.power,
            fuel: this.fuel,
        }
    }

}
