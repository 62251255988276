import MobileMain from "./MobileMain"
import OrdersToday from "./OrdersToday.vue"
import OrdersMy from "./OrdersMy.vue"
import Order from "./Order"
import OrderShow from "./OrderShow.vue"
import OrderShowAppliance from "./OrderShowAppliance.vue"
import IncidentsMy from "./IncidentsMy.vue"
import IncidentCreate from "./IncidentCreate.vue"
import NoteAddTechnical from "./NoteAddTechnical.vue"

const mobileRoutes = [
    {
        path: "/m",
        component: MobileMain,
        children: [
            {
                path: "home",
                name: "new_mobile_home",
                component: OrdersToday,
            },
            {
                path: "",
                redirect: {name: "new_mobile_home"},
            },
            {
                path: "my_orders/:date?",
                name: "new_mobile_order_my_list",
                component: OrdersMy,
            },
            {
                path: "orders/:id",
                name: "new_mobile_order",
                component: Order,
                children: [
                    {
                        path: "",
                        redirect: {name: "new_mobile_order_show"},
                    },
                    {
                        path: "show",
                        name: "new_mobile_order_show",
                        component: OrderShow,
                    },
                    {
                        path: "appliance/:appliance_id",
                        name: "new_mobile_order_appliance_show",
                        component: OrderShowAppliance,
                    },
                    {
                        path: "notes/add",
                        name: "new_mobile_order_add_note",
                        component: NoteAddTechnical,
                    },
                ],
            },
            {
                path: "incidents",
                name: "mobile_incidents",
                component: IncidentsMy,
            },
            {
                path: "incidents/create",
                name: "mobile_incidents_create",
                component: IncidentCreate,
            },
        ],
    },
]

export default mobileRoutes
