export default class SubjectContactBasicEntity {

    constructor() {
        this.id = ''
        this.main = false
        this.name = ''
        this.email = ''
        this.phone = ''
        this._action = null
    }

    /**
     * @param response : object
     * @returns SubjectContactBasicEntity
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.main = response.main
        obj.name = response.name ?? ''
        obj.email = response.email ?? ''
        obj.phone = response.phone ?? ''

        return obj
    }

}
