export default class DashboardOrder {

    constructor() {
        this.id = ""
        this.customId = null
        this.address = ""
        this.worker = ""
        this.workerStatus = 0
        this.cancelReasonStatus = 0
        this.scheduledStartTime = null
        this.scheduledRepairTime = 0
        this.status = 0
    }

    /**
     * @param response
     * @returns {DashboardOrderReview}
     */
    static buildFromResponse(response) {
        let entity = new this
        entity.id = response.id
        entity.customId = response.customId
        entity.address = response.address
        entity.worker = response.worker
        entity.workerStatus = response.workerStatus
        entity.cancelReasonStatus = response.cancelReasonStatus
        entity.scheduledStartTime = response.scheduledStartTime
        entity.scheduledRepairTime = response.scheduledRepairTime
        entity.status = response.status
        return entity
    }

}
