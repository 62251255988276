<template>
    <div class="container-fluid p-4">
        <RouterView v-if="!isLoading && hasAppliance"/>

        <div v-if="isLoading === true" class="p-5 text-center">
            <i class="fad fa-spinner fa-fw fa-spin text-primary mr-4"/> Nahrávám spotřebič
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    computed: {
        ...mapGetters({
            isLoading: "worker/appliance/detail/isLoading",
            hasAppliance: "worker/appliance/detail/hasRecord",
            appliance: "worker/appliance/detail/getRecord",
        })
    },

    mounted() {
        this.loadOrder(this.$route.params.id)
    },

    methods: {

        loadOrder(id) {
            this.$store.dispatch("worker/appliance/detail/getDetailOfAppliance", id)
                .catch((error) => {
                    if (error.response.status === 404) {
                        this.errorMessage("Spotřebič nebyl nalezen")
                        this.$router.push({name: "worker_dashboard"})
                    } else {
                        this.errorMessage(error.response.data.message)
                    }
                })
        },

    },

}
</script>
