export default class Incident {

    constructor() {
        this.id = ""
        this.customer = ""
        this.description = ""
        this.eventTime = null
        this.solved = false
        this.createdAt = null
        this.createdBy = ""
        this.solvedAt = null
        this.solvedBy = ""
    }

    /**
     * @param response
     * @returns {Incident}
     */
    static buildFromResponse(response) {
        let entity = new this
        entity.id = response.id
        entity.customer = response.customer
        entity.description = response.description
        entity.eventTime = response.eventTime
        entity.solved = response.solved
        entity.createdAt = response.createdAt
        entity.createdBy = response.createdBy
        entity.solvedAt = response.solvedAt
        entity.solvedBy = response.solvedBy

        return entity
    }

}
