<template>
    <div>

        <div v-if="!isLoading && hasAppliance">

            <Header :title="appliance.manufacturer.name">
                <template #buttons>
                    <BButton
                        variant="info"
                        :to="{name: 'new_mobile_order_show'}"
                    >
                        <i class="fad fa-chevron-left mr-3"/>Zpět
                    </BButton>
                </template>
            </Header>

            <div class="bg-little-gray mb-4 p-3 border-radius-sm">
                <ApplianceInfo :appliance="appliance"/>
            </div>

            <BTabs content-class="mt-3" pills v-if="hasAppliance">

                <BTab active lazy>
                    <template v-slot:title>Opravy</template>
                    <ApplianceRepairs :entity="appliance"/>
                </BTab>

                <BTab lazy>
                    <template v-slot:title>Soubory</template>
                    <ApplianceAttachments :entity="appliance"/>
                </BTab>

            </BTabs>

        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceAttachments from "../Appliance/Component/ApplianceAttachments.vue"
import ApplianceInfo from "./Component/ApplianceInfo.vue"
import ApplianceRepairs from "../Appliance/Component/ApplianceRepairs.vue"
import Header from "../Common/UI/Header.vue"

export default {

    components: {
        ApplianceAttachments,
        ApplianceInfo,
        ApplianceRepairs,
        Header,
    },

    computed: {
        ...mapGetters({
            isLoading: "appliance/isLoading",
            hasAppliance: "appliance/hasAppliance",
            appliance: "appliance/getAppliance",
        }),
    },

    created() {
        this.loadAppliance(this.$route.params.appliance_id)
    },

    methods: {

        loadAppliance(id) {
            this.$store.dispatch("appliance/clear")
            this.$store.dispatch("appliance/getAppliance", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
