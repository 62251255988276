<template>
    <div>

        <div class="py-2 px-3 mb-3 text-white" style="background-color: #2a7dc4;">
            <strong>Ostatní položky:</strong>
        </div>

        <div class="mb-3 px-3">
            <table class="table table-sm">
                <tbody>
                <tr class="bg-light">
                    <th colspan="6" class="text-left">
                        Položky
                    </th>
                </tr>
                <tr>
                    <th class="text-left">
                        Název položky
                    </th>
                    <th class="text-center w-10">
                        Počet m.j.
                    </th>
                    <th class="text-right w-15">
                        Cena / m.j.
                    </th>
                    <th class="text-right w-5">
                        Sleva
                    </th>
                    <th class="text-right w-15">
                        Cena po slevě
                    </th>
                    <th class="text-right w-15">
                        Celkem
                    </th>
                </tr>
                <tr v-for="item in assemblySheet.items" :key="item.id">
                    <td>
                        {{ item.itemName }}
                    </td>
                    <td class="text-center">
                        {{ item.quantity }}
                    </td>
                    <td class="text-right">
                        {{ item.pricePerItem|moneyFormat("CZK_LONG") }}
                    </td>
                    <td class="text-right">
                        {{ item.discount }} %
                    </td>
                    <td class="text-right">
                        {{ item.pricePerItemWithDiscount|moneyFormat("CZK_LONG") }}
                    </td>
                    <td class="text-right">
                        {{ item.totalAmount|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import AssemblySheetEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetEntity"

export default {

    props: {
        assemblySheet: {
            type: AssemblySheetEntity,
            required: true,
        },
    },

}
</script>
