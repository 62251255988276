export default class ApplianceManufacturerRequest {

    constructor() {
        this.name = ""
        this.active = true
    }

    /**
     * @param response : ApplianceManufacturerEntity
     * @returns ApplianceManufacturerRequest
     */
    static fromEntity(response) {
        let req = new this
        req.name = response.name
        req.active = response.active
        return req
    }

    toForm() {
        return this
    }

}
