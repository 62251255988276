<template>
    <div class="container">
        <LoadingBlock v-if="!hasDiscount && isLoading"/>
        <RouterView v-if="hasDiscount"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import LoadingBlock from "../Common/UI/LoadingBlock.vue"

export default {

    components: {
        LoadingBlock,
    },

    computed: {
        ...mapGetters({
            isLoading: 'discount/isLoading',
            hasDiscount: 'discount/hasDiscount',
            discount: 'discount/getDiscount',
        }),
    },

    mounted() {
        this.$store.dispatch("discount/clear")
        this.loadDiscount(this.$route.params.id)
    },

    methods: {

        loadDiscount(id) {
            this.$store.dispatch("discount/getDiscount", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
