<template>
    <div class="container">

        <div
            class="text-center text-dark m-0 p-4 display-4 d-flex justify-content-center align-items-center"
            v-if="!hasRecord && isLoading"
        >
            <i class="fad fa-spinner fa-fw fa-2x fa-spin text-danger mr-4"/>
            <strong>Nahrávám ...</strong>
        </div>

        <RouterView v-if="hasRecord"/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    computed: {
        ...mapGetters({
            isLoading: 'subject/isLoading',
            hasRecord: 'subject/hasSubject',
            record: 'subject/getSubject',
        }),
    },

    created() {
        this.$store.dispatch("subject/clear")
        this.loadSubject(this.$route.params.id)
    },

    methods: {

        loadSubject(id) {
            this.$store.dispatch("subject/getSubject", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
