import SubjectContactEntity from "./SubjectContactEntity"

export default class SubjectEntity {

    constructor() {
        this.id = ''
        this.type = 1
        this.fullName = ''
        this.firstName = ''
        this.lastName = ''
        this.birthDate = null
        this.academicDegree = ''
        this.companyName = ''
        this.wholeName = ''
        this.identificationNumber = null
        this.vatNumber = null
        this.primaryAddress = {
            id: null,
            wholeName: '',
        }
        this.secondaryAddress = {
            id: null,
            wholeName: '',
        }
        this.contacts = []
    }

    /**
     * @param response : object
     * @returns SubjectEntity
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.type = response.type
        obj.fullName = response.fullName ?? ''
        obj.firstName = response.firstName ?? ''
        obj.lastName = response.lastName ?? ''
        obj.birthDate = response.birthDate
        obj.academicDegree = response.academicDegree
        obj.companyName = response.companyName
        obj.wholeName = response.wholeName
        obj.identificationNumber = response.identificationNumber
        obj.vatNumber = response.vatNumber
        obj.primaryAddress.id = response.primaryAddress.id
        obj.primaryAddress.wholeName = response.primaryAddress.wholeName
        obj.secondaryAddress.id = response.secondaryAddress.id
        obj.secondaryAddress.wholeName = response.secondaryAddress.wholeName
        obj.contacts = response.contacts.map(contact => SubjectContactEntity.from(contact))
        return obj
    }

}
