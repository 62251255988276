<template>
    <div class="container">

        <Header icon="fad fa-piggy-bank" :title="'OM: ' + discount.supplyPoint.ean">
            <template #buttons>
                <DangerLinkButton
                    :to="'/discounts/' + discount.id + '/edit'"
                    icon="fad fa-pencil"
                    text="Upravit"
                    class="mr-3"
                />
                <BackLinkButton to="/discounts"/>
            </template>
        </Header>

        <div class="bg-little-gray p-4 mb-4">
            <BRow>
                <BCol>
                    <table class="table table-borderless mb-0">
                        <tr class="border-light border-bottom">
                            <td class="font-weight-700 w-30">
                                Odběrné místo:
                            </td>
                            <td>
                                {{ discount.supplyPoint.ean }}
                            </td>
                        </tr>
                        <tr class="border-light border-bottom">
                            <td class="font-weight-700 w-30">
                                Adresa:
                            </td>
                            <td>
                                {{ discount.supplyPoint.address }}
                            </td>
                        </tr>
                        <tr class="border-light border-bottom">
                            <td class="font-weight-700 w-30">
                                Zákazník:
                            </td>
                            <td>
                                {{ discount.customer }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-700 w-30">
                                Vlastník nem.:
                            </td>
                            <td>
                                {{ discount.propertyOwner }}
                            </td>
                        </tr>
                    </table>
                </BCol>
                <BCol>
                    <table class="table table-borderless mb-0">
                        <tr class="border-light border-bottom">
                            <td class="font-weight-700 w-30">
                                Účinnost:
                            </td>
                            <td>
                                {{ discount.effectiveFrom|prettyDate }} ~ {{ discount.effectiveTo|prettyDate }}
                            </td>
                        </tr>
                        <tr class="border-light border-bottom">
                            <td class="font-weight-700 w-30">Typ slevy:</td>
                            <td>
                                <span v-if="discount.type === 'individual'">
                                    Individuální
                                </span>
                                <span v-if="discount.type === 'fixed_contract'">
                                    Fix. smlouva KVP = {{ discount.contractAmendment || "N/A" }}
                                </span>
                                <span v-if="discount.type === 'service_contract'">
                                    Servisní smlouva - {{ discount.serviceContract || "N/A" }}
                                </span>
                            </td>
                        </tr>
                        <tr class="border-light border-bottom">
                            <td class="font-weight-700 w-30">
                                Obecná sleva:
                            </td>
                            <td>
                                {{ discount.generalAmount }} %
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-700 w-30">
                                Poznámka:
                            </td>
                            <td>
                                {{ discount.note }}
                            </td>
                        </tr>
                    </table>
                </BCol>
            </BRow>
        </div>

        <div class="mb-4">

            <h5 class="font-weight-600 text-dark mb-3">
                <i class="fad fa-tags text-danger fa-fw mr-3"/>Jednotlivé slevy
            </h5>

            <table class="table table-borderless mb-0">
                <thead>
                <tr class="border-light border-bottom">
                    <th class="font-weight-700 w-40">
                        Servisní úkon
                    </th>
                    <th class="font-weight-700 w-40">
                        Spotřebič
                    </th>
                    <th class="font-weight-700 w-20 text-right">
                        Výše slevy [%]
                    </th>
                </tr>
                </thead>
                <tr
                    v-for="(item, itemIndex) in discount.items"
                    :key="'item_' + itemIndex"
                    :class="{'border-light border-bottom': (discount.items.length - 1) !== itemIndex}"
                >
                    <td>
                        <span v-if="item.repairType">{{ item.repairTypeName }}</span>
                        <span v-if="!item.repairType">N/A</span>
                    </td>
                    <td>
                        <span v-if="item.appliance">{{ item.applianceName }}</span>
                        <span v-if="!item.appliance">N/A</span>
                    </td>
                    <td class="text-right">
                        {{ item.amount }} %
                    </td>
                </tr>
                <tr v-if="discount.items.length === 0">
                    <td colspan="3">
                        <TableWithoutRecords>
                            Žádné individuální slevy. Ve všech případech bude použita obecná sleva
                            <em>{{ discount.generalAmount }} %</em>.
                        </TableWithoutRecords>
                    </td>
                </tr>
            </table>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../Common/UI/Button/BackLinkButton.vue"
import DangerLinkButton from "../Common/UI/Button/DangerLinkButton.vue"
import Header from "../Common/UI/Header.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        BackLinkButton,
        DangerLinkButton,
        Header,
        TableWithoutRecords,
    },

    computed: {
        ...mapGetters({
            discount: 'discount/getDiscount',
        }),
    },

}
</script>
