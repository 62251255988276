export default class WorkerOrderMapEntity {

    constructor() {
        this.id = null
        this.orderNumber = ''
        this.startTime = null
        this.endTime = null
        this.supplyPoint = ''
        this.ean = ''
        this.gpsLat = null
        this.gpsLng = null
        this.customerName = ''
        this.propertyOwnerName = ''
        this.status = 0
    }

    /**
     * @param response : object
     * @returns {WorkerOrderMapEntity}
     */
    static from(response) {
        let obj = new this

        obj.id = response.id
        obj.orderNumber = response.orderNumber
        obj.startTime = response.startTime
        obj.endTime = response.endTime
        obj.supplyPoint = response.supplyPoint
        obj.ean = response.ean
        obj.gpsLat = response.gpsLat
        obj.gpsLng = response.gpsLng
        obj.customerName = response.customerName
        obj.propertyOwnerName = response.propertyOwnerName
        obj.status = response.status

        return obj
    }

}
