export default class Issue {

    constructor() {
        this.id = ""
        this.appliance = {
            id: null,
            name: ""
        }
        this.order = null
        this.orderKey = null
        this.createdAt = ""
        this.createdBy = {
            id: null,
            name: ""
        }
        this.comment = ""
        this.responsibleUser = {
            id: null,
            name: ""
        }
        this.solvedAt = null
        this.solvedBy = {
            id: null,
            name: ""
        }
    }

    /**
     * @param response
     * @returns {Issue}
     */
    static buildFromResponse(response) {
        let entity = new this
        entity.id = response.id
        entity.appliance.id = response.appliance.id
        entity.appliance.name = response.appliance.name
        entity.order = response.order
        entity.orderKey = response.orderKey
        entity.createdAt = response.createdAt
        entity.createdBy.id = response.createdBy.id
        entity.createdBy.name = response.createdBy.name
        entity.comment = response.comment
        entity.responsibleUser.id = response.responsibleUser.id
        entity.responsibleUser.name = response.responsibleUser.name
        entity.solvedAt = response.solvedAt
        entity.solvedBy.id = response.solvedBy.id
        entity.solvedBy.name = response.solvedBy.name
        return entity
    }

}
