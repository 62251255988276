import SupplyPointList from "./SupplyPointList"
import SupplyPointCreate from "./SupplyPointCreate.vue"
import SupplyPointUpdate from "./SupplyPointUpdate.vue"
import SupplyPointBlocking from "./SupplyPointBlocking.vue"
import CreateAppliance from "./ApplianceCreate.vue"
import MapOfSupplyPoints from "./MapOfSupplyPoints"
import SearchClient from "./SearchClient"
import SupplyPoint from "./SupplyPoint.vue"
import SupplyPointShow from "./SupplyPointShow.vue"
import WhiteMain from "../General/WhiteMain.vue"

const supplyPointRoutes = [

    {
        path: "/supply_points/map",
        name: "supply_points_map",
        component: MapOfSupplyPoints,
    },
    {
        path: "/supply_points",
        component: WhiteMain,
        children: [
            {
                path: "",
                name: "supply_point_list",
                component: SupplyPointList,
            },
            {
                path: "search",
                name: "supply_point_search",
                component: SearchClient,
            },
            {
                path: "create",
                name: "supply_points_create",
                component: SupplyPointCreate,
            },
            {
                path: ":id",
                name: "supply_point",
                component: SupplyPoint,
                props: true,
                children: [
                    {
                        path: "show",
                        name: "supply_point_show",
                        component: SupplyPointShow,
                    },
                    {
                        path: "edit",
                        name: "supply_point_edit",
                        component: SupplyPointUpdate,
                    },
                    {
                        path: "blocking",
                        name: "supply_point_blocking",
                        component: SupplyPointBlocking,
                    },
                    {
                        path: "appliances/create",
                        name: "supply_point_appliance_create",
                        component: CreateAppliance,
                    }
                ],
            },
        ],
    },
]

export default supplyPointRoutes
