export default class PlanningFilterEntity {

    constructor() {
        this.id = ''
        this.supplyPoint = ''
        this.ean = ''
        this.address = ''
        this.plannedDateOfRepair = null
        this.gpsLat = null
        this.gpsLng = null
        this.serviceContract = null
    }

    /**
     * @param response : object
     * @returns PlanningFilterEntity
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.supplyPoint = response.supplyPoint
        obj.ean = response.ean
        obj.address = response.address
        obj.plannedDateOfRepair = response.plannedDateOfRepair
        obj.gpsLat = response.gpsLat
        obj.gpsLng = response.gpsLng
        obj.serviceContract = response.serviceContract

        return obj
    }

}
