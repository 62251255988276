<template>
    <div class="vh-100 vw-100 bg-light">

        <loading
            :show="loading.show"
            :label="loading.customLabel !== '' ? loading.customLabel : loading.label"
        />

        <header class="bg-primary">
            <img src="../../Assets/logo_bw.svg" height="40">
        </header>

        <article>
            <div class="row no-gutters h-100">
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 p-3">

                    <BForm @submit.prevent="loadMap" class="mb-3">

                        <BFormGroup
                            id="worker"
                            label="Technik:"
                            label-for="worker"
                            description="Zobrazí i plánované zakázky technika daný den."
                            label-class="font-weight-600"
                        >
                            <BFormSelect
                                v-model="form.workerId"
                                :options="workers"
                                value-field="id"
                                text-field="fullName"
                                id="worker"
                            >
                                <template v-slot:first>
                                    <BFormSelect-option :value="null">
                                        -- Vybrat technika --
                                    </BFormSelect-option>
                                </template>
                            </BFormSelect>
                        </BFormGroup>

                        <BFormGroup
                            id="date"
                            label="Datum:"
                            label-for="date"
                            label-class="font-weight-600"
                        >
                            <BFormDatepicker
                                v-model="form.date"
                                locale="cs"
                                label-today-button="Dnes"
                                label-close-button="Zavřít"
                                today-button
                                close-button
                            />
                        </BFormGroup>

                        <BFormGroup
                            id="previous-days"
                            label="Opravy v minulosti:"
                            label-for="previous-days"
                            label-class="font-weight-600"
                        >
                            <BFormSelect
                                v-model="form.previousDays"
                                :options="list.previousDays"
                                value-field="id"
                                text-field="name"
                                id="previous-days"
                            />
                        </BFormGroup>

                        <BFormGroup
                            id="next-days"
                            label="Opravy v budoucnosti:"
                            label-for="next-days"
                            label-class="font-weight-600"
                        >
                            <BFormSelect
                                v-model="form.nextDays"
                                :options="list.nextDays"
                                value-field="id"
                                text-field="name"
                                id="next-days"
                            />
                        </BFormGroup>

                        <BButton type="submit" variant="primary" block>
                            Nahrát
                        </BButton>

                    </BForm>

                    <BackLinkButton :to="{name: 'appliance_list'}" class="btn-block"/>

                </div>

                <div class="col-xl-10 col-lg-9 col-md-8 col-sm-12 bg-white">
                    <GmapMap :center="center" :zoom="11.25" map-type-id="roadmap" class="w-100 h-100">
                        <GmapCluster :maxZoom="22" @click="clickOnCluster">
                            <GmapMarker
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :options="m.options"
                                :clickable="true"
                                @click="clickOnMarker(m.options.data)"
                                :key="index"
                                :label="m.label"
                                :title="m.options.data.order ? ('Zakázka: ' + m.options.data.sp_ean + ' - ' + m.options.data.sp_customer) : (m.options.data.sp_ean + ' - ' + m.options.data.sp_customer)"
                                :icon="m.icon"
                            />
                        </GmapCluster>
                    </GmapMap>

                    <BModal
                        :header-bg-variant="selected.supplyPoint.order ? 'light-orange' : 'white'"
                        :title="selected.supplyPoint.order ? 'Zakázka' : 'Plánovaná oprava'"
                        v-if="selected.supplyPoint"
                        size="lg"
                        id="supply-point-modal"
                        centered
                        body-class="p-0"
                        ok-only
                        ok-title="Zavřít"
                    >
                        <table class="table table-borderless mb-0">
                            <tr class="border-bottom">
                                <td class="bold bg-light w-35 align-middle">
                                    EAN / EIC
                                </td>
                                <td class="align-middle text-primary">
                                    <RouterLink
                                        :to="{ name: 'supply_point_show', params: { id: selected.supplyPoint.sp_id }}"
                                        target="_blank"
                                        class="text-dark"
                                    >
                                        <i :class="commodity.getItem(selected.supplyPoint.sp_commodity).icon"
                                           class="mr-3"/>{{ selected.supplyPoint.sp_ean }}
                                    </RouterLink>
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="bold bg-light w-35 align-middle">
                                    Místo instalace
                                </td>
                                <td class="align-middle">
                                    {{ selected.supplyPoint.sp_address }}
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="bold bg-light w-35 align-middle">
                                    Zákazník
                                </td>
                                <td class="align-middle">
                                    {{ selected.supplyPoint.sp_customer }}
                                </td>
                            </tr>
                            <tr class="border-bottom ">
                                <td class="bold bg-light w-35 align-middle">
                                    Vlastník nemovistosti
                                </td>
                                <td class="align-middle">
                                    {{ selected.supplyPoint.sp_property_owner }}
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="bold bg-light w-35 align-middle">
                                    Smlouva KV PLYN
                                </td>
                                <td class="align-middle">
                                    {{ selected.supplyPoint.sp_contract ? "Ano" : "Ne" }}
                                </td>
                            </tr>
                            <tr v-if="selected.supplyPoint.order" class="border-bottom">
                                <td class="bold bg-light w-35 align-middle">
                                    Zakázka
                                </td>
                                <td class="align-middle">
                                    <RouterLink
                                        :to="{ name: 'order_show', params: { id: selected.supplyPoint.order_id }}"
                                        target="_blank"
                                    >
                                        #{{ selected.supplyPoint.order_id }}
                                    </RouterLink>
                                </td>
                            </tr>
                            <tr v-if="selected.supplyPoint.order">
                                <td class="bold bg-light w-35 align-middle">
                                    Datum a čas
                                </td>
                                <td class="align-middle">
                                    {{
                                        {
                                            startTime: selected.supplyPoint.order_start_time,
                                            endTime: selected.supplyPoint.order_end_time
                                        }|prettyDateRange
                                    }}
                                </td>
                            </tr>
                        </table>

                        <table class="table table-bordeless mb-0" v-if="!selected.supplyPoint.order">
                            <thead>
                            <tr>
                                <th colspan="4" class="bg-light font-weight-600">
                                    Opravy
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    Výrobce
                                </th>
                                <th>
                                    Druh opravy
                                </th>
                                <th>
                                    Násl. oprava
                                </th>
                                <th>
                                    Placeno
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="repair in selected.supplyPoint.repairs" v-bind:key="repair.plan_id">
                                <td>
                                    {{ repair.plan_appliance_manufacturer_name }}
                                </td>
                                <td>
                                    {{ repair.plan_repair_type_name }}
                                </td>
                                <td>
                                    {{ repair.plan_planned_date|prettyDate }}
                                </td>
                                <td>
                                    {{ repair.plan_paid ? "Ano" : "Ne" }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </BModal>

                    <BModal
                        id="supply-point-clustered"
                        size="lg"
                        centered
                        title="Více odběrných míst v dané lokalitě"
                        body-class="p-0"
                        ok-only
                        ok-title="Zavřít"
                        v-if="clusteredMarkers.length > 0"
                        no-stacking
                    >
                        <table class="table table-borderless mb-0">
                            <thead>
                            <tr class="border-bottom">
                                <th class="bg-light bold align-middle w-25">
                                    EAN
                                </th>
                                <th class="bg-light bold align-middle">
                                    Zákazník
                                </th>
                                <th class="bg-light bold align-middle">
                                    Sml. KV PLYN
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                class="border-bottom" v-for="(marker, clusteredMarkerIndex) in clusteredMarkers"
                                v-bind:key="clusteredMarkerIndex"
                                v-on:click.prevent="clickOnMarker(marker)"
                            >
                                <td class="align-middle">
                                    <i :class="commodity.getItem(marker.sp_commodity).icon" class="mr-3"/>
                                    {{ marker.sp_ean }}
                                </td>
                                <td class="align-middle">
                                    {{ marker.sp_customer|shortify(70) }}
                                </td>
                                <td class="align-middle">
                                    {{ marker.sp_connectionContract ? "Ano" : "Ne" }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </BModal>

                </div>

            </div>
        </article>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../../Module/Common/UI/Button/BackLinkButton.vue"
import Commodity from "../../Model/Enum/commodity"
import GmapCluster from "vue2-google-maps/dist/components/cluster"
import loading from "vue-full-loading"
import moment from "moment"
import Vue from "vue"

export default {

    components: {
        BackLinkButton,
        GmapCluster,
        loading,
    },

    data() {
        return {
            commodity: Commodity,
            selected: {
                supplyPoint: null,
            },
            development: (process.env.NODE_ENV !== "production"),
            center: {
                lat: 50.226725,
                lng: 12.798262
            },
            clusteredMarkers: [],
            markers: [],
            supplyPoints: [],
            loading: {
                show: false,
                label: "Načítám odběrná místa",
                customLabel: ""
            },
            list: {
                previousDays: [
                    {id: 9999, name: "Maximum"},
                    {id: 183, name: "Zpětně 6 měsíců"},
                    {id: 31, name: "Zpětně 1 měsíc"},
                    {id: 7, name: "Zpětně 7 dní"},
                ],
                nextDays: [
                    {id: 7, name: "Následujících 7 dní"},
                    {id: 14, name: "Následujících 14 dní"},
                    {id: 31, name: "Následujících 1 měsíc"},
                    {id: 183, name: "Následujících 6 měsíců"},
                    {id: 9999, name: "Maximum"},
                ],
            },
            form: {
                workerId: null,
                date: moment().format("YYYY-MM-DD"),
                previousDays: 7,
                nextDays: 7,
            },
        }
    },

    computed: {
        ...mapGetters({
            workersAreLoading: "user/workers/isLoading",
            hasWorkers: "user/workers/hasWorkers",
            workers: "user/workers/getWorkers",
        }),
    },

    created() {
        if (!this.hasWorkers) {
            this.loadWorkers()
        }

        this.loadMap()
    },

    methods: {

        loadMap() {
            this.loading.show = true
            let self = this
            this.$store.dispatch("appliance/repairsMap/getRepairsMap", this.form)
                .then((data) => {

                    self.supplyPoints = []
                    self.markers = []
                    self.clusteredMarkers = []

                    data.forEach(sp => {
                        let icon = "http://maps.google.com/mapfiles/ms/micons/__ICON__"
                        if (sp.order) { // Zakázka
                            icon = icon.replace("__ICON__", "yellow.png")
                        } else { // Plánovaná oprava
                            let oldestDate = null
                            sp.repairs.forEach(repair => {
                                let diff = moment(String(repair.plan_planned_date)).format("DD.MM.YYYY")
                                if (oldestDate === null || oldestDate > diff) {
                                    oldestDate = diff
                                }
                            })
                            if (oldestDate < self.form.date) {
                                icon = icon.replace("__ICON__", "red-dot.png")
                            } else {
                                icon = icon.replace("__ICON__", "green-dot.png")
                            }
                        }

                        self.markers.push({
                            position: {
                                lat: parseFloat(sp.sp_gps_lat),
                                lng: parseFloat(sp.sp_gps_lng),
                            },
                            options: {
                                id: sp.sp_id,
                                afterDeadline: true,
                                data: sp,
                            },
                            label: sp.order ? {
                                color: "#333",
                                fontWeight: "bold",
                                fontSize: "20px",
                                text: Vue.filter("prettyDateRange")({
                                    startTime: sp.order_start_time,
                                    endTime: sp.order_end_time
                                }, true)
                            } : {
                                color: "#fff",
                                text: " "
                            },
                            icon: icon,
                            clickable: true,
                        })
                        self.supplyPoints.push(sp)
                    })

                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
                .finally(() => {
                    self.loading.show = false
                })
        },

        loadWorkers() {
            this.$store.dispatch("user/workers/clearAll")
            this.$store.dispatch("user/workers/findWorkers")
        },


        clickOnMarker(data) {
            this.selected.supplyPoint = data
            this.$bvModal.show("supply-point-modal")
        },

        clickOnCluster(cluster) {
            let self = this
            self.clusteredMarkers = []
            cluster.markers_.repairs.forEach(marker => {
                if (marker && marker.data !== undefined) {
                    self.clusteredMarkers.push(marker.data)
                }
            })
            if (self.clusteredMarkers.length > 0) {
                self.$bvModal.show("supply-point-clustered")
            } else {
                this.warningMessage("Nastala neočekáváná chyba")
            }
        },

    },

}
</script>

<style scoped>
header {
    height: 4rem;
    padding: 0.75rem 0.75rem;
}

article {
    height: calc(100vh - 4rem);
}
</style>
