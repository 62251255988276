export default class AttachmentRequest {

    constructor(id, name) {
        this.id = id
        this.name = name
    }

    toForm() {
        return this.id
    }

}
