<template>
    <div>

        <Header title="Blokace odběrného místa">
            <template #buttons>
                <BackLinkButton :to='"/supply_points/" + supplyPoint.id + "/show"'/>
            </template>
        </Header>

        <div class="bg-little-gray p-4 mb-4 border-radius-sm">
            <BForm @submit.stop.prevent="processForm">

                <BFormGroup
                    id="input-block-benefit"
                    label="Blokace čerpání benefitu:"
                    label-for="block-benefit"
                    label-class="font-weight-700"
                    label-cols-md="12"
                    label-cols-lg="3"
                >
                    <BFormCheckbox id="block-benefit" v-model="request.blockBenefits">
                        Blokováno
                    </BFormCheckbox>
                </BFormGroup>

                <BFormGroup
                    id="input-block-period"
                    label="Blokace periodických prohlídek:"
                    label-for="block-period"
                    label-class="font-weight-700"
                    label-cols-md="12"
                    label-cols-lg="3"
                >
                    <BFormCheckbox id="block-period" v-model="request.blockPeriodRepairs">
                        Blokováno
                    </BFormCheckbox>
                </BFormGroup>

                <BFormGroup
                    id="input-comment"
                    label="Poznámka:"
                    label-for="comment"
                    label-class="font-weight-700"
                    label-cols-md="12"
                    label-cols-lg="3"
                >
                    <BFormTextarea
                        id="comment"
                        v-model="request.blockingComment"
                        placeholder="Komentář k blokaci"
                        rows="3"
                        max-rows="6"
                    />
                </BFormGroup>

                <BFormGroup label-cols-md="12" label-cols-lg="3" class="mb-0">
                    <BButton type="submit" variant="primary">Nastavit</BButton>
                </BFormGroup>

            </BForm>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../Common/UI/Button/BackLinkButton.vue"
import Header from "../Common/UI/Header.vue"
import SupplyPointBlockRequest from "@/NewModel/SupplyPoint/Request/SupplyPointBlockRequest"

export default {

    components: {
        BackLinkButton,
        Header,
    },

    computed: {
        ...mapGetters({
            supplyPoint: "supplyPoint/getSupplyPoint",
        }),
    },

    data() {
        return {
            request: new SupplyPointBlockRequest(),
        }
    },

    mounted() {
        this.request = SupplyPointBlockRequest.from(this.supplyPoint)
    },

    methods: {

        processForm() {
            let self = this
            this.$store.dispatch("supplyPoint/blockSupplyPoint", {
                id: this.supplyPoint.id,
                request: this.request
            })
                .then(() => {
                    this.successMessage("Formulář byl úspěšně uložen.")
                    this.$router.push("/supply_points/" + self.supplyPoint.id + "/show")
                })
        },

    },

}
</script>
