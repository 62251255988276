<template>
    <div class="container-fluid p-4">

        <div class="d-flex justify-content-between align-items-center mb-5">
            <h3 class="font-weight-600 text-dark mb-0">
                <i class="fa-duotone fa-list-check text-primary fa-fw mr-3"/>Incidenty k vyřešení
            </h3>
            <div class="buttons">
                <PrimaryLinkButton
                    to="/worker/incidents/create"
                    icon="fad fa-plus-circle"
                    text="Vytvořit nový incident"
                />
            </div>
        </div>

        <div v-if="isLoading === false && records.length > 0">
            <IncidentRow
                :record="record"
                v-for="record in records"
                :key="record.id"
            />
        </div>

        <TableLoading v-else-if="isLoading === true && records.length === 0"/>
        <TableWithoutRecords v-else/>

        <hr class="my-5"/>

        <div class="d-flex align-items-center justify-content-center">
            <i class="fa-light fa-square-question fa-fw fa-xl mr-2"/>
            Zobrazujeme Vámi nahlášené incidenty, které jsou nedořešené nebo vyřešené v posledních 30 dnech.
        </div>


    </div>
</template>

<script>
import {mapGetters} from "vuex"
import IncidentRow from "./Component/IncidentRow.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        IncidentRow,
        PrimaryLinkButton,
        TableLoading,
        TableWithoutRecords,
    },

    computed: {
        ...mapGetters({
            isLoading: "profile/incidents/isLoading",
            hasRecords: "profile/incidents/hasIncidents",
            records: "profile/incidents/getIncidents",
        })
    },

    mounted() {
        this.loadRecords()
    },

    methods: {

        loadRecords() {
            this.$store.dispatch("profile/incidents/findMyIncidents")
                .catch((error) => {
                        this.errorMessage(error.response.data.message)
                    }
                )
        },

    },

}

</script>
