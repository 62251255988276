import AssemblySheetService from "../AssemblySheetService"
import AssemblySheetEntity from "../Entity/AssemblySheetEntity"
import AssemblySheetPricingEntity from "../Entity/AssemblySheetPricingEntity"

const
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",

    UPDATING_RECORD = "UPDATING_RECORD",
    UPDATING_RECORD_SUCCESS = "UPDATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "UPDATING_RECORD_ERROR",

    SIGNING_RECORD = "SIGNING_RECORD",
    SIGNING_RECORD_SUCCESS = "SIGNING_RECORD_SUCCESS",
    SIGNING_RECORD_ERROR = "SIGNING_RECORD_ERROR",

    RECALCULATION_RECORD = "RECALCULATION_RECORD",
    RECALCULATION_RECORD_SUCCESS = "RECALCULATION_RECORD_SUCCESS",
    RECALCULATION_RECORD_ERROR = "RECALCULATION_RECORD_ERROR"

const initialState = {
    isLoading: false,
    isUpdating: false,
    isSigning: false,
    isRecalculating: false,
    record: null,
    error: null
}

export const state = {...initialState}

export const actions = {

    async getAssemblySheet({commit}, id) {
        try {
            commit(FETCHING_RECORD)
            let response = await AssemblySheetService.getAssemblySheet(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async updateAssemblySheet({commit}, {id, formData}) {
        try {
            commit(UPDATING_RECORD)
            let response = await AssemblySheetService.updateAssemblySheet(id, formData)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async signingAssemblySheet({commit}, {id, formData}) {
        try {
            commit(SIGNING_RECORD)
            let response = await AssemblySheetService.signingAssemblySheet(id, formData)
            commit(SIGNING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(SIGNING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async recalculationAssemblySheet({commit}, id) {
        try {
            commit(RECALCULATION_RECORD)
            let response = await AssemblySheetService.recalculationAssemblySheet(id)
            commit(RECALCULATION_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(RECALCULATION_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

}

export const mutations = {

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
        state.record = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = AssemblySheetEntity.from(data)

    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.record = null
    },

    [UPDATING_RECORD](state) {
        state.isUpdating = true
        state.error = null
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isUpdating = false
        state.error = null
        if(state.record) {
            state.record.pricing = AssemblySheetPricingEntity.from(data)
        }
    },
    [UPDATING_RECORD_ERROR](state, error) {
        state.isUpdating = false
        state.error = error
    },

    [SIGNING_RECORD](state) {
        state.isSigning = true
        state.error = null
    },
    [SIGNING_RECORD_SUCCESS](state) {
        state.isSigning = false
        state.error = null
    },
    [SIGNING_RECORD_ERROR](state, error) {
        state.isSigning = false
        state.error = error
    },

    [RECALCULATION_RECORD](state) {
        state.isRecalculating = true
        state.error = null
    },
    [RECALCULATION_RECORD_SUCCESS](state, data) {
        state.isRecalculating = false
        state.error = null
        if(state.record) {
            state.record.pricing = AssemblySheetPricingEntity.from(data)
        }
    },
    [RECALCULATION_RECORD_ERROR](state, error) {
        state.isRecalculating = false
        state.error = error
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    isUpdating(state) {
        return state.isUpdating
    },
    isSigning(state) {
        return state.isSigning
    },
    isRecalculating(state) {
        return state.isRecalculating
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasRecord(state) {
        return state.record !== null
    },
    getRecord(state) {
        return state.record
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
