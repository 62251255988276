<template>
    <div class="container">

        <Header icon="fad fa-plus-circle" title="Vytvořit typ opravy">
            <template #buttons>
                <BackLinkButton to="/setup/repair_types"/>
            </template>
        </Header>

        <RepairTypeForm
            :request="request"
            :is-processing="isCreating"
            v-on:submitted="onSubmitted"
        />

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../../Common/UI/Button/BackLinkButton.vue"
import Header from "../../Common/UI/Header.vue"
import RepairTypeForm from "./Component/RepairTypeForm.vue"
import RepairTypeRequest from "../../../NewModel/RepairType/Request/RepairTypeRequest"

export default {

    components: {
        Header,
        BackLinkButton,
        RepairTypeForm,
    },

    computed: {
        ...mapGetters({
            isCreating: 'repairType/isCreating',
        }),
    },

    data() {
        return {
            request: new RepairTypeRequest(),
        }
    },

    methods: {

        /**
         * @param request : RepairTypeRequest
         */
        onSubmitted(request) {
            this.$store.dispatch("repairType/createRepairType", request)
                .then(() => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'setup_repair_type_list'})
                })
                .catch((error) => {
                    this.warningMessage(error.response.data.message)
                })
        },

    },

}
</script>
