export default class AddressEntity {

    constructor() {
        this.id = null
        this.street = ''
        this.postalCode = ''
        this.city = ''
        this.cityPart = ''
        this.houseNumber = ''
        this.guidanceNumber = ''
        this.numberWhole = ''
        this.country = ''
        this.region = ''
        this.validated = false
        this.wholeName = ''
        this.gpsLat = null
        this.gpsLng = null
        this.municipalityPartName = ''
        this.ruianCode = ''
        this.parcelNrUsed = false
        this.siteCity = ''
        this.siteRegister = ''
        this.siteNumber = ''
    }

    /**
     * @param response : object
     * @returns AddressEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.street = response.street ?? ''
        obj.postalCode = response.postalCode ?? ''
        obj.city = response.city ?? ''
        obj.cityPart = response.cityPart ?? ''
        obj.houseNumber = response.houseNumber ?? ''
        obj.guidanceNumber = response.guidanceNumber ?? ''
        obj.numberWhole = response.numberWhole ?? ''
        obj.country = response.country ?? ''
        obj.region = response.region ?? ''
        obj.validated = response.validated
        obj.wholeName = response.wholeName ?? ''
        obj.gpsLat = response.gpsLat
        obj.gpsLng = response.gpsLng
        obj.municipalityPartName = response.municipalityPartName ?? ''
        obj.ruianCode = response.ruianCode ?? ''
        obj.parcelNrUsed = response.parcelNrUsed
        obj.siteCity = response.siteCity ?? ''
        obj.siteRegister = response.siteRegister ?? ''
        obj.siteNumber = response.siteNumber ?? ''
        return obj
    }

}
