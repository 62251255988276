<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-5">
            <div class="d-flex align-items-center">
                <h3 class="font-weight-600 text-dark mb-0">
                    <i class="fad fa-house text-primary fa-fw mr-3"/>{{ supplyPoint.ean }} ({{ supplyPoint.customer }})
                </h3>
            </div>
            <div class="d-flex align-items-center">
                <PrimaryLinkButton
                    :to="'/worker/orders/' + $route.query.order"
                    v-if="$route.query.order"
                    icon="fad fa-chevron-left"
                    text="Zpět do zakázky"
                />
                <PrimaryButton icon="fad fa-chevron-left" text="Zpět" @click.prevent="$router.go(-1)" v-else/>
            </div>
        </div>

        <BTabs
            content-class="mt-5"
            active-nav-item-class="text-white bg-primary border-radius-sm"
            pills
            lazy
            justified
        >
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase" active>
                <template #title>
                    <div class="m-1">
                        Informace
                    </div>
                </template>

                <div class="bg-little-gray p-4">
                    <table class="table table-borderless table-sm mb-0">
                        <tr>
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="EIC / EAN">
                                EIC / EAN:
                            </td>
                            <td>
                                {{ supplyPoint.ean }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Adresa odběrného místa">
                                Adresa odběrného místa:
                            </td>
                            <td>
                                {{ supplyPoint.address }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Jméno zákazníka">
                                Zákazník:
                            </td>
                            <td>
                                {{ supplyPoint.customer }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Jméno vlastníka">
                                Vlastník nemovitosti:
                            </td>
                            <td>
                                {{ supplyPoint.propertyOwner }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Úroveň servisní smlouvy">
                                Úroveň servisní smlouvy:
                            </td>
                            <td>
                                {{ serviceContractLevel.getItem(supplyPoint.serviceContract).text }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Název odběrného místa">
                                Název odběrného místa:
                            </td>
                            <td>
                                {{ supplyPoint.name }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Patro">
                                Patro:
                            </td>
                            <td>
                                {{ supplyPoint.floorNumber }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Číslo bytu">
                                Číslo bytu:
                            </td>
                            <td>
                                {{ supplyPoint.apartmentNumber }}
                            </td>
                        </tr>
                    </table>

                </div>

            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Spotřebiče
                        <BBadge
                            variant="danger"
                            class="ml-1"
                            v-if="supplyPoint.appliances && supplyPoint.appliances.length > 0"
                        >
                            {{ supplyPoint.appliances.length }}
                        </BBadge>
                    </div>
                </template>
                <Appliances :records="supplyPoint.appliances"/>
            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Kontakty
                        <BBadge
                            variant="danger"
                            class="ml-1"
                            v-if="(supplyPoint.customerContacts ? supplyPoint.customerContacts.length : 0) + (supplyPoint.propertyOwnerContacts ? supplyPoint.propertyOwnerContacts.length : 0) > 0"
                        >
                            {{
                                (supplyPoint.customerContacts ? supplyPoint.customerContacts.length : 0) + (supplyPoint.propertyOwnerContacts ? supplyPoint.propertyOwnerContacts.length : 0)
                            }}
                        </BBadge>
                    </div>
                </template>

                <div v-if="supplyPoint.customerContacts && supplyPoint.customerContacts.length !== 0">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <h5 class="h5 font-weight-900 mb-0">Zákazník</h5>
                    </div>
                    <Contacts :records="supplyPoint.customerContacts"/>
                </div>

                <div v-if="supplyPoint.propertyOwnerContacts && supplyPoint.propertyOwnerContacts.length !== 0">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <h5 class="h5 font-weight-900 mb-0">Vlastník nemovitosti</h5>
                    </div>
                    <Contacts :records="supplyPoint.propertyOwnerContacts"/>
                </div>
            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Zakázky
                        <BBadge
                            variant="danger"
                            class="ml-1"
                            v-if="supplyPoint.orders && supplyPoint.orders.length > 0"
                        >
                            {{ supplyPoint.orders.length }}
                        </BBadge>
                    </div>
                </template>
                <Orders :records="supplyPoint.orders"/>
            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Poznámky
                        <BBadge
                            variant="danger"
                            class="ml-1"
                            v-if="supplyPoint.technicalNotes && supplyPoint.technicalNotes.length > 0"
                        >
                            {{ supplyPoint.technicalNotes.length }}
                        </BBadge>
                    </div>
                </template>
                <Notes :records="supplyPoint.technicalNotes"/>
            </BTab>
        </BTabs>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Appliances from "./Component/Appliances.vue"
import Contacts from "./Component/Contacts.vue"
import Notes from "./Component/Notes.vue"
import Orders from "./Component/Orders.vue"
import PrimaryButton from "../../Common/UI/Button/PrimaryButton.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"
import ServiceContractLevel from "../../../Model/Enum/service_contract_level"

export default {

    components: {
        Appliances,
        Contacts,
        Notes,
        Orders,
        PrimaryButton,
        PrimaryLinkButton,
    },

    data() {
        return {
            serviceContractLevel: ServiceContractLevel,
        }
    },

    computed: {
        ...mapGetters({
            supplyPoint: "worker/supplyPoint/detail/getRecord",
        }),
    },

    methods: {

        formatBreakLines(text) {
            return text.replace(/\n/g, "<br />")
        },

    },

}
</script>
