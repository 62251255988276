<template>
    <tr class="border-top">
        <template v-if="contact._action !== null">
            <td/>
            <td class="align-middle">
                <BFormInput
                    v-model="request.name"
                    :disabled="isProcessing"
                    type="text"
                    size="sm"
                    placeholder="Název kontaktu"
                    required
                />
            </td>
            <td class="align-middle">
                <BFormInput
                    v-model="request.phone"
                    :disabled="isProcessing"
                    type="text"
                    size="sm"
                    placeholder="Telefonní číslo"
                    required
                />
            </td>
            <td class="align-middle px-4">
                <BFormInput
                    v-model="request.email"
                    :disabled="isProcessing"
                    type="email"
                    size="sm"
                    placeholder="E-mailová adresa"
                />
            </td>
            <td class="align-middle text-right w-10">
                <BLink variant="danger" @click="cancelRow()">
                    <i class="fa fa-times fa-fw"/>
                </BLink>
                <BLink
                    :disabled="isProcessing"
                    @click="saveRow()"
                    variant="success"
                    class="ml-1"
                >
                    <i class="fad fa-spinner-third fa-spin fa-fw" v-if="isProcessing"/>
                    <i class="fad fa-check fa-fw" v-else/>
                </BLink>
            </td>
        </template>
        <template v-else>
            <td class="p-2 w-5 align-middle pointer"
                v-bind:class="{'bg-little-gray' : selected === contact.id}"
                @click="contactSelected(contact.id)"
                :id="'contact_' + contact.id"
            >
                <i class="fad fa-circle fa-fw"
                   v-bind:class="selected === contact.id ? 'text-success' : 'text-danger'"/>
            </td>
            <td class="align-middle" v-bind:class="{'bg-little-gray' : selected === contact.id}">
                <span v-if="contact.name">{{ contact.name }}</span>
                <em v-else>nevyplněno</em>
            </td>
            <td class="align-middle" v-bind:class="{'bg-little-gray' : selected === contact.id}">
                {{ contact.phone }}
            </td>
            <td class="align-middle" v-bind:class="{'bg-little-gray' : selected === contact.id}">
                {{ contact.email }}
            </td>
            <td class="align-middle text-right w-10" v-bind:class="{'bg-little-gray' : selected === contact.id}">
                <BLink variant="primary" @click="openEditFormMode()" class="ml-3">
                    <i class="fad fa-edit fa-fw"/>
                </BLink>
            </td>
        </template>
    </tr>
</template>

<script>
import {mapGetters} from "vuex"
import SubjectContactBasicEntity from "../../../../NewModel/Subject/Entity/SubjectContactBasicEntity"
import SubjectContactRequest from "../../../../NewModel/Subject/Request/SubjectContactRequest"

export default {

    props: {
        subjectId: {
            type: String,
            required: true,
        },
        contact: {
            type: SubjectContactBasicEntity,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        selected: {
            default: null,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isProcessing: "subject/contacts/isProcessing",
        }),
    },

    data() {
        return {
            request: new SubjectContactRequest,
        }
    },

    mounted() {
        if (this.contact._action === "create") {
            this.openCreateFormMode()
        }
    },

    methods: {

        contactSelected(contact) {
            this.$emit("update:selected", contact)
        },

        openFormMode(mode) {
            this.contact._action = mode
        },

        openCreateFormMode() {
            this.openFormMode("create")
        },

        openEditFormMode() {
            this.openFormMode("edit")
            this.fillFormFromEntity(this.contact)
        },

        fillFormFromEntity(entity) {
            this.request = SubjectContactRequest.from(entity)
        },

        cancelRow() {
            if (this.contact._action === "edit") {
                this.fillFormFromEntity(this.contact)
                this.contact._action = null
            } else {
                this.$emit("cancelled", {
                    subjectId: this.subjectId,
                    index: this.index
                })
            }
        },

        saveRow() {
            try {
                this.checkForm()
                if (this.contact._action === "edit") {
                    this.$store.dispatch("subject/contacts/updateSubjectContact", {
                        subject: this.subjectId,
                        id: this.contact.id,
                        request: this.request
                    })
                        .then((response) => {
                            this.contact.action = null
                            this.$emit("updated", {
                                subjectId: this.subjectId,
                                index: this.index,
                                entity: response
                            })
                        })
                } else if (this.contact._action === "create") {
                    this.$store.dispatch("subject/contacts/createSubjectContact", {
                        id: this.subjectId,
                        request: this.request
                    })
                        .then((response) => {
                            this.contact.action = null
                            this.$emit("created", {
                                subjectId: this.subjectId,
                                index: this.index,
                                entity: response
                            })
                        })
                }

            } catch (err) {
                this.warningMessage(err)
                return false
            }
        },

        checkForm() {
            if (this.request.name === "") {
                throw "Vyplňte jméno!"
            }
            if (this.request.phone === "") {
                throw "Vyplňte telefonní číslo!"
            }
        },

    },

}
</script>
