export default class OrderFilterEntity {

    constructor() {
        this.id = null
        this.orderNumber = ''
        this.supplyPoint = ''
        this.customer = ''
        this.address = ''
        this.worker = ''
        this.scheduledStartTime = null
        this.status = 0
    }

    /**
     * @param response : object
     * @returns {OrderFilterEntity}
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.orderNumber = response.orderNumber
        obj.supplyPoint = response.supplyPoint
        obj.customer = response.customer
        obj.address = response.address
        obj.worker = response.worker
        obj.scheduledStartTime = response.scheduledStartTime
        obj.status = response.status
        return obj
    }

}
