import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const AttachmentService = {

    /**
     * @param id : string
     * @returns {Promise<any>}
     */
    getAttachment(id) {
        return httpClient.get("v3/attachments/" + id, {
            headers: authHeader()
        })
    },
    /**
     * @param id : string
     * @returns {Promise<any>}
     */
    downloadAttachment(id) {
        return httpClient.get("v3/attachments/" + id + "/download", {
            responseType: "blob",
            headers: authHeader()
        })
    },

    /**
     * @param formData
     * @returns {Promise<any>}
     */
    uploadAttachments(formData) {
        return httpClient.post("v3/attachments", formData, {
            headers: {
                ...{
                    "Content-Type": "multipart/form-data"
                }, ...authHeader(),
            }
        })
    },

}

export default AttachmentService
