<template>
    <div>
        <b-form-file
            class="mb-3"
            browse-text="Najít"
            id="attachments"
            placeholder="Vyberte soubory v počítači nebo je přetáhněte do tohoto políčka."
            drop-placeholder="Zde je pusťte"
            :multiple="isMultiple" @input="addFiles"
            ref="manualFiles"
        />

        <div class="p-3 d-flex align-items-center border-radius-sm table-warning mb-3" v-if="fileUploadIsLoading">
            <i class="fad fa-spinner fa-fw fa-2x fa-spin text-danger mr-3"></i>
            Nahrávám dokumenty na server ...
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    props: {
        isMultiple: {
            default: true,
            type: Boolean,
            required: false
        }
    },

    computed: {
        ...mapGetters({
            fileUploadIsLoading: 'attachment/isUploading',
        }),
    },

    methods: {

        addFiles(files) {

            let upload = []
            if (files instanceof File) {
                upload.push(files)
            } else {
                if (files.length === 0) {
                    return
                }
                upload = upload.concat(files)
            }

            this.$store.dispatch("attachment/uploadAttachments", upload)
                .then((response) => {
                        if (response.length === upload.length) {
                            this.successMessage('Soubory byly úspěšně uloženy na server!')
                        } else if (response.length > 0) {
                            this.warningMessage('Ne všechny soubory byly nahrány na server')
                        } else if (response.length === 0) {
                            this.errorMessage('Soubor se nepodařilo nahrát na server.')
                        }

                        this.$emit('uploaded', response)

                    },
                    error => {
                        this.errorMessage(error.response.data.message)
                    }
                )
        }

    }
}
</script>
