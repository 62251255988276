export default class SupplyPointMetaLastNoteEntity {

    constructor() {
        this.date = ''
        this.message = ''
    }

    /**
     * @param response : object
     * @returns SupplyPointMetaLastNoteEntity
     */
    static from(response) {

        let obj = new this
        obj.date = response.date
        obj.message = response.message
        return obj
    }

}
