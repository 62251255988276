<template>
    <div>

        <Header icon="fad fa-space-shuttle" title="Modely spotřebičů">
            <template #buttons>
                <PrimaryLinkButton
                    to="/setup/boilers/models/create"
                    icon="fad fa-plus-circle"
                    text="Přidat model spotřebiče"
                />
            </template>
        </Header>

        <BoilerModelsList/>

    </div>
</template>

<script>
import BoilerModelsList from "./Component/ApplianceModelsList.vue"
import Header from "../../Common/UI/Header.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        BoilerModelsList,
        Header,
        PrimaryLinkButton,
    },

}
</script>
