export default class OrderMaterialPickupRequest {

    constructor() {
        this.eanCode = ""
        this.itemName = ""
        this.specification = ""
        this.quantity = 1
        this.discount = 0
        this.attachments = []
    }

    toForm() {
        return this
    }

}
