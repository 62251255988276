<template>
    <div>
        <form ref="form" @submit.prevent="processForm()">

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="name" class="bold col-form-label">
                        Výrobce spotřebiče: <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <div class="alert alert-info p-4" v-if="manufacturers_isLoading">
                        <i class="fa fa-spinner fa-spin mr-4"/>Nahrávám výrobce spotřebičů ...
                    </div>
                    <BFormSelect
                        v-model="request.manufacturer"
                        :options="manufacturers_records"
                        v-if="manufacturers_hasRecords"
                        value-field="id"
                        text-field="name"
                        required
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="name" class="bold col-form-label">
                        Model spotřebiče: <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormInput
                        autocomplete="off"
                        v-model="request.name"
                        id="name"
                        type="text"
                        required
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="description" class="bold col-form-label">
                        Popis:
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormTextarea
                        v-model="request.description"
                        id="description"
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12"/>
                <BCol lg="9" md="12">
                    <BFormCheckbox v-model="request.active">Aktivní</BFormCheckbox>
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="attachments" class="bold col-form-label">Dokumenty:</label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormFile
                        @input="addFiles"
                        ref="manualFiles"
                        id="attachments"
                        browse-text="Najít"
                        placeholder="Vyberte soubory v počítači nebo je přetáhněte do tohoto políčka."
                        drop-placeholder="Zde je pusťte"
                        multiple
                        class="mb-3"
                    />

                    <div
                        class="p-3 d-flex align-items-center border-radius-sm table-warning mb-3"
                        v-if="fileUploadIsLoading"
                    >
                        <i class="fad fa-spinner fa-fw fa-2x fa-spin text-danger mr-3"/>
                        Nahrávám dokumenty na server ...
                    </div>

                    <table class="table table-borderless mb-0">
                        <thead>
                        <tr>
                            <th colspan="2" class="bg-light">
                                Dokumentace
                            </th>
                        </tr>
                        </thead>
                        <tbody v-if="request.attachments.length > 0">
                        <tr
                            v-for="(attachment, index) in request.attachments" v-bind:key="'attachment_' + index"
                            class="border-top"
                        >
                            <td class="align-middle">
                                <AttachmentDownloadLink
                                    :id="attachment.id"
                                    :name="attachment.name"
                                    :text="attachment.name"
                                />
                            </td>
                            <td class="align-middle text-right w-5">
                                <a
                                    href=""
                                    class="btn btn-danger btn-sm"
                                    v-on:click.prevent="removeFile(attachment.id, index)"
                                >
                                    <i class="fad fa-minus"/>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-if="request.attachments.length === 0">
                        <tr>
                            <td colspan="2">
                                <TableWithoutRecords>Nejsou k dispozici žádné přílohy</TableWithoutRecords>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </BCol>
            </BRow>

            <BRow>
                <BCol lg="3" md="12"/>
                <BCol lg="9" md="12">
                    <BButton type="submit" variant="primary" :disabled="isProcessing">
                        <i class="fad fa-save mr-3"/>Uložit
                    </BButton>
                </BCol>
            </BRow>

        </form>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceModelEntity from "../../../../NewModel/Appliance/Model/Entity/ApplianceModelEntity"
import ApplianceModelRequest from "../../../../NewModel/Appliance/Model/Request/ApplianceModelRequest"
import AttachmentDownloadLink from "../../../../Module/Common/Attachment/AttachmentDownloadLink.vue"
import AttachmentRequest from "../../../../NewModel/Attachment/Request/AttachmentRequest"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
        AttachmentDownloadLink,
    },

    props: {
        entity: {
            type: ApplianceModelEntity,
            required: false,
            default: null,
        },
        request: {
            type: ApplianceModelRequest,
            required: true,
        },
        isProcessing: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            manufacturers_isLoading: 'appliance/manufacturers/isLoading',
            manufacturers_hasRecords: 'appliance/manufacturers/hasManufacturers',
            manufacturers_records: 'appliance/manufacturers/getManufacturers',
            attachments_isLoading: 'appliance/models/attachments/isLoading',
            attachments_haveRecords: 'appliance/models/attachments/hasAttachments',
            attachments_records: 'appliance/models/attachments/getAttachments',
            fileUploadIsLoading: 'attachment/isUploading',
        }),
    },

    created() {
        this.loadBoilerManufacturers()
        if (this.entity) {
            this.loadAttachments()
        }
    },

    methods: {

        loadBoilerManufacturers() {
            this.$store.dispatch("appliance/manufacturers/findAllActiveManufacturers")
        },

        loadAttachments() {
            let self = this
            self.request.attachments = []
            this.$store.dispatch("appliance/models/attachments/getAttachments", this.entity.id)
                .then((records) => {
                    self.request.attachments = records.map(o => new AttachmentRequest(o.id, o.name))
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        removeFile(id, index) {
            this.request.attachments.splice(index, 1)
        },

        addFiles(files) {
            if (files.length === 0) {
                return
            }
            let self = this
            this.$store.dispatch("attachment/uploadAttachments", files)
                .then((records) => {
                    if (records.length === files.length) {
                        this.successMessage('Soubory byly úspěšně uloženy na server. Nezapomeňte spotřebič uložit!')
                    } else if (records.length > 0) {
                        this.warningMessage('Ne všechny soubory byly nahrány na server.')
                    } else if (records.length === 0) {
                        this.errorMessage('Soubor se nepodařilo nahrát na server.')
                    }
                    self.request.attachments = [...self.request.attachments, ...records.map((o) => new AttachmentRequest(o.id, o.name))]
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        processForm() {
            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Selhala validace. Byla všechna požadovaná pole vyplněna?')
                return
            }
            this.$emit('submitted', this.request)
        },

    },

}
</script>
