<template>
    <div>

        <Header title="Incidenty k vyřešení">
            <template #buttons>
                <RouterLink :to="{name: 'mobile_incidents_create'}">
                    <i class="fad fa-plus-circle fa-fw fa-2x"/>
                </RouterLink>
            </template>
        </Header>

        <TableLoading v-if="isLoading && !hasRecords" class="mb-4"/>
        <TableWithoutRecords v-else-if="!isLoading && !hasRecords" class="mb-4"/>

        <div v-else>
            <Incident
                v-for="record in records"
                :record="record"
                :key="'incident_' + record.id"
                @updated="incidentUpdated"
            />
        </div>

        <small>
            <i class="fad fa-info-circle text-info fa-fw mr-2"/>
            Zobrazujeme incidenty nahlášeny Vámi, které jsou nedořešené nebo vyřešené v posledních 30 dnech.
        </small>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Incident from "./Component/Incident.vue"
import Header from "../../Module/Common/UI/Header.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        Header,
        Incident,
        TableLoading,
        TableWithoutRecords,
    },

    data() {
        return {
            date: null,
            dateFormat: null,
            todaySelected: false,
            user: null,
            numberOfOrders: 0,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: "incident/my/isLoading",
            hasRecords: "incident/my/hasIncidents",
            records: "incident/my/getIncidents",
        }),
    },

    mounted() {
        this.loadData()
    },

    methods: {

        loadData() {
            this.$store.dispatch("incident/my/clearAll")
            this.$store.dispatch("incident/my/getMyIncidents")
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        incidentUpdated() {
            this.loadData()
        },

    },

}
</script>


