import OrderService from "../OrderService"

const
    UPDATING_RECORD = "UPDATING_RECORD",
    UPDATING_RECORD_SUCCESS = "UPDATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "UPDATING_RECORD_ERROR"

const initialState = {
    isUpdating: false,
    error: null,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param id : string
     * @param attachments : Array
     * @returns {Promise<*>}
     */
    async createAttachments({commit}, {id, attachments}) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.createAttachments(id, attachments)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param attachment : string
     * @returns {Promise<*>}
     */
    async assigningJobSheet({commit}, {id, attachment}) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.assigningJobSheet(id, attachment)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param attachment : string
     * @returns {Promise<*>}
     */
    async deleteAttachment({commit}, {id, attachment}) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.deleteAttachment(id, attachment)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param request : OrderAttachmentsRequest
     * @returns {Promise<*>}
     */
    async updateAttachments({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.updateAttachments(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

}

export const mutations = {

    [UPDATING_RECORD](state) {
        state.isUpdating = true
        state.error = null
    },
    [UPDATING_RECORD_SUCCESS](state) {
        state.isUpdating = false
        state.error = null
    },
    [UPDATING_RECORD_ERROR](state, error) {
        state.isUpdating = false
        state.error = error
    },

}

export const getters = {

    isUpdating(state) {
        return state.isUpdating
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
