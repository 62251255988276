export default class ApplianceFilterEntity {

    constructor() {
        this.id = null
        this.name = ''
        this.applianceType = ''
        this.address = ''
        this.serialNumber = ''
    }

    /**
     * @param response : object
     * @returns ApplianceFilterEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.applianceType = response.applianceType
        obj.address = response.address
        obj.serialNumber = response.serialNumber
        return obj
    }

}
