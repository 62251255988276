<template>
    <div>
        <div class="bg-little-gray p-4" v-if="records.length > 0">
            <table class="table table-fit table-borderless mb-0">
                <thead>
                <tr>
                    <th class="text-left align-middle w-10">
                        Datum
                    </th>
                    <th class="text-center align-middle w-10">
                        Autor
                    </th>
                    <th class="align-middle w-30">
                        Spotřebič
                    </th>
                    <th class="align-middle text-left">
                        Zpráva
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(record, recordIndex) in records" :key="recordIndex" class="border-top">
                    <td class="align-middle text-left">
                        {{ record.createdAt|prettyDate }}
                    </td>
                    <td class="align-middle text-center">
                        <BAvatar
                            size="md"
                            :src="record.createdBy.picture || null"
                            :text="record.createdBy.acronym"
                            v-if="record.createdBy"
                        />
                        <BAvatar size="md" variant="light" v-else/>
                    </td>
                    <td class="align-middle">
                        <span v-if="record.appliance">
                            #{{ String(record.appliance.id).slice(-5) }} / {{ record.appliance.fullName|shortify }}
                        </span>
                    </td>
                    <td class="align-middle text-left">
                        {{ record.message }}
                    </td>
                </tr>
                </tbody>
            </table>

        </div>

        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        records: {
            required: true,
        },
    },

}
</script>
