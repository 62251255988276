<template>
    <div>

        <BButton v-b-modal.add-note-modal variant="light" size="sm">
            <i class="fal fa-file-plus fa-fw mr-2"/>Přidat poznámku
        </BButton>

        <BModal id="add-note-modal" title="Nová poznámka" @ok.prevent="processForm">

            <BForm ref="form">
                <BFormGroup
                    id="input-group-type"
                    label-for="input-type"
                    label-class="font-weight-600"
                >
                    <template v-slot:label>
                        Umístění poznámky: <span class="text-danger ml-1">*</span>
                    </template>
                    <BFormSelect v-model="request.type" id="input-type" class="border-radius-sm">
                        <BFormSelectOption :value="1">Komunikace</BFormSelectOption>
                        <BFormSelectOption :value="2">Technická poznámka</BFormSelectOption>
                    </BFormSelect>
                </BFormGroup>

                <BFormGroup
                    id="input-group-message"
                    label-for="input-message"
                    label-class="font-weight-600"
                    class="mb-0"
                >
                    <template v-slot:label>
                        Obsah zprávy: <span class="text-danger ml-1">*</span>
                    </template>
                    <BFormTextarea id="input-message" v-model="request.message" rows="5"/>
                </BFormGroup>

            </BForm>

            <template #modal-footer="{ ok }">
                <BButton type="submit" variant="primary" block :disabled="isCreating" @click="ok()">
                    <i class="fad fa-paper-plane fa-fw mr-3"/>Uložit poznámku
                </BButton>
            </template>

        </BModal>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import SupplyPointNoteRequest from "../../../NewModel/SupplyPoint/Request/SupplyPointNoteRequest"

export default {

    props: {
        supplyPoint: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            request: new SupplyPointNoteRequest(),
        }
    },

    computed: {
        ...mapGetters({
            isCreating: 'supplyPoint/notes/isCreating',
        })
    },

    methods: {

        processForm() {

            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Selhala validace. Byly všechny požadované pole vyplněny?')
                return false
            }

            let self = this
            this.$store.dispatch("supplyPoint/notes/createNote", {
                id: this.supplyPoint,
                request: this.request
            })
                .then((createdNote) => {
                    self.$emit('onCreate', createdNote)
                    self.request = new SupplyPointNoteRequest()

                    self.$eventBus.$emit('supply-point-meta-updated', 1)
                    self.$nextTick(() => {
                        this.$bvModal.hide('add-note-modal')
                        this.successMessage('Poznámka byla vytvořena')
                    })
                })
        },

    },

}
</script>
