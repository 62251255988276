<template>
    <div>

        <TableWithoutRecords v-if="order.appliances.length === 0" />

        <div
            class="bg-little-gray mb-3 p-3 border-radius-sm"
            v-for="appliance in order.appliances" :key="appliance.id"
            :to="{name: 'new_mobile_order_appliance_show', params: { 'appliance_id': appliance.appliance }}"
        >

            <div class="mb-3">
                <div class="mb-2">
                    <BLink
                        :to="{name: 'new_mobile_order_appliance_show', params: { 'appliance_id': appliance.appliance }}"
                        class="h6 font-weight-700 text-danger"
                    >
                        #{{ String(appliance.appliance).slice(-5) }} / {{ appliance.name }}
                    </BLink>
                </div>
                <div v-if="appliance.applianceType">
                    <span class="font-weight-900">Druh</span>: {{ appliance.applianceType }}
                </div>
                <div v-if="appliance.serialNumber">
                    <span class="font-weight-900">Sér. číslo</span>: {{ appliance.serialNumber }}
                </div>
            </div>

            <div v-for="repair in appliance.repairs" v-bind:key="repair.id">
                <div class="d-flex justify-content-between align-items-center border-top py-2">
                    <div>
                        <i class="fal fa-chevron-right mr-2"/>{{ repair.repairTypeName }}
                        <span style="font-style: italic;" v-if="repair.worker"><br/>({{ repair.workerName }})</span>
                    </div>
                    <div>
                        <span class="font-weight-700">{{ repair.paid ? 'Placená' : 'Zdarma' }}</span>
                    </div>
                    <div v-if="repair.discount">
                        <span class="font-weight-600 text-danger">
                            SLEVA: <strong class="font-weight-900">{{ repair.discount }}%</strong>
                        </span>
                    </div>
                </div>
                <div v-if="repair.comment">
                    <span class="font-weight-900">Komentář: </span>{{ repair.comment }}
                </div>
            </div>

            <TableWithoutRecords v-if="appliance.repairs.length === 0" />

        </div>

    </div>
</template>

<script>
import OrderMobileEntity from "../../../NewModel/Order/Entity/OrderMobileEntity"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        order: {
            type: OrderMobileEntity,
            required: true,
        },
    },

}
</script>
