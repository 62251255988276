import AttachmentEntity from "../../Attachment/Entity/AttachmentEntity"

export default class ApplianceRepairEntity {

    constructor() {
        this.id = null
        this.dateOfRepair = null
        this.order = null
        this.orderNumber = ''
        this.repairType = ''
        this.worker = ''
        this.description = ''
        this.attachments = []
    }

    /**
     * @param response : object
     * @returns ApplianceEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.dateOfRepair = response.dateOfRepair
        obj.order = response.order
        obj.orderNumber = response.orderNumber
        obj.repairType = response.repairType
        obj.worker = response.worker
        obj.description = response.description
        obj.attachments = response.attachments.map(
            attachment => {
                return AttachmentEntity.from(attachment)
            })
        return obj
    }

}
