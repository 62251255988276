<template>
    <div>
        <form ref="form" @submit.prevent="processForm()">

            <BRow class="mb-3">
                <BCol lg="3" md="12">
                    <label for="name" class="bold col-form-label">
                        Název výrobce: <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormInput
                        v-model="request.name"
                        id="name"
                        type="text"
                        autocomplete="off"
                        required
                    />
                </BCol>
            </BRow>

            <BRow class="mb-3">
                <BCol lg="3" md="12"/>
                <BCol lg="9" md="12">
                    <BFormCheckbox v-model="request.active">
                        Aktivní
                    </BFormCheckbox>
                </BCol>
            </BRow>

            <BRow>
                <BCol lg="3" md="12"/>
                <BCol lg="9" md="12">
                    <BButton type="submit" variant="primary" :disabled="isProcessing">
                        <i class="fad fa-save mr-3"/>Uložit
                    </BButton>
                </BCol>
            </BRow>

        </form>
    </div>
</template>

<script>
import ApplianceManufacturerRequest
    from "../../../../NewModel/Appliance/Manufacturer/Request/ApplianceManufacturerRequest"

export default {

    props: {
        request: {
            type: ApplianceManufacturerRequest,
            required: true,
        },
        isProcessing: {
            type: Boolean,
            required: true,
        },
    },

    methods: {

        processForm() {
            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Selhala validace. Byla všechna požadovaná pole vyplněna?')
                return
            }
            this.$emit('submitted', this.request)
        },

    },

}
</script>
