import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const IncidentService = {

    /**
     * @returns {Promise<any>}
     */
    getMyIncidents() {
        return httpClient.get("v3/incidents/my", {headers: authHeader()})
    },

    /**
     * @returns {Promise<any>}
     */
    getOverviewOfIncidents() {
        return httpClient.get("v3/incidents/overview", {headers: authHeader()})
    },

    /**
     * @param request : IncidentRequest
     * @returns {Promise<any>}
     */
    createIncident(request) {
        return httpClient.post("v3/incidents", request.toForm(), {headers: authHeader()})
    },

    /**
     * @param id : int
     * @returns {Promise<any>}
     */
    resolveIncident(id) {
        return httpClient.put("v3/incidents/" + id + "/resolve", {}, {headers: authHeader()})
    },

}

export default IncidentService
