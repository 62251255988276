<template>
    <div class="container">

        <Header icon="fad fa-plus-circle" title="Vytvořit uživatele">
            <template #buttons>
                <BackLinkButton to="/setup/users"/>
            </template>
        </Header>

        <UserForm
            :request="request"
            :is-processing="isCreating"
            v-on:submitted="onSubmitted"
        />

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../../Common/UI/Button/BackLinkButton.vue"
import Header from "../../Common/UI/Header.vue"
import UserForm from "./Component/UserForm.vue"
import UserRequest from "../../../NewModel/User/Request/UserRequest"

export default {

    components: {
        BackLinkButton,
        Header,
        UserForm,
    },

    data() {
        return {
            request: new UserRequest(),
        }
    },

    computed: {
        ...mapGetters({
            isCreating: 'user/isCreating',
        }),
    },

    created() {
        this.$store.dispatch("user/clear")
    },

    methods: {

        /**
         * @param request : UserRequest
         */
        onSubmitted(request) {
            this.$store.dispatch("user/createUser", request)
                .then(() => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'setup_user_list'})
                })
                .catch((err) => {
                    this.warningMessage(err.response.message)
                })
        },

    },

}
</script>
