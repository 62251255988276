export default class PlanningApplianceRepairEntity {

    constructor() {
        this.id = null
        this.repairType = ''
        this.plannedDateOfRepair = null
        this.dateOfLastRepair = null
        this.isBenefit = false
        this.isPaid = false
        this.isBlocked = false
    }

    /**
     * @param response : object
     * @returns PlanningApplianceRepairEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.repairType = response.repairType || ''
        obj.plannedDateOfRepair = response.plannedDateOfRepair
        obj.dateOfLastRepair = response.dateOfLastRepair
        obj.isBenefit = response.isBenefit
        obj.isPaid = response.isPaid
        obj.isBlocked = response.isBlocked

        return obj
    }

}
