<template>
    <BModal
        id="modal-add-technical-note-supply-point"
        header-bg-variant="primary"
        header-text-variant="white"
        footer-bg-variant="light"
        footer-text-variant="dark"
        size="xl"
        body-class="p-4"
        no-stacking
    >
        <template #modal-header>
            <div class="p-3">
                <h4 class="display-4 mb-0">
                    <i class="fa-light fa-house fa-fw mr-3"/> Přidat technickou poznámku k odběrnému místu
                </h4>
            </div>
        </template>
        <BOverlay :show="isCreating">
            <BForm ref="addTechnicalNoteSupplyPointForm" @submit.prevent="handleSubmit">
                <BFormGroup
                    id="input-group-message"
                    label-for="input-message"
                    label-class="font-weight-600"
                >
                    <template v-slot:label>
                        Obsah zprávy: <span class="text-danger ml-1">*</span>
                    </template>
                    <BFormTextarea
                        id="input-message"
                        v-model="message"
                        rows="5"
                        placeholder="Prosím, buďte struční a výstižní."
                        required
                    />
                </BFormGroup>


            </BForm>
        </BOverlay>
        <template #modal-footer>
            <BButton type="submit" variant="secondary" :disabled="isCreating" @click="handleSubmit">
                <i class="fa-light fa-floppy-disk fa-fw mr-3"/>Uložit poznámku
            </BButton>
        </template>
    </BModal>
</template>
<script>
import {mapGetters} from "vuex"

export default {

    props: {
        supplyPoint: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isCreating: "worker/supplyPoint/note/isCreating",
        }),
    },

    data() {
        return {
            message: "",
        }
    },

    methods: {

        checkFormValidity() {
            return this.$refs.addTechnicalNoteSupplyPointForm.checkValidity()
        },

        handleSubmit() {
            let self = this
            this.$store.dispatch("worker/supplyPoint/note/addTechnicalNote", {
                id: this.supplyPoint,
                formData: {
                    message: this.message
                },
            })
                .then((record) => {
                    self.$emit("created", record)
                    this.successMessage("Poznámka uložena", "Uloženo")
                    this.$root.$emit("bv::hide::modal", "modal-add-technical-note-supply-point")
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
