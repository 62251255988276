<template>
    <div>
        <div class="d-flex justify-content-between mb-4">
            <div class="w-30">
                <img src="../../../../Assets/lemigas_logo.png" alt="logo" class="img-fluid"/>
            </div>
            <div class="text-right small">
                <div class="text-primary font-weight-900">LEMIGAS s.r.o., 363 01 Jenišov 68</div>
                <div>Telefon: +420 602 100 165</div>
                <div>E-mail: servis@lemigas.cz</div>
                <div>Web: www.lemigas.cz</div>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-4 p-3" style="background-color: #dedede">
            <h3 class="h3 font-weight-900 mb-0">MONTÁŽNÍ LIST</h3>
            <div class="text-right">
                <div class="font-weight-900">Číslo zakázky:</div>
                <div>{{ assemblySheet.assemblySheetId }}</div>
            </div>
        </div>

        <div class="bg-light py-2 px-3">
            <strong>Objednavatel:</strong>
        </div>
        <div class="d-flex justify-content-between py-2 px-3 mb-4">
            <div class="w-70">
                <div>
                    <span>{{ assemblySheet.customer.name }}</span>
                </div>
                <div>
                    <span>{{ assemblySheet.customer.address }}</span>
                </div>
            </div>
            <div class="w-30">
                <div v-if="assemblySheet.customer.identificationNumber">
                    <strong>IČO: </strong>
                    <span>{{ assemblySheet.customer.identificationNumber }}</span>
                </div>
                <div v-if="assemblySheet.customer.vatNumber">
                    <strong>DIČ: </strong>
                    <span>{{ assemblySheet.customer.vatNumber }}</span>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between mb-4">
            <div class="w-50">
                <div class="bg-light py-2 px-3">
                    <strong>Pracoviště:</strong>
                </div>
                <div class="py-2 px-3">
                    <span>{{ assemblySheet.address.name }}</span>
                </div>
            </div>
            <div class="w-50">
                <div class="bg-light py-2 px-3">
                    <strong>Realizace:</strong>
                </div>
                <div class="py-2 px-3">
                    <div>
                        <strong>Zahájení: </strong>
                        <span>{{ assemblySheet.scheduledStartTime|prettyDateTime }}</span>
                    </div>
                    <div>
                        <strong>Ukončení: </strong>
                        <span>{{ assemblySheet.scheduledEndTime|prettyDateTime }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AssemblySheetEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetEntity"

export default {

    props: {
        assemblySheet: {
            type: AssemblySheetEntity,
            required: true,
        },
    },

}
</script>
