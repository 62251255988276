<template>
    <div>
        <div class="py-2 px-3 mb-3 text-white" style="background-color: #2a7dc4;">
            <strong>Servisní úkony na spotřebičích:</strong>
        </div>

        <div v-for="(appliance, applianceIndex) in assemblySheet.appliances" :key="appliance.id" class="mb-5">

            <div class="py-2 px-3 mb-2 d-flex justify-content-between align-items-center">

                <div class="w-50">
                    <strong class="font-weight-900 text-primary">
                        #{{ applianceIndex + 1 }} / {{ appliance.appliance.name }}
                    </strong>
                </div>

                <div class="w-50 text-right">
                    Výrobní číslo: {{ appliance.hasSerialNumber ? appliance.serialNumber : "Není uvedeno" }}
                </div>

            </div>

            <div class="py-2 px-3"
                 v-if="appliance.repairs.length === 0 && assemblySheet.getApplianceMaterials(appliance.id).length === 0">
                <em>Žádné provedené práce ani vydaný materiál</em>
            </div>

            <div v-if="appliance.repairs.length > 0" class="mb-3 px-3">
                <table class="table table-sm table-borderless">
                    <tbody>
                    <tr>
                        <th colspan="6" class="text-left bg-light">
                            <div>Provedená práce</div>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-left">
                            Označení
                        </th>
                        <th class="text-center w-10">
                            Počet m.j.
                        </th>
                        <th class="text-right w-15">
                            Cena / m.j.
                        </th>
                        <th class="text-right w-5">
                            Sleva
                        </th>
                        <th class="text-right w-15">
                            Cena po slevě
                        </th>
                        <th class="text-right w-15">
                            Celkem
                        </th>
                    </tr>
                    <tr v-for="repair in appliance.repairs" :key="repair.id" class="border-top border-light">
                        <td>
                            {{ repair.itemName }}
                        </td>
                        <td class="text-center">
                            {{ repair.quantity }}
                        </td>
                        <td class="text-right">
                            {{ repair.pricePerItem|moneyFormat("CZK_LONG") }}
                        </td>
                        <td class="text-right">
                            {{ repair.discount }} %
                        </td>
                        <td class="text-right">
                            {{ repair.pricePerItemWithDiscount|moneyFormat("CZK_LONG") }}
                        </td>
                        <td class="text-right">
                            {{ repair.totalAmount|moneyFormat("CZK_LONG") }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="assemblySheet.getApplianceMaterials(appliance.id).length > 0" class="mb-3 px-3">
                <table class="table table-sm">
                    <tbody>
                    <tr class="bg-light">
                        <th colspan="6" class="text-left">
                            Materiál
                        </th>
                    </tr>
                    <tr>
                        <th class="text-left">
                            Označení
                        </th>
                        <th class="text-center w-10">
                            Počet m.j.
                        </th>
                        <th class="text-right w-15">
                            Cena / m.j.
                        </th>
                        <th class="text-right w-5">
                            Sleva
                        </th>
                        <th class="text-right w-15">
                            Cena po slevě
                        </th>
                        <th class="text-right w-15">
                            Celkem
                        </th>
                    </tr>
                    <tr v-for="material in assemblySheet.getApplianceMaterials(appliance.id)" :key="material.id">
                        <td>
                            {{ material.itemName }}
                        </td>
                        <td class="text-center">
                            {{ material.quantity }}
                        </td>
                        <td class="text-right">
                            {{ material.pricePerItem|moneyFormat("CZK_LONG") }}
                        </td>
                        <td class="text-right">
                            {{ material.discount }} %
                        </td>
                        <td class="text-right">
                            {{ material.pricePerItemWithDiscount|moneyFormat("CZK_LONG") }}
                        </td>
                        <td class="text-right">
                            {{ material.totalAmount|moneyFormat("CZK_LONG") }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import AssemblySheetEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetEntity"

export default {

    props: {
        assemblySheet: {
            type: AssemblySheetEntity,
            required: true,
        },
    },

}
</script>
