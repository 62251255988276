import NewOrderRepairEntity from "../../../NewModel/Order/Entity/NewOrderRepairEntity"

export default class NewOrderApplianceEntity {

    constructor() {
        this.id = null
        this.appliance = null
        this.name = ''
        this.applianceType = ''
        this.serialNumber = ''
        this.detailPlaceOfInstallation = ''
        this.repairs = []
    }

    /**
     * @param response : object
     * @returns {NewOrderApplianceEntity}
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.appliance = response.appliance
        obj.name = response.name
        obj.applianceType = response.applianceType
        obj.serialNumber = response.serialNumber
        obj.detailPlaceOfInstallation = response.detailPlaceOfInstallation
        obj.repairs = response.repairs.map((repair) => NewOrderRepairEntity.from(repair))
        return obj
    }

}
