<template>

    <div
        class="bg-little-gray mb-5 p-3 border-radius-sm"
        v-if="serviceOrderStatus.getItem(order.status) !== null
         && serviceOrderStatus.getItem(order.status).workerStatusEnabled === true"
    >

        <div class="font-weight-700 text-dark mb-2">Změna stavu:</div>

        <BForm @submit="onSubmit">

            <!-- Worker status -->
            <BFormSelect
                v-model="request.workerStatus"
                :options="workerStatus.list()"
                value-field="id"
                disabled-field="disabled"
                text-field="text"
                class="mb-3"
            >
                <template #first>
                    <BFormSelectOption :value="null" disabled>-- Vybrat --</BFormSelectOption>
                </template>
            </BFormSelect>

            <div
                class="mb-3"
                v-if="workerStatus.getItem(request.workerStatus) !== null && workerStatus.getItem(request.workerStatus).cancelReasonRequired === true"
            >
                <h6 class="font-weight-700 text-dark mb-2">Důvod:</h6>
                <BFormSelect
                    v-model="request.cancelReasonStatus"
                    :options="cancelStatus.list()"
                    value-field="id"
                    text-field="text"
                    disabled-field="disabled"
                />
            </div>

            <BButton type="submit" variant="primary">Uložit</BButton>

        </BForm>

    </div>

</template>

<script>
import WorkerStatus from "../../../Model/Enum/worker_status"
import CancelStatus from "../../../Model/Enum/cancel_status"
import ServiceOrderStatus from "../../../Model/Enum/service_order_status"
import OrderMobileEntity from "../../../NewModel/Order/Entity/OrderMobileEntity"
import OrderChangeStatusRequest from "../../../NewModel/Order/Request/OrderChangeStatusRequest"

export default {

    props: {
        order: {
            type: OrderMobileEntity,
            required: true,
        },
    },

    data() {
        return {
            workerStatus: WorkerStatus,
            cancelStatus: CancelStatus,
            serviceOrderStatus: ServiceOrderStatus,
            request: new OrderChangeStatusRequest(),
        }
    },

    mounted() {
        this.request.workerStatus = this.order.workerStatus
        this.request.cancelReasonStatus = this.order.cancelReasonStatus
    },

    methods: {

        onSubmit(evt) {

            evt.preventDefault()
            let self = this

            let enumWorkerStatus = this.workerStatus.getItem(this.request.workerStatus)
            if (enumWorkerStatus === null) {
                alert("Vyberte stav")
                this.warningMessage('Vyberte stav')
                return false
            } else if (enumWorkerStatus.disabled) {
                this.warningMessage('Vyberte nový stav')
                return false
            } else if (enumWorkerStatus && enumWorkerStatus.cancelReasonRequired === true) {
                let enumCancelReasonStatus = this.cancelStatus.getItem(this.request.cancelReasonStatus)
                if (enumCancelReasonStatus === null) {
                    this.warningMessage('Vyberte důvod zrušení')
                    return false
                } else if (enumCancelReasonStatus.disabled) {
                    this.warningMessage('Vyberte stav')
                    return false
                }
            }

            this.$store.dispatch("order/changeOrderStatus", {
                id: this.order.id,
                request: this.request,
            })
                .then(() => {
                    this.order.cancelReasonStatus = this.request.cancelReasonStatus
                    this.order.workerStatus = this.request.workerStatus
                    this.successMessage("Nový stav nastaven")
                    self.recordLocation()
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })

            return false
        },

        recordLocation() {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.$store.dispatch("tracking/doLog", {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        order: this.order.id
                    })
                }
            )
        },

    },

}
</script>
