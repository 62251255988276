<template>
    <AssemblySheetForm :readonly-mode="false" :assembly-sheet="assemblySheet"/>
</template>

<script>
import {mapGetters} from "vuex"
import AssemblySheetForm from "./Component/AssemblySheetForm.vue"

export default {

    components: {
        AssemblySheetForm,
    },

    computed: {
        ...mapGetters({
            assemblySheet: "assemblySheet/process/getRecord",
        }),
    },

}
</script>
