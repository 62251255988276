<template>
    <div>

        <Header icon="fad fa-calendar-alt" title="Plánování"/>

        <FilterForm :request="request" class="mb-4" v-on:filterChanged="filterChanged"/>

        <div class="">
            <BTable
                ref="table"
                borderless
                :fields="fields"
                :busy="isLoading"
                :current-page="request.page"
                :per-page="request.perPage"
                show-empty
                :sort-by.sync="request.sortBy"
                :sort-desc.sync="request.sortDesc"
                :items="myProvider"
                tbody-tr-class="border-bottom"
                thead-tr-class="border-bottom"
                sort-icon-left
            >

                <template v-slot:table-busy>
                    <TableLoading/>
                </template>
                <template v-slot:empty>
                    <TableWithoutRecords/>
                </template>
                <template #cell(ean)="row">
                    <BLink
                        :to="{ name: 'supply_point_show', params: { id: row.item.supplyPoint }}"
                        target="_blank"
                    >
                        {{ row.value }}
                    </BLink>
                </template>
                <template #cell(serviceContract)="row">
                    {{ serviceContractLevel.getItem(row.value).text }}
                </template>
                <template #cell(action)="row">
                    <BButton
                        size="sm"
                        variant="success"
                        v-on:click.prevent="useAddress(row.item)"
                    >
                        <i class="fad fa-map-marker fa-fw"/>
                    </BButton>
                    <BButton
                        size="sm"
                        variant="primary"
                        class="ml-2"
                        :to="{ name: 'planning_show', params: { plan_id: row.item.id }}"
                        target="_blank"
                    >
                        Otevřít
                    </BButton>
                </template>
                <template #head(plannedDateOfRepair)="data">
                    <span class="text-danger" v-if="request.onlyFree">Nejbližší oprava (zdarma)</span>
                    <span v-else>Datum nejbližší opravy</span>
                </template>

            </BTable>

            <div class="mt-3">
                <strong>Celkem záznamů:</strong> {{ totalRecords }}
            </div>

            <nav class="d-flex justify-content-center" v-if="!isLoading && totalRecords > request.perPage">
                <BPagination
                    v-model="request.page"
                    :total-rows="totalRecords"
                    :per-page="request.perPage"
                    prev-text="Předchozí"
                    next-text="Následující"
                />
            </nav>

        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import FilterForm from "./Component/FilterForm.vue"
import Header from "../Common/UI/Header.vue"
import PlanningFilterRequest from "../../NewModel/Planning/Request/PlanningFilterRequest"
import ServiceContractLevel from "../../Model/Enum/service_contract_level"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"
import Vue from "vue"

export default {

    components: {
        FilterForm,
        Header,
        TableLoading,
        TableWithoutRecords,
    },

    data() {
        return {
            request: new PlanningFilterRequest(),
            serviceContractLevel: ServiceContractLevel,
            fields: [
                {
                    key: "ean",
                    label: "EAN",
                    sortable: true,
                    class: "align-middle",
                },
                {
                    key: "address",
                    label: "Adresa",
                    sortable: true,
                    class: "align-middle",
                },
                {
                    key: "plannedDateOfRepair",
                    sortable: true,
                    label: "Datum nejbližší opravy",
                    class: "align-middle",
                    formatter: function (a) {
                        return Vue.filter("prettyDate")(a)
                    },
                },
                {
                    key: "serviceContract",
                    label: "Ser. smlouva",
                    class: "align-middle text-center",
                    sortable: false,
                },
                {
                    key: "action",
                    label: "Akce",
                    class: "text-right align-middle",
                    sortable: false,
                },
            ],
        }
    },

    computed: {
        ...mapGetters({
            isLoading: "planning/isLoading",
            haveRecords: "planning/hasPlans",
            records: "planning/getPlans",
            totalRecords: "planning/getTotalPlans",
        }),
    },

    methods: {

        myProvider(ctx, callback) {
            let items = []
            this.$store.dispatch("planning/clearAll")
            this.$store.dispatch("planning/getFilteredRecords", this.request)
                .then((response) => {
                    callback(response)
                })
                .catch(() => {
                    callback([])
                })
            return items || []
        },

        useAddress(item) {
            this.request.address = item.address
            this.request.gpsLat = item.gpsLat
            this.request.gpsLng = item.gpsLng
            this.filterChanged()
        },

        filterChanged() {
            this.request.page = 1
            this.$refs.table.refresh()
        },

    },

}
</script>
