import AssemblySheetService from "../AssemblySheetService"
import AssemblySheetItemEntity from "../Entity/AssemblySheetItemEntity"

const
    PROCESSING_RECORD = "PROCESSING_RECORD",
    PROCESSING_RECORD_SUCCESS = "PROCESSING_RECORD_SUCCESS",
    PROCESSING_RECORD_ERROR = "PROCESSING_RECORD_ERROR"

const initialState = {
    isProcessing: false,
    record: null
}

export const state = {...initialState}

export const actions = {

    async createItem({commit}, {assemblySheetId, formData}) {
        try {
            commit(PROCESSING_RECORD)
            let response = await AssemblySheetService.createItem(
                assemblySheetId,
                formData
            )
            commit(PROCESSING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(PROCESSING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async updateItem({commit}, {assemblySheetId, itemId, formData}) {
        try {
            commit(PROCESSING_RECORD)
            let response = await AssemblySheetService.updateItem(
                assemblySheetId,
                itemId,
                formData
            )
            commit(PROCESSING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(PROCESSING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async deleteItem({commit}, {assemblySheetId, itemId}) {
        try {
            commit(PROCESSING_RECORD)
            let response = await AssemblySheetService.deleteItem(
                assemblySheetId,
                itemId,
            )
            commit(PROCESSING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(PROCESSING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    }

}

export const mutations = {

    [PROCESSING_RECORD](state) {
        state.isProcessing = true
        state.error = null
        state.record = null
    },
    [PROCESSING_RECORD_SUCCESS](state, data) {
        state.isProcessing = false
        state.error = null
        state.record = AssemblySheetItemEntity.from(data)
    },
    [PROCESSING_RECORD_ERROR](state, error) {
        state.isProcessing = false
        state.error = error
        state.record = null
    }

}

export const getters = {

    isProcessing(state) {
        return state.isProcessing
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasRecord(state) {
        return state.record !== null
    },
    getRecord(state) {
        return state.record
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
