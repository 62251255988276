import WorkerService from "../../WorkerService"

const
    CREATING_RECORDS = "CREATING_RECORDS",
    CREATING_RECORDS_SUCCESS = "CREATING_RECORDS_SUCCESS",
    CREATING_RECORDS_ERROR = "CREATING_RECORDS_ERROR"

const initialState = {
    isCreating: false,
    error: null
}

export const state = {...initialState}

export const actions = {

    async addTechnicalNote({commit}, {id, formData}) {
        try {
            commit(CREATING_RECORDS)
            let response = await WorkerService.addTechnicalNoteAppliance(id, formData)
            commit(CREATING_RECORDS_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(CREATING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    }

}

export const mutations = {

    [CREATING_RECORDS](state) {
        state.isCreating = true
        state.error = null
        state.record = null
    },
    [CREATING_RECORDS_SUCCESS](state, data) {
        state.isCreating = false
        state.error = null
        state.record = data
    },
    [CREATING_RECORDS_ERROR](state, error) {
        state.isCreating = false
        state.error = error
        state.record = null
    },

}

export const getters = {

    isCreating(state) {
        return state.isCreating
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
