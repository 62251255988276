<template>
    <BForm ref="form" @submit.stop.prevent="processForm()">

        <BRow class="mb-4">
            <BCol lg="3" md="12">
                <label for="ean" class="font-weight-700 col-form-label">
                    EAN/EIC:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput autocomplete="off" v-model="request.ean" id="ean" type="text"/>
            </BCol>
        </BRow>

        <BRow class="mb-4">
            <BCol lg="3" md="12">
                <label class="font-weight-700 col-form-label" for="address-suggestion">
                    Adresa: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BInputGroup v-if="request.address.id">
                    <BFormInput v-model="request.address.wholeName" disabled/>
                    <BInputGroupAppend>
                        <BButton variant="primary" v-on:click="request.resetAddress()">
                            X
                        </BButton>
                    </BInputGroupAppend>
                </BInputGroup>
                <AddressSuggestion @picked="addressSelected" v-else/>
                <div class="small text-muted mt-1 pointer">
                    <BLink
                        to="#"
                        v-b-modal.address-modal-form
                        class="text-muted"
                        style="text-decoration: none;"
                    >
                        Vytvořit adresu ručně
                    </BLink>
                    <span class="mx-2">|</span>
                    <BLink
                        to="#"
                        v-b-modal.parcel-modal-form
                        class="text-muted"
                        style="text-decoration: none;"
                    >
                        Vytvořit parcelu ručně
                    </BLink>
                </div>
            </BCol>
        </BRow>

        <BRow class="mb-4">
            <BCol lg="3" md="12">
                <label for="name" class="font-weight-700 col-form-label">
                    Detail OM:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormRow>
                    <BCol>
                        <BFormInput
                            v-model="request.name"
                            id="name"
                            type="text"
                            autocomplete="off"
                            placeholder="Název odběrného místa"
                        />
                    </BCol>
                    <BCol>
                        <BFormInput
                            v-model="request.floorNumber"
                            id="floorNumber"
                            type="text"
                            autocomplete="off"
                            placeholder="Patro"
                        />
                    </BCol>
                    <BCol>
                        <BFormInput
                            v-model="request.apartmentNumber"
                            id="apartmentNumber"
                            type="text"
                            autocomplete="off"
                            placeholder="Číslo bytu"
                        />
                    </BCol>
                </BFormRow>
            </BCol>
        </BRow>

        <BRow class="mb-4">
            <BCol lg="3" md="12">
                <label class="font-weight-700 col-form-label">
                    Zákazník: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <div
                    class="bg-little-gray p-4 d-flex justify-content-between align-items-center"
                    v-if="request.customer.id"
                >
                    <div class="flex-grow-1">
                        <h6 class="h6 mb-2 font-weight-800">
                            {{ request.customer.name }}
                        </h6>
                        <div>
                            {{ request.customer.address }}
                        </div>
                    </div>
                    <div>
                        <BButton variant="text" @click.prevent="request.resetCustomer()">
                            Storno <i class="fad fa-times fa-fw text-danger"/>
                        </BButton>
                    </div>
                </div>
                <div v-else>
                    <SubjectSuggestion @picked="customerSelected"/>
                    <BLink class="small text-muted" @click="openSubjectModalForm('customer')">
                        Vytvořit nový subjekt
                    </BLink>
                </div>
            </BCol>
        </BRow>

        <BRow class="mb-4">
            <BCol lg="3" md="12">
                <label class="font-weight-700 col-form-label">Vlastník nemovitosti:</label>
            </BCol>
            <BCol lg="9" md="12">
                <div
                    class="bg-little-gray p-4 d-flex justify-content-between align-items-center"
                    v-if="request.propertyOwner.id"
                >
                    <div class="flex-grow-1">
                        <h6 class="h6 mb-2 font-weight-800">
                            {{ request.propertyOwner.name }}
                        </h6>
                        <div>
                            {{ request.propertyOwner.address }}
                        </div>
                    </div>
                    <div>
                        <BButton variant="text" @click.prevent="request.resetPropertyOwner()">
                            Storno <i class="fad fa-times fa-fw text-danger"/>
                        </BButton>
                    </div>
                </div>
                <div v-else>
                    <SubjectSuggestion @picked="propertyOwnerSelected"/>
                    <BLink class="small text-muted" @click.prevent="openSubjectModalForm('propertyOwner')">
                        Vytvořit nový subjekt
                    </BLink>
                </div>
            </BCol>
        </BRow>

        <BRow class="mb-4">
            <BCol lg="3" md="12">
                <label class="font-weight-700 col-form-label">Servisní smlouva:</label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormSelect
                    v-model="request.serviceContract"
                    :options="list.serviceContractLevel.arrayList()"
                    value-field="id"
                    text-field="text"
                >
                    <template #first>
                        <BFormSelectOption :value="null" disabled>
                            Vyberte, pokud potřebujete vymezit úroveň servisní smlouvy
                        </BFormSelectOption>
                    </template>
                </BFormSelect>
            </BCol>
        </BRow>

        <BRow class="mb-4">
            <BCol lg="3" md="12">
                <label class="font-weight-700 col-form-label">Komodita: <span class="text-danger">*</span></label>
            </BCol>
            <BCol lg="9" md="12">
                <b-form-group>
                    <b-form-radio-group
                        v-model="request.commodity"
                        :options="list.commodity"
                        name="radio-options"
                        value-field="id"
                        text-field="text"
                    />
                </b-form-group>
            </BCol>
        </BRow>

        <BRow>
            <BCol lg="3" md="12"/>
            <BCol lg="9" md="12">
                <BButton type="submit" variant="primary" :disabled="isProcessing">
                    <i class="fad fa-save mr-3"/>Uložit
                </BButton>
            </BCol>
        </BRow>

        <BModal
            id="subject-modal-form"
            size="lg"
            centered
            title="Vytvoření nového subjektu"
            hide-footer
            content-class="border-0"
            header-bg-variant="primary"
            header-text-variant="white"
            body-class="p-4"
        >
            <SubjectFormComponent @submitted="subjectSelected"/>
        </BModal>

        <BModal
            size="lg"
            centered
            id="address-modal-form"
            title="Vytvoření nové adresy"
            hide-footer
            content-class="border-0"
            header-bg-variant="primary"
            header-text-variant="white"
            body-class="p-4"
        >
            <AddressFormComponent @picked="addressSelected"/>
        </BModal>

        <BModal
            size="lg"
            centered
            id="parcel-modal-form"
            title="Vytvoření nové parcely"
            hide-footer
            header-bg-variant="primary"
            header-text-variant="white"
            footer-bg-variant="little-gray"
            body-class="p-4"
        >
            <ParcelFormComponent @picked="addressSelected"/>
        </BModal>

    </BForm>
</template>

<script>
import {mapGetters} from 'vuex'
import AddressFormComponent from "../../Address/Component/AddressFormComponent.vue"
import AddressSuggestion from "../../Address/Component/AddressSuggestion.vue"
import Commodity from "@/Model/Enum/commodity"
import ParcelFormComponent from "../../Address/Component/ParcelFormComponent.vue"
import ServiceContractLevel from "@/Model/Enum/service_contract_level"
import SubjectFormComponent from "../../Subject/Component/SubjectFormComponent.vue"
import SubjectSuggestion from "../../Subject/Component/SubjectSuggestion.vue"
import SubjectRequest from "@/NewModel/Subject/Request/SubjectRequest"
import SupplyPointRequest from "../../../NewModel/SupplyPoint/Request/SupplyPointRequest"

export default {

    props: {
        request: {
            type: SupplyPointRequest,
            required: false,
            default: () => new SupplyPointRequest(),
        },
        isProcessing: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        AddressFormComponent,
        AddressSuggestion,
        ParcelFormComponent,
        SubjectFormComponent,
        SubjectSuggestion,
    },

    data() {
        return {
            subjectRequest: new SubjectRequest(),
            modal: {
                subject: null,
            },
            list: {
                commodity: Commodity.list(),
                serviceContractLevel: ServiceContractLevel,
            },
        }
    },

    computed: {
        ...mapGetters({
            isCreating: 'supplyPoint/isCreating',
            isUpdating: 'supplyPoint/isUpdating',
        }),
    },

    methods: {

        processForm() {

            try {

                if (!this.$refs.form.checkValidity()) {
                    throw 'Selhala validace. Byly všechny požadované pole vyplněny?'
                }

                if (this.request.address.id === null) {
                    throw 'Zadejte adresu odběrného místa'
                }

                if (this.request.commodity === null) {
                    throw 'Vyberte komoditu'
                }

                if (this.request.customer === null) {
                    throw 'Vyplňte zákazníka'
                }

            } catch (err) {
                this.warningMessage(err)
                return false
            }

            this.$emit('submitted', this.request)
        },

        addressSelected(address) {
            this.request.address.id = address.id
            this.request.address.wholeName = address.wholeName
            this.$nextTick(() => {
                this.$bvModal.hide('address-modal-form')
                this.$bvModal.hide('parcel-modal-form')
            })
        },

        customerSelected(entity) {
            this.request.customer.id = entity.id
            this.request.customer.name = entity.wholeName
            this.request.customer.address = entity.address.wholeName || "N/A"
        },

        propertyOwnerSelected(entity) {
            this.request.propertyOwner.id = entity.id
            this.request.propertyOwner.name = entity.wholeName
            this.request.propertyOwner.address = entity.address.wholeName || "N/A"
        },

        subjectSelected(entity) {
            if (this.modal.subject === 'customer') {
                this.customerSelected(entity)
            } else if (this.modal.subject === 'propertyOwner') {
                this.propertyOwnerSelected(entity)
            }
            this.$nextTick(() => {
                this.$bvModal.hide('subject-modal-form')
            })
        },

        openSubjectModalForm(mode) {
            this.modal.subject = mode
            this.$nextTick(() => {
                this.$bvModal.show('subject-modal-form')
            })
        },

    },

}
</script>
