import SubjectContactRequest from "./SubjectContactRequest"
import SubjectType from "../../../Model/Enum/subject_type"

export default class SubjectRequest {

    constructor() {
        this.type = 1
        this.firstName = ''
        this.lastName = ''
        this.birthDate = null
        this.academicDegree = ''
        this.companyName = ''
        this.identificationNumber = ''
        this.vatNumber = ''
        this.address = {
            id: null,
            wholeName: '',
        }
        this.contacts = []
        this.force = false
    }

    /**
     * @param response : SubjectBasicEntity
     * @returns {SubjectRequest}
     */
    static from(response) {
        let req = new this
        req.type = response.type
        req.firstName = response.firstName || ''
        req.lastName = response.lastName || ''
        req.birthDate = response.birthDate
        req.academicDegree = response.academicDegree || ''
        req.companyName = response.companyName || ''
        req.identificationNumber = response.identificationNumber || ''
        req.vatNumber = response.vatNumber || ''
        req.address.id = response.address.id
        req.address.wholeName = response.address.wholeName
        req.contacts = response.contacts.map(contact => SubjectContactRequest.from(contact))
        return req
    }

    toForm() {
        return {
            type: this.type,
            firstName: this.firstName || '',
            lastName: this.lastName || '',
            birthDate: this.birthDate,
            academicDegree: this.academicDegree || '',
            companyName: this.companyName || '',
            identificationNumber: this.identificationNumber || '',
            vatNumber: this.vatNumber || '',
            address: this.address.id,
            contacts: this.contacts.map(contact => contact.toForm()),
            force: this.force,
        }
    }

    isNaturalPerson() {
        return SubjectType.getItem(this.type).company === false
    }

    isCompany() {
        return SubjectType.getItem(this.type).company === true
    }

    resetAddress() {
        this.address = {
            id: null,
            wholeName: ''
        }
    }

}
