import SubjectOrderRepairEntity from "./SubjectOrderRepairEntity"

export default class SubjectOrderEntity {

    constructor() {
        this.id = ''
        this.orderNumber = ''
        this.address = ''
        this.scheduledStartTime = null
        this.scheduledEndTime = null
        this.customer = null
        this.propertyOwner = null
        this.payer = 0
        this.worker = ''
        this.status = 1
        this.repairs = []
    }

    /**
     * @param response : object
     * @returns SubjectOrderEntity
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.orderNumber = response.orderNumber || ''
        obj.address = response.address || ''
        obj.scheduledStartTime = response.scheduledStartTime
        obj.scheduledEndTime = response.scheduledEndTime
        obj.customer = response.customer
        obj.propertyOwner = response.propertyOwner
        obj.payer = response.payer
        obj.worker = response.worker
        obj.status = response.status
        obj.repairs = response.repairs.map(
            repair => SubjectOrderRepairEntity.from(repair)
        )
        return obj
    }

}
