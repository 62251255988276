<template>
    <div>
        <div class="mb-3 d-flex justify-content-between">
            <h5 class="font-weight-700 text-dark mb-0">
                Kontakty
            </h5>
            <div class="small d-flex align-items-end">
                <BLink variant="primary" @click="addCustomerContact()">
                    Přidat kontakt zákazníka
                </BLink>
                <BLink class="ml-4" variant="primary" @click="addPropertyOwnerContact()" v-if="propertyOwner">
                    Přidat kontakt vlastníka nemoviosti
                </BLink>
            </div>
        </div>
        <div>
            <table class="table table-sm table-borderless mb-0">
                <tbody>
                <OrderContactFormRow
                    v-for="(contact, contactIndex) in contacts"
                    :key="contact + contactIndex"
                    :subjectId="updated"
                    :contact="contact"
                    :index="contactIndex"
                    :selected="orderContact"
                    @update:selected="contactSelected($event)"
                    @picked="contactSelected"
                    @updated="contactProcessed"
                    @created="contactProcessed"
                    @cancelled="contactCancelled"
                />
                </tbody>
                <tbody v-if="contacts.length === 0">
                <tr>
                    <td colspan="5">
                        <div class="d-flex justify-content-center align-items-center">
                            Žádný kontakt není k dispozici.
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import SubjectContactBasicEntity from "../../../../NewModel/Subject/Entity/SubjectContactBasicEntity"
import SubjectContactRequest from "../../../../NewModel/Subject/Request/SubjectContactRequest"
import OrderContactFormRow from "./OrderContactFormRow"

export default {

    components: {
        OrderContactFormRow,
    },

    props: {
        contacts: {
            type: Array,
            default: () => [],
            required: false,
        },
        orderContact: {
            default: null,
        },
        customer: {
            type: String,
            required: true,
            default: null,
        },
        propertyOwner: {
            type: String,
            required: true,
            default: null,
        },
    },

    data() {
        return {
            request: new SubjectContactRequest(),
            updated: null,
        }
    },

    created() {
        this.updated = this.customer
    },

    methods: {

        contactSelected(contact) {
            this.$emit("update:order-contact", contact)
        },

        addCustomerContact() {
            this.updated = this.customer
            this.addContact()
        },

        addPropertyOwnerContact() {
            this.updated = this.propertyOwner
            this.addContact()
        },

        addContact() {
            // Check if opened item already exists
            let l = this.contacts.find((a) => a._action === "create")
            if (typeof l !== "undefined") {
                return
            }

            let contact = new SubjectContactBasicEntity()
            contact._action = "create"
            this.contacts.push(contact)
        },

        contactProcessed(data) {
            // Search assembly sheet other item by id and replace then
            if (typeof this.contacts[data.index] !== "undefined") {
                this.contacts.splice(data.index, 1, data.entity)
            }
        },

        contactCancelled(data) {
            // Search assembly sheet other item by id and replace then
            if (typeof this.contacts[data.index] !== "undefined") {
                this.contacts.splice(data.index, 1)
            }
        },

    },

}
</script>
