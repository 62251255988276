<template>
    <div>

        <Header icon="fad fa-user-crown" title="Informace o subjektu">
            <template #buttons>
                <PrimaryLinkButton
                    :to="{ name: 'subject_update', params: {id: subject.id}}"
                    v-if="subject.editable"
                    icon="fad fa-pencil-ruler"
                    text="Upravit"
                />
            </template>
        </Header>

        <BTabs pills content-class="mt-3" nav-class="text-warning">
            <BTab title="Základní informace" active>
                <div class="bg-little-gray p-4 mb-4">
                    <table class="table table-sm table-borderless mb-0">
                        <tbody>
                        <tr>
                            <td class="align-middle font-weight-600 w-15 font-weight-700">
                                Typ kontaktu:
                            </td>
                            <td class="align-middle">
                                {{ subjectType.getItem(subject.type).text }}
                            </td>
                        </tr>
                        <tr class="border-top" v-if="subjectType.getItem(subject.type).company">
                            <td class="align-middle font-weight-600 w-15 font-weight-700">
                                Název:
                            </td>
                            <td class="align-middle">
                                {{ subject.companyName}}
                            </td>
                        </tr>
                        <tr class="border-top" v-if="!subjectType.getItem(subject.type).company">
                            <td class="align-middle font-weight-600 w-15 font-weight-700">
                                Příjmení a jméno:
                            </td>
                            <td class="align-middle">
                                {{ subject.fullName }}
                            </td>
                        </tr>
                        <tr class="border-top" v-if="subjectType.getItem(subject.type).company">
                            <td class="align-middle font-weight-600 w-15 font-weight-700">
                                IČO:
                            </td>
                            <td class="align-middle">
                                <div v-if="subject.identificationNumber">
                                    {{ subject.identificationNumber }}
                                    <a :href="'https://wwwinfo.mfcr.cz/cgi-bin/ares/darv_res.cgi?ico=' + subject.identificationNumber + '&jazyk=cz&xml=1'"
                                       target="_blank" class="ml-3">
                                        <i class="fad fa-external-link ml-2"/> ARES
                                    </a>
                                </div>
                                <em v-else>Nevyplněno</em>
                            </td>
                        </tr>
                        <tr class="border-top" v-if="subjectType.getItem(subject.type).company">
                            <td class="align-middle font-weight-600 w-15 font-weight-700">
                                DIČ:
                            </td>
                            <td class="align-middle">
                                <div v-if="subject.vatNumber">
                                    {{ subject.vatNumber }}
                                    <span
                                        class="text-success font-weight-600 ml-3 p-1"
                                        v-if="subject.unreliableVatPayer === false"
                                    >
                                        Spolehlivý plátce DPH
                                    </span>
                                    <span
                                        class="text-danger font-weight-600 ml-3 p-1"
                                        v-if="subject.unreliableVatPayer === true"
                                    >
                                        Nespolehlivý plátce DPH!
                                    </span>
                                </div>
                                <em v-else>Nevyplněno</em>
                            </td>
                        </tr>
                        <tr class="border-top" v-if="!subjectType.getItem(subject.type).company">
                            <td class="align-middle font-weight-600 w-15 font-weight-700">
                                Datum narození:
                            </td>
                            <td class="align-middle">
                                <span v-if="subject.birthDate">{{ subject.birthDate|prettyDate }}</span>
                                <em v-else>Nevyplněno</em>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="align-middle font-weight-600 w-15 font-weight-700">
                                Primární adresa:
                            </td>
                            <td class="align-middle">
                                <span v-if="subject.address">{{ subject.address.wholeName }}</span>
                                <em v-else>Nevyplněno</em>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mb-4">
                    <h5 class="font-weight-600 text-dark mb-3">
                        <i class="fad fa-user-circle text-danger fa-fw mr-3"/>Kontakty
                    </h5>
                    <div class="mb-4">
                        <SubjectContactsComponent :subject="subject.id"/>
                    </div>
                </div>

            </BTab>

            <BTab title="Smlouvy" lazy>
                <SubjectContractsComponent :subject="subject.id"/>
            </BTab>

            <BTab title="Odběrná místa" lazy>
                <SubjectSupplyPointsComponent :subject="subject.id"/>
            </BTab>

            <BTab title="Zakázky" lazy>
                <SubjectOrdersComponent :subject="subject.id"/>
            </BTab>

        </BTabs>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Header from "../../Module/Common/UI/Header.vue"
import PrimaryLinkButton from "../../Module/Common/UI/Button/PrimaryLinkButton.vue"
import SubjectContactsComponent from "@/Module/Subject/Component/SubjectContactsComponent.vue"
import SubjectContractsComponent from "./Component/SubjectContractsComponent.vue"
import SubjectOrdersComponent from "@/Module/Subject/Component/SubjectOrdersComponent.vue"
import SubjectSupplyPointsComponent from "./Component/SubjectSupplyPointsComponent.vue"
import SubjectType from "../../Model/Enum/subject_type"

export default {

    components: {
        Header,
        PrimaryLinkButton,
        SubjectContactsComponent,
        SubjectContractsComponent,
        SubjectOrdersComponent,
        SubjectSupplyPointsComponent,
    },

    data() {
        return {
            subjectType: SubjectType,
        }
    },

    computed: {
        ...mapGetters({
            subject: "subject/getSubject",
        }),
    },

}
</script>
