export default class OrderMaterialEntity {

    constructor() {
        this.id = ""
        this.order = ""
        this.material = ""
        this.eanCode = null
        this.itemName = ""
        this.specification = ""
        this.quantity = 1
        this.discount = 0
        this.createdAt = ""
        this.createdBy = ""
        this.updatedAt = null
        this.updatedBy = ""
    }

    /**
     * @param response : object
     * @returns {OrderMaterialEntity}
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.order = response.order
        obj.material = response.material
        obj.eanCode = response.eanCode
        obj.itemName = response.itemName
        obj.specification = response.specification
        obj.quantity = response.quantity
        obj.discount = response.discount
        obj.createdAt = response.createdAt
        obj.createdBy = response.createdBy
        obj.updatedAt = response.updatedAt
        obj.updatedBy = response.updatedBy
        return obj
    }

}
