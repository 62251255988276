import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const PlanningService = {

    /**
     * @param id : string
     * @returns {Promise<any>}
     */
    getPlan(id) {
        return httpClient.get("v3/planning/" + id, {headers: authHeader()})
    },

    /**
     * @param request : PlanningFilterRequest
     */
    filterPlans(request) {
        return httpClient.post("v3/planning/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param length : string
     * @param message : string
     * @returns {Promise<any>}
     */
    putToSleep(id, length, message) {
        return httpClient.post("v3/planning/" + id + "/put_to_sleep", {
            message: message
        }, {
            params: {
                length: length
            },
            headers: authHeader()
        })
    },

}

export default PlanningService
