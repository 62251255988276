<template>
    <div>
        <BForm ref="form">
            <BRow class="mb-3">
                <BCol>
                    <label class="bold col-form-label" for="siteCity">
                        Obec <span class="text-danger">*</span>
                    </label>
                    <BFormInput
                        v-model="request.siteCity"
                        required
                        id="siteCity"
                        type="text"
                        placeholder="Obec"
                    />
                </BCol>
                <BCol>
                    <label class="bold col-form-label" for="siteRegister">
                        Katastrální území <span class="text-danger">*</span>
                    </label>
                    <BFormInput
                        v-model="request.siteRegister"
                        required
                        id="siteRegister"
                        type="text"
                        placeholder="Katastrální území"
                    />
                </BCol>
            </BRow>

            <BRow class="mb-3">
                <BCol>
                    <label class="bold col-form-label" for="siteNumber">
                        Parcelní číslo <span class="text-danger">*</span>
                    </label>
                    <BFormInput
                        v-model="request.siteNumber"
                        required
                        id="siteNumber"
                        type="text"
                        placeholder="Parcelní číslo"
                    />
                </BCol>
                <BCol>
                    <label class="bold col-form-label" for="gpsLat">
                        GPS Zeměpisná šířka <span class="text-danger">*</span>
                    </label>
                    <BInputGroup prepend="N">
                        <BFormInput
                            v-model.number="request.gpsLat" placeholder="GPS Zeměpisná šířka"
                            required
                            id="gpsLat"
                            type="text"
                        />
                    </BInputGroup>
                </BCol>
                <BCol>
                    <label class="bold col-form-label" for="gpsLng">
                        GPS Zeměpisná délka <span class="text-danger">*</span>
                    </label>
                    <BInputGroup prepend="E">
                        <BFormInput
                            v-model="request.gpsLng"
                            required
                            id="gpsLng"
                            type="text"
                            placeholder="GPS Zeměpisná délka"
                        />
                    </BInputGroup>
                </BCol>
            </BRow>

            <BRow class="mb-3">
                <BCol>
                    <BButton
                        @click="processForm"
                        variant="primary"
                        block
                    >
                        Vytvořit parcelu
                    </BButton>
                </BCol>
            </BRow>
        </BForm>

        <GmapMap
            :center="{lat: request.gpsLat, lng: request.gpsLng}"
            :zoom="11.25"
            map-type-id="roadmap"
            class="mb-3 bg-light-yellow text-dark w-100"
            style="height: 480px;"
            :options="{
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUI: false
                }"
        >
            <GmapMarker
                v-on:dragend="markerChanged"
                :draggable="true"
                :position="{lat: request.gpsLat, lng: request.gpsLng}"
                title="Souřadnice parcely"
            />
        </GmapMap>

    </div>
</template>

<script>
import AddressParcelRequest from "../../../NewModel/Address/Request/AddressParcelRequest"

export default {


    data() {
        return {
            request: new AddressParcelRequest(),
        }
    },

    methods: {

        markerChanged(e) {
            this.request.gpsLat = parseFloat(e.latLng.lat().toFixed(5))
            this.request.gpsLng = parseFloat(e.latLng.lng().toFixed(5))
        },

        processForm() {

            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Vyplňte všechna povinná pole formuláře!')
                return false
            }

            let self = this
            this.$store.dispatch("address/createByParcel", this.request)
                .then((address) => {
                    this.successMessage('Parcelní místo bylo vytvořeno.')
                    self.$emit('picked', address)
                    self.request = new AddressParcelRequest()
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
