import WorkerService from "../WorkerService"

const
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",

    UPDATING_RECORD = "UPDATING_RECORD",
    UPDATING_RECORD_SUCCESS = "UPDATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "UPDATING_RECORD_ERROR"

const initialState = {
    isLoading: false,
    isUpdating: false,
    record: null,
    error: null
}

export const state = {...initialState}

export const actions = {

    async getUser({commit}) {
        try {
            commit(FETCHING_RECORD)
            let response = await WorkerService.getUser()
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async updateUser({commit}, {formData}) {
        try {
            commit(UPDATING_RECORD)
            let response = await WorkerService.updateUser(formData)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    }

}

export const mutations = {

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
        state.record = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = data

    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.record = null
    },

    [UPDATING_RECORD](state) {
        state.isUpdating = true
        state.error = null
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isUpdating = false
        state.error = null
        state.record = data
    },
    [UPDATING_RECORD_ERROR](state, error) {
        state.isUpdating = false
        state.error = error
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    isUpdating(state) {
        return state.isUpdating
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasRecord(state) {
        return state.record !== null
    },
    getRecord(state) {
        return state.record
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
