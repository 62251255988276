<template>
    <BModal
        id="modal-add-job-sheet"
        header-bg-variant="primary"
        header-text-variant="white"
        footer-bg-variant="light"
        footer-text-variant="dark"
        size="xl"
        body-class="p-4"
        no-stacking
    >

        <template #modal-header>
            <div class="p-3">
                <h4 class="display-4 mb-0">
                    <i class="fa-light fa-file-contract fa-fw mr-3"/> Přidat (foto)kopii montážní listu
                </h4>
            </div>
        </template>

        <div class="table-warning p-3 border-radius-sm mb-4">
            Přo příložení montážního listu do této zakázky je nutné je nejdříve vybrat v galerii (popřípadě je pořídit
            fotoaparátem) a poté kliknout na tlačítko Uložit, kdy dojde ke spárování montážního listu se zakázkou.
        </div>

        <h6 class="h6 font-weight-600">Vybrat montážní list</h6>
        <AttachmentUploadForm
            input-class="mb-0"
            input-size="lg"
            input-placeholder="Vybrat nebo vyhledat"
            :is-multiple="false"
            @uploaded="uploaded"
        />

        <div v-if="records.length > 0" class="mt-4">
            <h5 class="h6 font-weight-600">K přiložení do zakázky:</h5>
            <table class="table table-sm table-borderless mb-0">
                <thead>
                <tr>
                    <th class="w-80 bg-light font-weight-600 text-left">
                        Název
                    </th>
                    <th class="w-20 bg-light font-weight-600 text-right">
                        Velikost
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(record, recordIndex) in records"
                    :key="record.id + '-' + recordIndex"
                    class="border-top"
                >
                    <td>
                        {{ record.name }}
                    </td>
                    <td class="text-right">
                        {{ record.size|prettyBytes }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <template #modal-footer>
            <BButton
                type="submit"
                variant="secondary"
                :disabled="isUpdating"
                @click="handleSubmit"
            >
                <i class="fa-light fa-floppy-disk fa-fw mr-3"/>Uložit
            </BButton>
        </template>
    </BModal>
</template>

<script>
import {mapGetters} from "vuex"
import AttachmentUploadForm from "../../../Common/Attachment/AttachmentUploadForm.vue"

export default {

    components: {
        AttachmentUploadForm,
    },

    props: {
        order: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isUpdating: "order/attachment/assignment/isUpdating",
        }),
    },

    data() {
        return {
            records: [],
        }
    },

    methods: {

        uploaded(data) {
            this.records = data
        },

        handleSubmit() {

            if (this.records.length === 0) {
                this.warningMessage("Není co uložit.")
                return
            }

            this.$store.dispatch("order/attachment/assignment/assigningJobSheet", {
                id: this.order,
                attachment: this.records.map(record => record.id)
            })
                .then((response) => {
                    if (response === true) {
                        this.$emit("created", this.records)
                        this.records = []
                        this.successMessage("Montážní list byl úspěšně uložen do zakázky")
                    }
                    this.$root.$emit("bv::hide::modal", "modal-add-job-sheet")
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
