export default class DiscountItemEntity {

    constructor() {
        this.id = null
        this.discount = null
        this.repairType = null
        this.repairTypeName = ''
        this.appliance = null
        this.applianceName = ''
        this.amount = null
    }

    /**
     * @param response : object
     * @returns DiscountItemEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.discount = response.discount
        obj.repairType = response.repairType
        obj.repairTypeName = response.repairTypeName
        obj.appliance = response.appliance
        obj.applianceName = response.applianceName
        obj.amount = response.amount
        return obj
    }

}
