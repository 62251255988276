import IncidentService from "./IncidentService"
import IncidentMyStore from "./Store/IndicentMyStore"
import IncidentOverviewStore from "./Store/IncidentOverviewStore"

const
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    UPDATING_RECORD = "UPDATING_RECORD",
    UPDATING_RECORD_SUCCESS = "UPDATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "UPDATING_RECORD_ERROR",
    CLEAR_RECORDS = "CLEAR_RECORDS",
    CLEAR_RECORD = "CLEAR_RECORD"

const initialState = {
    isLoading: false,
    isUpdating: false,
    isCreating: false,
    records: [],
    record: null,
    error: null
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param id : int
     * @returns {Promise<*>}
     */
    async resolveIncident({commit}, id) {
        try {
            commit(FETCHING_RECORDS)
            let response = await IncidentService.resolveIncident(id)
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param request : IncidentRequest
     * @returns {Promise<*>}
     */
    async createIncident({commit}, request) {
        try {
            commit(CREATING_RECORD)
            let response = await IncidentService.createIncident(request)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

    async clear({commit}) {
        commit(CLEAR_RECORD)
    },

}

export const mutations = {

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.records = data
    },
    [FETCHING_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
    },

    [UPDATING_RECORD](state) {
        state.isUpdating = true
        state.error = null
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isUpdating = false
        state.error = null
        state.record = data
    },
    [UPDATING_RECORD_ERROR](state, error) {
        state.isUpdating = false
        state.error = error
    },

    [CREATING_RECORD](state) {
        state.isCreating = true
        state.error = null
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isCreating = false
        state.error = null
        state.record = data
    },
    [CREATING_RECORD_ERROR](state, error) {
        state.isCreating = false
        state.error = error
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    isUpdating(state) {
        return state.isUpdating
    },
    isCreating(state) {
        return state.isCreating
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasIncidents(state) {
        return state.records.length > 0
    },
    getIncidents(state) {
        return state.records
    },
    hasIncident(state) {
        return state.record !== null
    },
    getIncident(state) {
        return state.record
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        my: IncidentMyStore,
        overview: IncidentOverviewStore,
    }
}
