import ApplianceService from "../../ApplianceService"
import ApplianceSuggestionEntity from "../../Entity/ApplianceSuggestionEntity"

const
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isLoading: false,
    records: [],
    totalRecords: 0,
    error: null,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param query: string
     * @param manufacturer: Number|null
     * @returns {Promise<[]>}
     */
    async searchModel({commit}, {query, manufacturer}) {
        try {
            commit(FETCHING_RECORDS)
            let response = await ApplianceService.searchModel({query, manufacturer})
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

}

export const mutations = {

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.map(record => ApplianceSuggestionEntity.from(record))
        state.totalRecords = data.length
    },
    [FETCHING_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
        state.totalRecords = 0
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.error = null
        state.records = []
        state.totalRecords = 0
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },

    hasError(state) {
        return state.error !== null
    },

    error(state) {
        return state.error
    },

    hasRecords(state) {
        return state.records.length > 0
    },

    getRecords(state) {
        return state.records
    },

    getTotalRecords(state) {
        return state.totalRecords
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
