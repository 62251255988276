import Layout from "./Layout.vue"
import Dashboard from "./General/Dashboard.vue"
import OrderList from "./Order/List.vue"
import Order from "./Order/Order.vue"
import OrderDetail from "./Order/OrderDetail.vue"
import IncidentList from "./Incident/List.vue"
import IncidentCreate from "./Incident/CreateIncident.vue"
import SupplyPoint from "./SupplyPoint/SupplyPoint.vue"
import SupplyPointDetail from "./SupplyPoint/SupplyPointDetail.vue"
import Appliance from "./Appliance/Appliance.vue"
import ApplianceDetail from "./Appliance/ApplianceDetail.vue"
import AssemblySheetWrapper from "./AssemblySheet/Wrapper.vue"
import AssemblySheetEdit from "./AssemblySheet/Edit.vue"
import AssemblySheetShow from "./AssemblySheet/Show.vue"
import AssemblySheetCustomer from "./AssemblySheet/Customer.vue"
import WorkerUser from "./User/User.vue"
import OrderMap from "./Order/Map.vue"

const routes = [
    // Assembly sheets
    {
        path: "/worker/assembly_sheets/:id/customer",
        component: AssemblySheetCustomer,
    },
    {
        path: "/worker",
        component: Layout,
        children: [
            {
                path: "dashboard",
                name: "worker_dashboard",
                component: Dashboard,
            },
            {
                path: "user",
                name: "worker_user",
                component: WorkerUser,
            },
            {
                path: "map",
                name: "worker_order_map",
                component: OrderMap,
            },
            {
                path: "orders",
                name: "worker_order_list",
                component: OrderList,
            },
            {
                path: "orders/:id",
                component: Order,
                children: [
                    {
                        path: "",
                        name: "worker_order_show",
                        component: OrderDetail,
                    },
                ],
            },
            {
                path: "supply_points/:id",
                component: SupplyPoint,
                children: [
                    {
                        path: "",
                        name: "worker_supply_point_show",
                        component: SupplyPointDetail,
                    },
                ],
            },
            {
                path: "appliances/:id",
                component: Appliance,
                children: [
                    {
                        path: "",
                        name: "worker_appliance_show",
                        component: ApplianceDetail,
                    },
                ],
            },
            {
                path: "assembly_sheets/:id",
                component: AssemblySheetWrapper,
                children: [
                    {
                        path: "",
                        name: "worker_assembly_sheet_show",
                        component: AssemblySheetShow,
                    },
                    {
                        path: "edit",
                        name: "worker_assembly_sheet_edit",
                        component: AssemblySheetEdit,
                    },
                ],
            },
            {
                path: "incidents",
                name: "worker_incident_list",
                component: IncidentList,
            },
            {
                path: "incidents/create",
                name: "worker_incident_create",
                component: IncidentCreate,
            },
            {
                path: "",
                redirect: "/worker/dashboard",
            },
        ],
    },
]
export default routes
