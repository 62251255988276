<template>
    <span>
    <BDropdownItem
        href="#"
        v-b-modal.cancel-order
        :disabled="isProcessing"
    >
        <i class="fad fa-times-circle mr-3 text-danger"/>Zrušit zakázku
    </BDropdownItem>
    <BModal
        id="cancel-order"
        ref="orderCancelModal"
        title="Opravdu chcete ZRUŠIT tuto zakázku?"
        header-bg-variant="light"
        header-text-variant="dark"
        ok-title="Ano, zrušit"
        ok-variant="danger"
        cancel-title="Storno"
        @ok="handleCancelOrderOk"
    >
        <form ref="orderCancelModalForm" @submit.stop.prevent="process">
            <BFormGroup
                label="Poznámka:"
                label-class="font-weight-600"
                label-for="cancel-order-note-input"
            >
                <BFormTextarea
                    v-model="cancelRequest.note"
                    id="cancel-order-note-input"
                    placeholder="Zadejte důvod zrušení této zakázky"
                />
            </BFormGroup>
        </form>
    </BModal>
        </span>
</template>

<script>
import {mapGetters} from "vuex"
import OrderCancelRequest from "../../../NewModel/Order/Request/OrderCancelRequest"

export default {

    computed: {
        ...mapGetters({
            order: 'order/getOrder',
            isProcessing: 'order/isProcessing',
        }),
    },

    data() {
        return {
            cancelRequest: new OrderCancelRequest(),
        }
    },

    methods: {

        handleCancelOrderOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.process()
        },

        process() {
            if (!this.$refs.orderCancelModalForm.checkValidity()) {
                return
            }

            this.$store.dispatch("order/cancelOrder", {
                id: this.order.id,
                request: this.cancelRequest
            })
                .then((order) => {
                        this.successMessage('Zakázka byla úspěšně zrušena.')
                        this.$router.push({name: 'order_cancelled', params: {id: order.id}})
                    },
                    error => {
                        this.errorMessage(error.response.data.message)
                    }
                )
        },

    },

}
</script>
