export default class ApplianceEntity {

    constructor() {
        this.id = null
        this.name = ''
        this.supplyPoint = {
            id: null,
            ean: '',
            commodity: null,
            address: '',
        }
        this.applianceType = {
            id: null,
            name: '',
        }
        this.manufacturer = {
            id: null,
            name: '',
        }
        this.model = {
            id: null,
            name: '',
            fullName: '',
        }
        this.modelType = {
            id: null,
            name: '',
            fullName: '',
        }
        this.address = {
            id: null,
            name: '',
        }
        this.serialNumber = ''
        this.dateOfInstallation = null
        this.warrantyToDate = null
        this.regulation = ''
        this.fuel = null
        this.detailPlaceOfInstallation = ''
        this.note = ''
        this.active = true
        this.status = 0
    }

    /**
     * @param response : object
     * @returns ApplianceEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.supplyPoint.id = response.supplyPoint.id
        obj.supplyPoint.ean = response.supplyPoint.ean
        obj.supplyPoint.commodity = response.supplyPoint.commodity
        obj.supplyPoint.address = response.supplyPoint.address
        obj.applianceType.id = response.applianceType.id
        obj.applianceType.name = response.applianceType.name
        obj.manufacturer.id = response.boilerManufacturer.id
        obj.manufacturer.name = response.boilerManufacturer.name
        obj.model.id = response.boilerModel.id
        obj.model.name = response.boilerModel.name
        obj.model.fullName = response.boilerModel.name
        obj.modelType.id = response.boilerType.id
        obj.modelType.name = response.boilerType.name
        obj.modelType.fullName = response.boilerType.name
        obj.address.id = response.address.id
        obj.address.name = response.address.fullName
        obj.serialNumber = response.serialNumber
        obj.dateOfInstallation = response.dateOfInstallation
        obj.warrantyToDate = response.warrantyToDate
        obj.regulation = response.regulation
        obj.fuel = response.fuel
        obj.detailPlaceOfInstallation = response.detailPlaceOfInstallation
        obj.note = response.note
        obj.active = response.active
        obj.status = response.status
        return obj
    }

}
