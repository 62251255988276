<template>
    <div>

        <Header icon="fad fa-users-crown" title="Subjekty"/>

        <SubjectGrid/>

    </div>
</template>

<script>
import Header from "../../Module/Common/UI/Header.vue"
import SubjectGrid from "@/Module/Subject/Component/SubjectGrid.vue"

export default {

    components: {
        Header,
        SubjectGrid,
    },

}
</script>
