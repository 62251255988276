<template>
    <div class="min-vh-100 bg-white">

        <div v-if="applicationEnabled">

            <BNavbar toggleable="lg" type="dark" variant="primary" class="shadow-sm mb-4" sticky>
                <BContainer class="bg-primary">

                    <BNavbarBrand href="/m">
              <span class="navbar-brand mb-0 h1">
                <i
                    class="fad fa-user-hard-hat text-warning mr-2 fa-fw"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Vývojové prostředí!"
                    v-if="development"
                />
                <i class="fad fa-user-hard-hat text-success mr-2"
                   data-toggle="tooltip"
                   data-placement="top"
                   title="Produktivní prostředí!"
                   v-if="!development"
                />
                Servis
              </span>
                    </BNavbarBrand>

                    <BNavbarToggle target="nav-collapse"/>

                    <BCollapse id="nav-collapse" is-nav>

                        <BNavbarNav>

                            <BNavItem :to="{name: 'new_mobile_home'}" active-class="active">
                                <i class="fad fa-home mr-3"/><span>Domů</span>
                            </BNavItem>

                            <BNavItem :to="{name: 'new_mobile_order_my_list'}" active-class="active">
                                <i class="fad fa-clipboard-list-check mr-3"/><span>Moje zakázky</span>
                            </BNavItem>

                            <BNavItem :to="{name: 'mobile_incidents'}" active-class="active">
                                <i class="fad fa-map-marker-exclamation mr-3"/><span>Incidenty k vyřešení</span>
                            </BNavItem>

                        </BNavbarNav>

                        <BNavbarNav class="ml-auto">

                            <BNavItem @click.prevent="logOut">
                                <i class="fad fa-sign-out-alt mr-3"/><span>Odhlásit</span>
                            </BNavItem>

                        </BNavbarNav>

                    </BCollapse>

                </BContainer>
            </BNavbar>

            <BContainer class="pb-4">
                <RouterView/>
            </BContainer>
        </div>

        <div class="text-center p-4" v-else>
            <div class="m-3">
                Aplikace vyžaduje pro současné i připravované funkcionality oprávnění pro fotoaparát a polohu.<br/>
                Pokud jste tuto možnost deaktivoval/-a, je nutné ji znovu povolit.
            </div>
            <BButton variant="primary" @click="reloadWindow()">Obnovit stránku</BButton>
        </div>

    </div>
</template>

<script>
export default {

    data() {
        return {
            development: (process.env.NODE_ENV !== "production"),
            applicationEnabled: true,
        }
    },

    methods: {

        logOut() {
            this.$store.dispatch("auth/logout")
            this.$router.push("/in")
        },

        checkPermissions() {

            navigator.permissions.query({name: "geolocation"})
                .then((result) => {
                    if (result.state !== "granted") {
                        this.applicationEnabled = false
                        this.tryGeolocation()
                    }
                })

        },

        tryGeolocation() {
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log("Latitude: " + position.coords.latitude)
                    console.log("Longitude:" + position.coords.longitude)
                },
                error => {
                    if (error.code === error.PERMISSION_DENIED) {
                        this.applicationEnabled = false
                    }
                },
            )
        },

        reloadWindow() {
            window.location.reload()
        },

    },

    mounted() {
        this.checkPermissions()
    },

}
</script>
