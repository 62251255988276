import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const AresService = {

    /**
     * @param query :string
     * @returns {Promise<any>}
     */
    getSubject(query) {
        return httpClient.get("v3/ares", {
            params: {
                query: query
            },
            headers: authHeader()
        })
    }

}

export default AresService
