export default class AssemblySheetApplianceTypeEntity {

    constructor() {
        this.id = null
        this.name = ""
    }

    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.name = response.name
        return obj
    }

}
