import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import "bootstrap-vue/dist/bootstrap-vue.css"
import BootstrapVue from "bootstrap-vue"
import VueMoment from "vue-moment"
import VueCookies from "vue-cookies"
import * as VueGoogleMaps from "vue2-google-maps"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import VueSignaturePad from "vue-signature-pad"
import "./Filters/vue.prettyBytes.filter"
import "./Filters/vue.prettyDate.filter"
import "./Filters/vue.shortify.filter"
import "./Filters/vue.moneyFormat.filter"


// Notification
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

const options = {
    position: "top-right",
    timeout: 3500,
    closeOnClick: true,
    pauseOnHover: true,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    maxToasts: 20,
    newestOnTop: true
}
Vue.use(Toast, options)

Vue.use(BootstrapVue)
Vue.use(VueMoment)
Vue.use(VueCookies)
Vue.use(DatePicker)
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyC0sbr5hAeQHfPoSGZaN4ZwVB2Yjseuc1w",
        libraries: "places,drawing,visualization",
    },
    installComponents: true
})
Vue.use(VueSignaturePad)

Vue.mixin({
    methods: {
        successMessage: function (text) {
            this.$toast(text, {
                type: 'success'
            })
        },
        errorMessage: function (text) {
            this.$toast(text, {
                type: 'error'
            })
        },
        warningMessage: function (text) {
            this.$toast(text, {
                type: 'warning'
            })
        },
        infoMessage: function (text, timeout = 3500) {
            this.$toast(text, {
                type: 'info',
                timeout: timeout
            })
        }
    },
})

// Temporarily until all users are gradually converted to the new form of authorization
if (localStorage.getItem("user") !== null) {
    let user = JSON.parse(localStorage.getItem("user"))
    if (user && user.accessToken) {
        localStorage.setItem("token", user.accessToken)
        localStorage.removeItem("user")
    }
}

let userToken = localStorage.getItem("token")
if (typeof userToken === "string" && userToken.length > 32) {
    (async () => store.dispatch("auth/getUser").then(() => {
    }))()
}

Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue() // Global event bus

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#vue-app")
