<template>

    <div class="bg-little-gray border-radius-sm">

        <div
            class="bg-danger text-white text-center mb-3 p-3 border-radius-sm"
            v-if="serviceOrderStatus.getItem(order.status) !== null && serviceOrderStatus.getItem(order.status).cancelled"
        >
            Tato zakázka byla zrušena!
        </div>

        <table class="table table-borderless mb-0">

            <tr class="border-bottom">
                <td class="w-5 text-primary text-center">
                    <i class="fad fa-barcode"/>
                </td>
                <td>
                    {{ order.supplyPoint.ean }}
                </td>
            </tr>

            <tr class="border-bottom">
                <td class="w-5 text-primary text-center">
                    <i class="fad fa-map-marker-check"/>
                </td>
                <td>
                    <a :href="'https://maps.google.cz/?daddr=_address_'.replace('_address_', encodeURIComponent(order.address))"
                       target="_blank">
                        {{ order.address }}
                    </a>
                    <div v-if="distance_hasResponse">
                        <em>
                            Vzdálenost: {{ distance_response.distance }}, Orientační doba dojezdu:
                            {{ distance_response.durationInTraffic }}
                        </em>
                    </div>
                </td>
            </tr>

            <tr
                class="border-bottom"
                v-if="order.supplyPoint.name || order.supplyPoint.floorNumber || order.supplyPoint.apartmentNumber"
            >
                <td class="w-5 text-primary text-center">
                    <i class="fad fa-info-circle"/>
                </td>
                <td>
                    <div v-if="order.supplyPoint.name">
                        <strong class="font-weight-600">Název OM:</strong> {{ order.supplyPoint.name }}
                    </div>
                    <div v-if="order.supplyPoint.floorNumber">
                        <strong class="font-weight-600">Patro:</strong> {{ order.supplyPoint.floorNumber }}
                    </div>
                    <div v-if="order.supplyPoint.apartmentNumber">
                        <strong class="font-weight-600">Číslo bytu:</strong> {{ order.supplyPoint.apartmentNumber }}
                    </div>
                </td>
            </tr>

            <tr class="border-bottom" v-if="order.customer">
                <td class="w-5 text-primary text-center">
                    <i class="fad fa-user-circle"/>
                </td>
                <td v-bind:class="{bold: order.payer === 1}">
                    {{ order.customerName }}
                </td>
            </tr>

            <tr class="border-bottom" v-if="order.propertyOwner">
                <td class="w-5 text-primary  text-center">
                    <i class="fad fa-building"/>
                </td>
                <td v-bind:class="{bold: order.payer === 2}">
                    {{ order.propertyOwnerName }}
                </td>
            </tr>

            <tr class="border-bottom">
                <td class="w-5 text-primary text-center">
                    <i class="fad fa-money-bill"/>
                </td>
                <td>
                    <div>
                        <strong class="font-weight-600">Plátce:</strong> {{ payer.print(order.payer) }}
                    </div>
                    <div>
                        <strong class="font-weight-600">Forma úhrady:</strong>
                        <span v-if="order.formOfPayment === 'invoice'"> fakturou</span>
                        <span v-if="order.formOfPayment === 'cash'"> hotově</span>
                    </div>
                    <div v-if="order.estimatedAmount && order.estimatedAmount > 0">
                        <strong class="font-weight-600">Předběžná smluvní cena:</strong>
                        {{ order.estimatedAmount|moneyFormat('CZK') }}
                    </div>
                </td>
            </tr>

            <tr class="border-bottom">
                <td class="w-5 text-primary text-center">
                    <i class="fad fa-clock"/>
                </td>
                <td>
                    {{ {startTime: order.scheduledStartTime, endTime: order.scheduledEndTime}|prettyDateRange }}
                </td>
            </tr>

            <tr v-if="order.contact.id !== null" class="border-bottom">
                <td class="w-5 text-primary text-center align-middle">
                    <i class="fad fa-phone-laptop"/>
                </td>
                <td>
                    <div
                        class="font-weight-600 mb-4"
                        v-if="order.contact.name && String(order.contact.name).length > 0"
                    >
                        {{ order.contact.name }}
                    </div>
                    <div v-if="order.contact.phone && order.contact.phone.length > 0">
                        <a :href="'tel:' + order.contact.phone" class="bold">{{ order.contact.phone }}</a>
                    </div>
                </td>
            </tr>

            <tr class="border-bottom">
                <td class="w-5 text-primary text-center align-middle">
                    <i class="fad fa-user-hard-hat"/>
                </td>
                <td>
                    {{ order.workerName }}
                </td>
            </tr>

            <tr v-if="order.supplyPoint.activeContract || order.supplyPoint.serviceContract > 0" class="border-bottom">
                <td class="w-5 text-primary text-center align-middle">
                    <i class="fad fa-wrench"/>
                </td>
                <td class="align-middle text-danger">
                    {{ serviceContractLevel.print(order.supplyPoint.serviceContract) }}
                </td>
            </tr>

            <tr v-if="order.note">
                <td class="w-5 text-primary text-center align-middle">
                    <i class="fad fa-sticky-note"/>
                </td>
                <td>
                    {{ order.note }}
                </td>
            </tr>

        </table>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import payer from "../../../Model/Enum/payer"
import ServiceContractLevel from "../../../Model/Enum/service_contract_level"
import ServiceOrderStatus from "../../../Model/Enum/service_order_status"
import OrderMobileEntity from "../../../NewModel/Order/Entity/OrderMobileEntity"

export default {

    props: {
        order: {
            type: OrderMobileEntity,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            distance_isLoading: "address/distance/isLoading",
            distance_hasResponse: "address/distance/hasResponse",
            distance_response: "address/distance/getResponse",
        }),
    },

    data() {
        return {
            payer: payer,
            serviceContractLevel: ServiceContractLevel,
            serviceOrderStatus: ServiceOrderStatus,
        }
    },

    mounted() {
        if (this.order.address.id) {
            let self = this
            navigator.geolocation.getCurrentPosition(
                position => {
                    self.loadDistance(position.coords.latitude, position.coords.longitude)
                }
            )
        }
    },

    methods: {

        loadDistance(gpsLat, gpsLon) {
            this.$store.dispatch("address/distance/getDistance", {
                id: this.order.address.id,
                gpsLat: gpsLat,
                gpsLon: gpsLon,
            })
                .catch(() => {
                    this.errorMessage("Chyba při získávání vzdálenosti")
                })
        },

    },

}
</script>
