import FileSaver from "file-saver"
import AttachmentService from "./AttachmentService"

const
    FILE_DOWNLOADING = "FILE_DOWNLOADING",
    FILE_DOWNLOADING_SUCCESS = "FILE_DOWNLOADING_SUCCESS",
    FILE_DOWNLOADING_ERROR = "FILE_DOWNLOADING_ERROR",
    FILE_UPLOADING = "FILE_UPLOADING",
    FILE_UPLOADING_SUCCESS = "FILE_UPLOADING_SUCCESS",
    FILE_UPLOADING_ERROR = "FILE_UPLOADING_ERROR",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isUploading: false,
    isDownloading: false,
    records: [],
    error: null,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param id : string
     * @param name : string
     * @returns {Promise<*>}
     */
    async downloadAttachment({commit}, {id, name}) {
        try {
            commit(FILE_DOWNLOADING)
            let response = await AttachmentService.downloadAttachment(id)
            commit(FILE_DOWNLOADING_SUCCESS, response.data)
            FileSaver.saveAs(response.data, response.headers["x-suggested-filename"] || name, true)
            return response.data
        } catch (error) {
            commit(FILE_DOWNLOADING_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     *
     * @param commit
     * @param files: Array
     * @returns {Promise<[]>}
     */
    async uploadAttachments({commit}, files) {
        try {
            commit(FILE_UPLOADING)
            let formData = new FormData()
            files.forEach(f => {
                formData.append("files[]", f)
            })
            let response = await AttachmentService.uploadAttachments(formData)
            commit(FILE_UPLOADING_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FILE_UPLOADING_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clearRecords({commit}) {
        commit(CLEAR_RECORDS)
    }

}

export const mutations = {

    [FILE_DOWNLOADING](state) {
        state.isDownloading = true
        state.error = null
    },
    [FILE_DOWNLOADING_SUCCESS](state) {
        state.isDownloading = false
        state.error = null
    },
    [FILE_DOWNLOADING_ERROR](state, error) {
        state.isDownloading = false
        state.error = error
    },

    [FILE_UPLOADING](state) {
        state.isUploading = true
        state.records = []
        state.error = null
    },
    [FILE_UPLOADING_SUCCESS](state, data) {
        state.isUploading = false
        state.records = data
        state.error = null
    },
    [FILE_UPLOADING_ERROR](state, error) {
        state.isUploading = false
        state.records = []
        state.error = error
    },

    [CLEAR_RECORDS](state, error) {
        state.isDownloading = false
        state.isUploading = false
        state.records = []
        state.error = error
    },

}

export const getters = {

    isDownloading(state) {
        return state.isDownloading
    },
    isUploading(state) {
        return state.isUploading
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasAttachments(state) {
        return state.records.length > 0
    },
    getAttachments(state) {
        return state.records
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
