<template>
    <tr>
        <td class="align-middle">
            <BFormInput
                v-model="request.name"
                :disabled="false === request._editable"
            />
        </td>
        <td class="align-middle">
            <BFormSelect
                v-model="request.applianceType"
                :options="applianceTypes"
                value-field="id"
                text-field="name"
                required
                :disabled="false === request._editable"
            >
                <template #first>
                    <option :value="null" disabled>Vybrat typ</option>
                </template>
            </BFormSelect>
        </td>
        <td class="align-middle">
            <BInputGroup append="kW">
                <BFormInput
                    type="number"
                    v-model.number="request.power"
                    step="0.1"
                    min="0"
                    max="999"
                    :disabled="false === request._editable"
                />
            </BInputGroup>
        </td>
        <td class="align-middle">
            <BFormSelect
                v-model="request.fuel"
                :options="list.fuel"
                value-field="id"
                text-field="text"
                :disabled="false === request._editable"
            />
        </td>
        <td class="align-middle text-center">
            <BFormCheckbox
                v-model="request.active"
                :value="true"
                :unchecked-value="false"
                switch
                button-variant="success"
                :disabled="false === request._editable"
            />
        </td>
        <td class="align-middle text-right">
            <BButton
                variant="danger"
                size="sm"
                v-on:click="request._editable = true"
                v-if="false === request._editable"
            >
                <i class="fad fa-pencil fa-fw"/>
            </BButton>

            <BButton
                variant="success"
                size="sm"
                v-on:click="processForm"
                v-if="request._editable"
                class="mr-3"
                :disabled="request.name.length === 0 || request.applianceType === null"
            >
                <i class="fad fa-check fa-fw"/>
            </BButton>
            <BButton
                variant="dark"
                size="sm"
                v-on:click="cancelSaveBoilerType"
                v-if="request._editable"
            >
                <i class="fad fa-times fa-fw"/>
            </BButton>
        </td>
    </tr>
</template>

<script>
import ApplianceModelTypeRequest from "../../../../NewModel/Appliance/ModelType/Request/ApplianceModelTypeRequest"
import Fuel from "../../../../Model/Enum/fuel"

export default {
    props: {
        request: {
            type: ApplianceModelTypeRequest,
            required: true
        },
        applianceTypes: {
            type: Array,
            default: () => [],
        },
        index: {
            type: Number,
            required: true
        },
    },

    data() {
        return {
            list: {
                fuel: Fuel.arrayList(),
            }
        }
    },

    methods: {

        processForm() {
            this.$emit('submitted', {
                request: this.request,
                index: this.index,
            })
        },

        cancelSaveBoilerType() {
            this.$emit('cancelled', {
                request: this.request,
                index: this.index,
            })
        },

    }

}
</script>
