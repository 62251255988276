import SubjectBasicEntity from "./Entity/SubjectBasicEntity"
import SubjectService from "./SubjectService"
import SubjectContactStore from "./Store/SubjectContactStore"
import SubjectContractsStore from "./Store/SubjectContractsStore"
import SubjectFilterEntity from "./Entity/SubjectFilterEntity"
import SubjectSupplyPointsStore from "./Store/SubjectSupplyPointsStore"
import SubjectOrdersStore from "./Store/SubjectOrdersStore"

const
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    FETCHING_FILTERED_RECORDS = "FETCHING_FILTERED_RECORDS",
    FETCHING_FILTERED_RECORDS_SUCCESS = "FETCHING_FILTERED_RECORDS_SUCCESS",
    FETCHING_FILTERED_RECORDS_ERROR = "FETCHING_FILTERED_RECORDS_ERROR",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    UPDATING_RECORD = "CREATING_RECORD",
    UPDATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isLoading: false,
    isProcessing: false,
    record: null,
    records: [],
    totalRecords: 0,
    error: null,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param request : SimpleFormRequest
     * @returns {Promise<*>}
     */
    async getFilteredRecords({commit}, request) {
        try {
            commit(FETCHING_FILTERED_RECORDS)
            let response = await SubjectService.filterSubjects(request)
            commit(FETCHING_FILTERED_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_FILTERED_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param query : String
     * @returns {Promise<*>}
     */
    async searchSubject({commit}, query) {
        try {
            commit(FETCHING_RECORDS)
            let response = await SubjectService.searchSubject(query)
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<[]>}
     */
    async getSubject({commit}, id) {
        try {
            commit(FETCHING_RECORD)
            let response = await SubjectService.getSubject(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param request : SubjectRequest
     * @returns {Promise<*>}
     */
    async createSubject({commit}, request) {
        try {
            commit(CREATING_RECORD)
            let response = await SubjectService.createSubject(request)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param request : SubjectRequest
     * @returns {Promise<*>}
     */
    async updateSubject({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await SubjectService.updateSubject(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    clear({commit}) {
        commit(CLEAR_RECORD)
    },

    clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

}

export const mutations = {

    [FETCHING_FILTERED_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_FILTERED_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.records.map(record => SubjectFilterEntity.from(record))
        state.totalRecords = data.totalRecords
    },
    [FETCHING_FILTERED_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.map(record => SubjectBasicEntity.from(record))
        state.totalRecords = data.length
    },
    [FETCHING_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = SubjectBasicEntity.from(data)
    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
    },

    [CREATING_RECORD](state) {
        state.isProcessing = true
        state.error = null
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isProcessing = false
        state.error = null
        state.record = SubjectBasicEntity.from(data)
    },
    [CREATING_RECORD_ERROR](state, error) {
        state.isProcessing = false
        state.error = error
    },

    [UPDATING_RECORD](state) {
        state.isProcessing = true
        state.error = null
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isProcessing = false
        state.error = null
        state.record = SubjectBasicEntity.from(data)
    },
    [UPDATING_RECORD_ERROR](state, error) {
        state.isProcessing = false
        state.error = error
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.error = null
        state.record = null
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.error = null
        state.records = []
        state.totalRecords = 0
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },

    /** @deprecated */
    isCreating(state) {
        return state.isProcessing
    },

    /** @deprecated */
    isUpdating(state) {
        return state.isProcessing
    },

    isProcessing(state) {
        return state.isProcessing
    },

    hasError(state) {
        return state.error !== null
    },

    error(state) {
        return state.error
    },

    hasSubject(state) {
        return state.record !== null
    },

    getSubject(state) {
        return state.record
    },

    hasSubjects(state) {
        return state.records.length > 0
    },

    getSubjects(state) {
        return state.records
    },

    getTotalSubjects(state) {
        return state.totalRecords
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        contacts: SubjectContactStore,
        contracts: SubjectContractsStore,
        supplyPoints: SubjectSupplyPointsStore,
        orders: SubjectOrdersStore,
    }
}
