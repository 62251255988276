<template>
    <div class="mb-5">

        <Header icon="fad fa-car-mechanic" :title="'#' + order.orderNumber">
            <template #buttons>
                <button
                    v-on:click.prevent="$router.go(-1)"
                    class="btn btn-info ml-3"
                >
                    <i class="fad fa-chevron-left mr-3"/>Zpět
                </button>
            </template>
        </Header>

        <div class="bg-white mb-4 px-2 py-3 border-radius-sm">
            <OrderInfo :order="order"/>
        </div>

        <OrderStatus :order="order"/>

        <h4 class="font-weight-500 text-dark mb-3">
            <i class="fad fa-satellite mr-3"/>Položky
        </h4>
        <ApplianceList class="mb-5" :order="order"/>

        <h4 class="font-weight-500 text-dark mb-3">
            <i class="fad fa-file-word mr-3"/>Dokumenty
        </h4>

        <div class="bg-white mb-5">
            <OrderAttachments
                :attachments="request.attachments"
                class="bg-little-gray p-4 mb-4"
                :assembly-sheet="request.assemblySheet"
                @update:assembly-sheet="updateAssemblySheet($event)"
                @update:attachments="updateAttachments($event)"
                slim
            />
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
            <h4 class="font-weight-500 text-dark mb-0">
                <i class="fad fa-sticky-note mr-3"/>Techn. poznámky
            </h4>
            <div class="buttons">
                <BButton
                    variant="success"
                    size="sm"
                    v-on:click="$router.push({name: 'new_mobile_order_add_note', params: { 'id': order.id }})"
                >
                    <i class="fad fa-plus-circle mr-2"/>Přidat
                </BButton>
            </div>
        </div>

        <TechnicalNotes :supply-point="order.supplyPoint.id" class="mb-5"/>

    </div>

</template>

<script>
import {mapGetters} from 'vuex'
import ApplianceList from "./Component/ApplianceList"
import Header from "../Common/UI/Header.vue"
import OrderAttachments from "..//Order/Component/Form/OrderAttachments.vue"
import OrderAttachmentsRequest from "../../NewModel/Order/Request/OrderAttachmentsRequest"
import OrderInfo from "./Component/OrderInfo.vue"
import OrderStatus from "./Component/OrderStatus.vue"
import TechnicalNotes from "./Component/TechnicalNotes"

export default {

    components: {
        ApplianceList,
        Header,
        OrderAttachments,
        OrderInfo,
        OrderStatus,
        TechnicalNotes,
    },

    data() {
        return {
            request: new OrderAttachmentsRequest(),
        }
    },

    computed: {
        ...mapGetters({
            order: 'order/getOrder',
            isUpdating: 'order/attachment/assignment/isUpdating',
        }),
    },

    mounted() {
        this.request = OrderAttachmentsRequest.fromOrder(this.order)
    },

    methods: {

        updateAssemblySheet(id) {
            this.request.assemblySheet = id
            this.saveAttachments()
        },

        updateAttachments(attachments) {
            this.request.attachments = attachments
            this.saveAttachments()
        },

        saveAttachments() {
            this.$store.dispatch("order/attachments/updateAttachments", {
                id: this.order.id,
                request: this.request,
            })
                .then(() => {
                    this.successMessage("Změny uloženy!")
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
