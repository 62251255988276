import WorkerService from "../../../Worker/WorkerService"
import OrderService from "@/NewModel/Order/OrderService";

const
    DELETING_RECORD = "DELETING_RECORD",
    DELETING_RECORD_SUCCESS = "DELETING_RECORD_SUCCESS",
    DELETING_RECORD_ERROR = "DELETING_RECORD_ERROR",

    CREATING_RECORDS = "CREATING_RECORDS",
    CREATING_RECORDS_SUCCESS = "CREATING_RECORDS_SUCCESS",
    CREATING_RECORDS_ERROR = "CREATING_RECORDS_ERROR"

const initialState = {
    isDeleting: false,
    isCreating: false
}

export const state = {...initialState}

export const actions = {

    async cancelMaterial({commit}, {order, material}) {
        try {
            commit(DELETING_RECORD)
            let response = await WorkerService.cancelOrderMaterial(order, material)
            commit(DELETING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(DELETING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     *
     * @param commit
     * @param id : string
     * @param request : OrderMaterialPickupRequest
     * @returns {Promise<*>}
     */
    async bookMaterial({commit}, {id, request}) {
        try {
            commit(CREATING_RECORDS)
            let response = await OrderService.createOrderMaterials(id, request)
            commit(CREATING_RECORDS_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(CREATING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    }

}

export const mutations = {

    [DELETING_RECORD](state) {
        state.isDeleting = true
        state.error = null
    },
    [DELETING_RECORD_SUCCESS](state) {
        state.isDeleting = false
        state.error = null
    },
    [DELETING_RECORD_ERROR](state, error) {
        state.isDeleting = false
        state.error = error
    },

    [CREATING_RECORDS](state) {
        state.isCreating = true
        state.error = null
    },
    [CREATING_RECORDS_SUCCESS](state) {
        state.isCreating = false
        state.error = null
    },
    [CREATING_RECORDS_ERROR](state, error) {
        state.isCreating = false
        state.error = error
    }

}

export const getters = {

    isDeleting(state) {
        return state.isDeleting
    },
    isCreating(state) {
        return state.isCreating
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
