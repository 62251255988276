<template>
    <div class="container-fluid p-4">
        <div class="d-flex justify-content-between align-items-center mb-4">
            <h3 class="font-weight-600 text-dark mb-0">
                <i class="fad fa-user-cog text-primary fa-fw mr-3"/>Uživatel
            </h3>
            <div class="buttons">
                <BButton
                    @click="editSignature = !editSignature"
                    variant="primary"
                    class="mt-3"
                >
                    Změnit podpis
                </BButton>
            </div>
        </div>

        <div v-if="isLoading && !hasUser" class="p-5 text-center">
            <i class="fad fa-spinner fa-fw fa-spin text-primary mr-4"/> Nahrávám uživatele
        </div>

        <div v-if="!isLoading && hasUser">
            <table class="table table-borderless mb-0">
                <tr>
                    <td class="align-middle font-weight-600 w-30">
                        E-mailová adresa:
                    </td>
                    <td class="align-middle">
                        {{ user.email }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="align-middle font-weight-600 w-30">
                        Celé jméno:
                    </td>
                    <td class="align-middle">
                        {{ user.fullName }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="align-middle font-weight-600 w-30">
                        Google kalendář:
                    </td>
                    <td class="align-middle">
                        {{ user.googleCalendar }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="align-middle font-weight-600 w-30">
                        Podpis:
                    </td>
                    <td class="align-middle">
                        <div class="border-primary" v-if="editSignature === false && user.signature">
                            <img
                                :src="'https://servis-api.lemigas.cz/signature?name=' + user.signature"
                                class="img-fluid"
                            />
                        </div>
                        <div class="border-primary" v-else>
                            <SignaturePad @signed="onSigned"/>
                        </div>

                    </td>
                </tr>
            </table>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import SignaturePad from "../AssemblySheet/Component/SignaturePad.vue"

export default {

    components: {
        SignaturePad,
    },

    computed: {
        ...mapGetters({
            isLoading: "worker/user/isLoading",
            isUpdating: "worker/user/isUpdating",
            hasUser: "worker/user/hasRecord",
            user: "worker/user/getRecord",
        })
    },

    data() {
        return {
            editSignature: false,
        }
    },

    mounted() {
        this.loadUser()
    },

    methods: {

        loadUser() {
            this.$store.dispatch("worker/user/getUser")
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        onSigned(signature) {
            let self = this
            this.$store.dispatch("worker/user/updateUser", {
                formData: {
                    signature: signature,
                }
            })
                .then(() => {
                    this.successMessage("Podpis byl změněn.")
                })
                .catch(error => {
                    this.errorMessage(error.response.data.message)
                })
                .finally(() => {
                    self.editSignature = false
                })
        },

    },

}
</script>
