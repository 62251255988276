import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const ApplianceService = {

    findAllActiveAppliances() {
        return httpClient.get("v3/appliances", {
            headers: authHeader()
        })
    },

    /**
     * @param request : SimpleFormRequest
     */
    filterAppliances(request) {
        return httpClient.post("v3/appliances/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    filter(serverParams) {
        return httpClient.get("v3/appliances/filter", {
            params: serverParams,
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getAppliance(id) {
        return httpClient.get("v3/appliances/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getApplianceIssues(id) {
        return httpClient.get("v3/appliances/" + id + "/issues", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getApplianceRepairs(id) {
        return httpClient.get("v3/appliances/" + id + "/repairs", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    dismountAppliance(id) {
        return httpClient.delete("v3/appliances/" + id + "/dismount", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    deleteAppliance(id) {
        return httpClient.delete("v3/appliances/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param request : ApplianceRequest
     */
    createAppliance(request) {
        return httpClient.post("v3/appliances", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @param request : ApplianceRequest
     */
    updateAppliance(id, request) {
        return httpClient.put("v3/appliances/" + id, request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getApplianceAttachments(id) {
        return httpClient.get("v3/appliances/" + id + "/attachments", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @param attachments : Array
     */
    addApplianceAttachment(id, attachments) {
        return httpClient.post("v3/appliances/" + id + "/attachments", attachments, {
            headers: authHeader()
        })
    },

    /**
     * @param appliance : Number
     * @param attachment : String
     */
    deleteApplianceAttachment(appliance, attachment) {
        return httpClient.delete("v3/appliances/" + appliance + "/attachments/" + attachment, {
            headers: authHeader()
        })
    },

    /**
     * @param appliance : Number
     * @param repair : String
     */
    deleteApplianceRepair(appliance, repair) {
        return httpClient.delete("v3/appliances/" + appliance + "/repairs/" + repair, {
            headers: authHeader()
        })
    },

    /**
     * @param request : RepairsPlanRequest
     */
    getRepairsMap(request) {
        return httpClient.post("v3/appliances/map/repairs", request, {
            headers: authHeader()
        })
    },

    /**
     * @param query : string
     * @returns {Promise<any>}
     */
    searchManufacturer({query}) {
        return httpClient.get("v3/appliances/search", {
            params: {
                type: "manufacturer",
                query: query,
            },
            headers: authHeader()
        })
    },

    /**
     * @param query : string
     * @param manufacturer : int|null
     * @returns {Promise<any>}
     */
    searchModel({query, manufacturer}) {
        return httpClient.get("v3/appliances/search", {
            params: {
                type: "model",
                query: query,
                manufacturer: manufacturer || null
            },
            headers: authHeader()
        })
    },

    /**
     * @param query : string
     * @param manufacturer : Number|null
     * @param model : Number|null
     * @returns {Promise<any>}
     */
    searchModelType({query, manufacturer, model}) {
        return httpClient.get("v3/appliances/search", {
            params: {
                type: "type",
                query: query,
                manufacturer: manufacturer || null,
                model: model || null,
            },
            headers: authHeader()
        })
    }

}

export default ApplianceService
