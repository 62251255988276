import AddressDistanceStore from "./Store/AddressDistanceStore"
import AddressService from "./AddressService"
import AddressWhispererStore from "./Store/AddressWhispererStore"

const
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD"

const initialState = {
    isCreating: false,
    record: null,
    error: null,
}


export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param request : AddressRequest
     * @returns {Promise<*>}
     */
    async createByAddress({commit}, request) {
        try {
            commit(CREATING_RECORD)
            let response = await AddressService.createByAddress(request)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param request : AddressParcelRequest
     * @returns {Promise<*>}
     */
    async createByParcel({commit}, request) {
        try {
            commit(CREATING_RECORD)
            let response = await AddressService.createByParcel(request)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

}

export const mutations = {

    [CREATING_RECORD](state) {
        state.isCreating = true
        state.error = null
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isCreating = false
        state.error = null
        state.record = data
    },
    [CREATING_RECORD_ERROR](state, error) {
        state.isCreating = false
        state.error = error
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.error = null
        state.record = null
    },

}

export const getters = {

    isCreating(state) {
        return state.isCreating
    },

    hasError(state) {
        return state.error !== null
    },

    error(state) {
        return state.error
    },

    hasAddress(state) {
        return state.record !== null
    },

    getAddress(state) {
        return state.record
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        distance: AddressDistanceStore,
        whisperer: AddressWhispererStore,
    }
}
