import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const RepairTypeService = {

    findAllActiveRepairTypes() {
        return httpClient.get("v3/repair_types", {
            headers: authHeader()
        })
    },

    /**
     * @param request : SimpleFormRequest
     */
    filterRepairTypes(request) {
        return httpClient.post("v3/repair_types/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    findRootRepairTypes() {
        return httpClient.get("v3/repair_types/root", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getRepairType(id) {
        return httpClient.get("v3/repair_types/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param request : RepairTypeRequest
     */
    createRepairType(request) {
        return httpClient.post("v3/repair_types", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @param request : RepairTypeRequest
     */
    updateRepairType(id, request) {
        return httpClient.put("v3/repair_types/" + id, request.toForm(), {
            headers: authHeader()
        })
    },

}

export default RepairTypeService
