<template>
    <div>

        <Header icon="fad fa-user-circle" title="Můj profil"/>

        <div class="bg-white p-3 border-radius-sm">
            <table class="table table-borderless mb-0">
                <tr class="border-light border-bottom">
                    <td class="font-weight-700 w-15">
                        Id:
                    </td>
                    <td>
                        {{ currentUser.id }}
                    </td>
                </tr>
                <tr class="border-light border-bottom">
                    <td class="font-weight-700 w-15">
                        Email:
                    </td>
                    <td>
                        {{ currentUser.email }}
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-700 w-15">
                        Oprávnění:
                    </td>
                    <td>
                        <ul class="mb-0">
                            <li v-for="(role,index) in currentUser.roles" :key="index">{{ role }}</li>
                        </ul>
                    </td>
                </tr>
            </table>
        </div>

    </div>
</template>

<script>
import Header from "../../Common/UI/Header.vue"

export default {

    components: {Header},

    computed: {
        currentUser() {
            return this.$store.state.auth.user
        },
    },

}
</script>
