<template>
    <div>

        <div
            class="text-center text-dark m-0 p-4 display-4 d-flex justify-content-center align-items-center"
            v-if="isLoading && !hasOrder"
        >
            <i class="fad fa-spinner fa-fw fa-2x fa-spin text-danger mr-4"/>
            <strong>Nahrávám zakázku ...</strong>
        </div>

        <RouterView v-if="hasOrder"/>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {

    computed: {
        ...mapGetters({
            isLoading: 'order/isLoading',
            hasOrder: 'order/hasOrder',
            order: 'order/getOrder',
        }),
    },

    mounted() {
        this.$store.dispatch("order/clear")
        this.loadOrder(this.$route.params.id)
    },

    watch: {
        $route() {
            this.loadOrder(this.$route.params.id)
        },
    },

    methods: {

        loadOrder(id) {
            this.$store.dispatch("order/getOrder", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
