<template>
    <div class="bg-white border-radius-sm px-3 mb-4">
        <div class="d-flex justify-content-between align-items-center border-light border-bottom py-3 pointer">
            <div>
                <i class="fad fa-user fa-fw mr-2 text-primary"/>{{ record.customer }}
            </div>
            <div v-if="record.eventTime">
                {{ record.eventTime|prettyDateTimeShort }}<i class="fad fa-calendar-week fa-fw ml-2 text-primary"/>
            </div>
        </div>
        <div class="border-light border-bottom py-3">
            {{ record.description }}
        </div>
        <div class="d-flex justify-content-between align-items-center py-3">
            <strong>Stav:</strong>
            <span v-if="record.solved" class="text-success">vyřešeno</span>
            <BLink class="text-danger" v-else @click.prevent="resolveIncident(record.id)"
                   style="text-decoration-style: dashed; text-decoration-line: underline; text-decoration-thickness: 1px;">
                nevyřešeno
            </BLink>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    props: ['record'],

    computed: {
        ...mapGetters({
            isLoading: 'incident/isLoading',
            hasRecords: 'incident/hasIncidents',
            records: 'incident/getIncidents',
        }),
    },

    methods: {

        resolveIncident(id) {
            this.$bvModal.msgBoxConfirm('Opravu označit tento incident jako vyřešený?', {
                title: 'Potvrzení',
                okVariant: 'primary',
                cancelVariant: 'default',
                centered: true,
                okTitle: "Ano",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("incident/resolveIncident", id)
                            .then(() => {
                                this.$emit('updated')
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

    },

}
</script>
