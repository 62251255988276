import SubjectService from "../SubjectService"
import SubjectSupplyPointEntity from "../Entity/SubjectSupplyPointEntity"

const
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isLoading: false,
    records: [],
}

export const state = {...initialState}

export const actions = {

    async loadSupplyPoints({commit}, id) {
        try {
            commit(FETCHING_RECORDS)
            let response = await SubjectService.getSubjectSupplyPoints(id)
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

}

export const mutations = {

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.records = []
    },
    [FETCHING_RECORDS_SUCCESS](state, records) {
        state.isLoading = false
        state.records = records.map(supplyPoint => SubjectSupplyPointEntity.from(supplyPoint))
    },
    [FETCHING_RECORDS_ERROR](state) {
        state.isLoading = false
        state.records = []
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.records = []
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    }, hasSupplyPoints(state) {
        return state.records.length > 0
    }, getSupplyPoints(state) {
        return state.records
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
