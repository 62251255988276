import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const ProfileService = {

    getIncidents() {
        return httpClient.get("v3/profile/incidents", {headers: authHeader()})
    }

}

export default ProfileService
