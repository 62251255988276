<template>
    <BButton
        variant="warning"
        class="text-left"
        v-on:click.prevent="dismountAppliance()"
        :disabled="isProcessing"
        block
    >
        <i class="fad fa-spinner-third fa-fw fa-spin mr-2" v-if="isProcessing"/>
        <i class="fad fa-times-circle fa-fw mr-2" v-else/>
        Demontovat spotřebič
    </BButton>
</template>

<script>
import ApplianceEntity from "@/NewModel/Appliance/Entity/ApplianceEntity"
import Sweetalert2 from "sweetalert2"
import {mapGetters} from "vuex"

export default {

    computed: {
        ...mapGetters({
            isProcessing: 'appliance/isProcessing',
        }),
    },

    props: {
        appliance: {
            type: ApplianceEntity,
            required: true,
        },
    },

    methods: {

        dismountAppliance() {
            Sweetalert2.fire({
                title: 'Jste si jisti?',
                text: "Tato činnosti povede k deaktivaci spotřebiče, vyjmutí z aktivních zakázek apod.!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#439e55',
                cancelButtonColor: '#db2f36',
                confirmButtonText: 'Ano, vím co dělám!',
                cancelButtonText: 'Ne'
            })
                .then((result) => {
                    if (result.value) {
                        this.$store.dispatch("appliance/dismountAppliance", this.appliance.id)
                            .then(() => {
                                this.successMessage('Spotřebič byl označen jako demontovaný.');
                            })
                            .catch((error) => {
                                    this.errorMessage(error.response.data.message)
                                }
                            )
                    }
                })
        },

    },

}
</script>
