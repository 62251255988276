<template>
    <div>
        <div class="py-2 px-3 bg-light">
            <strong>Zpráva zákazníkovi:</strong>
        </div>

        <div class="py-2 px-3 mb-5">
            <div>
                Před potvrzením montážního listu ověřte správnost všech údajů. Na pozdější reklamace nebude brán
                zřetel.
            </div>
            <div class="mb-3">
                Dněšního dne zhotovitel předává a objednavatel přebírá předmět zakázky specifikované v montážním
                listu.
                Dílo je předáno bez vad.
            </div>

            <div
                style="padding: 5px; border: 2px solid #b4b4b6; min-height: 100px;"
                v-html="formatBreakLines(assemblySheet.messageForCustomer)"
            />

        </div>
    </div>
</template>

<script>
import AssemblySheetEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetEntity"

export default {
    props: {
        assemblySheet: {
            type: AssemblySheetEntity,
            required: true,
        },
    },

    methods: {

        formatBreakLines(text) {
            return text.replace(/\n/g, "<br />")
        },

    },

}
</script>
