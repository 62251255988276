import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const MaterialService = {

    /**
     * @param request : SimpleFormRequest
     */
    filterMaterials(request) {
        return httpClient.post("v3/materials/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param code : string
     * @returns {Promise<any>}
     */
    searchMaterial(code) {
        return httpClient.get("v3/materials/search", {
                params: {
                    code: code
                },
                headers: authHeader()
            }
        )
    }

}

export default MaterialService
