<template>
    <div>
        <RouterView v-if="!isLoading && hasOrder"/>

        <div class="p-3 mb-3" v-if="isLoading">
            <i class="fad text-danger fa-spinner fa-spin mr-4"/>Nahrávám ...
        </div>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {

    computed: {
        ...mapGetters({
            isLoading: 'order/isLoading',
            hasOrder: 'order/hasOrder',
            order: 'order/getOrder',
        })
    },

    mounted() {
        this.$store.dispatch("order/getMobileOrder", this.$route.params.id)
            .catch((error) => {
                this.errorMessage(error.response.data.message)
            })
    },

}
</script>
