export default class UserRequest {

    constructor() {
        this.email = ""
        this.fullName = ""
        this.active = true
        this.workerKey = null
        this.googleCalendar = ""
        this.googleCalendarColor = ""
        this.roles = []
        this.newPassword = null
    }

    static from(response) {

        let req = new this
        req.email = response.email
        req.fullName = response.fullName
        req.active = response.active
        req.workerKey = response.workerKey || null
        req.googleCalendar = response.googleCalendar || ""
        req.googleCalendarColor = response.googleCalendarColor || ""
        req.roles = response.roles
        return req
    }

    toForm() {
        return this
    }

}
