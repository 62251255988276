<template>
    <div>

        <Header icon="fad fa-industry" title="Výrobci spotřebičů">
            <template #buttons>
                <PrimaryLinkButton
                    to="/setup/boilers/manufacturers/create"
                    icon="fad fa-plus-circle"
                    text="Přidat výrobce spotřebiče"
                />
            </template>
        </Header>

        <BoilerManufacturersList/>

    </div>
</template>

<script>
import BoilerManufacturersList from "./Component/BoilerManufacturersList.vue"
import Header from "../../Common/UI/Header.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        BoilerManufacturersList,
        Header,
        PrimaryLinkButton,
    },

}
</script>
