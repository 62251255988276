<template>
  <table class="table table-fit table-borderless table-hover mb-0">
    <tbody>
      <search-client-table-row v-for="client in clients" v-bind:key="client.id" :client="client" />
    </tbody>
  </table>
</template>

<script>
import SearchClientTableRow from "./SearchClientTableRow";

export default {
  components: {SearchClientTableRow},
  props: ['clients'],
}
</script>
