export default class SupplyPointTechnicalNoteRequest {

    constructor() {
        this.target = ""
        this.appliance = null
        this.message = ""
    }

    toForm() {
        return {
            target: this.target,
            appliance: this.appliance,
            message: this.message,
        }
    }

}
