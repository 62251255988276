<template>
    <div class="bg-little-gray p-4 h-100">

        <div class="row no-gutters d-flex align-items-center">
            <div class="col mr-2">
                <div class="font-weight-bold text-uppercase mb-3">
                    Nezpracované zakázky
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ value }}
                </div>
            </div>
            <div class="col-auto">
                <i class="fa-solid fa-calendar-clock fa-2xl"/>
            </div>
        </div>

    </div>
</template>

<script>
export default {

    props: {
        value: {
            type: Number,
            required: true,
            default: 0,
        },
    },

}
</script>
