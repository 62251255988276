import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const AssemblySheetService = {

    getAssemblySheet(id) {
        return httpClient.get("v3/assembly_sheets/" + id, {headers: authHeader()})
    },

    updateAssemblySheet(id, formData) {
        return httpClient.put("v3/assembly_sheets/" + id, formData, {headers: authHeader()})
    },

    signingAssemblySheet(id, formData) {
        return httpClient.post("v3/assembly_sheets/" + id + "/signing", formData, {headers: authHeader()})
    },

    recalculationAssemblySheet(id) {
        return httpClient.get("v3/assembly_sheets/" + id + "/recalculate", {headers: authHeader()})
    },

    createAppliance(id, formData) {
        return httpClient.post("v3/assembly_sheets/" + id + "/appliances", formData, {headers: authHeader()})
    },

    findAvailableRepairTypes(id, query) {
        return httpClient.get("v3/assembly_sheets/" + id + "/available_repair_types", {
            headers: authHeader(),
            params: {
                query: query
            }
        })
    },

    findAvailableApplianceTypes(id) {
        return httpClient.get("v3/assembly_sheets/" + id + "/available_appliance_types", {headers: authHeader()})
    },

    findAvailableAppliances(id) {
        return httpClient.get("v3/assembly_sheets/" + id + "/available_appliances", {headers: authHeader()})
    },

    createApplianceRepair(assemblySheetId, applianceId, formData) {
        let url = "v3/assembly_sheets/" + assemblySheetId + "/appliances/" + applianceId + "/repairs"
        return httpClient.post(url, formData, {headers: authHeader()})
    },

    updateApplianceRepair(assemblySheetId, applianceId, repairId, formData) {
        let url = "v3/assembly_sheets/" + assemblySheetId + "/appliances/" + applianceId + "/repairs/" + repairId
        return httpClient.put(url, formData, {headers: authHeader()})
    },

    deleteApplianceRepair(assemblySheetId, applianceId, repairId) {
        let url = "v3/assembly_sheets/" + assemblySheetId + "/appliances/" + applianceId + "/repairs/" + repairId
        return httpClient.delete(url, {headers: authHeader()})
    },

    createMaterial(assemblySheetId, formData) {
        let url = "v3/assembly_sheets/" + assemblySheetId + "/materials"
        return httpClient.post(url, formData, {headers: authHeader()})
    },

    updateMaterial(assemblySheetId, materialId, formData) {
        let url = "v3/assembly_sheets/" + assemblySheetId + "/materials/" + materialId
        return httpClient.put(url, formData, {headers: authHeader()})
    },

    deleteMaterial(assemblySheetId, materialId) {
        let url = "v3/assembly_sheets/" + assemblySheetId + "/materials/" + materialId
        return httpClient.delete(url, {headers: authHeader()})
    },

    createItem(assemblySheetId, formData) {
        let url = "v3/assembly_sheets/" + assemblySheetId + "/items"
        return httpClient.post(url, formData, {headers: authHeader()})
    },

    updateItem(assemblySheetId, itemId, formData) {
        let url = "v3/assembly_sheets/" + assemblySheetId + "/items/" + itemId
        return httpClient.put(url, formData, {headers: authHeader()})
    },

    deleteItem(assemblySheetId, itemId) {
        let url = "v3/assembly_sheets/" + assemblySheetId + "/items/" + itemId
        return httpClient.delete(url, {headers: authHeader()})
    }

}

export default AssemblySheetService
