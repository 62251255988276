import _ from "lodash"

const SubjectTypeList = [
    {id: 1, text: "Právnická osoba / Podnikatel", short_text: "PO", company: true},
    {id: 2, text: "Fyzická osoba", short_text: "FO", company: false},
]

export default class SubjectType {

    constructor(id) {
        this.selected = id !== null ? id : null
        this.items = {...SubjectTypeList}
    }

    toString() {
        let o = _.find(this.items, {id: this.selected})
        return o === undefined ? "N/A" : o.text
    }

    static print(id) {
        let o = _.find({...SubjectTypeList}, {id: parseInt(id)})
        return o === undefined ? "N/A" : o.text
    }

    static list() {
        return {...SubjectTypeList}
    }

    static getItem(id) {
        let o = _.find({...SubjectTypeList}, {id: parseInt(id)})
        return o === undefined ? null : o
    }

}
