<template>
    <div
        class="d-flex flex-column flex-lg-row justify-content-start align-items-center bg-little-gray py-3 border-radius-sm mb-4">
        <div class="w-20 d-flex align-items-center px-3">
            <i class="fad fa-circle fa-fw text-danger fa-2x mr-2" v-if="record.workerStatus === 7"/>
            <span class="font-weight-300" v-if="record.workerStatus === 7">Zrušeno</span>
            <i class="fad fa-circle fa-fw text-success fa-2x mr-2" v-if="record.workerStatus === 2"/>
            <span class="font-weight-300" v-if="record.workerStatus === 2">Vyřízeno</span>
            <i class="fad fa-circle fa-fw text-warning fa-2x mr-2" v-if="record.workerStatus === 1"/>
            <span class="font-weight-300" v-if="record.workerStatus === 1">Na místě</span>
            <i class="fad fa-circle fa-fw text-primary fa-2x mr-2" v-if="record.workerStatus === 0"/>
            <span class="font-weight-300" v-if="record.workerStatus === 0">Nepřevzato</span>
        </div>
        <div class="w-12 font-weight-600">
            {{ {startTime: record.startTime, endTime: record.endTime}|prettyDateRange(true, false, false) }}
        </div>
        <div class="w-12">
            <BLink :to="'/worker/orders/' + record.id">#{{ record.sequenceNumber }}</BLink>
        </div>
        <div class="flex-grow-1 font-weight-300">
            {{ record.location }} - {{ record.customer }}
        </div>
        <div class="w-8 text-center">
            <BDropdown variant="link" dropleft toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <i class="fas fa-ellipsis-h fa-fw text-dark fa-2x"/>
                </template>
                <BDropdownText text-class="text-center font-weight-600 text-primary border-bottom pb-2">
                    #{{ record.sequenceNumber }}
                </BDropdownText>
                <BDropdownItem href="#" link-class="py-2" :to="'/worker/orders/' + record.id">
                    Zobrazit zakázku
                </BDropdownItem>
                <BDropdownItem
                    href="#"
                    link-class="py-2"
                    :to="'/worker/assembly_sheets/' + record.assemblySheet + '/edit'"
                    v-if="record.assemblySheetFinished === false && record.assemblySheet"
                >
                    Zpracovat montážní list
                </BDropdownItem>
                <BDropdownItem
                    href="#"
                    link-class="py-2"
                    :to="'/worker/assembly_sheets/' + record.assemblySheet + '/'"
                    v-if="record.assemblySheetFinished === true && record.assemblySheet"
                >
                    Zobrazit montážní list
                </BDropdownItem>
            </BDropdown>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        record: {
            type: Object,
            required: false,
        },
    },

}
</script>
