import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const SupplyPointService = {

    /**
     * @param request : SimpleFormRequest
     */
    filterSupplyPoints(request) {
        return httpClient.post("v3/supply_points/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    getSupplyPoint(id) {
        return httpClient.get("v3/supply_points/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param request : SupplyPointMapRequest
     */
    getSupplyPointsMap(request) {
        return httpClient.post("v3/supply_points/map", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param request : SupplyPointRequest
     */
    createSupplyPoint(request) {
        return httpClient.post("v3/supply_points", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param request : SupplyPointRequest
     */
    updateSupplyPoint(id, request) {
        return httpClient.put("v3/supply_points/" + id, request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param request : SupplyPointBlockRequest
     */
    blockSupplyPoint(id, request) {
        return httpClient.put("v3/supply_points/" + id + "/blocking", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    getSupplyPointAppliances(id) {
        return httpClient.get("v3/supply_points/" + id + "/appliances", {headers: authHeader()})
    },

    /**
     * @param id : string
     */
    getSupplyPointNotes(id) {
        return httpClient.get("v3/supply_points/" + id + "/notes", {headers: authHeader()})
    },

    /**
     * @param id : string
     */
    getSupplyPointTechnicalNotes(id) {
        return httpClient.get("v3/supply_points/" + id + "/notes/technical", {headers: authHeader()})
    },

    /**
     * @param id : string
     */
    getSupplyPointOrders(id) {
        return httpClient.get("v3/supply_points/" + id + "/orders", {headers: authHeader()})
    },

    /**
     * @param id : string
     */
    getSupplyPointMeta(id) {
        return httpClient.get("v3/supply_points/" + id + "/meta", {headers: authHeader()})
    },

    /**
     * @param id : string
     * @param request : SupplyPointNoteRequest
     */
    createNote(id, request) {
        return httpClient.post("v3/supply_points/" + id + "/notes", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param request : SupplyPointTechnicalNoteRequest
     */
    createTechnicalNote(id, request) {
        return httpClient.post("v3/supply_points/" + id + "/notes/technical", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param request : ApplianceRequest
     */
    createAppliance(id, request) {
        return httpClient.post("v3/supply_points/" + id + "/appliances", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param supplyPoint : string
     * @param appliance : int
     * @param request : ApplianceRequest
     * @deprecated
     */
    updateAppliance(supplyPoint, appliance, request) {
        return httpClient.post("v3/supply_points/" + supplyPoint + "/appliances/" + appliance, request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    createOrder(id) {
        return httpClient.post("v3/supply_points/" + id + "/orders", {}, {
            headers: authHeader()
        })
    },

    /**
     * @param query : string
     */
    searchClients(query) {
        return httpClient.get("v3/supply_points/clients", {
                params: {
                    query: query
                },
                headers: authHeader()
            }
        )
    },

}

export default SupplyPointService
