import WorkerApplianceTechnicalNoteStore from "./Store/WorkerApplianceTechnicalNoteStore"
import WorkerApplianceDetailStore from "./Store/WorkerApplianceDetailStore"

const initialState = {
    isLoading: false,
    error: null,
    order: null
}

export const state = {...initialState}
export const actions = {}

export const mutations = {}

export const getters = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        detail: WorkerApplianceDetailStore,
        note: WorkerApplianceTechnicalNoteStore
    }
}
