export default class OrderChangeStatusRequest {

    constructor() {
        this.workerStatus = null
        this.cancelReasonStatus = null
    }

    toForm() {
        return this
    }

}
