import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const DashboardService = {

    getQuickOrdersReview() {
        return httpClient.get("v3/dashboard/orders/quick", {headers: authHeader()})
    },

    getStatusOfTodayOrders() {
        return httpClient.get("v3/dashboard/orders/today", {headers: authHeader()})
    },

}

export default DashboardService
