import WorkerOrderStore from "./Order/WorkerOrderStore"
import WorkerSupplyPointStore from "./SupplyPoint/WorkerSupplyPointStore"
import WorkerApplianceStore from "./Appliance/WorkerApplianceStore"
import WorkerUserStore from "./User/WorkerUserStore"

export default {
    namespaced: true,
    modules: {
        appliance: WorkerApplianceStore,
        order: WorkerOrderStore,
        supplyPoint: WorkerSupplyPointStore,
        user: WorkerUserStore
    }
}
