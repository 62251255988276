<template>
    <div>

        <Header icon="fad fa-satellite" title="Druhy spotřebičů">
            <template #buttons>
                <PrimaryLinkButton
                    to="/setup/appliance_types/create"
                    icon="fad fa-plus-circle"
                    text="Přidat druh spotřebiče"
                />
            </template>
        </Header>

        <ApplianceTypesList/>

    </div>
</template>

<script>
import ApplianceTypesList from "./Component/ApplianceTypesList.vue"
import Header from "../../Common/UI/Header.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        ApplianceTypesList,
        Header,
        PrimaryLinkButton,
    },

}
</script>
