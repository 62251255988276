<template>
    <BButton variant="danger" class="text-left" v-on:click.prevent="deleteAppliance()" block :disabled="isProcessing">
        <i class="fad fa-spinner-third fa-fw fa-spin mr-2" v-if="isProcessing"/>
        <i class="fad fa-trash-alt fa-fw mr-2" v-else/>
        Odstranit spotřebič
    </BButton>
</template>

<script>
import ApplianceEntity from "@/NewModel/Appliance/Entity/ApplianceEntity"
import {mapGetters} from "vuex"

export default {

    computed: {
        ...mapGetters({
            isProcessing: 'appliance/isProcessing',
        }),
    },

    props: {
        appliance: {
            type: ApplianceEntity,
            required: true,
        },
    },

    methods: {

        deleteAppliance() {
            this.$bvModal.msgBoxConfirm('Opravdu chcete odstranit tento spotřebič?', {
                title: 'Potvrzení',
                okVariant: 'primary',
                cancelVariant: 'default',
                centered: true,
                okTitle: 'Ano, odstranit.',
                cancelTitle: 'Ne'
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch('appliance/deleteAppliance', this.appliance.id)
                            .then((result) => {
                                if (result) {
                                    this.successMessage('Odstraněno.')
                                    this.$router.push({
                                        name: 'supply_point_show',
                                        params: {
                                            id: this.appliance.supplyPoint.id
                                        }
                                    })
                                } else {
                                    this.warningMessage('Při odstranění spotřebiče nastala chyba.')
                                }
                            })
                            .catch((error) => {
                                    this.errorMessage(error.response.data.message)
                                }
                            )
                    }
                })
        },

    },

}
</script>
