import _ from "lodash"

const FuelList = [
    {id: 0, text: "Bez paliva"},
    {id: 1, text: "Plyn"},
    {id: 2, text: "Elektro"},
    {id: 3, text: "Dřevo"},
    {id: 4, text: "Uhlí"},
    {id: 9, text: "Neznámé"}
]

export default class Fuel {

    constructor(id) {
        this.selected = id !== null ? id : null
        this.items = {...FuelList}
    }

    toString() {
        let o = _.find(this.items, {id: this.selected})
        return o === undefined ? "N/A" : o.text
    }

    static print(id) {
        let o = _.find({...FuelList}, {id: parseInt(id)})
        return o === undefined ? "N/A" : o.text
    }

    static list() {
        return {...FuelList}
    }

    static arrayList() {
        return FuelList
    }

    static getItem(id) {
        let o = _.find({...FuelList}, {id: parseInt(id)})
        return o === undefined ? null : o
    }

}
