import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const WorkerService = {

    getOrder(id) {
        return httpClient.get("workers/orders/" + id, {headers: authHeader()})
    },

    findOrdersOfWorkerOfRequiredDate(date) {
        return httpClient.get("workers/orders/date/" + date, {headers: authHeader()})
    },

    cancelOrderMaterial(order, id) {
        return httpClient.delete("workers/orders/" + order + "/materials/" + id, {headers: authHeader()})
    },

    bookOrderMaterial(id, form) {
        return httpClient.post("workers/orders/" + id + "/materials", form, {headers: authHeader()})
    },

    findOrderMaterials(id) {
        return httpClient.get("workers/orders/" + id + "/materials", {headers: authHeader()})
    },

    findOrderItems(id) {
        return httpClient.get("workers/orders/" + id + "/items", {headers: authHeader()})
    },

    findOrderAttachments(id) {
        return httpClient.get("workers/orders/" + id + "/attachments", {headers: authHeader()})
    },

    changeWorkerStatus(order, workerStatus, cancelStatus) {
        return httpClient.put("workers/orders/" + order + "/change_worker_status", {
            workerStatus,
            cancelStatus
        }, {headers: authHeader()})
    },

    getMapOfOrders(date) {
        return httpClient.get("workers/orders/map/" + date, {headers: authHeader()})
    },


    // - SupplyPoint

    findSupplyPointTechnicalNotes(id) {
        return httpClient.get("workers/supply_points/" + id + "/technical_notes", {headers: authHeader()})
    },

    addTechnicalNoteSupplyPoint(id, formData) {
        return httpClient.post("workers/supply_points/" + id + "/technical_notes", formData, {headers: authHeader()})
    },

    getDetailOfSupplyPoint(id) {
        return httpClient.get("workers/supply_points/" + id, {headers: authHeader()})
    },

    // - Appliance

    addTechnicalNoteAppliance(id, formData) {
        return httpClient.post("workers/appliances/" + id + "/technical_notes", formData, {headers: authHeader()})
    },

    getDetailOfAppliance(id) {
        return httpClient.get("workers/appliances/" + id, {headers: authHeader()})
    },

    // My

    findMyIncidents() {
        return httpClient.get("incidents/my", {headers: authHeader()})
    },

    // - KPI

    getWeeklyOrderPerformance() {
        return httpClient.get("workers/kpi/orders/weekly", {headers: authHeader()})
    },

    createAssemblySheet(order) {
        return httpClient.post("workers/orders/" + order + "/assembly_sheet", {}, {headers: authHeader()})
    },

    getUser() {
        return httpClient.get("workers/user", {headers: authHeader()})
    },

    updateUser(formData) {
        return httpClient.put("workers/user", formData, {headers: authHeader()})
    },

}

export default WorkerService
