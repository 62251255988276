import authHeader from "../../../Model/Common/AuthHeader"
import {httpClient} from "../../../Model/Common"

const ApplianceModelTypeService = {

    findAllActiveModelTypes() {
        return httpClient.get("v3/model_types", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getModelType(id) {
        return httpClient.get("v3/model_types/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param request : ApplianceModelTypeRequest
     */
    createModelType(request) {
        return httpClient.post("v3/model_types", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @param request : ApplianceModelTypeRequest
     */
    updateModelType(id, request) {
        return httpClient.put("v3/model_types/" + id, request.toForm(), {
            headers: authHeader()
        })
    },

}

export default ApplianceModelTypeService
