<template>
    <div>
        <div class="bg-little-gray p-4" v-if="records.length > 0">
            <table class="table table-fit table-borderless mb-0">
                <thead>
                <tr>
                    <th class="align-middle text-left w-15">
                        Datum
                    </th>
                    <th class="align-middle w-15">
                        Zakázka
                    </th>
                    <th class="align-middle">
                        Položky
                    </th>
                    <th class="align-middle text-center w-10">
                        Technik
                    </th>
                    <th class="align-middle text-right w-15">
                        Stav
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="record in records" v-bind:key="record.id" class="border-top">
                    <td class="align-middle text-left">
                        {{ record.startTime|prettyDate }}
                    </td>
                    <td class="align-middle">
                        {{ record.sequenceNumber }}
                    </td>
                    <td class="align-middle">
                        {{ record.includedRepairs.join(", ") }}
                    </td>
                    <td class="align-middle text-center">
                        <BAvatar
                            size="md"
                            :src="record.worker.picture || null"
                            :text="record.worker.acronym"
                            v-if="record.worker"
                        />
                        <BAvatar size="md" variant="light" v-else/>
                    </td>
                    <td class="align-middle text-right">
                        <span class="font-weight-300" v-if="record.workerStatus === 7">Zrušeno</span>
                        <span class="font-weight-300" v-if="record.workerStatus === 2">Vyřízeno</span>
                        <span class="font-weight-300" v-if="record.workerStatus === 1">Na místě</span>
                        <span class="font-weight-300" v-if="record.workerStatus === 0">Nepřevzato</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        records: {
            required: true,
        },
    },

}
</script>
