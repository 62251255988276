<template>
    <div>

        <Header icon="fad fa-piggy-bank" title="Slevy">
            <template #buttons>
                <PrimaryLinkButton
                    to="/discounts/create"
                    icon="fad fa-plus-circle"
                    text="Vytvořit slevu"
                />
            </template>
        </Header>

        <DiscountsGrid class="mb-4"/>

    </div>
</template>

<script>
import DiscountsGrid from "./Component/DiscountsGrid.vue"
import Header from "../Common/UI/Header.vue"
import PrimaryLinkButton from "../Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        DiscountsGrid,
        Header,
        PrimaryLinkButton,
    },

}
</script>
