<template>
    <div>

        <Header icon="fad fa-users-cog" title="Uživatelé">
            <template #buttons>
                <PrimaryLinkButton
                    to="/setup/users/create"
                    icon="fad fa-plus-circle"
                    text="Přidat uživatele"
                />
            </template>
        </Header>

        <UsersList/>

    </div>
</template>

<script>
import Header from "../../Common/UI/Header.vue"
import UsersList from "./Component/UsersList.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        Header,
        PrimaryLinkButton,
        UsersList,
    },

}
</script>
