export default class ApplianceModelTypeEntity {

    constructor() {
        this.id = null
        this.name = ""
        this.fullName = ""
        this.applianceType = {
            id: null,
            name: ""
        }
        this.description = ""
        this.power = 0
        this.fuel = 0
    }

    /**
     * @param response : object
     * @returns ApplianceModelTypeEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.fullName = response.fullName
        obj.applianceType.id = response.applianceType.id
        obj.applianceType.name = response.applianceType.name
        obj.description = response.description
        obj.active = response.active
        obj.power = response.power
        obj.fuel = response.fuel
        return obj
    }

    to() {
        return this.id
    }

}
