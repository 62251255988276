import MaterialService from "../../MaterialService"

const
    FETCHING_RECORD = "RETCHING_RECORDS",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD"

const initialState = {
    isLoading: false,
    error: null,
    record: null
}

export const state = {...initialState}

export const actions = {

    async findByEanCode({commit}, {code}) {
        try {
            commit(FETCHING_RECORD)
            let response = await MaterialService.searchMaterial(code)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clear({commit}) {
        commit(CLEAR_RECORD)
    }

}

export const mutations = {

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
        state.record = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = data
    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.record = null
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.error = null
        state.record = null
    },

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasRecord(state) {
        return state.record !== null
    },
    getRecord(state) {
        return state.record
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
