<template>
    <tr class="border-top">
        <template v-if="item.action === 'edit' || item.action === 'create'">
            <td class="align-middle">
                <BFormInput
                    type="text"
                    size="sm"
                    v-model="form.itemName"
                    :disabled="isProcessing"
                    required
                />
            </td>
            <td class="align-middle text-center">
                <BFormInput
                    class="text-center"
                    min="1"
                    type="number"
                    v-model.number="form.quantity"
                    size="sm"
                    :disabled="isProcessing"
                    required
                />
            </td>
            <td class="align-middle text-right">
                <BInputGroup append="Kč" size="sm">
                    <BFormInput
                        class="text-right"
                        min="0"
                        step="0.1"
                        type="number"
                        v-model.number="form.pricePerItem"
                        :disabled="isProcessing"
                        required
                    />
                </BInputGroup>
            </td>
            <td class="align-middle text-right">
                <BInputGroup append="%" size="sm">
                    <BFormInput
                        class="text-right"
                        min="0"
                        max="100"
                        step="0.1"
                        type="number"
                        v-model.number="form.discount"
                        :disabled="isProcessing"
                        required
                    />
                </BInputGroup>
            </td>
            <td class="align-middle text-right">
                <BButton variant="danger" @click="cancelRow()">
                    <i class="fa fa-times fa-fw"/>
                </BButton>
                <BButton variant="success" @click="saveRow()" class="ml-3" :disabled="isProcessing">
                    <i class="fad fa-spinner-third fa-spin fa-fw" v-if="isProcessing"/>
                    <i class="fad fa-check fa-fw" v-else/>
                </BButton>
            </td>
        </template>
        <template v-else>
            <td class="align-middle">
                {{ item.itemName }}
            </td>
            <td class="align-middle text-center">
                {{ item.quantity }}
            </td>
            <td class="align-middle text-right">
                {{ item.pricePerItem|moneyFormat("CZK_LONG") }}
            </td>
            <td class="align-middle text-right">
                {{ item.discount }} %
            </td>
            <td class="align-middle text-right">
                <BButton variant="danger" @click="deleteRow()">
                    <i class="fad fa-trash fa-fw"/>
                </BButton>
                <BButton variant="primary" @click="openEditFormMode()" class="ml-3">
                    <i class="fad fa-edit fa-fw"/>
                </BButton>
            </td>
        </template>
    </tr>
</template>

<script>
import {mapGetters} from "vuex"
import AssemblySheetItemEntity
    from "../../../../../NewModel/AssemblySheet/Entity/AssemblySheetItemEntity"

export default {

    props: {
        assemblySheetId: {
            type: String,
            required: true,
        },
        item: {
            type: AssemblySheetItemEntity,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isProcessing: "assemblySheet/items/isProcessing",
        }),
    },

    data() {
        return {
            form: {
                itemName: "",
                pricePerItem: 0,
                discount: 0,
                quantity: 1,
            }
        }
    },

    mounted() {
        if (this.item.action === "create") {
            this.openCreateFormMode()
        }
    },

    methods: {

        openFormMode(mode) {
            this.item.action = mode
        },

        openCreateFormMode() {
            this.openFormMode("create")
        },

        fillFormFromEntity(item) {
            this.form.itemName = item.itemName
            this.form.pricePerItem = item.pricePerItem
            this.form.quantity = item.quantity
            this.form.discount = item.discount
        },

        openEditFormMode() {
            this.openFormMode("edit")
            this.fillFormFromEntity(this.item)
        },

        cancelRow() {
            if (this.item.action === "edit") {
                this.fillFormFromEntity(this.item)
                this.item.action = null
            } else {
                this.$emit("cancelled", {
                    index: this.index
                })
            }
        },

        deleteRow() {
            let self = this
            this.$bvModal.msgBoxConfirm("Opravdu chcete odstranit položku " + this.item.itemName + "?", {
                title: "Potvrzení",
                okVariant: "primary",
                cancelVariant: "default",
                centered: true,
                okTitle: "Ano",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("assemblySheet/items/deleteItem", {
                            assemblySheetId: this.assemblySheetId,
                            itemId: this.item.id,
                        })
                            .then(() => {
                                self.$emit("cancelled", {
                                    index: this.index
                                })
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

        saveRow() {
            try {
                this.checkForm()
                if (this.item.action === "edit") {
                    this.$store.dispatch("assemblySheet/items/updateItem", {
                        assemblySheetId: this.assemblySheetId,
                        itemId: this.item.id,
                        formData: {
                            itemName: this.form.itemName,
                            pricePerItem: parseFloat(this.form.pricePerItem),
                            discount: parseInt(this.form.discount),
                            quantity: parseFloat(this.form.quantity),
                        }
                    })
                        .then((response) => {
                            this.item.action = null
                            this.$emit("updated", {
                                itemId: this.item.id,
                                index: this.index,
                                entity: response
                            })
                        })
                } else if (this.item.action === "create") {
                    this.$store.dispatch("assemblySheet/items/createItem", {
                        assemblySheetId: this.assemblySheetId,
                        formData: {
                            itemName: this.form.itemName,
                            pricePerItem: parseFloat(this.form.pricePerItem),
                            discount: parseInt(this.form.discount),
                            quantity: parseFloat(this.form.quantity),
                        }
                    })
                        .then((response) => {
                            this.item.action = null
                            this.$emit("created", {
                                itemId: this.item.id,
                                index: this.index,
                                entity: response
                            })
                        })
                }

            } catch (err) {
                this.warningMessage(err)
                return false
            }
        },

        checkForm() {
            if (this.form.discount < 0 || this.form.discount > 100) {
                throw "Sleva musí být v rozmezí 0 až 100 %!"
            }
            if (this.form.quantity < 1) {
                throw "Počet musí být větší než 0!"
            }
        },

    },

}
</script>
