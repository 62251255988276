import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const AuthService = {

    /**
     * @param request : AuthRequest
     * @returns {Promise<*>}
     */
    login(request) {
        return httpClient.post("v3/auth/in", request.toForm())
    },

    logout() {
        return httpClient.delete("v3/auth/out", {headers: authHeader()})
    },

    getUser() {
        return httpClient.get("v3/auth/me", {headers: authHeader()})
    }

}

export default AuthService
