<template>
    <table class="table table-borderless mb-0">
        <thead>
        <th class="text-gray-700 align-middle w-10">
            Identifikátor
        </th>
        <th class="text-gray-700 align-middle w-25">
            Druh spotřebiče
        </th>
        <th class="text-gray-700 align-middle">
            Název
        </th>
        <th class="text-gray-700 align-middle w-10">
            Výrobní číslo
        </th>
        <th class="text-gray-700 align-middle w-15">
            Upřesnění místa
        </th>
        <th class="text-gray-700 align-middle w-5"/>
        </thead>
        <tbody>
        <tr
            class="border-top" v-for="appliance in appliances"
            :key="appliance.id"
        >
            <td class="align-middle">
                #{{ String(appliance.id).slice(-5) }}
            </td>
            <td class="align-middle">
                {{ appliance.applianceType }}
            </td>
            <td class="align-middle">
                {{ appliance.name }}
            </td>
            <td class="align-middle">
                {{ appliance.serialNumber }}
            </td>
            <td class="align-middle">
                {{ appliance.detailPlaceOfInstallation }}
            </td>
            <td class="text-right align-middle">
                <BButton
                    variant="primary"
                    size="sm"
                    :to="{ name: 'appliance_show', params: { appliance_id: appliance.id }}"
                >
                    <i class="fad fa-eye"/>
                </BButton>
            </td>
        </tr>
        <tr class="border-top" v-if="!isLoading && !hasAppliances">
            <td colspan="7">
                <TableWithoutRecords/>
            </td>
        </tr>
        <tr class="border-top" v-if="isLoading && !hasAppliances">
            <td colspan="7">
                <TableLoading/>
            </td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="border-top">
            <td colspan="4" class="text-gray-700">
                <strong>Celkem spotřebičů:</strong> {{ appliances.length }}
            </td>
        </tr>
        </tfoot>
    </table>
</template>

<script>
import {mapGetters} from "vuex"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TableWithoutRecords,
    },

    props: {
        supplyPoint: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isLoading: 'supplyPoint/appliances/isLoading',
            hasAppliances: 'supplyPoint/appliances/hasAppliances',
            appliances: 'supplyPoint/appliances/getAppliances',
        }),
    },

    created() {
        this.loadSupplyPointAppliances(this.supplyPoint)
    },

    methods: {

        loadSupplyPointAppliances(id) {
            this.$store.dispatch("supplyPoint/appliances/getAppliances", id)
        },

    },

}
</script>
