<template>
    <div>

        <Header icon="fad fa-pencil-ruler" title="Upravit druh spotřebiče">
            <template #buttons>
                <BackLinkButton to="/setup/appliance_types"/>
            </template>
        </Header>

        <ApplianceTypeForm
            :request="request"
            :is-processing="isUpdating"
            v-on:submitted="onSubmitted"
        />

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceTypeForm from "./Component/ApplianceTypeForm.vue"
import ApplianceTypeRequest from "../../../NewModel/Appliance/Type/Request/ApplianceTypeRequest"
import BackLinkButton from "../../Common/UI/Button/BackLinkButton.vue"
import Header from "../../Common/UI/Header.vue"

export default {

    components: {
        ApplianceTypeForm,
        BackLinkButton,
        Header,
    },

    computed: {
        ...mapGetters({
            applianceType: 'appliance/types/getType',
            isUpdating: 'appliance/types/isUpdating',
        }),
    },

    data() {
        return {
            request: new ApplianceTypeRequest(),
        }
    },

    mounted() {
        this.request = ApplianceTypeRequest.fromEntity(this.applianceType)
    },

    methods: {

        onSubmitted(request) {
            this.$store.dispatch("appliance/types/updateType", {
                id: this.applianceType.id,
                request: request,
            })
                .then(() => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'setup_appliance_type_list'})
                })
                .catch((err) => {
                    this.warningMessage(err.response.message)
                })
        },

    },

}
</script>
