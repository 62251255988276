export default class AssemblySheetPricingEntity {

    constructor() {
        this.id = null
        this.priceForWork = 0
        this.priceForMaterial = 0
        this.priceForTransport = 0
        this.priceForItems = 0
        this.priceForOverheadMaterial = 0
        this.expressSurcharge = 0
        this.hasExpressSurcharge = false
        this.transportPricePerKm = 0
        this.transportDistance = 0
        this.transportType = 1
        this.vatTax = 0
        this.totalAmount = 0
        this.totalAmountIncludingVat = 0
        this.taxAmount = 0
        this.totalDiscountAmount = 0
        this.pennyRounding = 0
    }

    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.priceForWork = response.priceForWork
        obj.priceForMaterial = response.priceForMaterial
        obj.priceForTransport = response.priceForTransport
        obj.priceForItems = response.priceForItems
        obj.priceForOverheadMaterial = response.priceForOverheadMaterial
        obj.expressSurcharge = response.expressSurcharge
        obj.hasExpressSurcharge = response.hasExpressSurcharge
        obj.transportPricePerKm = response.transportPricePerKm
        obj.transportDistance = response.transportDistance
        obj.transportType = response.transportType
        obj.vatTax = response.vatTax
        obj.totalAmount = response.totalAmount
        obj.totalAmountIncludingVat = response.totalAmountIncludingVat
        obj.taxAmount = response.taxAmount
        obj.totalDiscountAmount = response.totalDiscountAmount
        obj.pennyRounding = response.pennyRounding
        return obj
    }

    to() {
        return {
            priceForOverheadMaterial: this.priceForOverheadMaterial,
            hasExpressSurcharge: this.hasExpressSurcharge,
            transportDistance: this.transportDistance,
            transportType: this.transportType,
            vatTax: this.vatTax,
        }
    }

}
