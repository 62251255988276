<template>
    <div>

        <div class="mb-4">
            <div class="d-flex justify-content-between text-align-center">
                <BButtonGroup>
                    <BButton variant="primary" @click="filterActive = !filterActive">
                        <i class="fad fa-filter mr-2"/> <span class="font-weight-500">Filtr</span>
                    </BButton>
                    <BButton
                        variant="danger"
                        @click="resetFilters"
                        v-if="isFilterUsed"
                    >
                        <i class="far fa-times"/>
                    </BButton>
                </BButtonGroup>
            </div>

            <BCollapse :visible="filterActive || isFilterUsed" class="mt-3 bg-little-gray p-3">
                <BRow class="mb-3">
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="EAN/EIC:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-ean"
                        >
                            <BFormInput
                                id="filter-ean"
                                size="sm"
                                v-model="filterRequest.filter.ean"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Adresa:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-address"
                        >
                            <BFormInput
                                id="filter-address"
                                size="sm"
                                v-model="filterRequest.filter.address"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                </BRow>
                <BRow class="mb-3">
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Zákazník:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-customer"
                        >
                            <BFormInput
                                id="filter-customer"
                                v-model="filterRequest.filter.customer"
                                size="sm"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Vlastník nemovitosti:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-propertyOwner"
                        >
                            <BFormInput
                                id="filter-propertyOwner"
                                v-model="filterRequest.filter.propertyOwner"
                                size="sm"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                </BRow>
                <BRow>
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Komodita:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-commodity"
                        >
                            <BFormSelect
                                v-model="filterRequest.filter.commodity"
                                name="type"
                                id="filter-commodity"
                                @change="filterUpdated"
                                value-field="id"
                                text-field="text"
                                size="sm"
                                :options="commodity.arrayList()"
                            >
                                <template #first>
                                    <BFormSelectOption :value="null">-- Vybrat --</BFormSelectOption>
                                </template>
                            </BFormSelect>
                        </BFormGroup>
                    </BCol>
                    <BCol/>
                </BRow>
            </BCollapse>

        </div>
        <div>
            <BTable
                ref="table"
                :fields="fields"
                :busy="isLoading"
                :current-page.sync="filterRequest.currentPage"
                :per-page.sync="filterRequest.perPage"
                :sort-by.sync="filterRequest.sortBy"
                :sort-desc.sync="filterRequest.sortDesc"
                :items="myProvider"
                tbody-tr-class="border-bottom"
                thead-tr-class="border-bottom"
                hover
                sort-icon-left
                borderless
                class="mb-0"
                no-sort-reset
                @context-changed="gridUpdated"
                show-empty
            >
                <template v-slot:table-busy>
                    <TableLoading/>
                </template>
                <template v-slot:empty>
                    <TableWithoutRecords/>
                </template>
                <template v-slot:cell(detail)="data">
                    <small>
                    <span class="mr-2" v-if="data.item.name">
                        <strong class="font-weight-600">Název OM:</strong> {{ data.item.name }}
                    </span>
                        <span class="mr-2" v-if="data.item.floorNumber">
                        <strong class="font-weight-600">Patro:</strong> {{ data.item.floorNumber }}
                    </span>
                        <span v-if="data.item.apartmentNumber">
                        <strong class="font-weight-600">Byt:</strong> {{ data.item.apartmentNumber }}
                    </span>
                    </small>
                </template>
                <template v-slot:cell(ean)="data">
                    <i :class="commodity.getItem(data.item.commodity).icon" class="mr-2"/>
                    {{ data.item.ean }}
                </template>
                <template v-slot:cell(action)="data">
                    <RouterLink
                        :to="{ name: 'supply_point_show', params: { id: data.item.id }}"
                        class="btn btn-sm btn-primary"
                    >
                        <i class="fad fa-eye fw"/>
                    </RouterLink>
                    <BButton
                        size="sm"
                        v-bind:variant="checkMapPoint(data.item.id) ? 'success' : 'danger'"
                        class="ml-3"
                        @click="toggleMapPoint(data.item)"
                    >
                        <i class="fad fa-fw"
                           v-bind:class="checkMapPoint(data.item.id) ? 'fa-map-marker-plus' : 'fa-map-marker-minus'"/>
                    </BButton>
                </template>
            </BTable>
            <div class="mt-3">
                <strong>Celkem záznamů:</strong> {{ totalRecords }}
            </div>

            <TablePaginator :total-records="totalRecords" :filter-request="filterRequest" hide-go-to-end-buttons/>
        </div>
        <div class="mt-4 bg-little-gray p-3 mb-4 mt-4" v-if="selectedSupplyPoints.length > 0">
            <div class="d-flex align-items-start justify-content-between">
                <div>
                    <strong class="font-weight-700">Vybraná odběrná místa: </strong>
                    <span
                        v-bind:key="selectedSupplyPoint.id"
                        v-for="selectedSupplyPoint in selectedSupplyPoints"
                    >
                        {{ selectedSupplyPoint.ean }},
                    </span>
                </div>
                <div class="flex-shrink-0">
                    <RouterLink
                        :to="{ name: 'supply_points_map', query: { use_selected_sp: 1 }}"
                        class="btn btn-primary btn-block"
                    >
                        <i class="fad fa-map-marked-alt fw mr-3"/>Zobrazit vybrané na mapě
                    </RouterLink>
                    <b-button variant="danger" block @click="clearMapPoints()">
                        <i class="fad fa-map-marker-times fw mr-3"/>Vyčistit
                    </b-button>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Commodity from "@/Model/Enum/commodity"
import SimpleFormRequest from "../../../NewModel/Common/SimpleFormRequest"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TablePaginator from "@/Module/Common/UI/Table/TablePaginator.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TablePaginator,
        TableWithoutRecords,
    },

    data() {
        return {
            filterRequest: this.buildFilterRequest(),
            filterActive: false,
            fields: [
                {
                    key: 'ean',
                    label: 'EAN / EIC',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle w-15',
                },
                {
                    key: 'customer',
                    label: 'Zákazník',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle w-20',
                },
                {
                    key: 'propertyOwner',
                    label: 'Vlastník nemovitosti',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle w-20',
                },
                {
                    key: 'address',
                    label: 'Adresa',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle',
                },
                {
                    key: 'detail',
                    label: 'Detail OM',
                    sortable: false,
                    thClass: 'font-weight-700',
                    class: 'align-middle',
                },
                {
                    key: 'action',
                    label: 'Akce',
                    thClass: 'font-weight-700',
                    class: 'text-right align-middle w-10',
                }
            ],
            commodity: Commodity,
            selectedSupplyPoints: [],
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isLoading: 'supplyPoint/isLoading',
            totalRecords: 'supplyPoint/getTotalSupplyPoints',
        }),
        gridName: () => '_supplyPointGridFilter',
        isFilterUsed() {
            return this.filterRequest.filter.ean !== ''
                || this.filterRequest.filter.customer !== ''
                || this.filterRequest.filter.propertyOwner !== ''
                || this.filterRequest.filter.address !== ''
                || this.filterRequest.filter.commodity !== null
        },
    },

    created() {
        let storedSelectedSP = JSON.parse(localStorage.getItem('selectedSupplyPoints'))
        this.selectedSupplyPoints = storedSelectedSP ? storedSelectedSP : []

        if (this.$cookies.isKey(this.gridName) && this.user.filterSave) {
            this.filterRequest = SimpleFormRequest.restore(this.$cookies.get(this.gridName))
        }
    },

    mounted() {
        this.filterUpdated()
    },

    methods: {

        checkMapPoint(_id) {
            return this.selectedSupplyPoints.findIndex((item) => item.id === _id) === -1;
        },

        toggleMapPoint(row) {

            // Najdi index daneho odberneho mista
            let index = this.selectedSupplyPoints.findIndex((item) => item.id === row.id);

            if (index === -1) {
                // Pokud jsi jen nenasel, pridej ho na seznam
                this.selectedSupplyPoints.push({id: row.id, ean: row.ean})
            } else {
                // Pokud jsi ho nasel, odeber ho ze seznamu
                this.selectedSupplyPoints.splice(index, 1)
            }
            // Uloz do lokalniho uloziste
            localStorage.setItem('selectedSupplyPoints', JSON.stringify(this.selectedSupplyPoints))
        },

        clearMapPoints() {
            this.selectedSupplyPoints = []
            localStorage.setItem('selectedSupplyPoints', JSON.stringify(this.selectedSupplyPoints))
        },

        buildFilterRequest() {
            return new SimpleFormRequest({
                ean: '',
                commodity: null,
                customer: '',
                propertyOwner: '',
                address: '',
            }, "ean", 10, false)
        },

        gridUpdated(ctx) {
            if (ctx) {
                this.filterRequest.sortBy = ctx.sortBy
                this.filterRequest.sortDesc = ctx.sortDesc
                this.saveFilter()
            }
        },

        saveFilter() {
            if (this.user.filterSave) {
                this.$cookies.set(this.gridName, this.filterRequest.store())
            }
        },

        filterUpdated(resetPage = false) {
            if (resetPage) {
                this.filterRequest.currentPage = 1
            }
            this.saveFilter()
            this.$refs.table.refresh()
        },

        myProvider(ctx, callback) {
            let items = []
            this.$store.dispatch("supplyPoint/clearAll")
            this.$store.dispatch("supplyPoint/getFilteredRecords", this.filterRequest)
                .then((response) => {
                    callback(response)
                })
                .catch(() => {
                    callback([])
                })
            return items || []
        },

        resetFilters() {
            this.filterActive = false
            this.filterRequest = this.buildFilterRequest()
            this.filterUpdated()
        },

    },

}
</script>
