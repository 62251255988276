<template>
    <div>

        <div class="mb-4">
            <div class="d-flex justify-content-between text-align-center">
                <BButtonGroup>
                    <BButton variant="primary" @click="filterActive = !filterActive">
                        <i class="fad fa-filter mr-2"/><span class="font-weight-500">Filtr</span>
                    </BButton>
                    <BButton
                        variant="danger"
                        @click="resetFilters"
                        v-if="filterRequest.filter.name !== ''|| filterRequest.filter.manufacturer !== ''"
                    >
                        <i class="fad fa-times"/>
                    </BButton>
                </BButtonGroup>
            </div>

            <BCollapse :visible="filterActive" class="mt-4 p-4 bg-little-gray">
                <BRow>
                    <BCol>
                        <BFormGroup
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Výrobce:"
                            label-align-sm="right"
                            label-class="font-weight-700 m"
                            label-for="filter-manufacturer"
                            class="mb-0"
                        >
                            <BFormInput
                                id="filter-manufacturer"
                                v-model="filterRequest.filter.manufacturer"
                                size="sm"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                    <BCol>
                        <BFormGroup
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Název:"
                            label-align-sm="right"
                            label-class="font-weight-700 m"
                            label-for="filter-name"
                            class="mb-0"
                        >
                            <BFormInput
                                id="filter-name"
                                v-model="filterRequest.filter.name"
                                size="sm"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                </BRow>
            </BCollapse>

        </div>

        <BTable
            ref="table"
            :fields="fields"
            :busy="isLoading"
            :current-page.sync="filterRequest.currentPage"
            :per-page.sync="filterRequest.perPage"
            :sort-by.sync="filterRequest.sortBy"
            :sort-desc.sync="filterRequest.sortDesc"
            :items="myProvider"
            tbody-tr-class="border-bottom"
            thead-tr-class="border-bottom"
            hover
            sort-icon-left
            borderless
            class="mb-0"
            show-empty
        >
            <template v-slot:table-busy>
                <TableLoading/>
            </template>
            <template v-slot:empty>
                <TableWithoutRecords/>
            </template>
            <template v-slot:cell(action)="data">
                <RouterLink
                    :to="{name: 'setup_boiler_model_show', params: {id: data.item.id}}"
                    class="btn btn-primary btn-sm"
                >
                    <i class="fad fa-eye"/>
                </RouterLink>
            </template>
            <template v-slot:cell(active)="data">
                    <span v-bind:class="data.item.active === true ? 'text-success' : 'text-danger'">
                        {{ data.item.active ? 'Aktivní' : 'Neaktivní' }}
                    </span>
            </template>
        </BTable>
        <div class="mt-3">
            <strong>Celkem záznamů:</strong> {{ totalRecords }}
        </div>

        <TablePaginator :total-records="totalRecords" :filter-request="filterRequest" hide-go-to-end-buttons/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import SimpleFormRequest from "../../../../NewModel/Common/SimpleFormRequest"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TablePaginator from "@/Module/Common/UI/Table/TablePaginator.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TablePaginator,
        TableWithoutRecords,
    },

    data() {
        return {
            filterRequest: new SimpleFormRequest({
                name: "",
                manufacturer: "",
            }),
            filterActive: false,
            fields: [
                {
                    key: 'manufacturer',
                    label: "Výrobce",
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle',
                },
                {
                    key: 'name',
                    label: "Název",
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle',
                },
                {
                    key: 'active',
                    label: "Stav",
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle text-center w-10',
                },
                {
                    key: 'action',
                    label: "Akce",
                    thClass: 'font-weight-700',
                    class: 'text-right align-middle w-5',
                },
            ],
        }
    },

    mounted() {
        this.$store.dispatch("appliance/models/clearAll")
        this.filterUpdated()
    },

    computed: {
        ...mapGetters({
            isLoading: 'appliance/models/isLoading',
            hasRecords: 'appliance/models/hasModels',
            records: 'appliance/models/getModels',
            totalRecords: 'appliance/models/getTotalModels',
        }),
    },

    methods: {

        filterUpdated() {
            this.$refs.table.refresh()
        },

        myProvider(ctx, callback) {
            let items = []
            this.$store.dispatch("appliance/models/getFilteredRecords", this.filterRequest)
                .then((response) => {
                    callback(response)
                })
                .catch(() => {
                    callback([])
                })
            return items || []
        },

        resetFilters() {
            this.filterActive = false
            this.filterRequest = new SimpleFormRequest({
                name: "",
                manufacturer: ""
            })
            this.filterUpdated()
        },

    },

}
</script>
