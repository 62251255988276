<template>
    <div class="vh-100 vw-100 bg-light">

        <Loading
            :show="loading.show"
            :label="loading.customLabel !== '' ? loading.customLabel : loading.label"
        />

        <header class="bg-primary">
            <img src="../../Assets/logo_bw.svg" height="40">
        </header>

        <article>
            <div class="row no-gutters h-100">
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 p-3">

                    <div class="mb-3">
                        <BackLinkButton to="/supply_points" class="btn-block"/>
                    </div>

                    <table class="table table-borderless bg-white border-radius-sm">
                        <thead>
                        <tr class="border-bottom">
                            <th class="font-weight-700" colspan="2">
                                Odběrná místa:
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="border-bottom">
                            <td>
                                Načteno:
                            </td>
                            <td>
                                {{ markers.length }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Nevykresleno:
                            </td>
                            <td>
                                {{ missingCoords.length }}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
                <div class="col-xl-10 col-lg-9 col-md-8 col-sm-12 bg-white">

                    <GmapMap :center="center" :zoom="11.25" map-type-id="roadmap" class="w-100 h-100">
                        <Cluster :maxZoom="22" @click="clickOnCluster">
                            <GmapMarker
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :options="m.options"
                                :clickable="true" :draggable="true"
                                @click="clickOnMarker(m.options.data)"
                                :key="index"
                                :title="m.options.data.ean + ' - ' + m.options.data.customer"
                            />
                        </Cluster>
                    </GmapMap>

                    <BModal
                        size="lg"
                        id="supply-point-modal"
                        :title="'Odběrné místo ' + selected.supplyPoint.ean"
                        body-class="p-0"
                        v-if="selected.supplyPoint"
                        ok-title="Zavřít"
                        header-bg-variant="white"
                        no-stacking
                        centered
                        ok-only
                    >
                        <table class="table table-borderless mb-0">
                            <tr class="border-bottom">
                                <td class="bold bg-light w-35 align-middle">
                                    EAN/EIC
                                </td>
                                <td class="align-middle text-primary">
                                    <RouterLink
                                        :to="{ name: 'supply_point_show', params: { id: selected.supplyPoint.id }}"
                                        target="_blank" class="text-dark"
                                    >
                                        <i :class="commodity.getItem(selected.supplyPoint.commodity).icon"
                                           class="mr-3"/>{{ selected.supplyPoint.ean }}
                                    </RouterLink>
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="bold bg-light w-35 align-middle">
                                    Místo instalace
                                </td>
                                <td class="align-middle">
                                    {{ selected.supplyPoint.placeOfInstallation }}
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="bold bg-light w-35 align-middle">
                                    Zákazník
                                </td>
                                <td class="align-middle">
                                    {{ selected.supplyPoint.customer }}
                                </td>
                            </tr>
                            <tr class="border-bottom ">
                                <td class="bold bg-light w-35 align-middle">
                                    Vlastník nemovistosti
                                </td>
                                <td class="align-middle">
                                    {{ selected.supplyPoint.propertyOwner }}
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="bold bg-light w-35 align-middle">
                                    Smlouva KV PLYN
                                </td>
                                <td class="align-middle">
                                    {{ selected.supplyPoint.connectionContract ? "Ano" : "Ne" }}
                                </td>
                            </tr>
                        </table>
                    </BModal>

                    <BModal
                        v-if="clusteredMarkers.length"
                        id="supply-point-clustered"
                        size="lg"
                        centered title="Více odběrných míst v dané lokalitě"
                        body-class="p-0"
                        ok-only
                        ok-title="Zavřít"
                    >
                        <table class="table table-borderless mb-0">
                            <thead>
                            <tr class="border-bottom">
                                <th class="bg-light bold align-middle font-weight-700 w-35">
                                    EAN
                                </th>
                                <th class="bg-light bold align-middle font-weight-700">
                                    Zákazník
                                </th>
                                <th class="bg-light bold align-middle font-weight-700 w-15">
                                    Sml. plyn
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                class="border-bottom"
                                v-for="(marker, clusteredMarkerIndex) in clusteredMarkers"
                                v-bind:key="clusteredMarkerIndex"
                                v-on:click.prevent="clickOnMarker(marker)"
                            >
                                <td class="align-middle">
                                    <i :class="commodity.getItem(marker.commodity).icon" class="mr-3"/>
                                    {{ marker.ean }}
                                </td>
                                <td class="align-middle">
                                    {{ marker.customer|shortify(70) }}
                                </td>
                                <td class="align-middle">
                                    {{ marker.connectionContract ? "Ano" : "Ne" }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </BModal>

                </div>

            </div>
        </article>

    </div>
</template>

<script>
import BackLinkButton from "../../Module/Common/UI/Button/BackLinkButton.vue"
import Cluster from "vue2-google-maps/dist/components/cluster"
import Commodity from "../../Model/Enum/commodity"
import Loading from "vue-full-loading"
import SupplyPointMapRequest from "@/NewModel/SupplyPoint/Request/SupplyPointMapRequest"

export default {

    components: {
        BackLinkButton,
        Cluster,
        Loading,
    },

    data() {
        return {
            commodity: Commodity,
            selected: {
                supplyPoint: null,
            },
            development: (process.env.NODE_ENV !== "production"),
            center: {
                lat: 50.226725,
                lng: 12.798262,
            },
            markers: [],
            clusteredMarkers: [],
            missingCoords: [],
            supplyPoints: [],
            loading: {
                show: false,
                label: "Načítám odběrná místa",
                customLabel: "",
            },
        }
    },

    mounted() {
        this.loadSupplyPoints()
    },

    methods: {

        clickOnMarker(data) {
            this.selected.supplyPoint = data
            this.$bvModal.hide("supply-point-clustered")
            this.$bvModal.show("supply-point-modal")
        },

        clickOnCluster(cluster) {
            let self = this
            self.clusteredMarkers = cluster.markers_.map((marker) => marker.data)
            if (self.clusteredMarkers.length > 0) {
                this.$bvModal.show("supply-point-clustered")
            }
        },

        loadSupplyPoints() {

            this.loading.show = true
            let self = this
            let supplyPoints = []

            if (parseInt(this.$route.query.use_selected_sp) === 1) {
                let storedSelectedSP = JSON.parse(localStorage.getItem("selectedSupplyPoints"))
                supplyPoints = storedSelectedSP.map((sp) => sp.id)
            }

            this.$store.dispatch("supplyPoint/map/getSupplyPointsMap", new SupplyPointMapRequest(supplyPoints))
                .then((data) => {

                    self.supplyPoints = []
                    self.markers = []
                    self.clusteredMarkers = []
                    self.missingCoords = []

                    data.forEach((sp) => {
                        if (sp.gpsLat !== null && sp.gpsLng !== null) {
                            self.markers.push({
                                position: {
                                    lat: sp.gpsLat,
                                    lng: sp.gpsLng,
                                },
                                options: {
                                    id: sp.id,
                                    data: sp,
                                },
                                clickable: true,
                            })
                        } else {
                            self.missingCoords.push(sp)
                        }
                        self.supplyPoints.push(sp)
                    })

                    if (self.missingCoords.length > 0) {
                        this.warningMessage("Bylo nalezeno několik odběrných míst, u kterých chybí GPS souřadnice a tedy nemohou být umístěny do mapy.")
                    }

                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
                .finally(() => {
                    self.loading.show = false
                })
        },

    },

}
</script>

<style scoped>
header {
    height: 4rem;
    padding: 0.75rem 0.75rem;
}

article {
    height: calc(100vh - 4rem);
}
</style>
