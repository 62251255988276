import PlanningApplianceRepairEntity from "./PlanningApplianceRepairEntity"
import moment from "moment/moment"

export default class PlanningApplianceEntity {

    constructor() {
        this.id = null
        this.applianceId = null
        this.nearestRepairDate = null
        this.name = ''
        this.applianceType = null
        this.manufacturer = null
        this.model = null
        this.modelType = null
        this.serialNumber = ''
        this.dateOfInstallation = null
        this.warrantyToDate = null
        this.fuel = 0
        this.repairs = []
    }

    /**
     * @param response : object
     * @returns PlanningApplianceEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.applianceId = response.applianceId
        obj.nearestRepairDate = response.nearestRepairDate
        obj.name = response.name
        obj.applianceType = response.applianceType || ''
        obj.manufacturer = response.manufacturer || ''
        obj.model = response.model || ''
        obj.modelType = response.modelType || ''
        obj.serialNumber = response.serialNumber || ''
        obj.dateOfInstallation = response.dateOfInstallation
        obj.warrantyToDate = response.warrantyToDate
        obj.fuel = response.fuel
        obj.repairs = response.repairs.map(repair => PlanningApplianceRepairEntity.from(repair))

        return obj
    }

    /**
     * @returns {string}
     * */
    formatWarranty() {
        if (this.warrantyToDate) {
            return moment(String(this.warrantyToDate)).format("DD.MM.YYYY")
        } else {
            return "N/A"
        }
    }

    /**
     * @returns {boolean}
     */
    validWarranty() {
        if (!this.warrantyToDate) {
            return false
        }
        return moment(String(this.warrantyToDate.warrantyToDate)) >= moment()
    }


}
