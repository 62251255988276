export default class SupplyPointMapRequest {

    constructor(supplyPoints) {
        this.supplyPoints = supplyPoints
    }

    toForm() {
        return {
            supplyPoints: this.supplyPoints
        }
    }

}
