<template>
    <div class="p-4">
        <div v-if="hasAssemblySheet && !isLoading">

            <AssemblySheetHeaderFragment :assembly-sheet="assemblySheet"/>
            <AssemblySheetTransportFragment :assembly-sheet="assemblySheet"/>
            <AssemblySheetServiceOperationsFragment :assembly-sheet="assemblySheet"/>
            <AssemblySheetOtherMaterialsFragment
                :assembly-sheet="assemblySheet"
                v-if="assemblySheet.getFreeMaterials().length > 0"
                class="mb-4"
            />
            <AssemblySheetOtherItemsFragment
                :assembly-sheet="assemblySheet"
                v-if="assemblySheet.items.length > 0"
                class="mb-4"
            />
            <AssemblySheetPricingFragment :assembly-sheet="assemblySheet"/>
            <AssemblySheetCustomerFragment :assembly-sheet="assemblySheet"/>

            <div class="py-2 px-3 bg-light">
                <strong>Podpis objednavatele:</strong>
            </div>

            <SignaturePad
                :assembly-sheet="assemblySheet"
                @signed="onSigned"
                class="mb-5 bg-white px-3 py-4"
            />


            <div style="text-align: center;">
                <p>
                    Společnost zapsána v obchodním rejstříku Krajského soudu v Plzni,
                    oddíl C, vložka 25149, IČO: 26402734, DIČ: CZ26402734
                </p>
            </div>

            <div class="mt-4">
                <BLink :to="'/worker/assembly_sheets/' + assemblySheet.id + '/edit'">
                    Zpět do formuláře montážního listu
                </BLink>
            </div>

        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import AssemblySheetCustomerFragment from "./Component/AssemblySheetCustomerFragment.vue"
import AssemblySheetHeaderFragment from "./Component/AssemblySheetHeaderFragment.vue"
import AssemblySheetOtherItemsFragment from "./Component/AssemblySheetOtherItemsFragment.vue"
import AssemblySheetOtherMaterialsFragment from "./Component/AssemblySheetOtherMaterialsFragment.vue"
import AssemblySheetPricingFragment from "./Component/AssemblySheetPricingFragment.vue"
import AssemblySheetServiceOperationsFragment from "./Component/AssemblySheetServiceOperationsFragment.vue"
import AssemblySheetTransportFragment from "./Component/AssemblySheetTransportFragment.vue"
import SignaturePad from "./Component/SignaturePad.vue"

export default {
    components: {
        AssemblySheetCustomerFragment,
        AssemblySheetHeaderFragment,
        AssemblySheetOtherItemsFragment,
        AssemblySheetOtherMaterialsFragment,
        AssemblySheetPricingFragment,
        AssemblySheetServiceOperationsFragment,
        AssemblySheetTransportFragment,
        SignaturePad,
    },

    computed: {
        ...mapGetters({
            isLoading: "assemblySheet/process/isLoading",
            hasAssemblySheet: "assemblySheet/process/hasRecord",
            assemblySheet: "assemblySheet/process/getRecord",
        })
    },

    mounted() {
        this.loadAssemblySheet(this.$route.params.id)
    },

    methods: {

        loadAssemblySheet(id) {
            this.$store.dispatch("assemblySheet/process/getAssemblySheet", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        onSigned(signature) {
            navigator.geolocation.getCurrentPosition(position => {
                this.signingForm({
                    signature: signature,
                    location: position.coords.latitude + ", " + position.coords.longitude
                })
            }, () => {
                this.signingForm({
                    signature: signature,
                    location: "N/A"
                })
            })
        },

        signingForm(formData) {

            this.$store.dispatch("assemblySheet/process/signingAssemblySheet", {
                id: this.assemblySheet.id,
                formData: formData,
            })
                .then(() => {
                    this.successMessage("Dokument byl úspěšně podepsán. Děkujeme.")
                    this.$router.push({path: "/worker/orders/" + this.assemblySheet.order.id})
                        .catch(() => {
                        })
                })
                .catch(error => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
