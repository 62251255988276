import MaterialStore from "./Material/MaterialStore"
import OrderAttachmentStore from "./Attachment/OrderAttachmentStore"
import OrderService from "./OrderService"
import OrderMaterialsStore from "./Store/OrderMaterialsStore"
import OrderAttachmentsStore from "./Store/OrderAttachmentsStore"
import OrderFilterEntity from "./Entity/OrderFilterEntity"
import NewOrderEntity from "./Entity/NewOrderEntity"
import OrderMetadataStore from "./Store/OrderMetadataStore"
import OrderMobileEntity from "./Entity/OrderMobileEntity"

const
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    FETCHING_MOBILE_RECORD = "FETCHING_MOBILE_RECORD",
    FETCHING_MOBILE_RECORD_SUCCESS = "FETCHING_MOBILE_RECORD_SUCCESS",
    FETCHING_MOBILE_RECORD_ERROR = "FETCHING_MOBILE_RECORD_ERROR",
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    FETCHING_FILTERED_RECORDS = "FETCHING_FILTERED_RECORDS",
    FETCHING_FILTERED_RECORDS_SUCCESS = "FETCHING_FILTERED_RECORDS_SUCCESS",
    FETCHING_FILTERED_RECORDS_ERROR = "FETCHING_FILTERED_RECORDS_ERROR",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    UPDATING_RECORD = "CREATING_RECORD",
    UPDATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    UPDATING_RECORD_SUCCESS_WITHOUT_RECORD = "UPDATING_RECORD_SUCCESS_WITHOUT_RECORD",
    UPDATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isLoading: false,
    isProcessing: false,
    isCreating: false,
    isUpdating: false,
    record: null,
    records: [],
    totalRecords: 0,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param request : SimpleFormRequest
     * @returns {Promise<*>}
     */
    async getFilteredRecords({commit}, request) {
        try {
            commit(FETCHING_FILTERED_RECORDS)
            let response = await OrderService.filterOrders(request)
            commit(FETCHING_FILTERED_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_FILTERED_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<[]>}
     */
    async getOrder({commit}, id) {
        try {
            commit(FETCHING_RECORD)
            let response = await OrderService.getOrder(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<[]>}
     */
    async getMobileOrder({commit}, id) {
        try {
            commit(FETCHING_MOBILE_RECORD)
            let response = await OrderService.getMobileOrder(id)
            commit(FETCHING_MOBILE_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_MOBILE_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<*>}
     */
    async restoreOrderStatus({commit}, id) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.restoreOrderStatus(id)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<*>}
     */
    async interruptOrder({commit}, id) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.interruptOrder(id)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<*>}
     */
    async createChildOrder({commit}, id) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.createChildOrder(id)
            commit(UPDATING_RECORD_SUCCESS_WITHOUT_RECORD, response.data)
            return response.data
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<*>}
     */
    async refreshCalendarEvent({commit}, id) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.refreshCalendarEvent(id)
            commit(UPDATING_RECORD_SUCCESS_WITHOUT_RECORD, response.data)
            return response.data
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param request : OrderChangeStatusRequest
     * @returns {Promise<*>}
     */
    async changeOrderStatus({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.changeOrderStatus(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param request : OrderCancelRequest
     * @returns {Promise<*>}
     */
    async cancelOrder({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.cancelOrder(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param request : OrderRequest
     * @returns {Promise<*>}
     */
    async updateOrder({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await OrderService.updateOrder(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param date : string
     * @returns {Promise<[]>}
     */
    async findOrdersByDate({commit}, date) {
        try {
            commit(FETCHING_RECORDS)
            let response = await OrderService.findOrdersByDate(date)
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param date : string
     * @returns {Promise<[]>}
     */
    async findWorkerOrdersByDate({commit}, date) {
        try {
            commit(FETCHING_RECORDS)
            let response = await OrderService.findWorkerOrdersByDate(date)
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    clear({commit}) {
        commit(CLEAR_RECORD)
    },

    clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

}

export const mutations = {

    [FETCHING_FILTERED_RECORDS](state) {
        state.isLoading = true
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_FILTERED_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.records.map(order => OrderFilterEntity.from(order))
        state.totalRecords = data.totalRecords
    },
    [FETCHING_FILTERED_RECORDS_ERROR](state) {
        state.isLoading = false
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.map(order => NewOrderEntity.from(order))
        state.totalRecords = data.length
    },
    [FETCHING_RECORDS_ERROR](state) {
        state.isLoading = false
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_RECORD](state) {
        state.isLoading = true
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.record = NewOrderEntity.from(data)
    },
    [FETCHING_RECORD_ERROR](state) {
        state.isLoading = false
    },

    [FETCHING_MOBILE_RECORD](state) {
        state.isLoading = true
    },
    [FETCHING_MOBILE_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.record = OrderMobileEntity.from(data)
    },
    [FETCHING_MOBILE_RECORD_ERROR](state) {
        state.isLoading = false
    },

    [CREATING_RECORD](state) {
        state.isCreating = true
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isCreating = false
        state.record = NewOrderEntity.from(data)
    },
    [CREATING_RECORD_ERROR](state) {
        state.isCreating = false
    },

    [UPDATING_RECORD](state) {
        state.isUpdating = true
    },
    [UPDATING_RECORD_SUCCESS](state) {
        state.isUpdating = false
    },
    [UPDATING_RECORD_SUCCESS_WITHOUT_RECORD](state) {
        state.isUpdating = false
    },
    [UPDATING_RECORD_ERROR](state) {
        state.isUpdating = false
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.record = null
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.records = []
        state.totalRecords = 0
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },

    isCreating(state) {
        return state.isCreating
    },

    isUpdating(state) {
        return state.isUpdating
    },

    isProcessing(state) {
        return state.isProcessing
    },

    hasError() {
        return false
    },

    error() {
        return null
    },

    hasOrder(state) {
        return state.record !== null
    },

    getOrder(state) {
        return state.record
    },

    hasOrders(state) {
        return state.records.length > 0
    },

    getOrders(state) {
        return state.records
    },

    getTotalOrders(state) {
        return state.totalRecords
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        attachment: OrderAttachmentStore, // deprecated
        attachments: OrderAttachmentsStore,
        material: MaterialStore, // deprecated
        materials: OrderMaterialsStore,
        metadata: OrderMetadataStore,
    }
}
