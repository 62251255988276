export default class ApplianceRequest {

    constructor() {
        this.type = null
        this.manufacturer = {
            id: null,
            name: ''
        }
        this.model = {
            id: null,
            name: ''
        }
        this.modelType = {
            id: null,
            name: ''
        }
        this.serialNumber = ""
        this.dateOfInstallation = null
        this.warrantyToDate = null
        this.regulation = ""
        this.fuel = null
        this.detailPlaceOfInstallation = ""
        this.note = ""
    }

    /**
     * @param response: ApplianceEntity
     * @returns {ApplianceRequest}
     */
    static fromEntity(response) {

        let req = new this
        req.type = response.applianceType.id
        req.manufacturer.id = response.manufacturer.id
        req.manufacturer.name = response.manufacturer.name
        req.model.id = response.model.id
        req.model.name = response.model.name
        req.modelType.id = response.modelType.id
        req.modelType.name = response.modelType.name
        req.serialNumber = response.serialNumber
        req.dateOfInstallation = response.dateOfInstallation
        req.warrantyToDate = response.warrantyToDate
        req.regulation = response.regulation
        req.fuel = response.fuel
        req.detailPlaceOfInstallation = response.detailPlaceOfInstallation
        req.note = response.note
        return req
    }

    toForm() {
        return {
            type: this.type,
            manufacturer: this.manufacturer.id,
            model: this.model.id,
            modelType: this.modelType.id,
            serialNumber: this.serialNumber,
            dateOfInstallation: this.dateOfInstallation || null,
            warrantyToDate: this.warrantyToDate || null,
            regulation: this.regulation || '',
            fuel: this.fuel,
            detailPlaceOfInstallation: this.detailPlaceOfInstallation || '',
            note: this.note || '',

        }
    }

}
