<template>
    <div>

        <Header icon="fad fa-pencil-ruler" title="Upravit model spotřebiče">
            <template #buttons>
                <BackLinkButton to="/setup/boilers/models"/>
            </template>
        </Header>

        <BoilerModelForm
            :request="request"
            :is-processing="isUpdating"
            :entity="boilerModel"
            v-on:submitted="onSubmitted"
        />

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceModelRequest from "../../../NewModel/Appliance/Model/Request/ApplianceModelRequest"
import BackLinkButton from "../../Common/UI/Button/BackLinkButton.vue"
import BoilerModelForm from "./Component/ApplianceModelForm.vue"
import Header from "../../Common/UI/Header.vue"

export default {

    components: {
        BackLinkButton,
        BoilerModelForm,
        Header,
    },

    computed: {
        ...mapGetters({
            boilerModel: 'appliance/models/getModel',
            isUpdating: 'appliance/models/isUpdating',
        }),
    },

    data() {
        return {
            request: new ApplianceModelRequest(),
        }
    },

    mounted() {
        this.request = ApplianceModelRequest.fromEntity(this.boilerModel)
    },

    methods: {

        onSubmitted(request) {
            this.$store.dispatch("appliance/models/updateModel", {
                id: this.boilerModel.id,
                request: request,
            })
                .then(() => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'setup_boiler_model_list'})
                })
                .catch((err) => {
                    this.warningMessage(err.response.message)
                })
        },

    },

}
</script>
