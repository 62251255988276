export default class SubjectContactResponse {

    constructor() {
        this.id = ''
        this.subject = ''
        this.main = false
        this.contractMain = false
        this.name = ''
        this.email = ''
        this.phone = ''
    }

    /**
     * @param response : object
     * @returns SubjectContactResponse
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.subject = response.subject
        obj.main = response.main
        obj.contractMain = response.contractMain
        obj.name = response.name ?? ''
        obj.email = response.email ?? ''
        obj.phone = response.phone ?? ''

        return obj
    }

}
