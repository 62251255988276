<template>

    <div>

        <AttachmentUploadForm @uploaded="uploadedAttachments"/>

        <table class="table table-fit table-borderless mb-4">
            <thead>
            <tr>
                <th class="text-gray-700 align-middle w-20">
                    Datum
                </th>
                <th class="text-gray-700 align-middle w-20">
                    Nahrál
                </th>
                <th class="text-gray-700 align-middle">
                    Příloha
                </th>
                <th class="text-gray-700 align-middle w-15"/>
            </tr>
            </thead>
            <tbody>
            <template v-if="hasAttachments && !isLoading">
                <tr
                    class="border-top"
                    v-for="(attachment, attachmentIndex) in attachments"
                    :key="'attachment' + attachmentIndex + attachment.id"
                >
                    <td class="align-middle">
                        {{ attachment.createdAt|prettyDateTime }}
                    </td>
                    <td class="align-middle">
                        {{ attachment.createdBy.name }}
                    </td>
                    <td class="align-middle">
                        {{ attachment.name }}
                    </td>
                    <td class="align-middle text-right">
                        <AttachmentDownloadButton
                            :id="attachment.id"
                            :name="attachment.name"
                            icon="fad fa-download"
                            size="sm"
                        />
                    </td>
                </tr>
            </template>
            <tr class="border-top" v-else-if="isLoading && !hasAttachments">
                <td colspan="4">
                    <TableLoading/>
                </td>
            </tr>
            <tr class="border-top" v-else>
                <td colspan="4">
                    <TableWithoutRecords/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceEntity from "../../../NewModel/Appliance/Entity/ApplianceEntity"
import AttachmentDownloadButton from "../../Common/Attachment/AttachmentDownloadButton.vue"
import AttachmentUploadForm from "../../Common/Attachment/AttachmentUploadForm.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        AttachmentUploadForm,
        AttachmentDownloadButton,
        TableLoading,
        TableWithoutRecords,
    },

    props: {
        entity: {
            type: ApplianceEntity,
            required: true,
        },
    },

    data() {
        return {
            showForm: false,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'appliance/attachments/isLoading',
            hasAttachments: 'appliance/attachments/hasAttachments',
            attachments: 'appliance/attachments/getAttachments',
        }),
    },

    mounted() {
        this.loadAttachments(this.entity.id)
    },

    methods: {

        loadAttachments(id) {
            this.$store.dispatch("appliance/attachments/clearAll")
            this.$store.dispatch("appliance/attachments/findAttachments", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        uploadedAttachments(uploadedAttachments) {
            this.$store.dispatch("appliance/attachments/addAttachments", {
                id: this.entity.id,
                attachments: uploadedAttachments.map(a => a.id)
            })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
