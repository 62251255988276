export default class SupplyPointBlockRequest {

    constructor() {
        this.blockBenefits = false
        this.blockPeriodRepairs = false
        this.blockingComment = ""
    }

    static from(response) {

        let req = new this
        req.blockBenefits = response.blockBenefits
        req.blockPeriodRepairs = response.blockPeriodRepairs
        req.blockingComment = response.blockingComment || ""
        return req
    }

    toForm() {
        return {
            blockBenefits: this.blockBenefits,
            blockPeriodRepairs: this.blockPeriodRepairs,
            blockingComment: this.blockingComment
        }
    }

}
