<template>
    <div>
        <div v-if="isLoading === false && hasRecords">

            <div class="bg-little-gray p-4">
                <table class="table table-fit table-borderless mb-0">
                    <thead>
                    <tr>
                        <th class="align-middle w-60">
                            Název
                        </th>
                        <th class="text-center align-middle w-10">
                            Autor
                        </th>
                        <th class="text-center align-middle w-10">
                            Datum
                        </th>
                        <th class="text-right align-middle w-20">
                            Akce
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(record, recordIndex) in records" v-bind:key="record.id" class="border-top">
                        <td class="align-middle">
                            <i class="fa-solid fa-file-spreadsheet text-success fa-fw mr-3"
                               v-if="order.list === record.id"
                               title="Montážní list"
                            />
                            {{ record.name|shortify(30) }}
                        </td>
                        <td class="text-center align-middle">
                            <BAvatar
                                size="md"
                                :src="record.createdBy.picture || null"
                                :text="record.createdBy.acronym"
                                v-if="record.createdBy"
                            />
                            <BAvatar size="md" variant="light" v-else/>
                        </td>
                        <td class="text-center align-middle">
                            {{ record.createdAt|prettyDate }}
                        </td>
                        <td class="align-middle text-right">
                            <BButton
                                variant="danger"
                                size="sm"
                                v-on:click.prevent="unassignmentAttachment(record, recordIndex)"
                            >
                                <i class="fa-duotone fa-trash-alt fa-fw"/>
                            </BButton>
                            <AttachmentDownloadButton
                                variant="primary"
                                :id="record.id"
                                :name="record.name"
                                size="sm"
                                class="ml-4"
                                icon="fad fa-download"
                                text="Stáhnout"
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <TableLoading v-else-if="isLoading === true && hasRecords === false"/>
        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import AttachmentDownloadButton from "../../../../Module/Common/Attachment/AttachmentDownloadButton.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        AttachmentDownloadButton,
        TableWithoutRecords,
        TableLoading,
    },

    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isLoading: "worker/order/attachment/isLoading",
            hasRecords: "worker/order/attachment/hasRecords",
            records: "worker/order/attachment/getRecords",
        })
    },

    created() {
        this.$eventBus.$on("order-updated", () => {
            this.loadOrder(this.order.id)
        })
    },

    beforeDestroy() {
        this.$eventBus.$off("order-updated")
    },

    mounted() {
        this.loadOrder(this.order.id)
    },

    methods: {

        loadOrder(id) {
            this.$store.dispatch("worker/order/attachment/findOrderAttachments", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        unassignmentAttachment(record, recordIndex) {
            let self = this;
            this.$bvModal.msgBoxConfirm("Opravdu chcete odstranit dokument " + record.name + "?", {
                title: "Potvrzení",
                okVariant: "primary",
                cancelVariant: "default",
                centered: true,
                okTitle: "Ano, odstranit.",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("order/attachment/assignment/unassignmentAttachment", {
                            order: this.order.id,
                            attachment: record.id,
                        })
                            .then((response) => {
                                if (response === true) {
                                    self.records.splice(recordIndex, 1);
                                    self.order.metadata.numberOfAttachments -= 1
                                    self.successMessage("Soubor " + record + " byl úspěšně odstraněn.")
                                }
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

    },

}
</script>
