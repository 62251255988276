<template>
    <div class="container">

        <Header icon="fad fa-plus-circle" title="Vytvořit druh spotřebiče">
            <template #buttons>
                <BackLinkButton to="/setup/appliance_types"/>
            </template>
        </Header>

        <ApplianceTypeForm
            :request="request"
            :is-processing="isCreating"
            v-on:submitted="onSubmitted"
        />

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceTypeForm from "./Component/ApplianceTypeForm.vue"
import ApplianceTypeRequest from "../../../NewModel/Appliance/Type/Request/ApplianceTypeRequest"
import BackLinkButton from "../../Common/UI/Button/BackLinkButton.vue"
import Header from "../../Common/UI/Header.vue"

export default {

    components: {
        ApplianceTypeForm,
        BackLinkButton,
        Header,
    },

    computed: {
        ...mapGetters({
            isCreating: 'appliance/types/isCreating',
        }),
    },

    data() {
        return {
            request: new ApplianceTypeRequest(),
        }
    },

    methods: {

        /**
         * @param request : ApplianceTypeRequest
         */
        onSubmitted(request) {
            this.$store.dispatch("appliance/types/createType", request)
                .then(() => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'setup_appliance_type_list'})
                })
                .catch((err) => {
                    this.warningMessage(err.response.message)
                })
        },

    },

}
</script>
