export default class NewOrderRepairEntity {

    constructor() {
        this.id = null
        this.repairType = null
        this.repairTypeName = ''
        this.worker = null
        this.workerName = ''
        this.paid = false
        this.comment = ''
        this.discount = null
    }

    /**
     * @param response : object
     * @returns {NewOrderRepairEntity}
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.repairType = response.repairType
        obj.repairTypeName = response.repairTypeName
        obj.worker = response.worker
        obj.workerName = response.workerName
        obj.paid = response.paid
        obj.comment = response.comment
        obj.discount = response.discount
        return obj
    }

}
