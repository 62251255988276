import AresService from "./AresService"
import AresEntity from "@/NewModel/Ares/Entity/AresEntity"

const
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD"

const initialState = {
    isLoading: false,
    record: null,
    error: null
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param query : string
     * @returns {Promise<*>}
     */
    async getSubject({commit}, query) {
        try {
            commit(FETCHING_RECORD)
            let response = await AresService.getSubject(query)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clearRecord({commit}) {
        commit(CLEAR_RECORD)
    }

}

export const mutations = {

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
        state.record = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = AresEntity.from(data)
    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.record = null
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.record = null
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasSubject(state) {
        return state.record !== null
    },
    getSubject(state) {
        return state.record
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
