import ApplianceManufacturerService from "./ApplianceManufacturerService"
import ApplianceManufacturerSearchStore from "./Store/ApplianceManufacturerSearchStore"
import ApplianceManufacturerEntity from "./Entity/ApplianceManufacturerEntity"

const
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    FETCHING_FILTERED_RECORDS = "FETCHING_FILTERED_RECORDS",
    FETCHING_FILTERED_RECORDS_SUCCESS = "FETCHING_FILTERED_RECORDS_SUCCESS",
    FETCHING_FILTERED_RECORDS_ERROR = "FETCHING_FILTERED_RECORDS_ERROR",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    UPDATING_RECORD = "CREATING_RECORD",
    UPDATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    DELETING_RECORD = "DELETING_RECORD",
    DELETING_RECORD_SUCCESS = "DELETING_RECORD_SUCCESS",
    DELETING_RECORD_ERROR = "DELETING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    record: null,
    records: [],
    totalRecords: 0,
    error: null,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @returns {Promise<[]>}
     */
    async findAllActiveManufacturers({commit}) {
        try {
            commit(FETCHING_RECORDS)
            let response = await ApplianceManufacturerService.findAllActiveManufacturers()
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param request : SimpleFormRequest
     * @returns {Promise<*>}
     */
    async getFilteredRecords({commit}, request) {
        try {
            commit(FETCHING_FILTERED_RECORDS)
            let response = await ApplianceManufacturerService.filterManufacturers(request)
            commit(FETCHING_FILTERED_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_FILTERED_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @returns {Promise<*>}
     */
    async getManufacturer({commit}, id) {

        try {
            commit(FETCHING_RECORD)
            let response = await ApplianceManufacturerService.getManufacturer(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param request : SubjectContactRequest
     * @returns {Promise<*>}
     */
    async createManufacturer({commit}, request) {
        try {
            commit(CREATING_RECORD)
            let response = await ApplianceManufacturerService.createManufacturer(request)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @param request : SubjectContactRequest
     * @returns {Promise<*>}
     */
    async updateManufacturer({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await ApplianceManufacturerService.updateManufacturer(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clear({commit}) {
        commit(CLEAR_RECORD)
    },

    async clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

}

export const mutations = {

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.map(record => ApplianceManufacturerEntity.from(record))
        state.totalRecords = data.length
    },
    [FETCHING_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_FILTERED_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_FILTERED_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.records.map(record => ApplianceManufacturerEntity.from(record))
        state.totalRecords = data.totalRecords
    },
    [FETCHING_FILTERED_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = ApplianceManufacturerEntity.from(data)
    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
    },

    [CREATING_RECORD](state) {
        state.isCreating = true
        state.error = null
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isCreating = false
        state.error = null
        state.record = ApplianceManufacturerEntity.from(data)
    },
    [CREATING_RECORD_ERROR](state, error) {
        state.isCreating = false
        state.error = error
    },

    [UPDATING_RECORD](state) {
        state.isUpdating = true
        state.error = null
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isUpdating = false
        state.error = null
        state.record = ApplianceManufacturerEntity.from(data)
    },
    [UPDATING_RECORD_ERROR](state, error) {
        state.isUpdating = false
        state.error = error
    },

    [DELETING_RECORD](state) {
        state.isDeleting = true
        state.error = null
    },
    [DELETING_RECORD_SUCCESS](state) {
        state.isDeleting = false
        state.error = null
    },
    [DELETING_RECORD_ERROR](state, error) {
        state.isDeleting = false
        state.error = error
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.error = null
        state.record = null
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.error = null
        state.records = []
        state.totalRecords = 0
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },

    isCreating(state) {
        return state.isCreating
    },

    isUpdating(state) {
        return state.isUpdating
    },

    isDeleting(state) {
        return state.isDeleting
    },

    hasError(state) {
        return state.error !== null
    },

    error(state) {
        return state.error
    },

    hasManufacturer(state) {
        return state.record !== null
    },

    getManufacturer(state) {
        return state.record
    },

    hasManufacturers(state) {
        return state.records.length > 0
    },

    getManufacturers(state) {
        return state.records
    },

    getTotalManufacturers(state) {
        return state.totalRecords
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        search: ApplianceManufacturerSearchStore
    }
}
