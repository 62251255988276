<template>
    <div>

        <SubjectGridFilterForm
            :query.sync="filterRequest.filter.query"
            @updated="filterUpdated"
            class="mb-4"
        />

        <div>

            <BTable
                ref="table"
                :fields="fields"
                :busy="isLoading"
                :current-page.sync="filterRequest.currentPage"
                :per-page.sync="filterRequest.perPage"
                :sort-by.sync="filterRequest.sortBy"
                :sort-desc.sync="filterRequest.sortDesc"
                :items="myProvider"
                tbody-tr-class="border-bottom"
                thead-tr-class="border-bottom"
                hover
                sort-icon-left
                borderless
                class="mb-0"
                @context-changed="gridUpdated"
                show-empty
            >
                <template v-slot:table-busy>
                    <TableLoading/>
                </template>
                <template v-slot:empty>
                    <TableWithoutRecords title="Nebyly nalezeny žádné záznamy odpovídající požadovaným kritériím"/>
                </template>
                <template #cell(identifier)="row">
                    <span v-if="row.item.type === 1">{{ row.value }}</span>
                    <span v-if="row.item.type === 2">{{ row.item.identifier|prettyDate }}</span>
                </template>
                <template #cell(action)="row">
                    <BButton
                        size="sm"
                        variant="primary"
                        class="ml-2"
                        :to="{ name: 'subject_show', params: { id: row.item.id }}"
                    >
                        Otevřít
                    </BButton>
                </template>
            </BTable>
            <div class="mt-3">
                <strong>Celkem záznamů:</strong> {{ totalRecords }}
            </div>

            <TablePaginator :total-records="totalRecords" :filter-request="filterRequest" hide-go-to-end-buttons/>

        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import SimpleFormRequest from "@/NewModel/Common/SimpleFormRequest"
import SubjectGridFilterForm from "@/Module/Subject/Component/SubjectGridFilterForm.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TablePaginator from "@/Module/Common/UI/Table/TablePaginator.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        SubjectGridFilterForm,
        TableLoading,
        TablePaginator,
        TableWithoutRecords,
    },

    data() {
        return {
            filterRequest: this.buildFilterRequest(),
            filterActive: false,
            fields: [
                {
                    key: 'name',
                    label: 'Jméno subjektu',
                    sortable: true,
                    class: 'align-middle',
                },
                {
                    key: 'identifier',
                    label: 'IČO/Datum nar.',
                    sortable: true,
                    class: 'align-middle text-center',
                },
                {
                    key: 'address',
                    label: 'Adresa',
                    sortable: true,
                    class: 'align-middle',
                },
                {
                    key: 'secondaryAddress',
                    label: 'Korespondenční adresa',
                    sortable: true,
                    class: 'align-middle',
                },
                {
                    key: 'action',
                    label: 'Akce',
                    class: 'text-right align-middle',
                    sortable: false,
                },
            ],
        }
    },

    created() {
        if (this.$cookies.isKey(this.gridName) && this.user.filterSave) {
            this.filterRequest = SimpleFormRequest.restore(this.$cookies.get(this.gridName))
        }
    },

    mounted() {
        this.filterUpdated()
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isLoading: 'subject/isLoading',
            totalRecords: 'subject/getTotalSubjects',
        }),
        gridName: () => '_subjectGridFilter',
        isFilterUsed() {
            return this.filterRequest.filter.query !== ''
        },
    },

    methods: {

        buildFilterRequest() {
            return new SimpleFormRequest({
                query: "",
            }, "name", null)
        },

        gridUpdated(ctx) {
            if (ctx) {
                this.filterRequest.sortBy = ctx.sortBy
                this.filterRequest.sortDesc = ctx.sortDesc
                this.saveFilter()
            }
        },

        saveFilter() {
            if (this.user.filterSave) {
                this.$cookies.set(this.gridName, this.filterRequest.store())
            }
        },

        filterUpdated(resetPage = false) {
            if (resetPage) {
                this.filterRequest.currentPage = 1
            }
            this.saveFilter()
            this.$refs.table.refresh()
        },

        myProvider(ctx, callback) {
            let items = []
            this.$store.dispatch("subject/clearAll")
            this.$store.dispatch("subject/getFilteredRecords", this.filterRequest)
                .then((response) => {
                    callback(response)
                })
                .catch(() => {
                    callback([])
                })
            return items || []
        },

        resetFilters() {
            this.filterActive = false
            this.filterRequest = this.buildFilterRequest()
            this.filterUpdated()
        },

    },

}
</script>
