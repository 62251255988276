<template>
    <div>

        <Header icon="fad fa-user-crown" title="Informace o subjektu">
            <template #buttons>
                <BackLinkButton :to="{ name: 'subject_show', params: { id: subject.id }}" class="ml-4"/>
            </template>
        </Header>

        <div class="p-3 bg-white border-radius-sm mb-4">
            <SubjectFormComponent
                :id="subject.id"
                :request="request"
                @submitted="onUpdate"
            />
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Header from "../../Module/Common/UI/Header.vue"
import BackLinkButton from "../../Module/Common/UI/Button/BackLinkButton.vue"
import SubjectFormComponent from "./Component/SubjectFormComponent.vue"
import SubjectRequest from "../../NewModel/Subject/Request/SubjectRequest"

export default {

    components: {
        BackLinkButton,
        SubjectFormComponent,
        Header,
    },

    data() {
        return {
            request: new SubjectRequest(),
        }
    },

    computed: {
        ...mapGetters({
            subject: "subject/getSubject",
        }),
    },

    mounted() {
        this.request = SubjectRequest.from(this.subject)
    },

    methods: {

        onUpdate(subject) {
            this.$store.dispatch("subject/getSubject", subject.id)
            this.$router.push({name: "subject_show", params: {id: subject.id}})
        },

    },

}
</script>
