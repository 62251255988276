import SubjectSupplyPointApplianceEntity from "./SubjectSupplyPointApplianceEntity"

export default class SubjectSupplyPointEntity {

    constructor() {
        this.id = ''
        this.commodity = 1
        this.ean = ''
        this.address = ''
        this.contract = null
        this.contractNumber = ''
        this.benefits = []
        this.serviceContract = 0
        this.appliances = []
    }

    /**
     * @param response : object
     * @returns SubjectSupplyPointEntity
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.commodity = response.commodity
        obj.ean = response.ean || ''
        obj.address = response.address || ''
        obj.contract = response.contract
        obj.contractNumber = response.contractNumber || ''
        obj.benefits = response.benefits
        obj.serviceContract = response.serviceContract
        obj.appliances = response.appliances.map(
            appliance => SubjectSupplyPointApplianceEntity.from(appliance)
        )
        return obj
    }

}
