import OrderIssuedMaterialStore from "./Store/OrderIssuedMaterialStore"
import OrderMaterialStore from "./Store/OrderIssuedMaterialStore"

export default {
    namespaced: true,
    modules: {
        issuedMaterial: OrderIssuedMaterialStore,
        material: OrderMaterialStore,
    }
}
