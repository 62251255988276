<template>
    <div>

        <Header icon="fad fa-car-mechanic" title="Zakázky">
            <template #buttons>
                <BackLinkButton :to="{name: 'order_list'}"/>
            </template>
        </Header>

        <div class="text-center mt-5">
            <div class="mb-4">
                <BButton variant="primary" v-on:click="changeDate('today')" v-if="!todaySelected" class="mr-4">
                    <i class="fad fa-calendar-day mr-3"/>Dnes
                </BButton>
                <BButton variant="primary" v-on:click="changeDate(-1)">
                    <i class="fad fa-chevron-left"/>
                </BButton>
                <span class="mr-4 ml-4">{{ this.dateFormat }}</span>
                <BButton variant="primary" v-on:click="changeDate(+1)">
                    <i class="fad fa-chevron-right"/>
                </BButton>
            </div>

        </div>


        <table class="table table-fit table-borderless table-hover mb-0" v-if="haveOrders && !isLoading">
            <thead>
            <tr>
                <th class="text-center align-middle font-weight-600">
                    # ID
                </th>
                <th class="align-middle font-weight-600">
                    Adresa
                </th>
                <th class="align-middle font-weight-600">
                    Zákazník
                </th>
                <th class="align-middle font-weight-600">
                    Technik
                </th>
                <th class="text-center align-middle font-weight-600">
                    Čas
                </th>
                <th class="text-center align-middle font-weight-600">
                    Stav
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                class="border-top"
                style="cursor: pointer"
                v-for="order in orders" v-bind:key="order.id"
                v-on:click="$router.push({name: 'order_show', params: { 'id': order.id }})"
            >
                <td class="text-center align-middle">
                    {{ order.customId }}
                </td>
                <td class="align-middle">
                    {{ order.address.name }}
                </td>
                <td class="align-middle">
                    {{ order.customer.name }}
                </td>
                <td class="align-middle">
                    {{ order.worker.name }}
                </td>
                <td class="text-center align-middle">
                    {{ order.scheduledStartTime|reformatDate("YYYY-MM-DD HH:mm:ss", "HH:mm:ss") }} -
                    {{ order.scheduledEndTime|reformatDate("YYYY-MM-DD HH:mm:ss", "HH:mm:ss") }}
                </td>
                <td class="text-center align-middle">
                    {{ orderStatus.getItem(order.status).text }}
                </td>
            </tr>
            </tbody>
        </table>

        <TableLoading v-if="isLoading"/>

        <TableWithoutRecords v-if="!isLoading && !haveOrders"/>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BackLinkButton from "../../Module/Common/UI/Button/BackLinkButton.vue"
import Header from "../../Module/Common/UI/Header.vue"
import moment from 'moment'
import ServiceOrderStatus from "@/Model/Enum/service_order_status"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        BackLinkButton,
        Header,
        TableLoading,
        TableWithoutRecords,
    },

    data() {
        return {
            orderStatus: ServiceOrderStatus,
            date: null,
            dateFormat: null,
            todaySelected: false,
            numberOfOrders: 0,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'order/isLoading',
            haveOrders: 'order/hasOrders',
            orders: 'order/getOrders',
        }),
    },

    mounted() {
        this.loadDate()
    },

    methods: {

        loadOrders(date) {
            this.$store.dispatch("order/clearAll")
            this.$store.dispatch("order/findOrdersByDate", date.format("YYYY-MM-DD"))
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        changeDate(modifier) {
            let _date
            if (typeof modifier === 'number' && !isNaN(modifier)) {
                _date = this.date.add(modifier, 'd')
            } else {
                _date = moment()
            }

            this.updateDate(_date)
        },

        loadDate() {
            let orderListDate
            if (this.$cookies.isKey("orderListDate")) {
                orderListDate = this.$cookies.get("orderListDate")
            } else {
                orderListDate = null
            }

            let _date = moment()

            if (this.$route.params.date) {
                _date = moment(String(this.$route.params.date), 'DD.MM.YYYY')
            } else if (orderListDate) {
                _date = moment(String(orderListDate), 'DD.MM.YYYY')
            }

            this.updateDate(_date)
        },

        updateDate(_date) {
            this.date = _date
            this.dateFormat = _date.format("DD.MM.YYYY")
            this.todaySelected = moment(_date).isSame(moment(), 'day')
            this.$cookies.set("orderListDate", _date.format("DD.MM.YYYY"), "8h")
            this.$router.replace({
                name: 'order_daily',
                params: {date: _date.format("DD.MM.YYYY")}
            })
                .catch(() => {
                })
            this.loadOrders(_date)
        },

    },

}
</script>


