<template>
    <b-link v-bind:class="linkClass" :disabled="isCreating" @click="createAssemblySheet">
        {{ this.linkText }}
    </b-link>
</template>

<script>
import WorkerService from "../../../../NewModel/Worker/WorkerService"

export default {

    props: {
        order: {
            type: String,
            required: true,
        },
        linkText: {
            type: String,
            required: false,
            default: "Vytvořit montážní list",
        },
        linkClass: {
            type: String,
            required: false,
            default: "",
        },
    },

    data() {
        return {
            isCreating: false,
        }
    },

    methods: {

        createAssemblySheet() {
            let self = this

            this.$bvModal.msgBoxConfirm("Opravdu požadujete vytvořit montážní list?", {
                title: "Potvrzení",
                okVariant: "primary",
                cancelVariant: "default",
                centered: true,
                okTitle: "Ano",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {

                        self.isCreating = true

                        WorkerService.createAssemblySheet(this.order)
                            .then((response) => {
                                let id = response.data
                                this.successMessage("Montážní list byl úspěšně vytvořen.")
                                this.$router.push("/worker/assembly_sheets/" + id + "/edit")
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                            .finally(() => {
                                self.isCreating = false
                            })
                    }
                })
        },

    },

}
</script>
