import SubjectEntity from "../../Subject/Entity/SubjectEntity"
import AddressEntity from "../../Address/AddressEntity"
import SupplyPointConnectionContractEntity from "./SupplyPointConnectionContractEntity"

export default class SupplyPointEntity {

    constructor() {

        this.id = null
        this.ean = ''
        this.commodity = 1
        /** @type {SubjectEntity} */
        this.customer = null
        /** @type {SubjectEntity} */
        this.propertyOwner = null
        /** @type {AddressEntity} */
        this.address = null
        /** @type {SupplyPointConnectionContractEntity} */
        this.contract = null
        this.serviceContract = null
        this.blockBenefits = false
        this.blockPeriodRepairs = false
        this.blockingComment = ''
        this.activeOrder = null
        this.validDiscount = null
        this.fictive = false
        this.floorNumber = ''
        this.apartmentNumber = ''
        this.name = ''
        this.beCareful = false
    }

    /**
     * @param response : object
     * @returns SupplyPointEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.ean = response.ean
        obj.commodity = response.commodity
        obj.customer = response.customer ? SubjectEntity.from(response.customer) : null
        obj.propertyOwner = response.propertyOwner ? SubjectEntity.from(response.propertyOwner) : null
        obj.address = response.address ? AddressEntity.from(response.address) : null
        obj.contract = response.contract ? SupplyPointConnectionContractEntity.from(response.contract) : null
        obj.serviceContract = response.serviceContract
        obj.blockBenefits = response.blockBenefits
        obj.blockPeriodRepairs = response.blockPeriodRepairs
        obj.blockingComment = response.blockingComment
        obj.activeOrder = response.activeOrder
        obj.validDiscount = response.validDiscount
        obj.fictive = response.fictive
        obj.floorNumber = response.floorNumber
        obj.apartmentNumber = response.apartmentNumber
        obj.name = response.name
        obj.beCareful = response.beCareful
        return obj
    }

}
