import AddressService from "../AddressService"

const
    FETCHING_RECORD = "RETCHING_RECORDS",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR"

const initialState = {
    isLoading: false,
    error: null,
    response: null
}

export const state = {...initialState}

export const actions = {

    /**
     *
     * @param commit
     * @param id : string
     * @param gpsLat : float
     * @param gpsLon : float
     * @returns {Promise<any>}
     */
    async getDistance({commit}, {id, gpsLat, gpsLon}) {
        try {
            commit(FETCHING_RECORD)
            let response = await AddressService.getDistance({id, gpsLat, gpsLon})
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

}

export const mutations = {

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
        state.response = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.response = data
    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.response = null
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasResponse(state) {
        return state.response !== null
    },
    getResponse(state) {
        return state.response
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
