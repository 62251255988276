export default class ApplianceTypeEntity {

    constructor() {
        this.id = null
        this.name = ""
        this.commodity = 1
        this.active = false
        this.repairTypes = []
    }

    /**
     * @param response : object
     * @returns ApplianceTypeEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.commodity = response.commodity
        obj.active = response.active
        obj.repairTypes = response.repairTypes
        return obj
    }

    to() {
        return this.id
    }

}
