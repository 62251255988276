<template>

    <div class="bg-little-gray p-4">
        <h5 class="font-weight-600 text-dark mb-3">
            <i class="fad fa-filter fa-fw mr-3"/>Filtr
        </h5>

        <BFormRow>

            <BCol>
                <BFormGroup
                    label="Typ opravy"
                    label-class="font-weight-700"
                    class="mb-3"
                >
                    <BFormCheckboxGroup
                        v-model="request.includeOnlyRepairTypes"
                        :options="list.repairTypes"
                        value-field="id"
                        text-field="name"
                        v-on:change="reload"
                    />
                </BFormGroup>

                <BFormRow>
                    <BCol cols="12">
                        <BFormGroup
                            label="Servisní smlouva"
                            label-class="font-weight-700"
                            class="mb-3"
                        >
                            <BInputGroup>
                                <BInputGroupPrepend>
                                    <BButton
                                        variant="danger"
                                        v-on:click="clearServiceLevel"
                                        v-if="request.serviceLevel !== null"
                                    >
                                        <i class="fad fa-times-circle"/>
                                    </BButton>
                                </BInputGroupPrepend>
                                <BFormSelect
                                    v-model="request.serviceLevel"
                                    :options="serviceLevel.arrayList()"
                                    value-field="id"
                                    text-field="text"
                                    v-on:change="reload"
                                >
                                    <template #first>
                                        <BFormSelectOption :value="null">
                                            Vymezení úrovně servisní smlouvy
                                        </BFormSelectOption>
                                    </template>
                                </BFormSelect>
                                <BFormDatepicker
                                    id="filter-from"
                                    v-model="request.startDate"
                                    locale="cs-CZ"
                                    :start-weekday="1"
                                    label-no-date-selected="Od"
                                    v-on:input="reload"
                                />
                                <BFormDatepicker
                                    id="filter-to"
                                    v-model="request.endDate"
                                    locale="cs-CZ"
                                    :start-weekday="1"
                                    label-no-date-selected="Do"
                                    v-on:input="reload"
                                />
                            </BInputGroup>
                        </BFormGroup>
                    </BCol>
                </BFormRow>

                <BFormGroup
                    label-class="font-weight-700"
                    class="mb-0"
                >
                    <BFormRow>
                        <BCol>
                            <BFormCheckbox v-model="request.onlyFree" v-on:change="reload">
                                Zdarma
                            </BFormCheckbox>
                        </BCol>
                        <BCol>
                            <BFormCheckbox v-model="request.onlyBenefits" v-on:change="reload">
                                Pouze s benefity
                            </BFormCheckbox>
                        </BCol>
                    </BFormRow>
                </BFormGroup>

            </BCol>

            <BCol>

                <BFormGroup
                    label="Lokalita"
                    label-class="font-weight-700"
                >

                    <div class="bg-gray-200 border-radius-sm p-2" v-if="request.address">
                        {{ request.address }}
                    </div>

                    <AddressSuggestion @picked="addressSelected" v-else/>

                </BFormGroup>

                <BFormGroup
                    label="Radius"
                    label-class="font-weight-700"
                    class="mb-3"
                    v-if="request.gpsLat || request.gpsLng || (request.address && request.address.length >= 0)"
                >
                    <BInputGroup>
                        <BFormSelect
                            v-model="request.radius"
                            :options="list.radius"
                            value-field="value"
                            text-field="text"
                            v-on:change="reload"
                        />
                        <BInputGroupAppend>
                            <BButton variant="danger" v-on:click="clearAddress">
                                <i class="fad fa-times-circle"/>
                            </BButton>
                        </BInputGroupAppend>
                    </BInputGroup>
                </BFormGroup>

                <div class="small">
                    <strong>Zdarma</strong> - Zobrazení odběrných míst s opravami, na které má nárok zdarma v rámci
                    benefitů k dnešnímu dni<br/>
                    <strong>Pouze s benefity</strong> - Zobrazení odběrných míst s opravami, na které má nárok dle
                    benefitů k dnešnímu dni<br/>
                </div>

            </BCol>

        </BFormRow>

    </div>

</template>

<script>
import {mapGetters} from 'vuex'
import AddressSuggestion from "../../Address/Component/AddressSuggestion.vue"
import PlanningFilterRequest from '../../../NewModel/Planning/Request/PlanningFilterRequest'
import ServiceContractLevel from '../../../Model/Enum/service_contract_level'

export default {

    components: {
        AddressSuggestion,
    },

    props: {
        request: {
            type: PlanningFilterRequest,
            required: true,
        },
    },

    data() {
        return {
            list: {
                repairTypes: [],
                radius: [
                    {value: 1, text: '1 km'},
                    {value: 2, text: '2 km'},
                    {value: 5, text: '5 km'},
                    {value: 10, text: '10 km'},
                    {value: 15, text: '15 km'},
                    {value: 20, text: '20 km'},
                    {value: 30, text: '30 km'},
                    {value: 50, text: '50 km'},
                ]
            },
            serviceLevel: ServiceContractLevel,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'repairType/isLoading',
        }),
    },

    created() {
        this.loadRepairTypes()
    },

    methods: {

        loadRepairTypes() {

            this.repairTypes = []
            let self = this

            this.$store.dispatch('repairType/findAllActiveRepairTypes')
                .then((records) => {
                    records.forEach(repairType => {
                        if (repairType.type === 2) {
                            self.list.repairTypes.push(repairType)
                        }
                    })
                })
        },

        clearAddress() {
            this.request.address = ''
            this.request.gpsLat = null
            this.request.gpsLng = null
            this.reload()
        },

        clearServiceLevel() {
            this.request.serviceLevel = null
            this.reload()
        },

        reload() {
            this.$emit('filterChanged', this.request)
        },

        addressSelected(address) {
            this.request.address = address.wholeName
            this.request.gpsLat = address.gpsLat
            this.request.gpsLng = address.gpsLng
        },

    },

}
</script>
