import authHeader from "../../../Model/Common/AuthHeader"
import {httpClient} from "../../../Model/Common"

const ApplianceModelService = {

    findAllActiveModels() {
        return httpClient.get("v3/models", {
            headers: authHeader()
        })
    },

    /**
     * @param request : SimpleFormRequest
     */
    filterModels(request) {
        return httpClient.post("v3/models/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getModel(id) {
        return httpClient.get("v3/models/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param request : ApplianceModelRequest
     */
    createModel(request) {
        return httpClient.post("v3/models", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @param request : ApplianceModelRequest
     */
    updateModel(id, request) {
        return httpClient.put("v3/models/" + id, request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getModelAttachments(id) {
        return httpClient.get("v3/models/" + id + "/attachments", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getModelTypes(id) {
        return httpClient.get("v3/models/" + id + "/types", {
            headers: authHeader()
        })
    },

}

export default ApplianceModelService
