export default class DiscountItemRequest {

    constructor() {
        this.repairType = null
        this.appliance = null
        this.amount = 0
    }

    /**
     * @param response
     * @returns {DiscountItemRequest}
     */
    static from(response) {

        let req = new this
        req.repairType = response.repairType
        req.appliance = response.appliance
        req.amount = response.amount
        return req
    }

    toForm() {
        return this
    }

}
