import Vue from "vue"
import VueRouter from "vue-router"
import applianceRoutes from "./Module/Appliance/ApplianceRoutes"
import generalRoutes from "./Module/General/GeneralRoutes"
import setupRoutes from "./Module/Setup/SetupRoutes"
import ordersRouters from "./Module/Order/OrderRoutes"
import mobileRoutes from "./Module/Mobile/MobileRoutes"
import planningRoutes from "./Module/Planning//PlanningRoutes"
import DiscountsRoutes from "./Module/Discount/DiscountRoutes"
import WorkerRoutes from "./Module/Worker/Routes"
import PageNotFound from "./Module/General/PageNotFound.vue"
import SupplyPointRoutes from "./Module/SupplyPoint/SupplyPointRoutes"
import SubjectsRoutes from "./Module/Subject/SubjectRoutes"

Vue.use(VueRouter)

const directRoutes = [
    {
        path: "",
        redirect: {name: "dashboard"}
    },
    {
        path: "/",
        redirect: {name: "dashboard"}
    }
]

const fallBackRoutes = [
    {
        path: "*",
        name: "page-not-found",
        component: PageNotFound
    }
]

const routes = [
    ...directRoutes,
    ...generalRoutes,
    ...ordersRouters,
    ...setupRoutes,
    ...applianceRoutes,
    ...mobileRoutes,
    ...planningRoutes,
    ...SubjectsRoutes,
    ...DiscountsRoutes,
    ...WorkerRoutes,
    ...SupplyPointRoutes,
    ...fallBackRoutes,
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ["/in"]
    const authRequired = !publicPages.includes(to.path)
    const loggedIn = localStorage.getItem("token")
    if (authRequired && (!loggedIn || loggedIn === "null")) {
        next("/in")
    } else {
        next()
    }
})

export default router
