import DiscountItemRequest from "./DiscountItemRequest"

export default class DiscountRequest {

    constructor() {
        this.supplyPoint = ""
        this.effectiveFrom = ""
        this.effectiveTo = null
        this.type = ""
        this.generalAmount = 0
        this.serviceContract = null
        this.contract = null
        this.note = ""
        /** @type {DiscountItemRequest[]} */
        this.items = []
        this.ean = null
        this.commodity = null
    }

    /**
     * @param response
     * @returns {DiscountRequest}
     */
    static from(response) {

        let req = new this
        req.supplyPoint = response.supplyPoint.id
        req.effectiveFrom = response.effectiveFrom
        req.effectiveTo = response.effectiveTo || null
        req.type = response.type
        req.generalAmount = response.generalAmount
        req.serviceContract = response.serviceContract || null
        req.contract = response.contract || null
        req.note = response.note || ""
        req.items = response.items.map(item => {
            return DiscountItemRequest.from(item)
        })
        req.ean = response.supplyPoint.ean || null
        req.commodity = response.supplyPoint.commodity || null
        return req
    }

    toForm() {
        return {
            supplyPoint: this.supplyPoint,
            effectiveFrom: this.effectiveFrom,
            effectiveTo: this.effectiveTo,
            type: this.type,
            generalAmount: this.generalAmount,
            serviceContract: this.serviceContract,
            contract: this.contract,
            note: this.note,
            items: this.items.map(item => {
                return item.toForm()
            })
        }
    }

}
