<template>
    <div class="container-fluid p-4">

        <div class="d-flex justify-content-between align-items-center mb-5">
            <h3 class="font-weight-600 text-dark mb-0">
                <i class="fa-duotone fa-list-check text-primary fa-fw mr-3"/>Nový incident
            </h3>
            <div class="buttons">
                <PrimaryLinkButton
                    to="/worker/incidents"
                    icon="fad fa-chevron-circle-left"
                    text="Vytvořit nový incident"
                />
            </div>
        </div>

        <div class="p-4 bg-little-gray">
            <IncidentForm/>
        </div>

    </div>
</template>

<script>
import IncidentForm from "./Component/IncidentForm.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        IncidentForm,
        PrimaryLinkButton,
    },

}
</script>
