export default class AssemblySheetMaterialEntity {

    constructor() {
        this.id = null
        this.assemblySheetAppliance = null
        this.appliance = null
        this.recordOrder = 0
        this.itemName = ""
        this.pricePerItem = 0.0
        this.quantity = 0
        this.discount = 0
        this.pricePerItemWithDiscount = 0
        this.totalAmountWithoutDiscount = 0
        this.totalDiscountAmount = 0
        this.totalAmount = 0
        this.action = null
    }

    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.assemblySheetAppliance = response.assemblySheetAppliance
        obj.appliance = response.appliance
        obj.recordOrder = response.recordOrder
        obj.itemName = response.itemName
        obj.pricePerItem = response.pricePerItem
        obj.quantity = response.quantity
        obj.discount = response.discount
        obj.pricePerItemWithDiscount = response.pricePerItemWithDiscount
        obj.totalAmountWithoutDiscount = response.totalAmountWithoutDiscount
        obj.totalDiscountAmount = response.totalDiscountAmount
        obj.totalAmount = response.totalAmount
        return obj
    }

    to() {
        return {
            id: this.id,
            assemblySheetAppliance: this.assemblySheetAppliance,
            recordOrder: this.recordOrder,
            itemName: this.itemName,
            pricePerItem: this.pricePerItem,
            quantity: this.quantity,
        }
    }

}
