import _ from "lodash"

const ServiceContractLevelList = [
    {id: 0, text: "Lv 0 - Žádná"},
    {id: 1, text: "Lv 1 - Zákazník KV Plyn"},
    {id: 2, text: "Lv 2 - Servisní smlouva běžná"},
    {id: 3, text: "Lv 3 - Servisní smlouva VIP"},
]

export default class ServiceContractLevel {

    constructor(id) {
        this.selected = id !== null ? id : null
        this.items = {...ServiceContractLevelList}
    }

    toString() {
        let o = _.find(this.items, {id: this.selected})
        return o === undefined ? "N/A" : o.text
    }

    static print(id) {
        let o = _.find({...ServiceContractLevelList}, {id: parseInt(id)})
        return o === undefined ? "N/A" : o.text
    }

    static list() {
        return {...ServiceContractLevelList}
    }

    static arrayList() {
        return ServiceContractLevelList
    }

    static getItem(id) {
        let o = _.find({...ServiceContractLevelList}, {id: parseInt(id)})
        return o === undefined ? null : o
    }

}
