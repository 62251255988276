<template>
    <div class="min-vh-100 h-100 d-flex">

        <Sidebar/>

        <div id="content-wrapper" class="d-flex flex-column w-100">

            <div id="content" class="flex-grow-1">

                <Topbar/>

                <div class="container-fluid px-4">
                    <RouterView/>
                </div>

            </div>

            <footer/>

        </div>

    </div>
</template>

<script>
import Sidebar from "./Component/Sidebar.vue"
import Topbar from "./Component/Topbar.vue"

export default {

    components: {
        Sidebar,
        Topbar,
    },

}
</script>
