import _ from "lodash"

const PayerList = [
    {id: 1, text: "Zákazník"},
    {id: 2, text: "Vlastník nemovitosti"},
]

export default class Payer {

    constructor(id) {
        this.selected = id !== null ? id : null
        this.items = {...PayerList}
    }

    toString() {
        let o = _.find(this.items, {id: this.selected})
        return o === undefined ? "N/A" : o.text
    }

    static print(id) {
        let o = _.find({...PayerList}, {id: parseInt(id)})
        return o === undefined ? "N/A" : o.text
    }

    static list() {
        return {...PayerList}
    }

    static getItem(id) {
        let o = _.find({...PayerList}, {id: parseInt(id)})
        return o === undefined ? null : o
    }

}
