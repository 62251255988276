import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const DiscountService = {

    /**
     * @param request : SimpleFormRequest
     */
    filterDiscounts(request) {
        return httpClient.post("v3/discounts/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @returns {Promise<any>}
     */
    getDiscount(id) {
        return httpClient.get("v3/discounts/" + id, {headers: authHeader()})
    },

    /**
     * @param request : DiscountRequest
     * @returns {Promise<any>}
     */
    createDiscount(request) {
        return httpClient.post("v3/discounts/", request.toForm(), {headers: authHeader()})
    },

    /**
     * @param id : Number
     * @param request : DiscountRequest
     * @returns {Promise<any>}
     */
    updateDiscount(id, request) {
        return httpClient.put("v3/discounts/" + id, request.toForm(), {headers: authHeader()})
    },

    /**
     * @param id : Number
     * @returns {Promise<any>}
     */
    deleteDiscount(id) {
        return httpClient.delete("v3/discounts/" + id, {headers: authHeader()})
    }

}

export default DiscountService
