import Vue from "vue"
import moment from "moment"

Vue.filter("prettyDateTimeShort", function (value) {
    if (value) {
        let requiredTime = moment(String(value))
        let currentTime = moment()
        return requiredTime.format(requiredTime.format("YYYY") === currentTime.format("YYYY") ? "DD.MM. HH:mm" : "DD.MM.YYYY HH:mm")
    }
})
Vue.filter("prettyDateTime", function (value) {
    if (value) {
        return moment(String(value)).format("DD.MM.YYYY HH:mm")
    }
})
Vue.filter("prettyDate", function (value) {
    if (value) {
        return moment(String(value)).format("DD.MM.YYYY")
    }
})
Vue.filter("prettyDateRange", function (obj, short = false, withYear = false, withSuffix = true) {
    if (obj.startTime && obj.endTime) {
        // Create moment objects
        let startTime = moment(String(obj.startTime))
        let endTime = moment(String(obj.endTime))

        // Range in the same date
        if (startTime.format("YYYY-MM-DD") === endTime.format("YYYY-MM-DD"))
            return startTime.format(short ? "HH:mm" : "DD.MM." + (withYear ? "YYYY" : "") + " HH:mm") + " - " + endTime.format("HH:mm") + (withSuffix ? " hod." : "")
        else
            return startTime.format("DD.MM." + (withYear ? "YYYY" : "") + " HH:mm") + " - " + endTime.format("DD.MM. HH:mm")
    }
    return "N/A"
})
Vue.filter("reformatDate", function (value, inputFormat, outputFormat = null) {
    if (value) {
        return moment(String(value), inputFormat).format(outputFormat ? outputFormat : "DD.MM.YYYY")
    }
})
