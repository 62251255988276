<template>
    <ul class="navbar-nav sidebar sidebar-dark bg-primary accordion tablet" id="accordionSidebar">

        <div class="py-4">
            <BLink
                class="sidebar-brand d-flex flex-column align-items-center justify-content-center"
                :to="{name : 'worker_dashboard'}"
            >
                <i class="fad fa-user-hard-hat text-gray-500 fa-fw fa-2x" data-toggle="tooltip" data-placement="top"
                   title="Vývojové prostředí!" v-if="development"/>
                <i class="fad fa-user-hard-hat text-success fa-2x" data-toggle="tooltip" data-placement="top"
                   title="Produktivní prostředí!" v-if="!development"/>
                LEMIGAS
            </BLink>
        </div>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer text-white"
                :to="{name: 'worker_dashboard'}"
                active-class="bg-danger text-white"
                exact-active-class="bg-danger text-white"
            >
                <i class="fad fa-tachometer-alt text-white fa-2x"/>
                <span>Dashboard</span>
            </BLink>
        </li>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer text-white"
                :to="{name: 'worker_order_list'}"
                active-class="bg-danger text-white"
                exact-active-class="bg-danger text-white"
            >
                <i class="fad fa-clipboard-list-check text-white fa-2x"/>
                <span>Zakázky</span>
            </BLink>
        </li>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer text-white"
                :to="{name: 'worker_order_map'}"
                active-class="bg-danger text-white"
                exact-active-class="bg-danger text-white"
            >
                <i class="fad fa-map-location text-white fa-2x"/>
                <span>Mapa</span>
            </BLink>
        </li>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer text-white"
                :to="{name: 'worker_incident_list'}"
                active-class="bg-danger text-white"
                exact-active-class="bg-danger text-white"
            >
                <i class="fad fa-map-marker-exclamation text-white fa-2x"/>
                <span>Incidenty</span>
            </BLink>
        </li>

        <hr class="sidebar-divider my-0"/>

        <li class="nav-item">
            <BLink
                @click.prevent.stop="openFullscreen"
                router-tag="div"
                class="nav-link pointer text-white"
                v-if="!fullscreenEnabled"
            >
                <i class="fad fa-maximize text-white fa-2x"/>
                <span>Zvětšit</span>
            </BLink>
            <BLink
                @click.prevent.stop="closeFullscreen"
                router-tag="div"
                class="nav-link pointer text-white"
                v-if="fullscreenEnabled"
            >
                <i class="fad fa-minimize text-white fa-2x"/>
                <span>Obnovit</span>
            </BLink>
        </li>

        <hr class="sidebar-divider my-0">

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer text-white"
                :to="{name: 'worker_user'}"
                active-class="bg-danger text-white"
                exact-active-class="bg-danger text-white"
            >
                <i class="fad fa-user-cog text-white fa-2x"/>
                <span>Uživatel</span>
            </BLink>
        </li>

    </ul>
</template>

<script>
export default {

    data() {
        return {
            development: (process.env.NODE_ENV !== 'production'),
            fullscreenEnabled: false,
        }
    },

    methods: {

        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/in');
        },

        openFullscreen() {
            const elem = document.documentElement
            if (elem.requestFullscreen) {
                elem.requestFullscreen()
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen()
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen()
            }
            this.fullscreenEnabled = true
        },

        /* Close fullscreen */
        closeFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen()
            }
            this.fullscreenEnabled = false
        },

    },

}
</script>
