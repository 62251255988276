import ApplianceList from "./ApplianceList"
import MapOfApplianceRepairs from "./MapOfRepairs"
import ShowAppliance from "./ShowAppliance.vue"
import EditAppliance from "./EditAppliance.vue"
import Appliance from "./Appliance.vue"
import WhiteMain from "../General/WhiteMain.vue"

const applianceRoutes = [
    {
        path: "/appliances/planning/map",
        name: "appliance_planning_map",
        component: MapOfApplianceRepairs
    },
    {
        path: "/appliances",
        component: WhiteMain,
        children: [
            {
                path: "",
                name: "appliance_list",
                component: ApplianceList,
            },
            {
                path: ":appliance_id",
                name: "appliance",
                component: Appliance,
                children: [
                    {
                        path: "",
                        name: "appliance_show",
                        component: ShowAppliance
                    },
                    {
                        path: "edit",
                        name: "appliance_edit",
                        component: EditAppliance
                    },
                    {
                        path: "*",
                        redirect: {name: "appliance_show"},
                    },
                ],
            }
        ],
    },
]

export default applianceRoutes
