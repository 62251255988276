<template>
    <div class="ac">
        <BInput
            size="sm"
            type="text"
            v-model="search"
            :disabled="locked"
            @input="onChange"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
        />
        <ul class="ac-results" v-show="isOpen">
            <li v-if="isLoading" class="loading">
                Získávám seznam položek...
            </li>
            <li v-else v-for="(result, i) in results" :key="i" :class="{ 'is-active': i === arrowCounter }"
                class="ac-result" @click="setResult(result)">
                {{ result.name }}
            </li>
        </ul>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    props: {
        defaultText: {
            type: String,
            required: false,
            default: "",
        },
        locked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            search: "",
            results: [],
            isOpen: false,
            arrowCounter: -1,
        }
    },

    computed: {
        ...mapGetters({
            assemblySheet: "assemblySheet/process/getRecord",
            isLoading: "assemblySheet/availableRepairTypes/isLoading",
            hasRecords: "assemblySheet/availableRepairTypes/hasRecords",
            records: "assemblySheet/availableRepairTypes/getRecords",
        }),
    },

    created() {
        this.search = this.defaultText
    },

    mounted() {
        document.addEventListener("click", this.handleClickOutside)
    },

    destroyed() {
        document.removeEventListener("click", this.handleClickOutside)
    },

    methods: {

        onChange(input) {
            this.$emit("input", this.search)
            let self = this
            if (input.length < 2) {
                return
            }

            this.$store.dispatch("assemblySheet/availableRepairTypes/findAvailableRepairTypes", {
                id: this.assemblySheet.id,
                query: input
            })
                .then((response) => {
                    self.results = response
                    self.isOpen = response.length > 0

                })
        },

        setResult(result) {
            this.$emit("picked", result)
            this.search = result.name
            this.isOpen = false
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false
                this.arrowCounter = -1
            }
        },

        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1
            } else {
                this.arrowCounter = 0
            }
        },

        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1
            } else {
                this.arrowCounter = this.results.length - 1
            }
        },

    },

}
</script>

<style scoped>
.ac {
    position: relative;
}

.ac-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eaecf4;
    min-height: 1rem;
    max-height: 20rem;
    overflow: auto;
    z-index: 9999;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ac-result {
    list-style: none;
    text-align: left;
    padding: 0.6rem;
    cursor: pointer;
    font-size: 0.85rem;
}

.ac-result.is-active, .ac-result:hover {
    background-color: #253858;
    color: white;
}
</style>
