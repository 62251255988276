<template>

    <form ref="form" @submit.prevent="processForm">

        <div class="d-flex align-items-center mb-4">
            <div class="w-25 flex-shrink-0 font-weight-600">
                Emailová adresa: <span class="text-danger">*</span>
            </div>
            <div class="w-75 flex-shrink-1">
                <BFormInput
                    v-model="request.email"
                    id="email"
                    type="email"
                    autocomplete="off"
                    required
                />
            </div>
        </div>

        <div class="d-flex align-items-center mb-4">
            <div class="w-25 flex-shrink-0 font-weight-600">
                Příjmení a jméno: <span class="text-danger">*</span>
            </div>
            <div class="w-75 flex-shrink-1">
                <BFormInput
                    v-model="request.fullName"
                    id="fullName"
                    type="text"
                    autocomplete="off"
                    required
                />
            </div>
        </div>

        <div class="d-flex align-items-center mb-4">
            <div class="w-25 flex-shrink-0 font-weight-600">
                Google kalendář
            </div>
            <div class="w-75 flex-shrink-1">
                <BFormInput
                    v-model="request.googleCalendar"
                    id="googleCalendar"
                    type="text"
                    autocomplete="off"
                />
            </div>
        </div>

        <div class="d-flex align-items-center mb-4">
            <div class="w-25 flex-shrink-0 font-weight-600">
                Barva kalendáře
            </div>
            <div class="w-75 flex-shrink-1">
                <BFormInput
                    v-model="request.googleCalendarColor"
                    id="googleCalendarColor"
                    type="text"
                    autocomplete="off"
                />
            </div>
        </div>

        <div class="d-flex align-items-center mb-4">
            <div class="w-25 flex-shrink-0 font-weight-600">
                Heslo (v případě změny)
            </div>
            <div class="w-75 flex-shrink-1">
                <BFormInput
                    v-model="request.newPassword"
                    id="password"
                    type="password"
                    autocomplete="off"
                />
            </div>
        </div>

        <div class="d-flex align-items-center mb-4">
            <div class="w-25 flex-shrink-0 font-weight-600">
                Platnost účtu
            </div>
            <div class="w-75 flex-shrink-1">
                <BFormCheckbox v-model="request.active">
                    Aktivní
                </BFormCheckbox>
            </div>
        </div>

        <div class="d-flex align-items-center mb-4">
            <div class="w-25 flex-shrink-0 font-weight-600">
                Oprávnění
            </div>
            <div class="w-75 flex-shrink-1">
                <BFormGroup>
                    <BFormCheckboxGroup
                        v-model="request.roles"
                        :options="list.roles"
                        text-field="name"
                        value-field="id"
                        stacked
                    />
                </BFormGroup>
            </div>
        </div>

        <div class="d-flex align-items-center mb-4" v-if="request.roles.includes('worker')">
            <div class="w-25 flex-shrink-0 font-weight-600">
                Klíč technika (např. IP)
            </div>
            <div class="w-75 flex-shrink-1">
                <BFormInput
                    v-model="request.workerKey"
                    id="workerKey"
                    type="text"
                    autocomplete="off"
                />
            </div>
        </div>

        <div class="d-flex align-items-center">
            <div class="w-25 flex-shrink-0 font-weight-600"/>
            <div class="w-75 flex-shrink-1">
                <BButton type="submit" variant="primary" :disabled="isProcessing">
                    <i class="fad fa-save mr-3"/>Uložit
                </BButton>
            </div>
        </div>

    </form>
</template>

<script>
import UserRequest from "../../../../NewModel/User/Request/UserRequest"

export default {

    props: {
        request: {
            type: UserRequest,
            required: true,
        },
        isProcessing: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            list: {
                roles: [
                    {id: 'planner', name: 'Plánovač'},
                    {id: 'worker', name: 'Technik'},
                    {id: 'administrator', name: 'Administrátor'},
                ],
            },
        }
    },

    methods: {

        processForm() {
            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Selhala validace. Byla všechna požadovaná pole vyplněna?')
                return
            }
            this.$emit('submitted', this.request)
        },

    },

}
</script>
