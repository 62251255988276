export default class SimpleFormRequest {

    constructor(filterData = {}, defaultSort, defaultPerPage, sortDesc = false) {
        this.perPage = defaultPerPage || 10
        this.currentPage = 1
        this.sortBy = defaultSort || "id"
        this.sortDesc = sortDesc
        this.filter = filterData
    }

    toForm() {
        return {
            perPage: this.perPage,
            currentPage: this.currentPage,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            filter: this.filter,
        }
    }

    pack() {
        return this.toForm()
    }

    static unpack(packed) {
        let obj = new this
        obj.perPage = packed.perPage || 10
        obj.currentPage = packed.currentPage || 1
        obj.sortBy = packed.sortBy || "id"
        obj.sortDesc = packed.sortDesc || false
        obj.filter = packed.filter || {}
        return obj
    }

    store() {
        return {
            perPage: this.perPage,
            currentPage: this.currentPage,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            filter: this.filter,
        }
    }

    static restore(packed) {
        let obj = new this
        obj.perPage = packed.perPage || 10
        obj.currentPage = packed.currentPage || 1
        obj.sortBy = packed.sortBy || "id"
        obj.sortDesc = packed.sortDesc || false
        obj.filter = packed.filter || {}
        return obj
    }

}
