<template>
    <div>
        <table class="table table-fit-body table-borderless mb-0">
            <tbody>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-25">
                    {{ supplyPoint.commodity === 1 ? 'EIC' : 'EAN' }}:
                </td>
                <td>
                    <i class="mr-2" :class="commodity.getItem(supplyPoint.commodity).icon"/>{{ supplyPoint.ean }}
                </td>
            </tr>
            <tr class="border-bottom" v-if="supplyPoint.name || supplyPoint.floorNumber || supplyPoint.apartmentNumber">
                <td class="font-weight-700 align-middle w-25">
                    Detail OM:
                </td>
                <td>
                    <span class="mr-4" v-if="supplyPoint.name">
                        <strong class="font-weight-600">Název OM:</strong> {{ supplyPoint.name }}
                    </span>
                    <span class="mr-4" v-if="supplyPoint.floorNumber">
                        <strong class="font-weight-600">Patro:</strong> {{ supplyPoint.floorNumber }}
                    </span>
                    <span v-if="supplyPoint.apartmentNumber">
                        <strong class="font-weight-600">Číslo bytu:</strong> {{ supplyPoint.apartmentNumber }}
                    </span>
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-25">
                    Adresa:
                </td>
                <td>
                    <div v-if="supplyPoint.address">
                        {{ supplyPoint.address.wholeName }}
                        <a v-if="supplyPoint.address.parcelNrUsed === false"
                           :href="'https://www.google.cz/maps/place/' + encodeURIComponent(supplyPoint.address.wholeName)"
                           target="_blank">
                            <i class="fad fa-map-marker-alt ml-4"/>
                        </a>
                        <a v-else
                           :href="'https://maps.google.com/maps?q='+ supplyPoint.address.gpsLat +','+ supplyPoint.address.gpsLng +'&ll='+ supplyPoint.address.gpsLat +','+ supplyPoint.address.gpsLng"
                           target="_blank">
                            <i class="fad fa-map-marker-alt ml-4"/>
                        </a>
                    </div>
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-25">
                    Servisní smlouva:
                </td>
                <td>
                    {{ serviceContractLevel.getItem(supplyPoint.serviceContract).text }}
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-25">
                    Smlouva KVP:
                </td>
                <td v-if="supplyPoint.contract">
                    <a :href="'https://is.kvplyn.cz/#subsite=History/masterContractId=_MASTER_ID_/st=KvPlyn.Sites.Contract.Detail'.replace('_MASTER_ID_', supplyPoint.contract.masterContract)"
                       target="_blank">
                        {{ supplyPoint.contract.contractNumber }}
                    </a>
                    <span v-if="supplyPoint.contract.benefits.length > 0" class="ml-1">
                        (Benefity: {{ supplyPoint.contract.benefits.join(', ') }})
                    </span>
                </td>
                <td v-else>
                    Ne
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-25">
                    Zákazník:
                </td>
                <td>
                    {{ supplyPoint.customer ? supplyPoint.customer.wholeName : "" }}
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-25">
                    Majitel nemovitosti:
                </td>
                <td>
                    {{ supplyPoint.propertyOwner ? supplyPoint.propertyOwner.wholeName : "N/A" }}
                </td>
            </tr>
            <tr>
                <td class="font-weight-700 align-middle d-flex justify-content-between align-items-center">
                    Blokace:
                </td>
                <td class="align-middle">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="text-success font-weight-700"
                             v-if="!supplyPoint.blockBenefits && !supplyPoint.blockPeriodRepairs">
                            Bez blokace
                        </div>
                        <div class="text-danger font-weight-700" v-if="supplyPoint.blockBenefits">
                            Blokace čerp. benefitu
                        </div>
                        <div class="text-danger font-weight-700" v-if="supplyPoint.blockPeriodRepairs">
                            Blokace period. prohlídek
                        </div>
                        <BButton
                            v-on:click.prevent="$router.push({name: 'supply_point_blocking'})"
                            class="btn btn-sm btn-light ml-4"
                        >
                            Nastavit blokaci
                        </BButton>
                    </div>
                    <div
                        class="mt-2"
                        v-if="supplyPoint.blockingComment !== null && supplyPoint.blockingComment.length > 0"
                    >
                        <strong class="font-weight-700">Poznámka:</strong> {{ supplyPoint.blockingComment }}
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Commodity from "@/Model/Enum/commodity"
import ServiceContractLevel from "@/Model/Enum/service_contract_level"
import SupplyPointEntity from "../../../NewModel/SupplyPoint/Entity/SupplyPointEntity"

export default {

    props: {
        supplyPoint: {
            type: SupplyPointEntity,
            required: true,
        },
    },

    data() {
        return {
            commodity: Commodity,
            serviceContractLevel: ServiceContractLevel,
        }
    },

}
</script>
