import OrderService from "../OrderService"
import OrderMetadataEntity from "@/NewModel/Order/Entity/OrderMetadataEntity"

const
    FETCHING_DATA = "FETCHING_DATA",
    FETCHING_DATA_SUCCESS = "FETCHING_DATA_SUCCESS",
    FETCHING_DATA_ERROR = "FETCHING_DATA_ERROR",
    CLEAR_DATA = "CLEAR_DATA"

const initialState = {
    isLoading: false,
    data: null,
}

export const state = {
    ...initialState
}

export const actions = {

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<any>}
     */
    async getMetadata({commit}, id) {
        try {
            commit(FETCHING_DATA)
            let response = await OrderService.getMetadata(id)
            commit(FETCHING_DATA_SUCCESS, response.data)
            return state.data
        } catch (error) {
            commit(FETCHING_DATA_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clear({commit}) {
        commit(CLEAR_DATA)
    }

}

export const mutations = {

    [FETCHING_DATA](state) {
        state.isLoading = true
        state.data = null
    },
    [FETCHING_DATA_SUCCESS](state, data) {
        state.isLoading = false
        state.data = OrderMetadataEntity.from(data)
    },
    [FETCHING_DATA_ERROR](state) {
        state.isLoading = false
        state.data = null
    },

    [CLEAR_DATA](state) {
        state.isLoading = false
        state.data = null
    },

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    hasData(state) {
        return state.data !== null
    },
    getData(state) {
        return state.data
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
