import User from "./User/User.vue"
import UserList from "./User/UserList.vue"
import UserCreate from "./User/UserCreate.vue"
import UserUpdate from "./User/UserUpdate.vue"

import ApplianceModel from "./Model/ApplianceModel.vue"
import ApplianceModelList from "./Model/ApplianceModelList.vue"
import ApplianceModelCreate from "./Model/ApplianceModelCreate.vue"
import ApplianceModelUpdate from "./Model/ApplianceModelUpdate.vue"
import ApplianceModelShow from "./Model/ApplianceModelShow.vue"

import BoilerManufacturer from "./Manufacturer/BoilerManufacturer.vue"
import BoilerManufacturerList from "./Manufacturer/BoilerManufacturerList.vue"
import BoilerManufacturerCreate from "./Manufacturer/BoilerManufacturerCreate.vue"
import BoilerManufacturerUpdate from "./Manufacturer/BoilerManufacturerUpdate.vue"

import RepairType from "./RepairType/RepairType.vue"
import RepairTypeList from "./RepairType/RepairTypeList.vue"
import RepairTypeCreate from "./RepairType/RepairTypeCreate.vue"
import RepairTypeUpdate from "./RepairType/RepairTypeUpdate.vue"

import ApplianceType from "./ApplianceType/ApplianceType.vue"
import ApplianceTypeList from "./ApplianceType/ApplianceTypeList.vue"
import ApplianceTypeCreate from "./ApplianceType/ApplianceTypeCreate.vue"
import ApplianceTypeUpdate from "./ApplianceType/ApplianceTypeUpdate.vue"

import WhiteMain from "../General/WhiteMain.vue"

const setupRoutes = [
    {
        path: "/setup",
        component: WhiteMain,
        children: [

            // Users
            {
                path: "users",
                name: "setup_user_list",
                component: UserList,
            },
            {
                path: "users/create",
                name: "setup_user_create",
                component: UserCreate,
            },
            {
                path: "users/:id",
                name: "setup_user",
                component: User,
                children: [
                    {
                        path: "edit",
                        name: "setup_user_edit",
                        component: UserUpdate,
                    },
                ],
            },

            // Model types
            {
                path: "boilers/models",
                name: "setup_boiler_model_list",
                component: ApplianceModelList,
            },
            {
                path: "boilers/models/create",
                name: "setup_boiler_model_create",
                component: ApplianceModelCreate,
            },
            {
                path: "boilers/models/:id",
                name: "setup_boiler_model",
                component: ApplianceModel,
                children: [
                    {
                        path: "",
                        name: "setup_boiler_model_show",
                        component: ApplianceModelShow,
                    },
                    {
                        path: "edit",
                        name: "setup_boiler_model_edit",
                        component: ApplianceModelUpdate,
                    },
                ],
            },

            // Appliance Manufacturers
            {
                path: "boilers/manufacturers",
                name: "setup_boiler_manufacturer_list",
                component: BoilerManufacturerList,
            },
            {
                path: "boilers/manufacturers/create",
                name: "setup_boiler_manufacturer_create",
                component: BoilerManufacturerCreate,
            },
            {
                path: "boilers/manufacturers/:id",
                name: "setup_boiler_manufacturer",
                component: BoilerManufacturer,
                children: [
                    {
                        path: "edit",
                        name: "setup_boiler_manufacturer_edit",
                        component: BoilerManufacturerUpdate,
                    },
                ],
            },

            // Repair types
            {
                path: "repair_types",
                name: "setup_repair_type_list",
                component: RepairTypeList,
            },
            {
                path: "repair_types/create",
                name: "setup_repair_type_create",
                component: RepairTypeCreate,
            },
            {
                path: "repair_types/:id",
                name: "setup_repair_type",
                component: RepairType,
                children: [
                    {
                        path: "edit",
                        name: "setup_repair_type_edit",
                        component: RepairTypeUpdate,
                    },
                ],
            },

            // Appliance types
            {
                path: "appliance_types",
                name: "setup_appliance_type_list",
                component: ApplianceTypeList,
            },
            {
                path: "appliance_types/create",
                name: "setup_appliance_type_create",
                component: ApplianceTypeCreate,
            },
            {
                path: "appliance_types/:id",
                name: "setup_appliance_type",
                component: ApplianceType,
                children: [
                    {
                        path: "edit",
                        name: "setup_appliance_type_edit",
                        component: ApplianceTypeUpdate,
                    },
                ],
            },

            {
                path: "",
                redirect: {name: "setup_user_list"},
            },
        ],
    },
]

export default setupRoutes
