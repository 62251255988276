import _ from "lodash"

const CancelStatusList = [
    {id: 0, text: "-", disabled: true},
    {id: 1, text: "Není doma", disabled: false},
    {id: 2, text: "Odmítnuto", disabled: false},
    {id: 3, text: "Vyřízeno bez návštěvy", disabled: false},
]

export default class CancelStatus {

    constructor(id) {
        this.selected = id !== null ? id : null
        this.items = {...CancelStatusList}
    }

    toString() {
        let o = _.find(this.items, {id: this.selected})
        return o === undefined ? "N/A" : o.text
    }

    static print(id) {
        let o = _.find({...CancelStatusList}, {id: parseInt(id)})
        return o === undefined ? "N/A" : o.text
    }

    static list() {
        return {...CancelStatusList}
    }

    static getItem(id) {
        let o = _.find({...CancelStatusList}, {id: parseInt(id)})
        return o === undefined ? null : o
    }

}
