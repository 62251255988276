<template>
    <div>

        <Header title="Dnešní zakázky"/>

        <div v-if="haveOrders && !isLoading" class="mb-3">
            <div
                v-for="order in orders"
                v-bind:key="order.id"
                class="mb-3 cursor-pointer"
                v-on:click="$router.push({name: 'new_mobile_order_show', params: { 'id': order.id }})"
            >
                <OrderListItem v-bind:order="order"/>
            </div>

            <hr class="border-bottom border-light"/>

            <StatusLegend class="mb-4"/>

        </div>

        <TableLoading class="mb-3" v-if="isLoading"/>

        <TableWithoutRecords class="mb-3" v-if="!isLoading && !haveOrders"/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Header from "../Common/UI/Header.vue"
import moment from "moment"
import OrderListItem from "./Component/OrderListItem.vue"
import StatusLegend from "./Component/StatusLegend.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        Header,
        OrderListItem,
        StatusLegend,
        TableLoading,
        TableWithoutRecords,
    },

    data() {
        return {
            date: null,
            dateFormat: null,
            todaySelected: false,
            user: null,
            numberOfOrders: 0,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: "order/isLoading",
            haveOrders: "order/hasOrders",
            orders: "order/getOrders",
        }),
    },

    mounted() {
        this.today = moment()
        this.loadOrders()
    },

    methods: {

        loadOrders() {
            this.$store.dispatch("order/findWorkerOrdersByDate", this.today.format("YYYY-MM-DD"))
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>


