<template>

    <div class="bg-little-gray p-4">
        <h5 class="font-weight-600 text-dark mb-3">
            <i class="fad fa-filter fa-fw mr-3"/>Filtr
        </h5>

        <BFormGroup class="mb-0">
            <BFormInput
                v-model="originalQuery"
                type="text"
                class="mb-0"
                placeholder="Zadejte hledaný subjekt"
            />
        </BFormGroup>
    </div>

</template>

<script>
import {debounce} from "@/NewModel/Common/Helpers/Debounce"

export default {

    props: {
        query: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            originalQuery: this.query,
        };
    },

    watch: {
        originalQuery: debounce(function (input) {
            if (input.length > 3 || input.length === 0) {
                this.$emit('update:query', input)
                this.$emit('updated', input)
            }
        }, 600)
    },

}
</script>
