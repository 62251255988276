import UserEntity from "../../User/Entity/UserEntity"

export default class AssemblySheetApplianceRepairEntity {

    constructor() {
        this.id = null
        this.worker = null
        this.repairType = null
        this.recordOrder = 0
        this.itemName = ""
        this.pricePerItem = 0.0
        this.quantity = 0
        this.discount = 0
        this.pricePerItemWithDiscount = 0
        this.totalAmountWithoutDiscount = 0
        this.totalDiscountAmount = 0
        this.totalAmount = 0
        this.action = null
    }

    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.worker = response.worker ? UserEntity.from(response.worker) : null
        obj.repairType = response.repairType ? {
            id: response.repairType.id,
            name: response.repairType.name,
        } : null
        obj.recordOrder = response.recordOrder
        obj.itemName = response.itemName
        obj.pricePerItem = response.pricePerItem
        obj.quantity = response.quantity
        obj.discount = response.discount
        obj.pricePerItemWithDiscount = response.pricePerItemWithDiscount
        obj.totalAmountWithoutDiscount = response.totalAmountWithoutDiscount
        obj.totalDiscountAmount = response.totalDiscountAmount
        obj.totalAmount = response.totalAmount
        return obj
    }

    to() {
        return {
            id: this.id,
            worker: this.worker ? this.worker.id : null,
            repairType: this.repairType ? this.repairType.id : null,
            recordOrder: this.recordOrder,
            itemName: this.itemName,
            pricePerItem: this.pricePerItem,
            quantity: this.quantity,
            discount: this.discount,
        }
    }

}
