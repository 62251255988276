import OrderApplianceRepairRequest from "./OrderApplianceRepairRequest"

export default class OrderApplianceRequest {

    constructor() {
        this.id = null
        this.appliance = null
        this.name = ""
        this.appplianceType = ""
        this.serialNumber = ""
        this.repairs = []
    }

    /**
     * @param response : object
     * @returns {OrderApplianceRequest}
     */
    static from(response) {

        let request = new this
        request.id = response.id
        request.appliance = response.appliance
        request.name = response.name
        request.appplianceType = response.appplianceType
        request.serialNumber = response.serialNumber
        request.repairs = response.repairs.map(repair => OrderApplianceRepairRequest.from(repair))
        return request
    }

    toForm() {
        return {
            id: this.id,
            appliance: this.appliance,
            repairs: this.repairs.map(repair => repair.toForm()),
        }
    }

}
