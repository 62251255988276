<template>
    <table class="table table-borderless mb-0">

        <tr>
            <td class="w-5 text-center">
                <i class="fad fa-hashtag fa-fw"/>
            </td>
            <td>
                {{ String(appliance.id).slice(-5) }}
            </td>
        </tr>

        <tr v-if="appliance.serialNumber">
            <td class="w-5 text-center">
                <i class="fad fa-barcode fa-fw"/>
            </td>
            <td>
                {{ appliance.serialNumber }}
            </td>
        </tr>

        <tr class="border-top" v-if="appliance.dateOfInstallation">
            <td class="w-5 text-center">
                <i class="fad fa-calendar-plus fa-fw"/>
            </td>
            <td>
                {{ appliance.dateOfInstallation|prettyDate }}
            </td>
        </tr>

        <tr class="border-top" v-if="appliance.detailPlaceOfInstallation">
            <td class="w-5 text-center">
                <i class="fad fa-map-marker-check fa-fw"/>
            </td>
            <td>
                {{ appliance.detailPlaceOfInstallation }}
            </td>
        </tr>

        <tr class="border-top" v-if="appliance.note">
            <td class="w-5 text-center">
                <i class="fad fa-sticky-note fa-fw"/>
            </td>
            <td>
                {{ appliance.note }}
            </td>
        </tr>

        <tr v-if="!appliance.note && !appliance.detailPlaceOfInstallation && !appliance.dateOfInstallation && !appliance.serialNumber">
            <td colspan="2">
                <TableWithoutRecords>
                    Nejsou známy žádné informace o spotřebiči.
                </TableWithoutRecords>
            </td>
        </tr>

    </table>
</template>

<script>
import ApplianceEntity from "../../../NewModel/Appliance/Entity/ApplianceEntity"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        appliance: {
            type: ApplianceEntity,
            required: true,
        },
    },

}
</script>
