<template>
    <table class="table table-fit-body table-borderless mb-0">
        <tbody>

        <tr class="border-bottom" v-if="order.children.length > 0">
            <td class="font-weight-700 w-25">
                Podřízené zakázky:
            </td>
            <td class="align-middle">
                <div v-for="child in order.children" v-bind:key="'child:' + child.id">
                    <BLink :to="{ name: 'order_show', params: { id: child.id }}">
                        {{ child.customId }}
                    </BLink>
                </div>
            </td>
        </tr>

        <tr class="border-bottom">
            <td class="font-weight-700 w-25">
                Odběrné místo:
            </td>
            <td class="align-middle">
                <BLink
                    :to="{ name: 'supply_point_show', params: { id: order.supplyPoint }}"
                    target='_blank'
                >
                    {{ order.ean }}
                </BLink>
            </td>
        </tr>

        <tr class="border-bottom">
            <td class="font-weight-700 w-25">
                Adresa:
            </td>
            <td class="align-middle">
                {{ order.address }}
            </td>
        </tr>

        <tr class="border-bottom" v-if="order.customer">
            <td class="font-weight-700 w-25">
                Zákazník:
                <div v-if="order.payer === 1"><small>(hradí zakázku)</small></div>
            </td>
            <td class="align-middle">
                <div class="font-weight-700 text-uppercase">{{ order.customerName }}</div>
            </td>
        </tr>

        <tr class="border-bottom" v-if="order.propertyOwner">
            <td class="font-weight-700 w-25">
                Vlastník nemovitosti:
                <div v-if="order.payer === 2"><small>(hradí zakázku)</small></div>
            </td>
            <td class="align-middle">
                <div class="font-weight-700 text-uppercase">{{ order.propertyOwnerName }}</div>
            </td>
        </tr>

        <tr class="border-bottom">
            <td class="font-weight-700 w-25">
                Úhrada:
            </td>
            <td class="align-middle">
                <div v-if="order.formOfPayment === 'invoice'">Faktura</div>
                <div v-if="order.formOfPayment === 'cash'">Hotově</div>
                <div v-if="order.estimatedAmount && order.estimatedAmount > 0">Předběžná smluvní cena:
                    {{ order.estimatedAmount|moneyFormat('CZK') }}
                </div>
            </td>
        </tr>

        <tr class="border-bottom" v-if="order.contact !== null">
            <td class="font-weight-700 w-25">
                Kontakt:
            </td>
            <td class="align-middle">
                <span v-if="order.contact.name">
                    {{ order.contact.name }}<span v-if="order.contact.phone">, </span>
                </span>
                <span v-if="order.contact.phone">
                    {{ order.contact.phone }}<span v-if="order.contact.email">, </span>
                </span>
                <span v-if="order.contact.email">
                    <a :href="'mailto:' + order.contact.email">{{ order.contact.email }}</a>
                </span>
            </td>
        </tr>

        <tr class="border-bottom">
            <td class="font-weight-700 w-25">
                Technik:
            </td>
            <td class="align-middle">
                {{ order.worker ? order.workerName : "N/A" }}
            </td>
        </tr>

        <tr class="border-bottom">
            <td class="font-weight-700 w-25">
                Stav:
            </td>
            <td class="align-middle">
                {{ orderStatus.getItem(order.status).text }}
            </td>
        </tr>

        <tr class="border-top">
            <td class="font-weight-700 w-25">
                Datum a čas:
            </td>
            <td class="align-middle">
                {{
                    {
                        startTime: order.scheduledStartTime,
                        endTime: order.scheduledEndTime
                    }|prettyDateRange
                }}
            </td>
        </tr>

        <tr v-if="order.list" class="border-top">
            <td class="font-weight-700 w-25">
                Montážní list:
            </td>
            <td class="align-middle">
                <AttachmentDownloadLink
                    :id="order.list"
                    :name="('Montážní list k zakázce ' + (order.orderNumber || order.id) )"
                    style="cursor: pointer;"
                    icon="fad fa-download"
                    :text="order.list"
                />
            </td>
        </tr>

        <tr v-if="order.cancelNote" class="border-top">
            <td class="font-weight-700 w-25">
                Poznámka ke zrušení:
            </td>
            <td class="align-middle">
                {{ order.cancelNote }}
            </td>
        </tr>

        <tr v-if="order.note" class="border-top">
            <td class="font-weight-700 w-25">
                Poznámka:
            </td>
            <td class="align-middle">
                {{ order.note }}
            </td>
        </tr>

        </tbody>
    </table>
</template>

<script>
import AttachmentDownloadLink from "../../Common/Attachment/AttachmentDownloadLink.vue"
import NewOrderEntity from "../../../NewModel/Order/Entity/NewOrderEntity"
import ServiceOrderStatus from "@/Model/Enum/service_order_status"

export default {

    components: {
        AttachmentDownloadLink,
    },

    props: {
        order: {
            type: NewOrderEntity,
            required: true,
        }
    },

    data() {
        return {
            orderStatus: ServiceOrderStatus,
        }
    },

}
</script>
