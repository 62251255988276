<template>
    <div>

        <Header title="Úprava odběrného místa">
            <template #buttons>
                <BackLinkButton :to="{name: 'supply_point_show'}"/>
            </template>
        </Header>

        <SupplyPointForm :request="request" :is-processing="isProcessing" v-on:submitted="onSubmitted"/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Header from "../Common/UI/Header.vue"
import BackLinkButton from "../Common/UI/Button/BackLinkButton.vue"
import SupplyPointForm from "./Component/SupplyPointForm.vue"
import SupplyPointRequest from "../../NewModel/SupplyPoint/Request/SupplyPointRequest"

export default {

    components: {
        BackLinkButton,
        Header,
        SupplyPointForm,
    },

    data() {
        return {
            request: new SupplyPointRequest(),
        }
    },

    computed: {
        ...mapGetters({
            supplyPoint: "supplyPoint/getSupplyPoint",
            isProcessing: 'supplyPoint/isProcessing',
        }),
    },

    mounted() {
        this.request = SupplyPointRequest.from(this.supplyPoint)
    },

    methods: {

        /**
         * @param request : SupplyPointRequest
         */
        onSubmitted(request) {
            this.$store.dispatch("supplyPoint/updateSupplyPoint", {
                id: this.supplyPoint.id,
                request: request
            })
                .then((supplyPoint) => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$store.dispatch("supplyPoint/getSupplyPoint", supplyPoint.id)
                    this.$router.push("/supply_points/" + supplyPoint.id + "/show")
                })
                .catch((error) => {
                    this.warningMessage(error.response.data.message)
                })
        },

    },

}
</script>
