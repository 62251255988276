<template>
  <div id="app" class="min-vh-100 h-100">
    <router-view v-if="isPublic || isLoggedIn" />
    <div class="min-vw-100 min-vh-100 d-flex justify-content-center align-items-center" v-else>
      <i class="fad fa-spinner-third fa-spin fa-8x" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isLoading: 'auth/isLoading',
      user: 'auth/getUser',
    }),
    isPublic() {
      return this.$route.meta.Public;
    }
  },

  watch: {
    '$route'(to) {
      document.title = to.meta.title ? (+' | ' + 'Lemigas s.r.o.') : 'Lemigas s.r.o.';
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.swal2-content {
  font-size: 0.9rem !important;
}

.swal2-select {
  font-size: 0.9rem !important;
}

.multiselect__tag {
  background: #0079ab;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #006b99;
}

.multiselect__tags {
  font-size: 14px !important;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-size: inherit !important;
}

.multiselect__option--highlight {
  background: #0079ab !important;
}

.pointer {
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.bold {
  font-weight: 600 !important;
}

.mx-datepicker {
   position: relative;
   display: block !important;
   width: 100% !important;
}

</style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");
@import "Style/custom";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";
</style>
