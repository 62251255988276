<template>
    <tr class="border-top">
        <template v-if="repair.action === 'edit' || repair.action === 'create'">
            <td class="align-middle">
                <BInputGroup size="sm">
                    <AssemblySheetRepairFieldWithSuggestions
                        :locked="form.locked"
                        :defaultText="form.itemName"
                        @picked="onSuggestionPicked"
                        @input="onSuggestionInput"
                        class="w-90"
                    />
                    <BInputGroupAppend class="w-10">
                        <BButton
                            variant="light"
                            block
                            :disabled="form.locked === false"
                            @click.prevent="unlock">
                            X
                        </BButton>
                    </BInputGroupAppend>
                </BInputGroup>
            </td>
            <td class="align-middle text-center">
                <BFormInput
                    class="text-center"
                    min="1"
                    type="number"
                    v-model="form.quantity"
                    size="sm"
                    :disabled="isProcessing"
                    required
                />
            </td>
            <td class="align-middle text-right">
                <BInputGroup append="Kč" size="sm">
                    <BFormInput
                        class="text-right"
                        min="0"
                        step="0.1"
                        v-model.number="form.pricePerItem"
                        :disabled="isProcessing"
                        required
                    />
                </BInputGroup>
            </td>
            <td class="align-middle text-right">
                <BInputGroup append="%" size="sm">
                    <BFormInput
                        class="text-right"
                        min="0"
                        max="100"
                        step="0.1"
                        v-model.number="form.discount"
                        :disabled="isProcessing"
                        required
                    />
                </BInputGroup>
            </td>
            <td class="align-middle text-right">
                <BButton variant="danger" @click="cancelRow()">
                    <i class="fa fa-times fa-fw"/>
                </BButton>
                <BButton variant="success" @click="saveRow()" class="ml-3" :disabled="isProcessing">
                    <i class="fad fa-spinner-third fa-spin fa-fw" v-if="isProcessing"/>
                    <i class="fad fa-check fa-fw" v-else/>
                </BButton>
            </td>
        </template>
        <template v-else>
            <td class="align-middle">
                {{ repair.itemName }}
            </td>
            <td class="align-middle text-center">
                {{ repair.quantity }}
            </td>
            <td class="align-middle text-right">
                {{ repair.pricePerItem|moneyFormat("CZK_LONG") }}
            </td>
            <td class="align-middle text-right">
                {{ repair.discount }} %
            </td>
            <td class="align-middle text-right">
                <BButton variant="danger" @click="deleteRow()">
                    <i class="fad fa-trash fa-fw"/>
                </BButton>
                <BButton variant="primary" @click="openEditFormMode()" class="ml-3">
                    <i class="fad fa-edit fa-fw"/>
                </BButton>
            </td>
        </template>
    </tr>
</template>

<script>
import {mapGetters} from "vuex"
import AssemblySheetApplianceRepairEntity
    from "../../../../../NewModel/AssemblySheet/Entity/AssemblySheetApplianceRepairEntity"
import AssemblySheetRepairFieldWithSuggestions from "./AssemblySheetRepairFieldWithSuggestions.vue"

export default {

    components: {
        AssemblySheetRepairFieldWithSuggestions,
    },

    props: {
        assemblySheetId: {
            type: String,
            required: true,
        },
        applianceId: {
            type: String,
            required: true,
        },
        repair: {
            type: AssemblySheetApplianceRepairEntity,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isProcessing: "assemblySheet/applianceRepairs/isProcessing",
        }),
    },

    data() {
        return {
            form: {
                repairType: null,
                itemName: "",
                pricePerItem: 0,
                discount: 0,
                quantity: 1,
                locked: false,
            },
            list: {
                repairTypes: [],
            },
        }
    },

    mounted() {
        if (this.repair.action === "create") {
            this.openCreateFormMode()
        }
    },

    methods: {

        onSuggestionInput(value) {
            this.form.itemName = value
        },

        onSuggestionPicked(pick) {
            this.form.repairType = pick.id
            this.form.locked = true
            this.form.itemName = pick.name
            this.form.pricePerItem = pick.price
            this.form.discount = pick.discount || 0
        },

        unlock() {
            this.form.repairType = null
            this.form.locked = false
        },

        openFormMode(mode) {
            this.repair.action = mode
        },

        openCreateFormMode() {
            this.openFormMode("create")
        },

        fillFormFromEntity(repair) {
            if (this.repair.action === "create") {
                this.form.repairType = null
            } else {
                this.form.repairType = repair.repairType ? repair.repairType.id : 0
                this.form.locked = this.form.repairType > 0
            }
            this.form.itemName = repair.itemName
            this.form.pricePerItem = repair.pricePerItem
            this.form.quantity = repair.quantity
            this.form.discount = repair.discount
        },

        openEditFormMode() {
            this.openFormMode("edit")
            this.fillFormFromEntity(this.repair)
        },

        cancelRow() {
            if (this.repair.action === "edit") {
                this.fillFormFromEntity(this.repair)
                this.repair.action = null
            } else {
                this.$emit("cancelled", {
                    applianceId: this.applianceId,
                    index: this.index
                })
            }
        },

        deleteRow() {
            let self = this
            this.$bvModal.msgBoxConfirm("Opravdu chcete odstranit položku " + this.repair.itemName + "?", {
                title: "Potvrzení",
                okVariant: "primary",
                cancelVariant: "default",
                centered: true,
                okTitle: "Ano",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("assemblySheet/applianceRepairs/deleteRepair", {
                            assemblySheetId: this.assemblySheetId,
                            applianceId: this.applianceId,
                            repairId: this.repair.id,
                        })
                            .then(() => {
                                self.$emit("cancelled", {
                                    applianceId: this.applianceId,
                                    index: this.index,
                                })
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

        saveRow() {
            try {
                this.checkForm()
                if (this.repair.action === "edit") {
                    this.$store.dispatch("assemblySheet/applianceRepairs/updateRepair", {
                        assemblySheetId: this.assemblySheetId,
                        applianceId: this.applianceId,
                        repairId: this.repair.id,
                        formData: {
                            repairType: (this.form.repairType && this.form.repairType !== 0) ? this.form.repairType : null,
                            itemName: this.form.itemName,
                            pricePerItem: parseFloat(this.form.pricePerItem),
                            discount: parseInt(this.form.discount),
                            quantity: parseFloat(this.form.quantity),
                        }
                    })
                        .then((response) => {
                            this.repair.action = null
                            this.$emit("updated", {
                                applianceId: this.applianceId,
                                repairId: this.repair.id,
                                index: this.index,
                                entity: response
                            })
                        })
                } else if (this.repair.action === "create") {
                    this.$store.dispatch("assemblySheet/applianceRepairs/createRepair", {
                        assemblySheetId: this.assemblySheetId,
                        applianceId: this.applianceId,
                        formData: {
                            repairType: (this.form.repairType && this.form.repairType !== 0) ? this.form.repairType : null,
                            itemName: this.form.itemName,
                            pricePerItem: parseFloat(this.form.pricePerItem),
                            discount: parseInt(this.form.discount),
                            quantity: parseFloat(this.form.quantity),
                        }
                    })
                        .then((response) => {
                            this.repair.action = null
                            this.$emit("created", {
                                applianceId: this.applianceId,
                                repairId: this.repair.id,
                                index: this.index,
                                entity: response
                            })
                        })
                }

            } catch (err) {
                this.warningMessage(err)
                return false
            }
        },

        checkForm() {
            if (this.form.repairType === null && this.form.itemName === "") {
                throw "Pokud není vybrána oprava ze seznamu, je nutné vyplnit název položky!"
            }
            if (this.form.discount < 0 || this.form.discount > 100) {
                throw "Sleva musí být v rozmezí 0 až 100 %!"
            }
            if (this.form.quantity < 1) {
                throw "Počet musí být větší než 0!"
            }
        },

    },

}
</script>
