<template>
    <div>
        <div class="bg-little-gray p-4" v-if="records.length > 0">
            <table class="table table-fit table-borderless mb-0">
                <thead>
                <tr>
                    <th class="align-middle w-60">
                        Název
                    </th>
                    <th class="text-center align-middle w-10">
                        Autor
                    </th>
                    <th class="text-center align-middle w-10">
                        Datum
                    </th>
                    <th class="text-right align-middle w-20">
                        Akce
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="record in records" v-bind:key="record.id" class="border-top">
                    <td class="align-middle">
                        <i class="fa-solid fa-file-spreadsheet text-success fa-fw mr-3"/>
                        {{ record.name }}
                    </td>
                    <td class="text-center align-middle">
                        <BAvatar
                            size="md"
                            :src="record.createdBy.picture || null"
                            :text="record.createdBy.acronym"
                            v-if="record.createdBy"
                        />
                        <BAvatar size="md" variant="light" v-else/>
                    </td>
                    <td class="text-center align-middle">
                        {{ record.createdAt|prettyDate }}
                    </td>
                    <td class="align-middle text-right">
                        <AttachmentDownloadButton
                            :id="record.id"
                            :name="record.name"
                            size="sm"
                            class="ml-4"
                            icon="fad fa-download"
                            text="Stáhnout"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import AttachmentDownloadButton from "../../../Common/Attachment/AttachmentDownloadButton.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        AttachmentDownloadButton,
        TableWithoutRecords,
    },

    props: {
        records: {
            required: true,
        },
    },

}

</script>
