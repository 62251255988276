export default class ApplianceModelEntity {

    constructor() {
        this.id = null
        this.name = ""
        this.fullName = ""
        this.manufacturer = {
            id: null,
            name: ""
        }
        this.description = ""
        this.active = false
        this.commodity = 1
    }

    /**
     * @param response : object
     * @returns ApplianceModelEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.fullName = response.fullName
        obj.manufacturer.id = response.boilerManufacturer.id
        obj.manufacturer.name = response.boilerManufacturer.name
        obj.description = response.description
        obj.active = response.active
        obj.commodity = response.commodity
        return obj
    }

    to() {
        return this.id
    }

}
