export default class SupplyPointNoteRequest {

    constructor() {
        this.message = ""
        this.type = 1
    }

    toForm() {
        return {
            message: this.message,
            type: this.type
        }
    }

}
