<template>
    <BButton
        variant="primary"
        v-on:click="loadData"
        :disabled="String(identificationNumber).length < 8 || isLoading"
    >
        <span v-if="isLoading">
            <i class="fa fa-spinner-third fa-spin mr-2"/>Kontaktuji registr
        </span>
        <span v-else>
            Předvyplnit údaje podle IČO
        </span>
    </BButton>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    props: {
        identificationNumber: {
            type: String,
            default: "",
            required: false,
        },
    },

    computed: {
        ...mapGetters({
            isLoading: "ares/isLoading",
        }),
    },

    methods: {

        loadData() {
            this.$store.dispatch("ares/getSubject", this.identificationNumber)
                .then((data) => {
                    this.$emit("obtained", data)
                })
        },

    },

}
</script>
