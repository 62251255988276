<template>
    <div>

        <Header icon="fad fa-satellite" title="Spotřebiče">
            <template #buttons>
                <SuccessLinkButton
                    :to="{ name: 'appliance_planning_map'}"
                    icon="fad fa-map-marked-alt"
                    text="Mapa plánovaných oprav"
                />
            </template>
        </Header>

        <ApplianceGrid/>

    </div>
</template>

<script>
import ApplianceGrid from "./Component/ApplianceGrid.vue"
import Header from "../../Module/Common/UI/Header.vue"
import SuccessLinkButton from "../../Module/Common/UI/Button/SuccessLinkButton.vue"

export default {

    components: {
        ApplianceGrid,
        Header,
        SuccessLinkButton,
    },

}
</script>
