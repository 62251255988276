import _ from "lodash"

const CommodityList = [
    {id: 1, text: "Plyn", icon: "fad fa-burn text-success"},
    {id: 2, text: "Elektřina", icon: "fad fa-bolt text-danger"},
    {id: 9, text: "Ostatní", icon: "far fa-question-circle"},
]

export default class Commodity {

    constructor(id) {
        this.selected = id !== null ? id : null
        this.items = {...CommodityList}
    }

    toString() {
        let o = _.find(this.items, {id: this.selected})
        return o === undefined ? "N/A" : o.text
    }

    static print(id) {
        let o = _.find({...CommodityList}, {id: parseInt(id)})
        return o === undefined ? "N/A" : o.text
    }

    static list() {
        return {...CommodityList}
    }

    static arrayList() {
        return CommodityList
    }

    static getItem(id) {
        let o = _.find({...CommodityList}, {id: parseInt(id)})
        return o === undefined ? null : o
    }

}
