<template>
    <div>

        <Header title="Odběrné místo">
            <template #buttons>
                <PrimaryLinkButton
                    :to="{ name: 'supply_point_edit'}"
                    v-if="supplyPoint.contract === null"
                    icon="fad fa-pencil-ruler"
                    text="Upravit"
                    class="ml-4"
                />
                <BackLinkButton :to="{name: 'supply_point_list'}" class="ml-4"/>
            </template>
        </Header>

        <BTabs content-class="mt-4 p-0" active-nav-item-class="btn-primary" pills lazy>

            <BTab class="p-0" title-item-class="bg-light" active>

                <template #title>
                    Základní údaje
                </template>

                <BRow>
                    <BCol cols="8">

                        <SupplyPointInformation :supplyPoint="supplyPoint" class="bg-little-gray p-4 mb-4"/>

                        <div class="mb-4" v-if="hasMetadata && metadata.lastOrder">
                            <h5 class="font-weight-700 text-dark mb-2">Poslední zakázka</h5>
                            <div class="bg-little-gray p-4">
                                <div class="mb-1">
                                    {{ metadata.lastOrder.date|prettyDate }}
                                    <span class="mx-2">|</span>
                                    {{ metadata.lastOrder.orderNumber }}
                                    <span class="mx-2">|</span>
                                    {{ metadata.lastOrder.worker }}
                                </div>
                                <div>
                                    {{ metadata.lastOrder.includedRepairs.join(", ") }}
                                </div>
                            </div>
                        </div>

                        <div class="mb-4" v-if="hasMetadata && metadata.lastNote">
                            <h5 class="font-weight-700 text-dark mb-2">Poslední poznámka</h5>
                            <div class="bg-little-gray p-4">
                                <div class="mb-1">
                                    {{ metadata.lastNote.date|prettyDate }}
                                </div>
                                <div>
                                    {{ metadata.lastNote.message }}
                                </div>
                            </div>
                        </div>

                    </BCol>
                    <BCol>

                        <BButton variant="primary" block @click.prevent="createOrder" size="md" class="mb-4">
                            <i class="fal fa-plus-circle fa-fw mr-2"/> Vytvořit zakázku
                        </BButton>

                        <div class="table-warning p-4 mb-4 align-items-center mb-4"
                             v-if="supplyPoint.activeOrder !== null">
                            <i class="fad text-warning fa-exclamation-triangle fa-fw mr-3"/>
                            <strong class="font-weight-600">Probíhající zakázka</strong><br/>
                            <RouterLink
                                :to="{ name: 'order_show', params: {id: supplyPoint.activeOrder}}"
                                variant="warning"
                                size="sm"
                                class="text-dark"
                            >
                                Přejít do zakázky
                            </RouterLink>
                        </div>

                        <div class="table-danger p-4 mb-4" v-if="hasMetadata && metadata.beCareful">
                            <i class="fad text-danger fa-exclamation-triangle fa-fw mr-3"/>
                            <strong class="font-weight-600">Buďte obezřetní!</strong><br/>
                            Bližší informace naleznete v poznámkách.
                        </div>

                        <div class="table-warning p-4 mb-4"
                             v-if="hasMetadata && metadata.appliancesAgreeWithContract === false">
                            <i class="fad text-warning fa-exclamation-triangle fa-fw mr-3"/>
                            <strong class="font-weight-600">Nesouhlasí spotřebiče!</strong><br/>
                            Nesouhlasí typy spotřebičů nebo jejich počet oproti smlouvě!
                        </div>

                        <div class="table-info p-4 mb-4" v-if="hasMetadata && metadata.validDiscount">
                            <i class="fad text-info fa-piggy-bank fa-fw mr-3"/>
                            <strong class="font-weight-600">Existující sleva na OM!</strong><br/>
                            <RouterLink
                                :to="{name: 'discounts_show', params: {id: metadata.validDiscount}}"
                                target="_blank"
                                class="text-dark"
                            >
                                Přejít do detailu slevy
                            </RouterLink>
                        </div>

                    </BCol>
                </BRow>

            </BTab>

            <BTab title="Spotřebiče" title-item-class="bg-light ml-3">
                <template #title>
                    Spotřebiče
                    <BBadge
                        variant="danger"
                        class="ml-1"
                        v-if="hasMetadata && metadata.numberOfAppliances > 0"
                    >
                        {{ metadata.numberOfAppliances }}
                    </BBadge>
                </template>

                <SupplyPointAppliancesComponent :supplyPoint="supplyPoint.id" class="mb-4"/>
                <BButton
                    variant="light"
                    size="sm"
                    :to="'/supply_points/' + supplyPoint.id + '/appliances/create'"
                >
                    <i class="fad fa-plus-circle mr-2"/> Vytvořit nový spotřebič
                </BButton>

            </BTab>

            <BTab title="Kontakty" title-item-class="bg-light ml-3">

                <template #title>
                    Kontakty
                    <BBadge
                        variant="danger"
                        class="ml-1"
                        v-if="hasMetadata && metadata.numberOfContacts > 0"
                    >
                        {{ metadata.numberOfContacts }}
                    </BBadge>
                </template>

                <div class="mb-4" v-if="supplyPoint.customer">
                    <h5 class="font-weight-700 text-dark mb-2">Kontakty zákazníka</h5>
                    <SubjectContactsComponent
                        :subject="supplyPoint.customer.id"
                        @deleted="metadata.numberOfContacts -= 1"
                    />
                </div>

                <div
                    class="mb-4"
                    v-if="supplyPoint.propertyOwner && supplyPoint.propertyOwner.id !== supplyPoint.customer.id"
                >
                    <h5 class="font-weight-700 text-dark mb-2">Kontakty vlastníka</h5>
                    <SubjectContactsComponent
                        :subject="supplyPoint.propertyOwner.id"
                        @deleted="metadata.numberOfContacts -= 1"
                    />
                </div>

            </BTab>

            <BTab title-item-class="bg-light ml-3">

                <template #title>
                    Zakázky
                    <BBadge
                        variant="danger"
                        class="ml-1"
                        v-if="hasMetadata && metadata.numberOfOrders > 0"
                    >
                        {{ metadata.numberOfOrders }}
                    </BBadge>
                </template>

                <SupplyPointOrders :supplyPoint="supplyPoint.id"/>

            </BTab>

            <BTab title="Komunikace a poznámky" title-item-class="bg-light ml-3 p-0">

                <template #title>
                    Komunikace a poznámky
                    <BBadge
                        variant="danger"
                        class="ml-1"
                        v-if="hasMetadata && metadata.numberOfNotes > 0"
                    >
                        {{ metadata.numberOfNotes }}
                    </BBadge>
                </template>

                <SupplyPointNotes :supplyPoint="supplyPoint.id"/>

            </BTab>

        </BTabs>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../Common/UI/Button/BackLinkButton.vue"
import Header from "../Common/UI/Header.vue"
import PrimaryLinkButton from "../Common/UI/Button/PrimaryLinkButton.vue"
import SubjectContactsComponent from "../Subject/Component/SubjectContactsComponent.vue"
import SupplyPointAppliancesComponent from "./Component/SupplyPointAppliancesComponent.vue"
import SupplyPointInformation from "./Component/SupplyPointInformation.vue"
import SupplyPointNotes from "./Component/SupplyPointNotesComponent.vue"
import SupplyPointOrders from "./Component/SupplyPointOrdersComponent.vue"
import Sweetalert2 from "sweetalert2"

export default {

    components: {
        BackLinkButton,
        Header,
        PrimaryLinkButton,
        SubjectContactsComponent,
        SupplyPointAppliancesComponent,
        SupplyPointInformation,
        SupplyPointNotes,
        SupplyPointOrders,
    },

    computed: {
        ...mapGetters({
            supplyPoint: "supplyPoint/getSupplyPoint",
            hasMetadata: "supplyPoint/meta/hasData",
            metadata: "supplyPoint/meta/getData",
        }),
    },

    created() {
        this.$eventBus.$on("supply-point-meta-updated", () => {
            this.loadSupplyPointMetadata(this.$route.params.id)
        })
    },

    mounted() {
        this.loadSupplyPointMetadata(this.$route.params.id)
    },

    beforeDestroy() {
        this.$eventBus.$off("supply-point-meta-updated")
    },

    methods: {

        loadSupplyPointMetadata(id) {
            this.$store.dispatch("supplyPoint/meta/getMeta", id)
        },

        createOrder() {
            let self = this
            Sweetalert2.fire({
                title: "Nová zakázka",
                text: "Potvrďte vytvoření zakázky",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#437e9e",
                cancelButtonColor: "#db2f36",
                confirmButtonText: "Ano",
                cancelButtonText: "Ne"
            })
                .then((result) => {
                    if (result.value) {
                        this.$store.dispatch("supplyPoint/orders/createOrder", self.supplyPoint.id)
                            .then((order) => {
                                this.successMessage("Zakázka vytvořena. Za okamžik budete přesměrováni.")
                                this.$router.push({name: "order_show", params: {id: order.id}})
                            })
                    }
                })
        },

    },

}
</script>
