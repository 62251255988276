<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-5">
            <div class="d-flex align-items-center">
                <h3 class="font-weight-600 text-dark mb-0">
                    <i class="fad fa-satellite text-primary fa-fw mr-3"/>{{ appliance.name }}
                </h3>
            </div>
            <div class="d-flex align-items-center">
                <PrimaryLinkButton
                    :to="'/worker/orders/' + $route.query.order"
                    v-if="$route.query.order"
                    icon="fad fa-chevron-left"
                    text="Zpět do zakázky"
                />
                <PrimaryLinkButton
                    to="/worker/dashboard/"
                    v-else-if="$route.query.source === 'dashboard'"
                    icon="fad fa-chevron-left"
                    text="Zpět na dashboard"
                />
                <PrimaryLinkButton
                    :to="'/worker/supply_points/' + $route.query.supplyPoint"
                    v-else-if="$route.query.supplyPoint"
                    icon="fad fa-chevron-left"
                    text="Zpět do OM"
                />
                <PrimaryButton
                    @click.prevent="$router.go(-1)"
                    v-else
                    icon="fad fa-chevron-left"
                    text="Zpět"
                />
            </div>
        </div>


        <BTabs
            content-class="mt-5"
            active-nav-item-class="text-white bg-primary border-radius-sm"
            pills
            lazy
            justified
        >
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase" active>
                <template #title>
                    <div class="m-1">
                        Informace
                    </div>
                </template>

                <div class="bg-little-gray p-4">
                    <table class="table table-borderless table-sm mb-0">
                        <tr>
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Identifikátor spotřebiče">
                                Identifikátor spotřebiče:
                            </td>
                            <td>
                                #{{ String(appliance.id).slice(-5) }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Odběrné místo">
                                Odběrné místo:
                            </td>
                            <td>
                                <BLink class="border-dashed" :to="'/worker/supply_points/' + appliance.supplyPoint.id">
                                    {{ appliance.supplyPoint.ean }}
                                </BLink>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Název spotřebiče">
                                Název spotřebiče:
                            </td>
                            <td>
                                {{ appliance.name }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Druh spotřebiče">
                                Druh spotřebiče:
                            </td>
                            <td>
                                {{ appliance.applianceType }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Sériové číslo">
                                Sériové číslo:
                            </td>
                            <td>
                                {{ appliance.serialNumber }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Datum instalace">
                                Datum instalace:
                            </td>
                            <td>
                                {{ appliance.dateOfInstallation|prettyDate }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Datum platnosti záruky">
                                Datum platnosti záruky:
                            </td>
                            <td>
                                {{ appliance.warrantyToDate|prettyDate }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Regulace">
                                Regulace:
                            </td>
                            <td>
                                {{ appliance.regulation }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Palivo">
                                Palivo:
                            </td>
                            <td>
                                {{ this.appliance.fuel ? this.fuel.getItem(this.appliance.fuel).text : "N/A" }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Upřesnění místa">
                                Upřesnění místa:
                            </td>
                            <td>
                                {{ appliance.location }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="font-weight-600 w-30" v-b-tooltip.hover title="Poznámka ke spotřebiči">
                                Poznámka ke spotřebiči:
                            </td>
                            <td>
                                {{ appliance.note }}
                            </td>
                        </tr>
                    </table>

                </div>

            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Opravy
                        <BBadge
                            variant="danger"
                            class="ml-1"
                            v-if="appliance.repairs && appliance.repairs.length > 0"
                        >
                            {{ appliance.repairs.length }}
                        </BBadge>
                    </div>
                </template>
                <Repairs :records="appliance.repairs"/>
            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Dokumenty
                        <BBadge
                            variant="danger"
                            class="ml-1"
                            v-if="appliance.documents && appliance.documents.length > 0"
                        >
                            {{ appliance.documents.length }}
                        </BBadge>
                    </div>
                </template>
                <Documents :records="appliance.documents"/>
            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Poznámky
                        <BBadge
                            variant="danger"
                            class="ml-1"
                            v-if="appliance.technicalNotes && appliance.technicalNotes.length > 0"
                        >
                            {{ appliance.technicalNotes.length }}
                        </BBadge>
                    </div>
                </template>
                <Notes :records="appliance.technicalNotes"/>
            </BTab>
        </BTabs>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Documents from "./Component/Documents.vue"
import Fuel from "../../../Model/Enum/fuel"
import Notes from "./Component/Notes.vue"
import PrimaryButton from "../../Common/UI/Button/PrimaryButton.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"
import Repairs from "./Component/Repairs.vue"

export default {

    components: {
        Documents,
        Notes,
        PrimaryButton,
        PrimaryLinkButton,
        Repairs,
    },

    data() {
        return {
            fuel: Fuel,
        }
    },

    computed: {
        ...mapGetters({
            appliance: "worker/appliance/detail/getRecord",
        })
    },

    methods: {

        formatBreakLines(text) {
            return text.replace(/\n/g, "<br />")
        },

    },

}
</script>
