<template>

    <table class="table table-borderless mb-0">
        <thead>
        <tr>
            <th class="w-25">
                Název
            </th>
            <th class="w-25">
                Druh spotřebiče
            </th>
            <th class="w-15 text-center">
                Výkon
            </th>
            <th class="w-15 text-center">
                Palivo
            </th>
            <th class="w-5 text-center">
                Aktivní
            </th>
            <th class="w-15 text-right"/>
        </tr>
        </thead>
        <tbody v-if="records">
        <ApplianceModelTypeForm
            v-for="(request, index) in records"
            v-bind:key="'boiler_type' + index"
            :request="request"
            :applianceTypes="applianceTypes_records"
            :index="index"
            class="border-top"
            @cancelled="onCancelled"
            @submitted="onSubmitted"
        />
        </tbody>
        <tbody v-if="!boilerTypes_hasRecords">
        <tr class="border-top">
            <td colspan="5">
                <TableWithoutRecords>Nejsou k dispozici žádná provedení k tomuto modelu.</TableWithoutRecords>
            </td>
        </tr>
        </tbody>
        <tfoot class="border-top">
        <tr>
            <td colspan="5">
                <BLink v-on:click.prevent="addBoilerType">
                    Přidat nové provedení
                </BLink>
            </td>
        </tr>
        </tfoot>
    </table>

</template>

<script>
import {mapGetters} from "vuex"
import ApplianceModelEntity from "../../../../NewModel/Appliance/Model/Entity/ApplianceModelEntity"
import ApplianceModelTypeForm from "../../../../Module/Setup/Model/Component/ApplianceModelTypeForm.vue"
import ApplianceModelTypeRequest from "../../../../NewModel/Appliance/ModelType/Request/ApplianceModelTypeRequest"
import Fuel from "../../../../Model/Enum/fuel"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
        ApplianceModelTypeForm,
    },

    props: {
        entity: {
            type: ApplianceModelEntity,
            required: false,
            default: null,
        },
    },

    computed: {
        ...mapGetters({
            applianceTypes_isLoading: 'appliance/types/isLoading',
            applianceTypes_hasRecords: 'appliance/types/hasTypes',
            applianceTypes_records: 'appliance/types/getTypes',
            boilerTypes_isLoading: 'appliance/models/types/isLoading',
            boilerTypes_hasRecords: 'appliance/models/types/getModelTypes',
            boilerTypes_records: 'appliance/models/types/getModelTypes',
        }),
    },

    data() {
        return {
            records: [],
            list: {
                fuel: Fuel.arrayList(),
            },
        }
    },

    created() {
        this.loadApplianceTypes()
        this.loadBoilerTypes()
    },

    methods: {

        loadApplianceTypes() {
            this.$store.dispatch("appliance/types/findAllActiveTypes")
        },

        loadBoilerTypes() {
            let self = this
            this.$store.dispatch("appliance/models/types/getModelTypes", this.entity.id)
                .then((records) => {
                    self.records = records.map((record) => {
                        return ApplianceModelTypeRequest.fromEntity(record, self.entity.manufacturer.id, self.entity.id)
                    })
                })
        },

        addBoilerType() {
            let request = new ApplianceModelTypeRequest()
            request.manufacturer = this.entity.manufacturer.id
            request.model = this.entity.id
            request._editable = true
            request._new = true
            this.records.push(request)
        },

        onCancelled({request, index}) {
            if (request._new) {
                this.records.splice(index, 1)
            } else {
                request._editable = false
            }
        },

        onSubmitted({request, index}) {
            if (request._new) {
                this.createModelType(request, index)
            } else {
                this.updateModelType(request, index)
            }
        },

        createModelType(request, index) {
            let self = this
            this.$store.dispatch("appliance/modelTypes/createModelType", request)
                .then((response) => {
                    this.successMessage('Úspěšně uloženo!')
                    let newOne = ApplianceModelTypeRequest.fromEntity(response, self.entity.manufacturer.id, self.entity.id)
                    this.$nextTick(() => {
                        self.records.splice(index, 1, newOne)
                    })
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        updateModelType(request, index) {
            let self = this
            this.$store.dispatch("appliance/modelTypes/updateModelType", {
                id: request._id,
                request: request
            })
                .then((response) => {
                    this.successMessage('Úspěšně uloženo!')
                    let newOne = ApplianceModelTypeRequest.fromEntity(response, self.entity.manufacturer.id, self.entity.id)
                    this.$nextTick(() => {
                        self.records.splice(index, 1, newOne)
                    })
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
