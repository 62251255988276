<template>
    <div class="container mb-5">

        <Header icon="fad fa-car-mechanic" :title="'Zakázka ' + (order.orderNumber ? '#' + order.orderNumber : '')">
            <template #buttons>
                <InfoLinkButton
                    :to="{ name: 'order_show'}"
                    class="ml-4"
                    icon="fad fa-chevron-left"
                    text="Zpět"
                />
            </template>
        </Header>

        <BOverlay
            bg-color="white"
            spinner-variant="primary"
            spinner-type="grow"
            :opacity="0.75"
            :show="hasMetadata === false"
        >

            <BRow class="mb-4">

                <BCol cols="4" class="">
                    <div class="bg-little-gray p-4 h-100">
                        <div class="font-weight-900 mb-2">EAN:</div>
                        <div>{{ order.ean }}</div>
                    </div>
                </BCol>

                <BCol cols="4">
                    <div class="bg-little-gray p-4 h-100">
                        <div class="font-weight-900 mb-2">Adresa:</div>
                        <div>{{ order.address }}</div>
                    </div>
                </BCol>

                <BCol cols="4">
                    <div class="bg-little-gray p-4 h-100">
                        <div class="font-weight-900 mb-2">Kontakt:</div>
                        <div v-if="request.contact && contact">
                            <div class="mb-1">
                                <i class="far fa-user-alt text-primary fa-fw mr-2"/>
                                {{ contact.name || 'Nevyplněno' }}
                            </div>
                            <div class="mb-1" v-if="contact.phone && contact.phone.length > 0">
                                <i class="far fa-phone-alt text-primary fa-fw mr-2"/>{{ contact.phone }}
                            </div>
                            <div v-if="contact.email && contact.email.length > 0">
                                <i class="far fa-envelope-open text-primary fa-fw mr-2"/>{{ contact.email }}
                            </div>
                        </div>
                        <div
                            class="d-flex align-items-center justify-content-center table-danger p-3 text-center"
                            style="border-radius: 0.5rem"
                            v-else
                        >
                            <i class="fad fa-exclamation-triangle fa-fw text-dark mr-3"/>
                            Není vybrán kontakt!
                        </div>
                    </div>
                </BCol>

            </BRow>

            <BRow class="mb-4">

                <BCol cols="4">
                    <div class="bg-little-gray p-4 h-100">
                        <div class="font-weight-900 mb-2">Plátce:</div>
                        <div>
                            <BFormGroup class="mb-0">
                                <BFormRadio
                                    v-model="request.payer"
                                    name="payer"
                                    :value="1"
                                    :disabled="order.customer === null"
                                >
                                    Zákazník
                                </BFormRadio>
                                <BFormRadio
                                    v-model="request.payer"
                                    name="payer"
                                    :value="2"
                                    :disabled="order.propertyOwner === null"
                                >
                                    Vlastník nemovitosti
                                </BFormRadio>
                            </BFormGroup>
                        </div>
                    </div>
                </BCol>

                <BCol cols="4">
                    <div class="bg-little-gray p-4 h-100">
                        <div class="font-weight-900 mb-2">Zákazník:</div>
                        <div>{{ order.customerName }}</div>
                    </div>
                </BCol>

                <BCol cols="4">
                    <div class="bg-little-gray p-4 h-100" v-if="order.propertyOwner">
                        <div class="font-weight-900 mb-2">Vlastník:</div>
                        <div>{{ order.propertyOwnerName }}</div>
                    </div>
                </BCol>

            </BRow>


            <div class="mb-5" v-if="hasMetadata">
                <OrderContactPicker
                    :contacts="metadata.contacts"
                    :orderContact.sync="request.contact"
                    @update:order-contact="request.contact = $event"
                    :customer="order.customer || ''"
                    :property-owner="order.propertyOwner || ''"
                />
            </div>

            <div class="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <h5 class="font-weight-700 text-dark mb-0">
                        Položky zakázky
                    </h5>
                </div>
                <BLink class="text-muted small" @click="clearAppliances" v-if="request.appliances.length > 0">
                    Vyprázdnit
                </BLink>
                <OrderAppliancePicker
                    :available-appliances="metadata.appliances"
                    :appliances="request.appliances"
                    :order="order"
                    v-if="hasMetadata"
                />
            </div>

            <div
                v-for="(appliance, applianceIndex) in request.appliances"
                v-bind:key="'appliance_' + applianceIndex"
                class="border-bottom bg-little-gray p-4 mb-5"
            >
                <div class="mb-3">
                    <i class="fad fa-wrench text-primary fa-fw mr-2"/><strong class="font-weight-900">{{
                        appliance.name
                    }}</strong>, sériové číslo:
                    <strong class="font-weight-900" v-if="appliance.serialNumber">{{ appliance.serialNumber }}</strong>
                    <em class="text-danger" v-if="appliance.serialNumber === ''">neznámé</em>
                </div>

                <table class="table table-sm table-borderless mb-0" v-if="hasMetadata">
                    <thead>
                    <tr>
                        <th>
                            Servisní úkon
                        </th>
                        <th>
                            Technik
                        </th>
                        <th class="text-center">
                            Platba
                        </th>
                        <th>
                            Komentář
                        </th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="p-3 border-top" v-for="(repair, repairIndex) in appliance.repairs"
                        v-bind:key="'order_appliance_repair_' + repairIndex"
                    >
                        <td class="align-middle">
                            <BSelect
                                v-model="repair.repairType"
                                :options="metadata.repairTypes"
                                value-field="id"
                                text-field="name"
                            >
                                <template #first>
                                    <BFormSelectOption :value="null" disabled>
                                        -- Vybrat servisní úkon --
                                    </BFormSelectOption>
                                </template>
                            </BSelect>
                        </td>
                        <td class="align-middle">
                            <BSelect
                                v-model="repair.worker"
                                :options="metadata.workers"
                                value-field="id"
                                text-field="name"
                            >
                                <template #first>
                                    <BFormSelectOption :value="null" disabled>
                                        -- Vybrat jiného technika --
                                    </BFormSelectOption>
                                </template>
                            </BSelect>
                        </td>
                        <td class="align-middle text-center">
                            <BFormCheckbox v-model="repair.paid" :unchecked-value="false" :value="true" switch>
                                Placeno
                            </BFormCheckbox>
                        </td>
                        <td class="align-middle">
                            <BInput v-model="repair.comment"/>
                        </td>
                        <td class="text-right align-middle">
                            <BLink v-on:click.prevent="deleteRepair(appliance, repairIndex)">
                                <i class="far fa-times"/>
                            </BLink>
                        </td>
                    </tr>
                    <tr class="border-top" v-if="appliance.repairs.length === 0">
                        <td colspan="5">
                            V tomto spotřebiči dosud nebyla nastavena žádná položka.
                        </td>
                    </tr>
                    </tbody>
                    <tfoot class="border-top">
                    <tr>
                        <td colspan="5">
                            <BLink v-on:click.prevent="addNewRepair(appliance)">
                                Přidat další položku k tomuto spotřebiči
                            </BLink>
                        </td>
                    </tr>
                    </tfoot>
                </table>

            </div>

            <TableWithoutRecords v-if="request.appliances.length === 0" class="mb-4">
                V zakázce dosud není přidán žádný spotřebič.
            </TableWithoutRecords>

            <div class="d-flex justify-content-between align-items-center mb-3">
                <h5 class="font-weight-700 text-dark mb-0">
                    Přílohy zakázky
                </h5>
            </div>

            <OrderAttachments
                :attachments="request.attachments"
                v-if="hasMetadata"
                class="bg-little-gray p-4 mb-4"
                :assembly-sheet="request.assemblySheet"
                @update:assembly-sheet="request.assemblySheet = $event"
            />

            <div class="mb-4 bg-little-gray p-4">

                <div class="mb-4">
                    <div class="mb-2 font-weight-900">Přidělený technik:</div>
                    <div>
                        <BFormSelect
                            v-model="request.worker"
                            :options="hasMetadata ? metadata.workers : []"
                            text-field="name"
                            value-field="id"
                        >
                            <template #first>
                                <BFormSelectOption :value="null" disabled>
                                    -- Vybrat technika --
                                </BFormSelectOption>
                            </template>
                        </BFormSelect>
                    </div>
                </div>

                <div class="mb-4">
                    <BFormRow>
                        <BCol>
                            <div class="mb-2 font-weight-900">Termín realizace:</div>
                            <BFormInput type="datetime-local" v-model="request.scheduledStartTime" required/>
                        </BCol>
                        <BCol>
                            <div class="mb-2 font-weight-900">Odhadovaná doba zásahu:</div>
                            <BFormSelect
                                v-model="request.scheduledRepairTime"
                                :options="list.repairTimes"
                                text-field="name"
                                value-field="id"
                            >
                                <template #first>
                                    <BFormSelectOption :value="null" disabled>
                                        -- Délka servisního zásahu --
                                    </BFormSelectOption>
                                </template>
                            </BFormSelect>
                        </BCol>
                    </BFormRow>
                </div>

                <div class="mb-4">
                    <BFormRow>
                        <BCol>
                            <div class="mb-2 font-weight-900">Forma úhrady:</div>
                            <BFormGroup class="mb-0" v-slot="{ ariaDescribedby }">
                                <BFormRadioGroup
                                    id="radio-formOfPayment"
                                    v-model="request.formOfPayment"
                                    :aria-describedby="ariaDescribedby"
                                    name="formOfPayment"
                                >
                                    <BFormRadio v-model="request.formOfPayment" value="invoice">Faktura</BFormRadio>
                                    <BFormRadio v-model="request.formOfPayment" value="cash">Hotově</BFormRadio>
                                </BFormRadioGroup>
                            </BFormGroup>
                        </BCol>
                        <BCol>
                            <div class="mb-2 font-weight-900">Předběžná smluvní cena:</div>
                            <BInputGroup append="Kč">
                                <BFormInput
                                    v-model.number="request.estimatedAmount"
                                    :disabled="request.formOfPayment === 'invoice'"
                                    type="number"
                                    min="0"
                                    step="100"
                                    class="text-right"
                                    placeholder="Předběžná smluvní cena"
                                />
                            </BInputGroup>
                        </BCol>
                    </BFormRow>
                </div>

                <div class="mb-4">
                    <div class="mb-2 font-weight-900">Notifikace:</div>
                    <div>
                        <BFormRow>
                            <BCol>
                                <BCheckbox
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="request.muteNotifications"
                                >
                                    Neupozorňovat zákazníka
                                </BCheckbox>
                            </BCol>
                            <BCol>
                                <BCheckbox
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="request.sendSms"
                                    v-if="!request.muteNotifications"
                                >
                                    Poslat i SMS zprávu
                                </BCheckbox>
                            </BCol>
                        </BFormRow>
                    </div>
                </div>

                <div class="mb-4">
                    <div class="mb-2 font-weight-900">Ostatní:</div>
                    <BCheckbox :value="true" :unchecked-value="false" v-model="request.regenerateEvent">
                        Přegenerovat (jednorázově) událost v kalendáři
                    </BCheckbox>
                </div>

                <div class="mb-2 font-weight-900">Poznámka k realizaci:</div>
                <div>
                    <BFormTextarea
                        v-model="request.note"
                        rows="3"
                    />
                </div>

            </div>

            <div class="table-warning mb-4">
                <div class="d-flex p-3 align-items-center">
                    <div class="w-25 flex-shrink-0 font-weight-600">
                        <i class="fad fa-exclamation-triangle mr-3"/>Uzavření
                    </div>
                    <div class="w-75 flex-shrink-1">

                        <BFormCheckboxGroup
                            id="actions"
                            v-model="request.actions"
                        >
                            <BFormCheckbox value="close">Uzavřít zakázku</BFormCheckbox>
                            <BFormCheckbox v-if="order.children.length > 0" value="closeChildren">
                                Uzavřít i podřízené zakázky
                            </BFormCheckbox>
                        </BFormCheckboxGroup>
                        <small class="font-weight-300">
                            Při uzavření zakázky z konceptu nedojde k zápisu do Google kalendáře ani k následnému
                            odeslání notifikace.
                        </small>
                    </div>
                </div>
            </div>

            <div class="mb-4">
                <BButton variant="primary" block size="lg" class="font-weight-light py-4" v-on:click="processForm">
                    <i class="fad fa-save mr-3"/>Uložit zakázku
                </BButton>
            </div>

        </BOverlay>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Header from "@/Module/Common/UI/Header.vue"
import InfoLinkButton from "@/Module/Common/UI/Button/InfoLinkButton.vue"
import OrderAppliancePicker from "@/Module/Order/Component/Form/OrderAppliancePicker.vue"
import OrderApplianceRepairRequest from "@/NewModel/Order/Request/OrderApplianceRepairRequest"
import OrderAttachments from "@/Module/Order/Component/Form/OrderAttachments.vue"
import OrderContactPicker from "./Component/Form/OrderContactPicker.vue"
import OrderRequest from "@/NewModel/Order/Request/OrderRequest"
import Payer from "@/Model/Enum/payer"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        Header,
        InfoLinkButton,
        OrderAppliancePicker,
        OrderAttachments,
        OrderContactPicker,
        TableWithoutRecords,
    },

    computed: {
        ...mapGetters({
            order: 'order/getOrder',
            metadataAreLoading: 'order/metadata/isLoading',
            hasMetadata: 'order/metadata/hasData',
            metadata: 'order/metadata/getData',
        }),
        contact() {
            if (this.hasMetadata) {
                let contact = this.metadata.contacts.find(contact => contact.id === this.request.contact)
                return contact ? contact : null
            }
            return null
        },
    },

    data() {
        return {
            request: new OrderRequest(),
            startTime: new Date().toLocaleString(),
            list: {
                payerList: Payer.list(),
                repairTimes: [
                    {id: 30, name: "0,5 hod."},
                    {id: 60, name: "1,0 hod."},
                    {id: 75, name: "1,25 hod."},
                    {id: 90, name: "1,5 hod."},
                    {id: 105, name: "1,75 hod."},
                    {id: 120, name: "2,0 hod."},
                    {id: 150, name: "2,5 hod."},
                    {id: 180, name: "3,0 hod."},
                    {id: 210, name: "3,5 hod."},
                    {id: 240, name: "4,0 hod."},
                    {id: 270, name: "4,5 hod."},
                    {id: 300, name: "5,0 hod."},
                    {id: 330, name: "5,5 hod."},
                    {id: 360, name: "6,0 hod."},
                    {id: 390, name: "6,5 hod."},
                    {id: 420, name: "7,0 hod."},
                    {id: 450, name: "7,5 hod."},
                    {id: 480, name: "8,0 hod."},
                    {id: 720, name: "12,0 hod."},
                    {id: 1440, name: "1 den"},
                    {id: 2880, name: "2 dny"},
                    {id: 4320, name: "3 dny"},
                    {id: 7200, name: "5 dnů"},
                ],
            },
        }
    },

    mounted() {
        this.request = OrderRequest.from(this.order)
        this.$store.dispatch('order/metadata/getMetadata', this.order.id)
            .then((response) => {
                if(this.request.contact === null && response.contacts.length === 1) {
                    this.request.contact = response.contacts[0].id
                }
            })
    },

    methods: {

        clearAppliances() {
            this.request.appliances = []
        },

        addNewRepair(appliance) {

            let repair = new OrderApplianceRepairRequest()

            if (this.request.worker) {
                repair.worker = this.request.worker
            } else if (appliance.repairs[appliance.repairs.length - 1] !== undefined) {
                repair.worker = appliance.repairs[appliance.repairs.length - 1].worker
            }
            appliance.repairs.push(repair)
        },

        deleteRepair(appliance, index) {
            appliance.repairs.splice(index, 1)
        },

        validateForm() {
            for (const appliance of this.request.appliances) {
                for (const repair of appliance.repairs) {
                    if (repair.repairType === null) {
                        const text = `U spotřebiče ${appliance.name} není vyplněn servisní úkon. Doplňte jej nebo tento úkon odstraňte.`
                        throw new Error(text)
                    }
                }
            }

            if (this.endTime <= this.startTime) {
                throw new Error('Termín realizace je neplatný.')
            }

            if (this.request.contact === null) {
                throw new Error('Není vyplněna kontaktní osoba.')
            }

            if (this.request.worker === null) {
                throw new Error('Není vyplněn zodpovědný technik.')
            }
        },

        processForm() {

            try {
                this.validateForm.call(this)
            } catch (err) {
                this.warningMessage(err.message)
                return false
            }

            let self = this

            this.$store.dispatch("order/updateOrder", {
                id: this.order.id,
                request: this.request
            })
                .then(() => {
                    if (self.request.actions.includes('close')) {
                        this.$router.push({name: 'order_successfully_finished'})
                    } else {
                        this.$router.push({name: 'order_successfully_updated'})
                    }

                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })

        },

    },

}
</script>
