<template>
    <BModal
        id="modal-add-technical-note-appliance"
        header-bg-variant="primary"
        header-text-variant="white"
        footer-bg-variant="light"
        footer-text-variant="dark"
        size="xl"
        body-class="p-4"
        no-stacking
    >
        <template #modal-header>
            <div class="p-3">
                <h4 class="display-4 mb-0">
                    <i class="fa-light fa-flux-capacitor fa-fw mr-3"/> Přidat technickou poznámku ke spotřebiči
                </h4>
            </div>
        </template>
        <BOverlay :show="isCreating">
            <BForm ref="addTechnicalNoteApplianceForm" @submit.prevent="handleSubmit">

                <BFormGroup
                    id="input-group-appliance"
                    label-for="input-appliance"
                    label-class="font-weight-700"
                >
                    <template v-slot:label>
                        Spotřebič: <span class="text-danger ml-1">*</span>
                    </template>
                    <BFormRadioGroup
                        v-model="appliance"
                        id="input-appliance"
                        class="bg-white p-2 border-radius-sm"
                        stacked
                        value-field="id"
                    >
                        <BFormRadio
                            name="appliance"
                            :value="record.id"
                            v-for="(record, recordIndex) in appliances"
                            :key="record.id"
                            v-bind:class="(appliances.length - 1) !== recordIndex ? 'mb-3': ''"
                        >
                            <div class="font-weight-600">
                                <span class="text-danger">#{{ String(record.id).slice(-5) }}</span> {{ record.name }}
                            </div>
                            <div v-if="record.serialNumber">Sériové číslo: {{ record.serialNumber }}</div>
                        </BFormRadio>
                    </BFormRadioGroup>
                </BFormGroup>

                <BFormGroup
                    id="input-group-message"
                    label-for="input-message"
                    label-class="font-weight-600"
                >
                    <template v-slot:label>
                        Obsah zprávy: <span class="text-danger ml-1">*</span>
                    </template>
                    <BFormTextarea
                        id="input-message"
                        v-model="message"
                        rows="5"
                        placeholder="Prosím, buďte struční a výstižní."
                        required
                    />
                </BFormGroup>
            </BForm>
        </BOverlay>
        <template #modal-footer>
            <BButton type="submit" variant="secondary" :disabled="isCreating" @click="handleSubmit">
                <i class="fa-light fa-floppy-disk fa-fw mr-3"/>Uložit poznámku
            </BButton>
        </template>
    </BModal>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    props: {
        supplyPoint: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isCreating: "worker/supplyPoint/note/isCreating",
            appliances_isLoading: "supplyPoint/appliances/isLoading",
            appliances: "supplyPoint/appliances/getAppliances",
        }),
    },

    data() {
        return {
            appliance: null,
            message: "",
        }
    },

    mounted() {
        this.loadAppliances()
    },

    methods: {

        loadAppliances() {
            this.$store.dispatch("supplyPoint/appliances/getAppliances",
                this.supplyPoint
            )
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        checkFormValidity() {
            return this.$refs.addTechnicalNoteApplianceForm.checkValidity()
        },

        handleSubmit() {
            let self = this
            this.$store.dispatch("worker/appliance/note/addTechnicalNote", {
                id: this.appliance,
                formData: {
                    message: this.message,
                },
            })
                .then((record) => {
                    self.$emit("created", record)
                    this.successMessage("Poznámka uložena")
                    this.$root.$emit("bv::hide::modal", "modal-add-technical-note-appliance")
                })
                .catch((error) => {
                        this.errorMessage(error.response.data.message)
                    }
                )
        },

    },

}
</script>
