<template>
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top border-bottom">

        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"/>
        </button>

        <ul class="navbar-nav ml-auto">

            <div class="topbar-divider d-none d-sm-block"/>

            <li class="nav-item dropdown no-arrow">
                <BDropdown
                    id="dropdown-1"
                    variant="link"
                    toggle-tag="a"
                    toggle-class="nav-link dropdown-toggle no-underline"
                    menu-class="shadow"
                >
                    <template #button-content>
                        <div>
                            <div class="text-right">
                                <span class="d-none d-lg-inline font-weight-600 text-dark">
                                    {{ currentUser.fullName }}
                                </span>
                            </div>
                            <div class="text-right" v-if="currentUser.jobName">
                                <small class="text-dark">{{ currentUser.jobName }}</small>
                            </div>
                        </div>
                        <Avatar
                            :src="currentUser.picture || null"
                            :name="currentUser.fullName.split(' ').map(w => w[0]).join('')"
                            class="mx-3"
                        />
                    </template>
                    <BDdItem :to="{name: 'user_profile'}">
                        <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"/>Profil
                    </BDdItem>
                    <BDdDivider/>
                    <BDdItem v-on:click="logOut()">
                        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"/>
                        Odhlásit
                    </BDdItem>
                </BDropdown>
            </li>

        </ul>

    </nav>
</template>

<script>
import Avatar from "../../Common/UI/Avatar.vue"

export default {

    components: {
        Avatar,
    },

    data() {
        return {
            sidebarVisible: false,
            development: process.env.NODE_ENV !== 'production',
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user
        },
    },

    methods: {

        logOut() {
            this.$store.dispatch('auth/logout')
                .finally(() => {
                    this.$router.push('/in')
                })
        },

    },

}
</script>
