export default class AuthRequest {

    constructor() {
        this.username = ""
        this.password = ""
        this.rememberMe = false
    }

    toForm() {
        return this
    }

}
