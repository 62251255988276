<template>
    <div class="container">
        <LoadingBlock v-if="!hasSupplyPoint && isLoading"/>
        <RouterView v-if="hasSupplyPoint"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import LoadingBlock from "../Common/UI/LoadingBlock.vue"

export default {

    components: {
        LoadingBlock,
    },

    computed: {
        ...mapGetters({
            isLoading: 'supplyPoint/isLoading',
            hasSupplyPoint: 'supplyPoint/hasSupplyPoint',
            supplyPoint: 'supplyPoint/getSupplyPoint',
        }),
    },

    mounted() {
        this.$store.dispatch("supplyPoint/clear")
        this.loadSupplyPoint(this.$route.params.id)
    },

    methods: {

        loadSupplyPoint(id) {
            this.$store.dispatch("supplyPoint/getSupplyPoint", id)
        },

    },

}
</script>
