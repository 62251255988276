<template>
    <div>

        <div class="mb-4">
            <div class="d-flex justify-content-between text-align-center">
                <BButtonGroup>
                    <BButton variant="primary" @click="filterActive = !filterActive">
                        <i class="fad fa-filter mr-2"/> <span class="font-weight-500">Filtr</span>
                    </BButton>
                    <BButton
                        variant="danger"
                        @click="resetFilters"
                        v-if="isFilterUsed"
                    >
                        <i class="fad fa-times"/>
                    </BButton>
                </BButtonGroup>
            </div>

            <BCollapse :visible="filterActive || isFilterUsed" class="bg-little-gray mt-4 p-4">
                <BRow>
                    <BCol>
                        <BFormGroup
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Příjmení a jméno:"
                            label-align-sm="right"
                            label-class="font-weight-700 m"
                            label-for="filter-fullName"
                            class="mb-0"
                        >
                            <BFormInput
                                id="filter-fullName"
                                v-model="filterRequest.filter.fullName"
                                size="sm" @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                    <BCol>
                        <BFormGroup
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="E-mailová adresa:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-email"
                            class="mb-0"
                        >
                            <BFormInput
                                id="filter-email"
                                v-model="filterRequest.filter.email"
                                size="sm"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                </BRow>
            </BCollapse>

        </div>

        <BTable
            ref="table"
            :fields="fields"
            :busy="isLoading"
            :current-page.sync="filterRequest.currentPage"
            :per-page.sync="filterRequest.perPage"
            :sort-by.sync="filterRequest.sortBy"
            :sort-desc.sync="filterRequest.sortDesc"
            :items="myProvider"
            tbody-tr-class="border-bottom"
            thead-tr-class="border-bottom"
            hover
            sort-icon-left
            borderless
            class="mb-0"
            @context-changed="gridUpdated"
            show-empty
        >
            <template v-slot:table-busy>
                <TableLoading/>
            </template>
            <template v-slot:empty>
                <TableWithoutRecords/>
            </template>
            <template v-slot:cell(action)="data">
                <RouterLink
                    :to="{name: 'setup_user_edit', params: {id: data.item.id}}"
                    class="btn btn-primary btn-sm"
                >
                    <i class="fad fa-pencil"/>
                </RouterLink>
            </template>
            <template v-slot:cell(active)="data">
                <span v-bind:class="data.item.active === true ? 'text-success' : 'text-danger'">
                    {{ data.item.active ? "Aktivní" : "Neaktivní" }}
                </span>
            </template>
        </BTable>
        <div class="mt-3">
            <strong>Celkem záznamů:</strong> {{ totalRecords }}
        </div>

        <TablePaginator :total-records="totalRecords" :filter-request="filterRequest" hide-go-to-end-buttons/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import SimpleFormRequest from "../../../../NewModel/Common/SimpleFormRequest"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TablePaginator from "@/Module/Common/UI/Table/TablePaginator.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TablePaginator,
        TableWithoutRecords,
    },

    data() {
        return {
            filterRequest: this.buildFilterRequest(),
            filterActive: false,
            fields: [
                {
                    key: "fullName",
                    label: "Příjmení a jméno",
                    sortable: true,
                    thClass: "font-weight-700",
                    class: "align-middle",
                },
                {
                    key: "email",
                    label: "E-mailová adresa",
                    sortable: true,
                    thClass: "font-weight-700",
                    class: "align-middle",
                },
                {
                    key: "active",
                    label: "Stav",
                    sortable: true,
                    thClass: "font-weight-700",
                    class: "align-middle w-10",
                },
                {
                    key: "action",
                    label: "Akce",
                    thClass: "font-weight-700",
                    class: "text-right align-middle w-5",
                },
            ],
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isLoading: "user/isLoading",
            hasRecords: "user/hasUsers",
            records: "user/getUsers",
            totalRecords: "user/getTotalUsers",
        }),
        gridName: () => '_userGridFilter',
        isFilterUsed() {
            return this.filterRequest.filter.fullName !== ''
                || this.filterRequest.filter.email !== ''
        },
    },
    created() {
        if (this.$cookies.isKey(this.gridName) && this.user.filterSave) {
            this.filterRequest = SimpleFormRequest.restore(this.$cookies.get(this.gridName))
        }
    },

    mounted() {
        this.filterUpdated()
    },

    methods: {

        buildFilterRequest() {
            return new SimpleFormRequest({
                fullName: "",
                email: ""
            }, "fullName", 10, false)
        },

        gridUpdated(ctx) {
            if (ctx) {
                this.filterRequest.sortBy = ctx.sortBy
                this.filterRequest.sortDesc = ctx.sortDesc
                this.saveFilter()
            }
        },

        saveFilter() {
            if (this.user.filterSave) {
                this.$cookies.set(this.gridName, this.filterRequest.store())
            }
        },

        filterUpdated(resetPage = false) {
            if (resetPage) {
                this.filterRequest.currentPage = 1
            }
            this.saveFilter()
            this.$refs.table.refresh()
        },

        myProvider(ctx, callback) {
            let items = []
            this.$store.dispatch("user/clearAll")
            this.$store.dispatch("user/getFilteredRecords", this.filterRequest)
                .then((response) => {
                    callback(response)
                })
                .catch(() => {
                    callback([])
                })
            return items || []
        },

        resetFilters() {
            this.filterActive = false
            this.filterRequest = this.buildFilterRequest()
            this.filterUpdated()
        },

    },

}
</script>
