<template>
    <BAvatar
        variant="primary"
        class="font-weight-500"
        :src="(src && src.length > 0) ? src : null"
        :text="name ? name.split(' ').map(w => w[0]).join('') : '😎'"
    />
</template>


<script>
export default {

    props: {
        src: {
            type: String,
            required: false,
            default: null,
        },
        name: {
            type: String,
            required: false,
            default: "",
        },
    },

}
</script>
