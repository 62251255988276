import SupplyPointService from "../SupplyPointService"
import SupplyPointMetaEntity from "../Entity/SupplyPointMetaEntity"

const
    FETCHING_DATA = "FETCHING_DATA",
    FETCHING_DATA_SUCCESS = "FETCHING_DATA_SUCCESS",
    FETCHING_DATA_ERROR = "FETCHING_DATA_ERROR",
    CLEAR_DATA = "CLEAR_DATA"

const initialState = {
    isLoading: false,
    data: null,
    error: null
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<any>}
     */
    async getMeta({commit}, id) {
        try {
            commit(FETCHING_DATA)
            let response = await SupplyPointService.getSupplyPointMeta(id)
            commit(FETCHING_DATA_SUCCESS, response.data)
            return state.data
        } catch (error) {
            commit(FETCHING_DATA_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clear({commit}) {
        commit(CLEAR_DATA)
    }

}

export const mutations = {

    [FETCHING_DATA](state) {
        state.isLoading = true
        state.error = null
        state.data = null
    },
    [FETCHING_DATA_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.data = SupplyPointMetaEntity.from(data)
    },
    [FETCHING_DATA_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.data = null
    },

    [CLEAR_DATA](state) {
        state.isLoading = false
        state.error = null
        state.data = null
    },

}

export const getters = {

    /** @returns {boolean} */
    isLoading(state) {
        return state.isLoading
    },

    /** @returns {boolean} */
    hasError(state) {
        return state.error !== null
    },

    /** @returns {Error|null} */
    error(state) {
        return state.error
    },

    /** @returns {boolean} */
    hasData(state) {
        return state.data !== null
    },

    /** @returns {SupplyPointMetaEntity} */
    getData(state) {
        return state.data
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
