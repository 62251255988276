<template>
    <ul
        class="navbar-nav sidebar sidebar-dark bg-primary accordion"
        id="accordionSidebar"
        v-bind:class="{'toggled' : sidebarVisible}"
    >

        <BLink
            class="sidebar-brand d-flex align-items-center justify-content-center"
            :to="{name : 'dashboard'}"
        >
            <img src="../../../Assets/logo_bw.svg" class="w-60">
        </BLink>

        <hr class="sidebar-divider my-0">

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer" :to="{name: 'dashboard'}"
                active-class="bg-success text-white"
                exact-active-class="bg-success text-white"
            >
                <i class="fad fa-tachometer-alt fa-fw mr-2"/>
                <span>Dashboard</span>
            </BLink>
        </li>

        <hr class="sidebar-divider">

        <div class="sidebar-heading mb-2">
            Vaše možnosti
        </div>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer"
                :to="{name: 'supply_point_list'}"
                active-class="bg-success text-white"
                exact-active-class="bg-success text-white"
            >
                <i class="fad fa-house-damage fa-fw mr-2"/>
                <span>Odběrná místa</span>
            </BLink>
        </li>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer"
                :to="{name: 'appliance_list'}"
                active-class="bg-success text-white"
                exact-active-class="bg-success text-white"
            >
                <i class="fad fa-satellite fa-fw mr-2"/>
                <span>Spotřebiče</span>
            </BLink>
        </li>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer"
                :to="{name: 'order_list'}"
                active-class="bg-success text-white"
                exact-active-class="bg-success text-white"
            >
                <i class="fad fa-car-mechanic fa-fw mr-2"/>
                <span>Zakázky</span>
            </BLink>
        </li>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer"
                :to="{name: 'planning'}"
                active-class="bg-success text-white"
                exact-active-class="bg-success text-white"
            >
                <i class="fad fa-calendar-alt fa-fw mr-2"/>
                <span>Plánování</span>
            </BLink>
        </li>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer"
                :to="{name: 'subject_list'}"
                active-class="bg-success text-white"
                exact-active-class="bg-success text-white"
            >
                <i class="fad fa-users-crown fa-fw mr-2"/>
                <span>Subjekty</span>
            </BLink>
        </li>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link pointer"
                :to="{name: 'discounts_list'}"
                active-class="bg-success text-white"
                exact-active-class="bg-success text-white"
            >
                <i class="fad fa-piggy-bank fa-fw mr-2"/>
                <span>Slevy</span>
            </BLink>
        </li>

        <li class="nav-item">
            <BLink
                router-tag="div"
                class="nav-link collapsed pointer"
                v-b-toggle.collapse-settings
                aria-expanded="true"
            >
                <i class="fad fa-fw fa-cogs mr-2"/>
                <span>Nastavení</span>
            </BLink>
            <BCollapse tag="div" id="collapse-settings">
                <div class="bg-white py-2 collapse-inner rounded">
                    <BLink class="collapse-item" :to="{name: 'setup_user_list'}">
                        <i class="fad fa-users-cog mr-2"/>
                        <span>Uživatelé</span>
                    </BLink>
                    <BLink class="collapse-item" :to="{name: 'setup_appliance_type_list'}">
                        <i class="fad fa-satellite mr-2"/>
                        <span>Druhy spotřebičů</span>
                    </BLink>
                    <BLink class="collapse-item" :to="{name: 'setup_repair_type_list'}">
                        <i class="fad fa-tools mr-2"/>
                        <span>Typy oprav</span>
                    </BLink>
                    <BLink class="collapse-item" :to="{name: 'setup_boiler_manufacturer_list'}">
                        <i class="fad fa-industry mr-2"/>
                        <span>Výrobci spotřebičů</span>
                    </BLink>
                    <BLink class="collapse-item" :to="{name: 'setup_boiler_model_list'}">
                        <i class="fad fa-space-shuttle mr-2"/>
                        <span>Modely spotřebičů</span>
                    </BLink>
                </div>
            </BCollapse>
        </li>

        <hr class="sidebar-divider d-none d-md-block">

        <div class="text-center d-none d-md-inline my-4">
            <button
                class="rounded-circle border-0"
                id="sidebarToggle"
                v-on:click.prevent="sidebarVisible = !sidebarVisible"
            />
        </div>

        <div class="sidebar-card mb-4" v-if="development">
            <div class="mb-2">
                <i class="fad fa-construction text-warning fa-3x"/>
            </div>
            <p class="text-center mb-2">
                Nacházíte se ve<br/><strong>vývojovém prostředí</strong><br/>aplikace Servis!
            </p>
            <a class="btn btn-success btn-sm" href="https://servis.lemigas.cz">
                Přejít na <br/>produkční verzi
            </a>
        </div>

        <div class="text-center px-3">
            <BLink
                :to="{name: 'new_mobile_home'}"
                class="text-white"
            >
                <i class="fad fa-external-link-alt fa-fw mr-2"/><br/>Mobilní verze technika
            </BLink>
        </div>

    </ul>
</template>

<script>
export default {

    data() {
        return {
            sidebarVisible: false,
            development: (process.env.NODE_ENV !== 'production'),
        }
    },

    methods: {

        logOut() {
            this.$store.dispatch('auth/logout')
            this.$router.push('/in')
        },

    },

}
</script>
