import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const OrderService = {

    /**
     * @param request : SimpleFormRequest
     */
    filterOrders(request) {
        return httpClient.post("v3/orders/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    getOrder(id) {
        return httpClient.get("v3/orders/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    getMobileOrder(id) {
        return httpClient.get("v3/orders/" + id + "/mobile", {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    restoreOrderStatus(id) {
        return httpClient.post("v3/orders/" + id + "/status_restoring", {}, {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    createChildOrder(id) {
        return httpClient.post("v3/orders/" + id + "/child", {}, {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    interruptOrder(id) {
        return httpClient.post("v3/orders/" + id + "/interrupt", {}, {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    refreshCalendarEvent(id) {
        return httpClient.post("v3/orders/" + id + "/refresh_calendar_event", {}, {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param request : OrderChangeStatusRequest
     */
    changeOrderStatus(id, request) {
        return httpClient.put("v3/orders/" + id + "/change_status", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param request : OrderCancelRequest
     */
    cancelOrder(id, request) {
        return httpClient.post("v3/orders/" + id + "/cancel", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param request : OrderRequest
     */
    updateOrder(id, request) {
        return httpClient.put("v3/orders/" + id + "/form", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @returns {Promise<any>}
     */
    getOrderMaterials(id) {
        return httpClient.get("v3/orders/" + id + "/materials", {headers: authHeader()})
    },

    /**
     * @param id : string
     * @param request : OrderMaterialPickupRequest
     * @returns {Promise<any>}
     */
    createOrderMaterials(id, request) {
        return httpClient.post("v3/orders/" + id + "/materials", request.toForm(), {headers: authHeader()})
    },

    /**
     * @param order : string
     * @param orderMaterial : string
     * @returns {Promise<any>}
     */
    cancelOrderMaterial(order, orderMaterial) {
        return httpClient.delete("v3/orders/" + order + "/materials/" + orderMaterial, {headers: authHeader()})
    },

    /**
     * @param date : string
     * @returns {Promise<any>}
     */
    findOrdersByDate(date) {
        return httpClient.get("v3/orders/date/" + date, {
            headers: authHeader()
        })
    },

    /**
     * @param date : string
     * @returns {Promise<any>}
     */
    findWorkerOrdersByDate(date) {
        return httpClient.get("v3/orders/worker/date/" + date, {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param attachments : Array
     * @returns {Promise<any>}
     */
    createAttachments(id, attachments) {
        return httpClient.post("v3/orders/" + id + "/attachments", attachments, {headers: authHeader()})
    },

    /**
     * @param id : string
     * @param attachment : string
     * @returns {Promise<any>}
     */
    assigningJobSheet(id, attachment) {
        return httpClient.post("v3/orders/" + id + "/attachments/job_sheet", attachment, {headers: authHeader()})
    },

    /**
     * @param id : string
     * @param attachment : string
     * @returns {Promise<any>}
     */
    deleteAttachment(id, attachment) {
        return httpClient.delete("v3/orders/" + id + "/attachments/" + attachment, {headers: authHeader()})
    },

    /**
     * @param id : string
     * @param request : OrderAttachmentsRequest
     * @returns {Promise<any>}
     */
    updateAttachments(id, request) {
        return httpClient.put("v3/orders/" + id + "/attachments/", request.toForm(), {headers: authHeader()})
    },

    /**
     * @param id : string
     * @returns {Promise<any>}
     */
    getMetadata(id) {
        return httpClient.get("v3/orders/" + id + "/metadata", {headers: authHeader()})
    },

}

export default OrderService
