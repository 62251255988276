export default class SubjectOrderRepairEntity {

    constructor() {
        this.id = ''
        this.repairType = ''
        this.applianceName = ''
        this.applianceSerialNumber = ''
        this.worker = ''
    }

    /**
     * @param response : object
     * @returns SubjectOrderRepairEntity
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.repairType = response.repairType || ''
        obj.applianceName = response.applianceName || ''
        obj.applianceSerialNumber = response.applianceSerialNumber || ''
        obj.worker = response.worker || ''
        return obj
    }

}
