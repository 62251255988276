export default class AttachmentEntity {

    constructor() {
        this.id = ''
        this.name = ''
        this.size = 0
        this.active = true
        this.createdBy = {
            id: null,
            name: '',
        }
        this.createdAt = ''
    }

    /**
     * @param response : object
     * @returns AttachmentEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.size = response.size
        obj.active = response.active
        obj.createdBy.id = response.createdBy.id
        obj.createdBy.name = response.createdBy.name
        obj.createdAt = response.createdAt
        return obj
    }

}
