export default class SubjectConnectionContractEntity {

    constructor() {
        this.id = null
        this.contractNumber = ""
        this.supplyPoint = {
            id: null, ean: "", address: ""
        }
        this.validFromDate = null
        this.validToDate = null
        this.commodity = 1
        this.benefits = []
    }

    /**
     * @param response : object
     * @returns {SubjectConnectionContractEntity}
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.contractNumber = response.contractNumber
        obj.supplyPoint.id = response.supplyPoint.id
        obj.supplyPoint.ean = response.supplyPoint.ean
        obj.supplyPoint.address = response.supplyPoint.address
        obj.validFromDate = response.validFromDate
        obj.validToDate = response.validToDate
        obj.commodity = response.commodity
        obj.benefits = response.benefits
        return obj
    }

}
