<template>
    <div>
        <div class="bg-white border-radius-sm mb-4" style="height: 400px; border: 3px solid #9faec4;">
            <VueSignaturePad ref="signaturePad"/>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <BButton variant="light" @click="undo">
                <i class="fad fa-undo-alt mr-2"/>Zpět
            </BButton>
            <BButton variant="primary" @click="save">
                <i class="fad fa-signature-lock mr-2"/>Podepsáno
            </BButton>
        </div>
    </div>
</template>

<script>
export default {

    methods: {

        undo() {
            this.$refs.signaturePad.undoSignature()
        },

        save() {
            let signature
            let {isEmpty, data} = this.$refs.signaturePad.saveSignature()

            if (isEmpty) {
                this.warningMessage("Dokument není podepsán, prosím podepište se.")
                return
            } else {
                signature = data.replace(/^data:(.*,)?/, "")
            }

            this.$refs.signaturePad.lockSignaturePad()
            this.$emit("signed", signature)
        },

    },

}
</script>
