<template>
    <div>

        <Header icon="fad fa-car-mechanic" title="Zakázky"/>

        <OrdersGrid class="mb-4"/>

    </div>
</template>

<script>
import Header from "../../Module/Common/UI/Header.vue"
import OrdersGrid from "./Component/OrdersGrid"

export default {

    components: {
        Header,
        OrdersGrid,
    },

}
</script>
