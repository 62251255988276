<template>
    <div class="text-center text-dark m-0 p-4 display-4 d-flex justify-content-center align-items-center">
        <slot name="default">
            <i :class="icon" v-if="icon" class="fa-fw fa-2x fa-spin text-primary mr-4"/>
            <strong>{{ message }}</strong>
        </slot>
    </div>
</template>

<script>
export default {

    props: {
        icon: {
            type: String,
            required: false,
            default: 'fa-solid fa-loader fa-xl fa-spin text-primary',
        },
        message: {
            type: String,
            required: false,
            default: "Nahrávám ...",
        },
    },

}
</script>
