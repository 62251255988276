import IssueService from "./IssueService"
import Issue from "./Entity/Issue"
import IssueMyStore from "./Store/IssueMyStore"

const
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    UPDATING_RECORD = "UPDATING_RECORD",
    UPDATING_RECORD_SUCCESS = "UPDATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "UPDATING_RECORD_ERROR",
    DELETING_RECORD = "DELETING_RECORD",
    DELETING_RECORD_SUCCESS = "DELETING_RECORD_SUCCESS",
    DELETING_RECORD_ERROR = "DELETING_RECORD_ERROR",
    CLEAR_RECORDS = "CLEAR_RECORDS",
    CLEAR_RECORD = "CLEAR_RECORD"

const initialState = {
    isLoading: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
    record: null,
    records: [],
    totalRecords: 0,
    error: null
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @returns {Promise<[]>}
     */
    async findAllActiveIssues({commit}) {
        try {
            commit(FETCHING_RECORDS)
            let response = await IssueService.findAllActiveIssues()
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @returns {Promise<*>}
     */
    async getIssue({commit}, id) {

        try {
            commit(FETCHING_RECORD)
            let response = await IssueService.getIssue(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param request : IssueRequest
     * @returns {Promise<*>}
     */
    async createIssue({commit}, request) {
        try {
            commit(CREATING_RECORD)
            let response = await IssueService.createIssue(request)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @param request : IssueRequest
     * @returns {Promise<*>}
     */
    async updateIssue({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await IssueService.updateIssue(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @returns {Promise<*>}
     */
    async closeIssue({commit}, id) {
        try {
            commit(UPDATING_RECORD)
            let response = await IssueService.closeIssue(id)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @returns {Promise<*>}
     */
    async deleteIssue({commit}, id) {
        try {
            commit(DELETING_RECORD)
            let response = await IssueService.deleteIssue(id)
            commit(DELETING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(DELETING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

    async clear({commit}) {
        commit(CLEAR_RECORD)
    },

}

export const mutations = {

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.records = data.map((item) => {
            return Issue.buildFromResponse(item)
        })
    },
    [FETCHING_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
    },

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = Issue.buildFromResponse(data)
    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
    },

    [CREATING_RECORD](state) {
        state.isCreating = true
        state.error = null
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isCreating = false
        state.error = null
        state.record = Issue.buildFromResponse(data)
    },
    [CREATING_RECORD_ERROR](state, error) {
        state.isCreating = false
        state.error = error
    },

    [UPDATING_RECORD](state) {
        state.isUpdating = true
        state.error = null
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isUpdating = false
        state.error = null
        state.record = Issue.buildFromResponse(data)
    },
    [UPDATING_RECORD_ERROR](state, error) {
        state.isUpdating = false
        state.error = error
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.error = null
        state.record = null
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.error = null
        state.records = []
        state.totalRecords = 0
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    isUpdating(state) {
        return state.isUpdating
    },
    isCreating(state) {
        return state.isCreating
    },
    isDeleting(state) {
        return state.isDeleting
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasIssues(state) {
        return state.records.length > 0
    },
    getIssues(state) {
        return state.records
    },
    hasIssue(state) {
        return state.record !== null
    },
    getIssue(state) {
        return state.record
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        my: IssueMyStore,
    }
}
