<template>
    <div class="d-flex justify-content-between align-items-center mb-4">
        <h3 class="font-weight-700 text-dark mb-0">
            <slot name="title">
                <i class="text-primary fa-fw mr-3" :class="icon" v-if="icon"/>{{ title }}
            </slot>
        </h3>
        <div class="buttons">
            <slot name="buttons"/>
        </div>
    </div>
</template>


<script>
export default {

    props: {
        icon: {
            type: String,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: false,
            default: "",
        },
    },

}
</script>
