<template>
    <div class="container mb-5">

        <Header>
            <template #title>
                <i class="fad fa-car-mechanic text-primary fa-fw mr-3"/>
                <span class="text-info" v-if="order.status === 0">
                  Koncept zakázky
                </span>
                <span class="text-danger" v-else-if="order.status === 7">
                  Zrušená zakázka
                </span>
                <span v-else>
                  Zakázka
                </span>
                {{ order.orderNumber ? "#" + order.orderNumber : "" }}
            </template>
            <template #buttons>

                <CreateChildOrderButton
                    v-if="orderStatus.getItem(order.status).enableCreateChild && order.parent === null"/>

                <PrimaryLinkButton
                    :to="{ name: 'order_show', params: { id: order.parent }}"
                    v-if="order.parent"
                    icon="fad fa-sitemap"
                    text="Nadřazená zakázka"
                />
                <BDropdown
                    id="dropdown-right"
                    right variant="primary"
                    html="<i class='fad fa-cogs mr-2'></i> Akce"
                    class="ml-4"
                >
                    <RefreshCalendarOrderDropdownItem v-if="orderStatus.getItem(order.status).refreshCalendar"/>
                    <BDropdownDivider v-if="orderStatus.getItem(order.status).refreshCalendar"/>
                    <CancelOrderDropdownItem v-if="orderStatus.getItem(order.status).enableCancel"/>
                    <InterruptOrderDropdownItem v-if="orderStatus.getItem(order.status).enableInterruption"/>
                    <RestoreOrderDropdownItem v-if="orderStatus.getItem(order.status).enableRestoring"/>
                </BDropdown>
                <InfoLinkButton
                    :to="{ name: 'order_list' }"
                    class="ml-4"
                    icon="fad fa-chevron-left"
                    text="Zpět"
                />
                <DangerLinkButton
                    :to="{ name: 'order_form', params: { id: order.id }}"
                    v-if="orderStatus.getItem(order.status).enableForm"
                    icon="fad fa-pencil"
                    text="Upravit"
                    class="ml-4"
                />
                <SuccessLinkButton
                    :to="{ name: 'order_form', params: { id: order.id }, query: {finish: '1'}}"
                    v-if="orderStatus.getItem(order.status).enableFinish"
                    icon="fad fa-flag-checkered"
                    text="Uzavřít"
                    class="ml-4"
                />
            </template>
        </Header>

        <div class="bg-little-gray p-4 mb-5">
            <OrderInfoFragment :order="order"/>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-0 pb-3">
            <h4 class="font-weight-700 text-dark mb-0">
                Položky
            </h4>
        </div>
        <div class="mb-5">
            <table class="table table-fit table-borderless mb-0">
                <thead>
                <tr>
                    <th class="text-gray-700 align-middle w-20">
                        Typ opravy
                    </th>
                    <th class="text-gray-700 align-middle w-40">
                        Spotřebič
                    </th>
                    <th class="text-gray-700 align-middle text-center w-6">
                        Zdarma
                    </th>
                    <th class="text-gray-700 align-middle text-center w-6">
                        Sleva
                    </th>
                    <th class="text-gray-700 align-middle">
                        Poznámka
                    </th>
                </tr>
                </thead>
                <tbody
                    v-for="appliance in order.appliances"
                    v-bind:key="'appliance_' + appliance.id"
                    class="border-bottom"
                >
                <tr v-for="repair in appliance.repairs" v-bind:key="'repair_' + repair.id" class="border-top">
                    <td class="align-middle font-weight-600">
                        {{ repair.repairTypeName }}
                    </td>
                    <td class="align-middle font-weight-600">
                        <div>
                            {{ appliance.name }}
                        </div>
                        <div class="font-weight-400">
                            #{{ String(appliance.appliance).slice(-5) }}
                            <span v-if="appliance.serialNumber">/ Výr. č. {{ appliance.serialNumber }}</span>
                            <span v-if="appliance.detailPlaceOfInstallation">
                                <br />Umístění: {{ appliance.detailPlaceOfInstallation }}
                            </span>
                        </div>
                    </td>
                    <td class="text-center align-middle">
                        {{ repair.paid ? 'Ne' : 'Ano' }}
                    </td>
                    <td class="text-center align-middle">
                        {{ repair.discount ? (repair.discount + '%') : 'Ne' }}
                    </td>
                    <td class="text-left align-middle">
                        {{ repair.comment }}
                    </td>
                </tr>
                </tbody>
                <tbody v-if="order.appliances.length === 0">
                <tr class="border-top">
                    <td colspan="6">
                        <TableWithoutRecords>
                            V zakázce dosud nejsou uvedeny žádné servisní úkony.
                        </TableWithoutRecords>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-0 pb-3">
            <h4 class="font-weight-700 text-dark mb-0">
                Dokumenty
            </h4>
        </div>
        <div class="mb-5">
            <table class="table table-fit table-borderless mb-0">
                <thead>
                <tr>
                    <th class="text-gray-700 align-middle w-60">
                        Název
                    </th>
                    <th class="text-gray-700 align-middle w-10 text-center">
                        Velikost
                    </th>
                    <th class="text-gray-700 align-middle w-20 text-center">
                        Nahrál
                    </th>
                    <th class="text-gray-700 align-middle w-10 text-right"/>
                </tr>
                </thead>
                <tbody>
                <tr class="border-top" v-for="attachment in order.attachments"
                    v-bind:key="'attachment_' + attachment.id">
                    <td class="align-middle font-weight-600">
                        {{ attachment.name|shortify(90) }}
                        <BBadge variant="danger" pill class="ml-2" v-if="order.list === attachment.id">
                            Montážní list
                        </BBadge>
                    </td>
                    <td class="align-middle text-center font-weight-600">
                        {{ attachment.size|prettyBytes }}
                    </td>
                    <td class="align-middle text-center font-weight-600">
                        {{ attachment.createdBy.name }}
                    </td>
                    <td class="w-10 align-middle text-right">
                        <AttachmentDownloadButton
                            variant="primary"
                            :id="attachment.id"
                            :name="attachment.name"
                            size="sm"
                            class="mr-2"
                            icon="fad fa-download"
                        />
                    </td>
                </tr>
                <tr class="border-top" v-if="order.attachments.length === 0">
                    <td colspan="4">
                        <TableWithoutRecords>
                            Žádné dokumenty nebyly k této zakázce přiloženy.
                        </TableWithoutRecords>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import AttachmentDownloadButton from "../Common/Attachment/AttachmentDownloadButton.vue"
import CancelOrderDropdownItem from "./Component/CancelOrderDropdownItem.vue"
import CreateChildOrderButton from "@/Module/Order/Component/CreateChildOrderButton.vue"
import DangerLinkButton from "../Common/UI/Button/DangerLinkButton.vue"
import Header from "../Common/UI/Header.vue"
import InfoLinkButton from "@/Module/Common/UI/Button/InfoLinkButton.vue"
import InterruptOrderDropdownItem from "./Component/InterruptOrderDropdownItem.vue"
import OrderInfoFragment from "./Component/OrderInfoFragment.vue"
import PrimaryLinkButton from "../Common/UI/Button/PrimaryLinkButton.vue"
import RefreshCalendarOrderDropdownItem from "./Component/RefreshCalendarOrderDropdownItem.vue"
import RestoreOrderDropdownItem from "./Component/RestoreOrderDropdownItem.vue"
import ServiceOrderStatus from "@/Model/Enum/service_order_status"
import SuccessLinkButton from "../Common/UI/Button/SuccessLinkButton.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        AttachmentDownloadButton,
        CancelOrderDropdownItem,
        CreateChildOrderButton,
        DangerLinkButton,
        Header,
        InfoLinkButton,
        InterruptOrderDropdownItem,
        OrderInfoFragment,
        PrimaryLinkButton,
        RefreshCalendarOrderDropdownItem,
        RestoreOrderDropdownItem,
        SuccessLinkButton,
        TableWithoutRecords,
    },

    data() {
        return {
            orderStatus: ServiceOrderStatus,
        }
    },

    computed: {
        ...mapGetters({
            order: 'order/getOrder',
        }),
    },

}
</script>
