import _ from "lodash"

const TypeOfRepairTypeList = [
    {id: 1, text: "Jednorázová"},
    {id: 2, text: "Periodická"},
]

export default class TypeOfRepairType {

    constructor(id) {
        this.selected = id !== null ? id : null
        this.items = {...TypeOfRepairTypeList}
    }

    toString() {
        let o = _.find(this.items, {id: this.selected})
        return o === undefined ? "N/A" : o.text
    }

    static print(id) {
        let o = _.find({...TypeOfRepairTypeList}, {id: parseInt(id)})
        return o === undefined ? "N/A" : o.text
    }

    static list() {
        return {...TypeOfRepairTypeList}
    }

    static getItem(id) {
        let o = _.find({...TypeOfRepairTypeList}, {id: parseInt(id)})
        return o === undefined ? null : o
    }

}
