<template>
    <div>
        <BButton
            class="ml-4"
            variant="dark"
            v-if="serviceOrderStatus.getItem(order.status) !== null && serviceOrderStatus.getItem(order.status).workerStatusEnabled === true"
            v-b-modal="'modal-change-worker-status-' + order.id"
        >
            <i class="fa-solid fa-arrows-turn-to-dots fa-fw mr-3"/> Změna stavu
        </BButton>
        <BModal
            :id="'modal-change-worker-status-' + order.id"
            header-bg-variant="primary"
            header-text-variant="white"
            footer-bg-variant="light"
            footer-text-variant="dark"
            size="xl"
            body-class="p-4"
            no-stacking
        >
            <template #modal-header>
                <div class="p-3">
                    <h4 class="display-4 mb-0">
                        <i class="fa-light fa-arrows-turn-to-dots fa-fw mr-3"/> Změna stavu zakázky
                    </h4>
                </div>
            </template>

            <table class="table table-borderless">
                <tr class="border-bottom bg-light">
                    <td class="font-weight-600 w-30 text-center">
                        Identifikátor:
                    </td>
                    <td class="font-weight-600 w-30 text-center">
                        Stav zakázky:
                    </td>
                    <td class="font-weight-600 w-30 text-center">
                        Stav technika:
                    </td>
                </tr>
                <tr>
                    <td class="text-center">
                        #{{ order.sequenceNumber }}
                    </td>
                    <td class="text-center">
                        {{ serviceOrderStatus.getItem(order.status).text }}
                    </td>
                    <td class="text-center">
                        {{ workerStatus.getItem(order.workerStatus).text }}
                    </td>
                </tr>
            </table>

            <BForm @submit="handleSubmit">

                <BFormGroup
                    id="input-group-worker-status"
                    label-for="input-worker-status"
                    label-class="font-weight-700"
                    class="mb-0"
                >
                    <template v-slot:label>
                        Vybrat nový stav: <span class="text-danger ml-1">*</span>
                    </template>
                    <BFormSelect
                        v-model="form.workerStatus"
                        id="input-worker-status"
                        class="mb-0"
                        stacked
                    >
                        <template #first>
                            <BFormSelectOption :value="0" disabled>-- vybrat --</BFormSelectOption>
                        </template>
                        <template v-for="record in workerStatus.list()">
                            <BFormSelectOption
                                name="input-worker-status"
                                :value="record.id"
                                :key="record.id"
                                v-if="record.active"
                                class="p-3"
                            >
                                {{ record.text }}
                            </BFormSelectOption>
                        </template>
                    </BFormSelect>
                </BFormGroup>

                <BFormGroup
                    id="input-group-cancel-status"
                    label-for="input-cancel-status"
                    label-class="font-weight-700"
                    class="mb-0 mt-4"
                    v-if="workerStatus.getItem(form.workerStatus) !== null && workerStatus.getItem(form.workerStatus).cancelReasonRequired === true"
                >
                    <template v-slot:label>
                        Důvod zrušení: <span class="text-danger ml-1">*</span>
                    </template>
                    <BFormSelect
                        v-model="form.cancelStatus"
                        id="input-cancel-status"
                        class="mb-0"
                        stacked
                    >
                        <template #first>
                            <BFormSelectOption :value="0" disabled>-- vybrat --</BFormSelectOption>
                        </template>
                        <template v-for="record in cancelStatus.list()">
                            <BFormSelectOption
                                name="input-cancel-status"
                                :value="record.id"
                                :key="record.id"
                                v-if="record.disabled === false"
                                class="p-3"
                            >
                                {{ record.text }}
                            </BFormSelectOption>
                        </template>
                    </BFormSelect>
                </BFormGroup>
            </BForm>
            <template #modal-footer>
                <BButton type="submit" variant="secondary" @click="handleSubmit">
                    <i class="fa-light fa-floppy-disk fa-fw mr-3"/>Změnit stav
                </BButton>
            </template>
        </BModal>

    </div>

</template>

<script>
import {mapGetters} from "vuex"
import CancelStatus from "../../../../Model/Enum/cancel_status"
import OrderStatus from "../../../Mobile/Component/OrderStatus.vue"
import ServiceOrderStatus from "../../../../Model/Enum/service_order_status"
import WorkerStatus from "../../../../Model/Enum/worker_status"

export default {

    computed: {
        ...mapGetters({
            isUpdating: "worker/order/workerStatus/isUpdating",
        }),
        orderStatus() {
            return OrderStatus
        },
    },

    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            workerStatus: WorkerStatus,
            cancelStatus: CancelStatus,
            serviceOrderStatus: ServiceOrderStatus,
            form: {
                workerStatus: null,
                cancelStatus: null,
            },
        }
    },

    mounted() {
        this.form.workerStatus = this.order.workerStatus
        this.form.cancelStatus = this.order.cancelStatus
    },

    methods: {

        handleSubmit(evt) {

            evt.preventDefault()
            let self = this

            let enumWorkerStatus = this.workerStatus.getItem(this.form.workerStatus)
            if (enumWorkerStatus === null) {
                alert("Vyberte stav")
                return false
            } else if (enumWorkerStatus.disabled) {
                alert("Vyberte nový stav")
                return false
            } else if (enumWorkerStatus && enumWorkerStatus.cancelReasonRequired === true) {
                let enumCancelReasonStatus = this.cancelStatus.getItem(this.form.cancelStatus)
                if (enumCancelReasonStatus === null) {
                    alert("Vyberte důvod zrušení")
                    return false
                } else if (enumCancelReasonStatus.disabled) {
                    alert("Vyberte stav")
                    return false
                }
            }

            this.$store.dispatch("worker/order/workerStatus/changeStatus", {
                order: this.order.id,
                workerStatus: this.form.workerStatus,
                cancelStatus: this.form.cancelStatus,
            })
                .then(() => {
                    this.successMessage("Nový stav byl nastaven.")
                    self.recordLocation()
                    self.order.workerStatus = this.form.workerStatus
                    this.$root.$emit("bv::hide::modal", "modal-change-worker-status-" + self.order.id)
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })

            return false
        },

        recordLocation() {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.$store.dispatch("tracking/doLog", {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        order: this.order.id,
                    })
                }
            )
        },

    },

}
</script>
