export default class AuthUser {

    constructor() {
        this.id = null
        this.email = ""
        this.fullName = ""
        this.jobName = null
        this.phone = null
        this.picture = null
        this.filterPerPage = 10
        this.filterSave = true
        this.filterOpened = true
        this.mobileVersion = false
        this.roles = []
    }

    static buildFromResponse(response) {
        let obj = new this()
        obj.id = response.id
        obj.email = response.email
        obj.fullName = response.fullName
        obj.jobName = response.jobName
        obj.phone = response.phone
        obj.picture = response.picture
        obj.filterPerPage = response.filterPerPage || 10
        obj.filterSave = response.filterSave || true
        obj.filterOpened = response.filterOpened || true
        obj.mobileVersion = response.mobileVersion || false
        obj.roles = response.roles
        return obj
    }

}
