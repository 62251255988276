<template>
    <BProgress
        height="2rem"
        :value="value"
        :max="100"
        :precision="2"
        show-value
        show-progress
        animated
    >
        <BProgressBar :value="value">
            <span>Výkon: <strong>{{ this.settled }} / {{ this.total }}, tj. {{ value.toFixed(0) }} %</strong></span>
        </BProgressBar>
    </BProgress>
</template>

<script>
import WorkerService from "../../../NewModel/Worker/WorkerService"

export default {

    data() {
        return {
            isLoading: false,
            settled: 0,
            total: 0,
            value: 0,
        }
    },

    mounted() {
        this.loadPerformance()
    },

    methods: {

        loadPerformance() {

            let self = this
            self.isLoading = true
            self.settled = 0
            self.total = 0
            self.value = 0

            WorkerService.getWeeklyOrderPerformance()
                .then((response) => {
                    self.settled = response.data.settled
                    self.total = response.data.total
                    self.value = response.data.result * 100
                })
                .catch((error) => {
                    self.errorMessage(error.response.data.message)
                })
                .finally(() => {
                    self.isLoading = false
                })
        },

    },

}
</script>
