import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const TrackingService = {

    doLog(latitude, longitude, order) {
        return httpClient.get("v3/tracking/log", {
            params: {
                latitude: latitude,
                longitude: longitude,
                order: order || null,
            }, headers: authHeader()
        })
    }

}

export default TrackingService
