import SupplyPointMetaLastOrderEntity from "./SupplyPointMetaLastOrderEntity"
import SupplyPointMetaLastNoteEntity from "./SupplyPointMetaLastNoteEntity"

export default class SupplyPointMetaEntity {

    constructor() {
        this.numberOfAppliances = 0
        this.numberOfContacts = 0
        this.numberOfContractAppliances = 0
        this.numberOfNotes = 0
        this.numberOfOrders = 0
        this.beCareful = false
        this.validDiscount = null

        /** @type {SupplyPointMetaLastOrderEntity|null} */
        this.lastOrder = null

        /** @type {SupplyPointMetaLastNoteEntity|null} */
        this.lastNote = null

        this.appliancesAgreeWithContract = true
    }

    /**
     * @param response : object
     * @returns SupplyPointMetaEntity
     */
    static from(response) {

        let obj = new this
        obj.numberOfAppliances = response.numberOfAppliances
        obj.numberOfContacts = response.numberOfContacts
        obj.numberOfContractAppliances = response.numberOfContractAppliances
        obj.numberOfNotes = response.numberOfNotes
        obj.numberOfOrders = response.numberOfOrders
        obj.beCareful = response.beCareful
        obj.validDiscount = response.validDiscount
        obj.lastOrder = response.lastOrder ? SupplyPointMetaLastOrderEntity.from(response.lastOrder) : null
        obj.lastNote = response.lastNote ? SupplyPointMetaLastNoteEntity.from(response.lastNote) : null
        obj.appliancesAgreeWithContract = response.appliancesAgreeWithContract
        return obj
    }

}
