import SignIn from "./Login/Login.vue"
import PageNotFound from "./PageNotFound.vue"
import Profile from "./Profile/Profile.vue"
import Dashboard from "./Dashboard/Dashboard.vue"
import WhiteMain from "@/Module/General/WhiteMain.vue"

const generalRoutes = [
    {
        path: "/",
        component: WhiteMain,
        props: true,
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: Dashboard,
            },
            {
                path: "profile",
                name: "user_profile",
                component: Profile,
            },
            {
                path: "page_not_found",
                name: "page_not_found",
                component: PageNotFound,
                meta: {
                    Public: true,
                },
            },
            {
                path: "homepage/dashboard",
                name: "general_homepage",
                component: Dashboard,
            },
        ],
    },
    {
        path: "/in",
        name: "user_login",
        component: SignIn,
        meta: {
            Public: true,
        },
    },
]

export default generalRoutes
