export default class ApplianceSuggestionEntity {

    constructor() {
        this.id = null
        this.recordType = ''
        this.name = ''
        this.manufacturer = {
            id: null,
            name: ''
        }
        this.model = {
            id: null,
            name: ''
        }
        this.modelType = {
            id: null,
            name: ''
        }
        this.applianceType = {
            id: null,
            name: '',
            commodity: null,
        }
    }

    /**
     * @param response : object
     * @returns ApplianceManufacturerEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.recordType = response.recordType
        obj.name = response.name
        obj.manufacturer.id = response.manufacturer.id
        obj.manufacturer.name = response.manufacturer.name
        obj.model.id = response.model.id
        obj.model.name = response.model.name
        obj.modelType.id = response.modelType.id
        obj.modelType.name = response.modelType.name
        obj.applianceType.id = response.applianceType.id
        obj.applianceType.name = response.applianceType.name
        obj.applianceType.commodity = response.modelType.commodity
        return obj
    }

    to() {
        return this.id
    }

}
