<template>
    <div>

        <Header title="Odběrná místa">
            <template #buttons>
                <SuccessLinkButton
                    to="/supply_points/map"
                    icon="fad fa-map-marked-alt"
                    text="Mapa odměrných míst"
                />
                <PrimaryLinkButton
                    to="/supply_points/search"
                    icon="fad fa-search"
                    text="Univerzální vyhledávání"
                    class="ml-4"
                />
                <PrimaryLinkButton
                    to="/supply_points/create"
                    icon="fad fa-plus-circle"
                    text="Přidat nové OM"
                    class="ml-4"
                />
            </template>
        </Header>

        <SupplyPointGrid class="mb-4"/>

    </div>
</template>

<script>
import Header from "../../Module/Common/UI/Header.vue"
import PrimaryLinkButton from "../../Module/Common/UI/Button/PrimaryLinkButton.vue"
import SuccessLinkButton from "../../Module/Common/UI/Button/SuccessLinkButton.vue"
import SupplyPointGrid from "./Component/SupplyPointGrid"

export default {

    components: {
        Header,
        PrimaryLinkButton,
        SuccessLinkButton,
        SupplyPointGrid,
    },

}
</script>
