<template>
    <div class="container">
        <LoadingBlock v-if="isLoading && !hasAppliance"/>
        <RouterView v-if="hasAppliance"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import LoadingBlock from "../Common/UI/LoadingBlock.vue"

export default {

    components: {
        LoadingBlock,
    },

    computed: {
        ...mapGetters({
            isLoading: 'appliance/isLoading',
            hasAppliance: 'appliance/hasAppliance',
            appliance: 'appliance/getAppliance',
        }),
    },

    mounted() {
        this.$store.dispatch("appliance/clear")
        this.load(this.$route.params.appliance_id)
    },

    methods: {

        load(id) {
            this.$store.dispatch("appliance/getAppliance", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
