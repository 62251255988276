export default class ApplianceModelFilterEntity {

    constructor() {
        this.id = null
        this.name = ""
        this.fullName = ""
        this.manufacturer = ""
        this.active = false
        this.commodity = 1
    }

    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.fullName = response.fullName
        obj.manufacturer = response.manufacturer
        obj.active = response.active
        obj.commodity = response.commodity
        return obj
    }

}
