export default class OrderMetadataRepairTypeEntity {

    constructor() {
        this.id = null
        this.name = ''
        this.shortName = ''
    }

    /**
     * @param response : object
     * @returns {OrderMetadataRepairTypeEntity}
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.shortName = response.shortName
        return obj
    }

}
