<template>
    <BInputGroup class="mb-0">
        <BFormInput placeholder="# ID Spotřebiče" v-model="id" type="number"/>
        <BInputGroupAppend>
            <BButton variant="primary" @click.prevent="go" :disabled="id === ''">
                Zobrazit
            </BButton>
        </BInputGroupAppend>
    </BInputGroup>
</template>
<script>
export default {

    data() {
        return {
            id: '',
        }
    },

    methods: {

        go() {
            this.$router.push({
                name: "worker_appliance_show",
                params: {id: 41000000 + parseInt(this.id)},
                query: {
                    source: "dashboard"
                }
            })
                .catch(() => {
                })
        },

    },

}
</script>
