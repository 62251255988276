<template>
    <div>

        <Header icon="fad fa-plus-circle" title="Vytvoření nového spotřebiče">
            <template #buttons>
                <BackLinkButton :to="{ name: 'supply_point_show', params: { id: supplyPoint.id }}" text="Zpět do OM"/>
            </template>
        </Header>

        <div class="bg-white p-3 border-radius-sm">
            <div class="p-3 bg-light mb-3">
                <strong>Odběrné místo</strong>: {{ supplyPoint.id }}
                <span class="ml-3 mr-3">|</span>
                <strong>EAN:</strong> {{ supplyPoint.ean }}
            </div>

            <ApplianceForm
                v-bind:request="request"
                :is-processing="isProcessing"
                @submitted="onSubmitted"
            />

        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceForm from "../Appliance/Component/ApplianceForm.vue"
import ApplianceRequest from "../../NewModel/Appliance/Request/ApplianceRequest"
import BackLinkButton from "../Common/UI/Button/BackLinkButton.vue"
import Header from "../Common/UI/Header.vue"

export default {

    components: {
        ApplianceForm,
        BackLinkButton,
        Header,
    },

    data() {
        return {
            request: new ApplianceRequest(),
        }
    },

    computed: {
        ...mapGetters({
            isProcessing: "appliance/isProcessing",
            supplyPoint: "supplyPoint/getSupplyPoint",
        }),
    },

    mounted() {
        this.$store.dispatch("appliance/clear")
    },

    methods: {

        /**
         * @param request : ApplianceRequest
         */
        onSubmitted(request) {
            this.$store.dispatch("supplyPoint/appliances/createAppliance", {
                id: this.supplyPoint.id,
                request: request,
            })
                .then((response) => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'appliance_show'})
                    this.$router.push({
                        name: "appliance_show", params: {appliance_id: response.id,}
                    })
                        .catch(() => {
                        })
                })
                .catch((err) => {
                    this.warningMessage(err.response.data.message)
                })
        },

    },

}
</script>
