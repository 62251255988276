<template>
    <div>
        <div class="bg-little-gray p-4" v-if="records.length > 0">
            <table class="table table-fit table-borderless mb-0">
                <thead>
                <tr>
                    <th class="align-middle text-left w-15">
                        Identifikátor
                    </th>
                    <th class="align-middle">
                        Název spotřebiče
                    </th>
                    <th class="align-middle">
                        Sériové číslo
                    </th>
                    <th class="text-right align-middle w-5"/>
                </tr>
                </thead>
                <tbody>
                <tr v-for="record in records" v-bind:key="record.id" class="border-top">
                    <td class="align-middle text-left">
                        #{{ String(record.id).slice(-5) }}
                    </td>
                    <td class="align-middle">
                        {{ record.fullName || "Neidentifikováno" }}
                    </td>
                    <td class="align-middle">
                        {{ record.serialNumber }}
                    </td>
                    <td class="align-middle text-right">
                        <BButton
                            variant="primary"
                            size="sm"
                            :to="'/worker/appliances/' + record.id + '?supplyPoint=' + $route.params.id"
                        >
                            <i class="fa-duotone fa-eye fa-fw"/>
                        </BButton>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        records: {
            required: true,
        },
    },

}
</script>
