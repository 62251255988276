export default class AddressRequest {

    constructor() {
        this.street = ""
        this.houseNumber = ""
        this.guidanceNumber = null
        this.postalCode = ""
        this.city = ""
        this.cityPart = null
        this.region = null
        this.country = "Česká republika"
    }

    toForm() {
        return this
    }

}
