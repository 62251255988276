export default class IncidentRequest {

    constructor() {
        this.customer = ""
        this.description = ""
        this.eventTime = null
        this.createCalendarEvent = false
    }

    toForm() {
        return this
    }

}
