<template>
    <div>
        <BButton v-b-modal.add-window class="px-5" variant="gray-100">
            <i class="fa-solid fa-ellipsis-h fa-fw text-dark fa-2x"/>
        </BButton>

        <BModal
            id="add-window"
            header-bg-variant="primary"
            header-text-variant="white"
            footer-bg-variant="light"
            footer-text-variant="dark"
            size="xl"
            body-class="p-4"
            no-stacking
        >
            <template #modal-header>
                <div class="p-3">
                    <h4 class="display-4 mb-0">
                        <i class="fa-light fa-layer-plus fa-fw mr-3"/> Přiložení do zakázky
                    </h4>
                </div>
            </template>

            <div class="d-flex justify-content-between mb-4">
                <div v-b-modal.modal-add-technical-note-supply-point class="bg-light border-radius-sm p-4 w-30">
                    <div class="d-flex flex-column align-items-center text-center">
                        <i class="fa-duotone fa-house fa-fw fa-4x mb-4 text-primary"/>
                        <div class="font-weight-600">Technická poznámka k OM</div>
                    </div>
                </div>
                <div v-b-modal.modal-add-technical-note-appliance class="bg-light border-radius-sm p-4 w-30">
                    <div class="d-flex flex-column align-items-center text-center">
                        <i class="fa-duotone fa-flux-capacitor fa-fw fa-4x mb-4 text-primary"/>
                        <div class="font-weight-600">Technická poznámka ke spotřebiči</div>
                    </div>
                </div>
                <div v-b-modal.modal-add-job-sheet class="bg-light border-radius-sm p-4 w-30"
                     v-if="order.locked === false">
                    <div class="d-flex flex-column align-items-center text-center">
                        <i class="fa-duotone fa-file-contract fa-fw fa-4x mb-4 text-primary"/>
                        <div class="font-weight-600">Montážní list (příloha)</div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between mb-4">
                <div v-b-modal.modal-add-attachment class="bg-light border-radius-sm p-4 w-30">
                    <div class="d-flex flex-column align-items-center text-center">
                        <i class="fa-duotone fa-camera fa-fw fa-4x mb-4 text-primary"/>
                        <div class="font-weight-600">Dokument</div>
                    </div>
                </div>
                <div v-b-modal.modal-add-material class="bg-light border-radius-sm p-4 w-30"
                     v-if="order.locked === false">
                    <div class="d-flex flex-column align-items-center text-center">
                        <i class="fa-duotone fa-cog fa-fw fa-4x mb-4 text-primary"/>
                        <div class="font-weight-600">Materiál</div>
                    </div>
                </div>
            </div>

            <template #modal-footer="{cancel}">
                <div>
                    <BButton variant="dark" @click="cancel()">Zavřít okno</BButton>
                </div>
            </template>
        </BModal>

        <AddTechnicalNoteSupplyPoint :supply-point="order.supplyPoint.id" @created="technicalNoteCreated"/>
        <AddTechnicalNoteAppliance :supply-point="order.supplyPoint.id" @created="technicalNoteCreated"/>
        <AddMaterialModalForm :order="order.id" @created="materialCreated"/>
        <AddAttachmentModalForm :order="order.id" @created="attachmentsCreated"/>
        <AddJobSheetModalForm :order="order.id" @created="attachmentsCreated"/>

    </div>
</template>

<script>
import AddAttachmentModalForm from "./AddAttachmentModalForm.vue"
import AddJobSheetModalForm from "./AddJobSheetModalForm.vue"
import AddMaterialModalForm from "./AddMaterialModalForm.vue"
import AddTechnicalNoteAppliance from "./AddTechnicalNoteAppliance.vue"
import AddTechnicalNoteSupplyPoint from "./AddTechnicalNoteSupplyPoint.vue"

export default {

    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    components: {
        AddAttachmentModalForm,
        AddJobSheetModalForm,
        AddMaterialModalForm,
        AddTechnicalNoteAppliance,
        AddTechnicalNoteSupplyPoint,
    },

    methods: {

        technicalNoteCreated() {
            this.order.metadata.numberOfNotes += 1
            this.fireEventOrderUpdated()
        },

        materialCreated() {
            this.order.metadata.numberOfMaterials += 1
            this.fireEventOrderUpdated()
        },

        attachmentsCreated(data) {
            this.order.metadata.numberOfAttachments += data.length
            this.fireEventOrderUpdated()
        },

        fireEventOrderUpdated() {
            this.$eventBus.$emit("order-updated")
        },

    },

}
</script>
