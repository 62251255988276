<template>
    <RouterLink class="btn btn-info" :to="to">
        <slot name="default">
            <i class="fad fa-chevron-left mr-3"/>Zpět
        </slot>
    </RouterLink>
</template>

<script>
export default {

    props: {
        to: {
            type: [Object, String],
            required: true,
            default: () => {
            },
        },
        text: {
            type: String,
            required: false,
            default: "",
        },
    },

}
</script>
