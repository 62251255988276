import OrderApplianceRequest from "./OrderApplianceRequest"
import moment from "moment/moment"
import AttachmentRequest from "../../Attachment/Request/AttachmentRequest"

export default class OrderRequest {

    constructor() {
        this.contact = ''
        this.payer = 1
        this.worker = null
        this.scheduledStartTime = new Date().toLocaleString()
        this.scheduledRepairTime = 60
        this.formOfPayment = 'cash'
        this.estimatedAmount = 0
        this.sendSms = true
        this.muteNotifications = false
        this.note = ""
        this.appliances = []
        this.attachments = []
        this.assemblySheet = null
        this.actions = []
        this.regenerateEvent = false
    }

    static from(response) {

        let request = new this
        request.contact = response.contact ? response.contact.id : null
        request.payer = response.payer
        request.worker = response.worker
        request.scheduledStartTime = response.scheduledStartTime
            ? moment(response.scheduledStartTime).format("YYYY-MM-DD HH:mm")
            : moment().format("YYYY-MM-DD HH:mm")
        request.scheduledRepairTime = response.scheduledRepairTime
        request.formOfPayment = response.formOfPayment
        request.estimatedAmount = response.estimatedAmount
        request.sendSms = response.sendSms
        request.muteNotifications = response.muteNotifications
        request.note = response.note
        request.assemblySheet = response.list
        request.appliances = response.appliances.map(appliance => OrderApplianceRequest.from(appliance))
        request.attachments = response.attachments.map(attachment => new AttachmentRequest(attachment.id, attachment.name))
        return request
    }

    toForm() {
        return {
            contact: this.contact,
            payer: this.payer,
            worker: this.worker,
            scheduledStartTime: moment(this.scheduledStartTime).format("YYYY-MM-DD HH:mm"),
            scheduledRepairTime: this.scheduledRepairTime,
            formOfPayment: this.formOfPayment,
            estimatedAmount: this.estimatedAmount,
            sendSms: this.sendSms,
            muteNotifications: this.muteNotifications,
            note: this.note,
            assemblySheet: this.assemblySheet,
            appliances: this.appliances.map(appliance => appliance.toForm()),
            attachments: this.attachments.map(attachment => attachment.toForm()),
            actions: this.actions,
            regenerateEvent: this.regenerateEvent,
        }
    }

}
