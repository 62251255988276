import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const SubjectService = {


    /**
     * @param request : SimpleFormRequest
     */
    filterSubjects(request) {
        return httpClient.post("v3/subjects/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    getSubject(id) {
        return httpClient.get("v3/subjects/" + id + "/basic", {
            headers: authHeader()
        })
    },

    /**
     * @param request : SubjectRequest
     */
    createSubject(request) {
        return httpClient.post("v3/subjects", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     * @param request : SubjectRequest
     */
    updateSubject(id, request) {
        return httpClient.put("v3/subjects/" + id, request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : string
     */
    getSubjectContacts(id) {
        return httpClient.get("v3/subjects/" + id + "/contacts",
            {
                headers: authHeader()
            })
    },

    /**
     * @param id : string
     */
    getSubjectSupplyPoints(id) {
        return httpClient.get("v3/subjects/" + id + "/supply_points",
            {
                headers: authHeader()
            })
    },

    /**
     * @param id : string
     */
    getSubjectContracts(id) {
        return httpClient.get("v3/subjects/" + id + "/contracts",
            {
                headers: authHeader()
            })
    },

    /**
     * @param id : string
     */
    getSubjectOrders(id) {
        return httpClient.get("v3/subjects/" + id + "/orders",
            {
                headers: authHeader()
            })
    },

    /**
     * @param query : string
     */
    searchSubject(query) {
        return httpClient.get("v3/subjects/search?query=" + encodeURIComponent(query),
            {
                headers: authHeader()
            })
    },

    /**
     * @param id : string
     * @param request : SubjectContactRequest
     */
    createSubjectContact(id, request) {
        return httpClient.post("v3/subject/" + id + "/contacts", request.toForm(),
            {
                headers: authHeader()
            })
    },

    /**
     * @param subject : string
     * @param id : string
     * @param request : SubjectContactRequest
     */
    updateSubjectContact(subject, id, request) {
        return httpClient.put("v3/subject/" + subject + "/contacts/" + id, request.toForm(),
            {
                headers: authHeader()
            })
    },

    /**
     * @param subject : string
     * @param id : string
     */
    deleteSubjectContact(subject, id) {
        return httpClient.delete("v3/subject/" + subject + "/contacts/" + id,
            {
                headers: authHeader()
            })
    }

}

export default SubjectService
