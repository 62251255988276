<template>
    <table class="table table-fit table-borderless mb-0">
        <thead>
        <tr class="border-bottom border-light">
            <th class="align-middle text-gray-700">
                Název kontaktu
            </th>
            <th class="align-middle text-gray-700 w-20">
                Telefonní číslo
            </th>
            <th class="align-middle text-gray-700 w-30">
                Elektronická adresa
            </th>
            <th class="text-right text-gray-700 align-middle w-15">
                Akce
            </th>
        </tr>
        </thead>
        <tbody>
        <SubjectContactFormRow
            v-for="(contact, contactIndex) in contacts"
            :key="contact + contactIndex"
            :subjectId="subject"
            :contact="contact"
            :index="contactIndex"
            @updated="contactProcessed"
            @created="contactProcessed"
            @cancelled="contactCancelled"
            @deleted="contactDeleted"
        />
        </tbody>
        <tbody v-if="contacts.length === 0">
        <tr>
           <TableWithoutRecords />
        </tr>
        </tbody>
        <tfoot>
        <tr class="border-top">
            <td colspan="5">
                <BButton variant="light" size="sm" @click="addContact()">
                    <i class="fad fa-plus-circle fa-fw mr-2"/>Přidat kontakt
                </BButton>
            </td>
        </tr>
        </tfoot>
    </table>
</template>

<script>
import {mapGetters} from "vuex"
import SubjectContactFormRow from "./SubjectContactFormRow.vue"
import SubjectContactRequest from "../../../NewModel/Subject/Request/SubjectContactRequest"
import SubjectContactBasicEntity from "../../../NewModel/Subject/Entity/SubjectContactBasicEntity"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        SubjectContactFormRow,
        TableWithoutRecords,
    },

    props: {
        subject: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            request: new SubjectContactRequest(),
            updated: null,
            contacts: [],
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'subject/contacts/isLoading',
        }),
    },

    created() {
        this.loadContacts(this.subject);
    },

    methods: {

        loadContacts(id) {
            this.$store.dispatch("subject/contacts/getContacts", id)
                .then((contacts) => {
                    this.contacts = contacts
                })
        },

        addContact() {
            // Check if opened item already exists
            let l = this.contacts.find((a) => a._action === "create")
            if (typeof l !== "undefined") {
                return
            }

            let contact = new SubjectContactBasicEntity()
            contact._action = "create"
            this.contacts.push(contact)
        },

        contactProcessed(data) {
            // Search assembly sheet other item by id and replace then
            if (typeof this.contacts[data.index] !== "undefined") {
                this.contacts.splice(data.index, 1, data.entity)
            }
        },

        contactCancelled(data) {
            // Search assembly sheet other item by id and replace then
            if (typeof this.contacts[data.index] !== "undefined") {
                this.contacts.splice(data.index, 1)
            }
        },

        contactDeleted(data) {
            this.$emit('deleted', data)
        },

    },

}
</script>
