import OrderMetadataApplianceEntity from "./OrderMetadataApplianceEntity"
import SubjectContactBasicEntity from "../../Subject/Entity/SubjectContactBasicEntity"
import UserEntity from "../../User/Entity/UserEntity"
import OrderMetadataRepairTypeEntity from "@/NewModel/Order/Entity/OrderMetadataRepairTypeEntity"

export default class OrderMetadataEntity {

    constructor() {
        this.contacts = []
        this.appliances = []
        this.workers = []
        this.repairTypes = []
    }

    /**
     * @param response : object
     * @returns {OrderMetadataEntity}
     */
    static from(response) {
        let obj = new this
        obj.contacts = response.contacts.map(contact => SubjectContactBasicEntity.from(contact))
        obj.appliances = response.appliances.map(appliance => OrderMetadataApplianceEntity.from(appliance))
        obj.workers = response.workers.map(worker => UserEntity.from(worker))
        obj.repairTypes = response.repairTypes.map(repairType => OrderMetadataRepairTypeEntity.from(repairType))
        return obj
    }

}
