<template>

    <table class="table table-fit table-borderless mb-0">
        <thead>
        <tr>
            <th>
                Datum
            </th>
            <th>
                Zakázka
            </th>
            <th>
                Spotřebič
            </th>
            <th>
                Komentář
            </th>
            <th/>
        </tr>
        </thead>
        <tbody>
        <template v-if="!isLoading && hasIssues">
            <tr class="border-top" v-for="(issue, issueIndex) in issues" :key="issue.id">
                <td class="align-middle w-10 p-2">
                    {{ issue.createdAt|prettyDate }}
                </td>
                <td class="align-middle w-10 p-2">
                    <span v-if="issue.order">
                        <RouterLink :to="{ name: 'order_show', params: { id: issue.order }}">
                            #{{ issue.orderKey }}
                        </RouterLink>
                    </span>
                </td>
                <td class="align-middle w-10 p-2">
                    {{ issue.appliance.name }}
                </td>
                <td class="align-middle p-2">
                    {{ issue.comment }}
                </td>
                <td class="align-middle w-4 p-2">
                    <button class="btn btn-success btn-sm" v-on:click="closeIssue(issue.id, issueIndex)">
                        <i class="fad fa-check-circle"/>
                    </button>
                </td>
            </tr>
        </template>
        <tr class="border-top" v-else-if="isLoading && !hasIssues">
            <td colspan="5">
                <TableLoading/>
            </td>
        </tr>
        <tr class="border-top" v-else>
            <td colspan="5">
                <TableWithoutRecords/>
            </td>
        </tr>
        </tbody>
    </table>

</template>

<script>
import {mapGetters} from "vuex"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TableWithoutRecords,
    },

    computed: {
        ...mapGetters({
            isLoading: "issue/my/isLoading",
            hasIssues: "issue/my/hasIssues",
            issues: "issue/my/getIssues",
        }),
    },

    mounted() {
        this.loadMyIssues()
    },

    methods: {

        loadMyIssues() {
            this.$store.dispatch("issue/my/getMyIssues")
        },

        closeIssue(id, index) {
            let self = this
            this.$store.dispatch("issue/closeIssue", id)
                .then(() => {
                    this.successMessage("Úkol byl označen jako splněný.")
                    self.issues.splice(index, 1)
                })
        },

    },

}
</script>
