<template>
    <div>
        <div class="bg-little-gray p-4" v-if="records.length > 0">
            <table class="table table-borderless mb-0">
                <thead>
                <tr>
                    <th class="align-middle text-left w-10">
                        Datum
                    </th>
                    <th class="align-middle w-20">
                        Typ opravy
                    </th>
                    <th class="align-middle text-center w-10">
                        Zakázka
                    </th>
                    <th class="align-middle w-10 text-center">
                        Pracovník
                    </th>
                    <th class="align-middle">
                        Popis
                    </th>
                </tr>
                </thead>
                <tbody v-for="record in records" v-bind:key="record.id">
                <tr class="border-top">
                    <td class="align-middle text-left">
                        {{ record.dateOfRepair|prettyDate }}
                    </td>
                    <td class="align-middle">
                        {{ record.repairType }}
                    </td>
                    <td class="align-middle text-center">
                        {{ record.orderSequenceNumber }}
                    </td>
                    <td class="align-middle text-center">
                        <BAvatar
                            size="md"
                            :src="record.worker.picture || null"
                            :text="record.worker.acronym"
                            v-if="record.worker"
                        />
                        <BAvatar size="md" variant="light" v-else/>
                    </td>
                    <td class="align-middle text-right">
                        {{ record.comment }}
                    </td>
                </tr>
                <tr v-for="attachment in record.attachments" :key="attachment.id">
                    <td colspan="4" class="pt-2 pb-2 table-light align-middle">
                        <i class="fa-solid fa-chevron-right fa-fw mx-4"/>{{ attachment.name }}
                    </td>
                    <td class="pt-2 pb-2 table-light text-right align-middle">
                        <AttachmentDownloadButton
                            variant="light"
                            :id="attachment.id"
                            :name="attachment.name"
                            size="sm"
                            class="ml-4"
                            icon="fad fa-download"
                            text="Stáhnout"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import AttachmentDownloadButton from "../../../Common/Attachment/AttachmentDownloadButton.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        AttachmentDownloadButton,
        TableWithoutRecords,
    },

    props: {
        records: {
            required: true,
        },
    },

}
</script>
