<template>
    <BDropdownItem
        href="#"
        v-on:click.prevent="process"
        :disabled="isProcessing"
    >
        <i class="fad fa-repeat mr-3 text-warning"/>Obnovit zakázku do <strong>Naplánováno</strong>
    </BDropdownItem>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    computed: {
        ...mapGetters({
            order: 'order/getOrder',
            isProcessing: 'order/isProcessing',
        }),
    },

    methods: {
        process() {
            this.$bvModal.msgBoxConfirm('Opravdu chcete obnovit tuto zakázku do stavu Naplánováno?', {
                title: 'Potvrzení',
                okVariant: 'primary',
                cancelVariant: 'default',
                centered: true,
                okTitle: "Ano, zrušit.",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("order/restoreOrderStatus", this.order.id)
                            .then(() => {
                                this.successMessage('Zakázka byla úspěšně obnovena.')
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

    },

}
</script>
