<template>
    <BForm ref="form">


        <div class="table-warning p-3 text-dark mb-3">
            Tato adresa <strong>nebude zvalidována</strong>,
            tzn. nebudou k ní doplněny GPS souřadnice, RÚIAN kód apod.<br/>
            Pokud jde o adresu v České Republice, využijte přednostně našeptávač!
        </div>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label class="bold col-form-label" for="street">
                    Ulice: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.street"
                    id="street"
                    type="text"
                    autocomplete="off"
                    placeholder="Radlická"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label class="bold col-form-label" for="houseNumber">
                    Číslo popisné: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.houseNumber"
                    required
                    id="houseNumber"
                    type="text"
                    autocomplete="off"
                    placeholder="3294"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label class="bold col-form-label" for="guidanceNumber">
                    Číslo orientační:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.guidanceNumber"
                    id="guidanceNumber"
                    type="text"
                    autocomplete="off"
                    placeholder="10"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label class="bold col-form-label" for="postalCode">
                    PSČ: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.postalCode"
                    required
                    id="postalCode"
                    type="text"
                    autocomplete="off"
                    placeholder="15000"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label class="bold col-form-label" for="city">
                    Město: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.city"
                    required
                    id="city"
                    type="text"
                    autocomplete="off"
                    placeholder="Praha"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label class="bold col-form-label" for="cityPart">
                    Část obce:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.cityPart"
                    id="cityPart"
                    type="text"
                    autocomplete="off"
                    placeholder="Smíchov"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label class="bold col-form-label" for="region">
                    Kraj / region:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.region"
                    id="region"
                    type="text"
                    autocomplete="off"
                    placeholder="Hlavní město Praha"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label class="bold col-form-label" for="country">
                    Země: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.country"
                    required
                    id="country"
                    type="text"
                    autocomplete="off"
                    placeholder="Česká Republika"
                />
            </BCol>
        </BRow>

        <BRow>
            <BCol lg="3" md="12"/>
            <BCol lg="9" md="12">
                <BButton
                    @click="processForm"
                    variant="primary"
                    block
                >
                    Vytvořit adresu
                </BButton>
            </BCol>
        </BRow>

    </BForm>
</template>

<script>
import AddressRequest from "../../../NewModel/Address/Request/AddressRequest"

export default {

    data() {
        return {
            request: new AddressRequest(),
        }
    },

    methods: {

        processForm() {

            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Vyplňte všechna povinná pole formuláře!')
                return false
            }

            let self = this
            this.$store.dispatch("address/createByAddress", this.request)
                .then((address) => {
                    this.successMessage('Adresa byla vytvořena.')
                    self.$emit('picked', address)
                    self.request = new AddressRequest()
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
