<template>
    <div>

        <Header icon="fad fa-tachometer-alt" title="Dashboard"/>

        <DashboardOverview/>

        <h4 class="font-weight-700 text-dark mb-2">
            Dnešní zakázky
        </h4>
        <div class="bg-little-gray p-4 mb-4">
            <DashboardTodayOrders/>
        </div>

        <h4 class="font-weight-700 text-dark mb-2">
            Moje úkoly
        </h4>
        <div class="bg-little-gray p-4 mb-4">
            <DashboardIssues/>
        </div>

        <h4 class="font-weight-700 text-dark mb-2">
            Incidenty k dořešení
        </h4>
        <div class="bg-little-gray p-4 mb-4">
            <DashboardIncidents/>
        </div>

    </div>
</template>

<script>
import DashboardIncidents from "./Component/DashboardIncidents.vue"
import DashboardIssues from "./Component/DashboardIssues.vue"
import DashboardOverview from "./Component/DashboardOverview.vue"
import DashboardTodayOrders from "./Component/DashboardTodayOrders.vue"
import Header from "../../Common/UI/Header.vue"

export default {

    components: {
        DashboardIncidents,
        DashboardIssues,
        DashboardOverview,
        DashboardTodayOrders,
        Header,
    },

}
</script>
