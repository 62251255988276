<template>
    <div class="ac">
        <BInput
            type="text"
            v-model="search"
            :disabled="locked"
            :placeholder="placeholder"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
            @click="checkOpenedResults"
            id="address-suggestion"
            autocomplete="off"
        />
        <ul class="ac-results" v-show="isOpen">
            <li v-if="isLoading" class="p-3">
                Získávám seznam položek...
            </li>
            <li
                v-else
                v-for="(result, i) in results"
                :key="i"
                :class="{ 'is-active': i === arrowCounter }"
                class="ac-result"
                @click="addressSelected(result)"
            >
                {{ result.wholeAddress }}
            </li>
        </ul>
    </div>
</template>

<script>
import {debounce} from "../../../NewModel/Common/Helpers/Debounce"
import {mapGetters} from "vuex"

export default {

    props: {
        defaultText: {
            type: String,
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        locked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            search: "",
            results: [],
            isOpen: false,
            arrowCounter: -1,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: "address/whisperer/isLoading",
            hasRecords: "address/whisperer/hasAddresses",
            records: "address/whisperer/getAddresses",
        }),
    },

    created() {
        this.search = this.defaultText
    },

    mounted() {
        document.addEventListener("click", this.handleClickOutside)
    },

    destroyed() {
        document.removeEventListener("click", this.handleClickOutside)
    },

    watch: {
        search: debounce(function (input) {
            this.$emit("input", this.search)
            let self = this
            if (String(input).length < 2) {
                return
            }
            this.$store.dispatch("address/whisperer/whisperAddress", String(input))
                .then((response) => {
                    self.results = response
                    self.isOpen = response.length > 0
                })
                .finally(() => {
                    self.loading = false
                })
        }, 600),
    },

    methods: {

        addressSelected(result) {
            let self = this
            this.$store.dispatch("address/whisperer/validateWhisperedAddress", {
                addressText: result.wholeAddress,
                addressHash: result.hash
            })
                .then((data) => {
                    self.$emit("picked", data)
                    self.search = result.name
                    self.isOpen = false
                })
        },

        checkOpenedResults() {
            if (this.isOpen === false && this.results.length > 0) {
                this.isOpen = true
            }
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false
                this.arrowCounter = -1
            }
        },

        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1
            } else {
                this.arrowCounter = 0
            }
        },

        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1
            } else {
                this.arrowCounter = this.results.length - 1
            }
        },

    },

}
</script>

<style scoped>
.ac {
    position: relative
}

.ac-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eaecf4;
    min-height: 1rem;
    max-height: 20rem;
    overflow: auto;
    z-index: 9999;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ac-result {
    list-style: none;
    text-align: left;
    padding: 0.6rem;
    cursor: pointer;
    font-size: 0.85rem;
}

.ac-result.is-active, .ac-result:hover {
    background-color: #253858;
    color: white;
}
</style>
