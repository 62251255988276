<template>
    <div class="vw-100 vh-100 d-flex align-items-center justify-content-center">
        <div class="text-center">
            <div class="font-weight-700 text-danger mb-0" style="font-size: 7rem;">
                404
            </div>
            <div class="font-weight-500 mb-0" style="font-size: 3.5rem;">
                Oooooooops
            </div>
            <div class="font-weight-300" style="font-size: 2.5rem;">
                Vypadá to, že jste se ztratili.
            </div>
            <div class="mt-4">
                <PrimaryLinkButton
                    :to="{name: 'dashboard'}"
                    icon="fad fa-home-alt"
                    text="Přejít na úvodní stránku"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PrimaryLinkButton from "../Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        PrimaryLinkButton,
    },

}
</script>
