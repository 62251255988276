<template>
    <div>
        <AttachmentUpload v-on:uploaded="attachmentUploaded"/>
        <table class="table table-borderless table-responsive-md mb-0">
            <thead>
            <tr>
                <th class="w-5">
                    ML
                </th>
                <th class="align-middle">
                    Název
                </th>
                <th/>
            </tr>
            </thead>
            <tbody>
            <tr
                class="border-top" v-for="(attachment, index) in attachments"
                v-bind:key="'attachment_' + attachment.id"
            >
                <td class="align-middle pointer" @click="$emit('update:assembly-sheet', attachment.id)">
                    <i class="fad fa-circle fa-fw"
                       v-bind:class="assemblySheet === attachment.id ? 'text-success' : 'text-danger'"/>
                </td>
                <td class="align-middle">
                    {{ attachment.name|shortify(slim ? 12 : 40) }}
                </td>
                <td class="align-middle text-right" v-bind:class="[slim ? 'w-45' : 'w-15']">
                    <AttachmentDownloadLink :id="attachment.id" :name="attachment.name" icon="fad fa-download"/>
                    <BLink variant="dark" v-on:click.prevent="removeFile(attachment.id, index)">
                        <i class="fad fa-times ta-fw ml-3"/>
                    </BLink>
                </td>
            </tr>
            <tr class="border-top" v-if="attachments.length === 0">
                <td :colspan="!slim ? 5 : 3" class="text-center pt-3">
                    <i class="fad fa-times fa-fw text-danger mr-3"/>Žádné dokumenty nebyly k této zakázce přiloženy.
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import AttachmentUpload from "@/Components/General/AttachmentUpload"
import AttachmentDownloadLink from "../../../Common/Attachment/AttachmentDownloadLink.vue"
import AttachmentRequest from "@/NewModel/Attachment/Request/AttachmentRequest"

export default {

    components: {
        AttachmentDownloadLink,
        AttachmentUpload,
    },

    props: {
        assemblySheet: {
            default: null,
            required: false,
        },
        attachments: {
            type: Array,
            default: () => [],
            required: true,
        },
        slim: {
            type: Boolean,
            default: false,
        },
    },

    methods: {

        attachmentUploaded(files) {
            let self = this
            files.forEach(function (file) {
                self.attachments.push(new AttachmentRequest(file.id, file.name))
            })
            this.$emit('update:attachments', this.attachments)
        },

        removeFile(id, index) {
            if (this.assemblySheet === id) {
                this.assemblySheet = null
            }
            this.attachments.splice(index, 1)
            this.$emit('update:attachments', this.attachments)
        },

    },

}
</script>
