import DashboardOrdersReviewsStore from "./Store/DashboardOrdersReviewsStore"
import DashboardTodayOrdersStore from "./Store/DashboardTodayOrdersStore"

export default {
    namespaced: true,
    modules: {
        orderReviews: DashboardOrdersReviewsStore,
        todayOrders: DashboardTodayOrdersStore,
    }
}
