<template>

    <table class="table table-fit table-borderless mb-0">
        <thead>
        <tr>
            <th>
                Zákazník
            </th>
            <th>
                Popis
            </th>
            <th>
                Datum a čas
            </th>
            <th>
                Technik
            </th>
            <th class="text-right">Stav
            </th>
        </tr>
        </thead>
        <tbody>
        <template v-if="!isLoading && hasIncidents">
            <tr class="border-top" v-for="(incident, index) in incidents" :key="incident.id">
                <td class="align-middle w-10">
                    {{ incident.customer }}
                </td>
                <td class="align-middle">
                    {{ incident.description }}
                </td>
                <td class="align-middle w-10">
                    {{ incident.eventTime|prettyDateTimeShort }}
                </td>
                <td class="align-middle w-10">
                    {{ incident.createdBy || "-" }}
                </td>
                <td class="align-middle w-5 text-right">
                    <span v-if="incident.solved" class="text-success">vyřešeno</span>
                    <BLink
                        class="text-danger"
                        v-else
                        @click.prevent="resolveIncident(incident, index)"
                        style="text-decoration-style: dashed; text-decoration-line: underline; text-decoration-thickness: 1px;"
                    >
                        nevyřešeno
                    </BLink>
                </td>
            </tr>
        </template>
        <tr class="border-top" v-else-if="isLoading && !hasIncidents">
            <td colspan="5">
                <TableLoading/>
            </td>
        </tr>
        <tr class="border-top" v-else>
            <td colspan="5">
                <TableWithoutRecords/>
            </td>
        </tr>
        </tbody>
    </table>

</template>

<script>
import {mapGetters} from "vuex"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TableWithoutRecords,
    },

    computed: {
        ...mapGetters({
            isLoading: "incident/overview/isLoading",
            hasIncidents: "incident/overview/hasIncidents",
            incidents: "incident/overview/getIncidents",
        }),
    },

    mounted() {
        this.loadIncidents()
    },

    methods: {

        loadIncidents() {
            this.$store.dispatch("incident/overview/getOverviewOfIncidents")
        },

        resolveIncident(incident) {
            this.$bvModal.msgBoxConfirm("Opravu označit tento incident jako vyřešený?", {
                title: "Potvrzení",
                okVariant: "primary",
                cancelVariant: "default",
                centered: true,
                okTitle: "Ano",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("incident/resolveIncident", incident.id)
                            .then(() => {
                                incident.solved = true
                                this.successMessage("Icident byl označen jako vyřešený.")
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

    },

}
</script>
