export default class SupplyPointMetaLastOrderEntity {

    constructor() {
        this.id = ''
        this.date = ''
        this.orderNumber = ''
        this.worker = ''
        this.includedRepairs = []
    }

    /**
     * @param response : object
     * @returns SupplyPointMetaLastOrderEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.date = response.date
        obj.orderNumber = response.orderNumber
        obj.worker = response.worker
        obj.includedRepairs = response.includedRepairs
        return obj
    }

}
