import PlanningApplianceEntity from "./PlanningApplianceEntity"
import SubjectContactResponse from "../../Subject/Entity/SubjectContactResponse"

export default class PlanningEntity {

    constructor() {
        this.id = ''
        this.supplyPoint = {
            id: null,
            ean: '',
            commodity: 1,
            address: '',
            serviceContract: 1,
            activeContract: false,
            contractCustomId: null,
            contractMasterId: null,
            activeOrder: null,
            validDiscount: null
        }
        this.customer = {
            id: null,
            name: '',
            wholeName: '',
            type: null,
            birthDate: null,
            identificationNumber: null,
            contacts: [],
        }
        this.propertyOwner = {
            id: null,
            name: '',
            wholeName: '',
            type: null,
            birthDate: null,
            identificationNumber: null,
            contacts: [],
        }
        this.nearestRepairDate = null
        this.createdAt = ''
        this.lastUpdateAt = null
        this.ignoreUntilDate = null
        this.appliances = []
    }

    /**
     * @param response : object
     * @returns PlanningEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.supplyPoint.id = response.supplyPoint.id
        obj.supplyPoint.ean = response.supplyPoint.ean
        obj.supplyPoint.commodity = response.supplyPoint.commodity
        obj.supplyPoint.address = response.supplyPoint.address

        obj.supplyPoint.serviceContract = response.supplyPoint.serviceContract
        obj.supplyPoint.activeContract = response.supplyPoint.activeContract
        obj.supplyPoint.contractCustomId = response.supplyPoint.contractCustomId
        obj.supplyPoint.contractMasterId = response.supplyPoint.contractMasterId
        obj.supplyPoint.activeOrder = response.supplyPoint.activeOrder
        obj.supplyPoint.validDiscount = response.supplyPoint.validDiscount

        obj.customer.id = response.customer.id
        obj.customer.name = response.customer.name
        obj.customer.wholeName = response.customer.wholeName
        obj.customer.type = response.customer.type
        obj.customer.birthDate = response.customer.birthDate
        obj.customer.identificationNumber = response.customer.identificationNumber
        obj.customer.contacts = response.customer.contacts.map(contact => SubjectContactResponse.from(contact))

        obj.propertyOwner.id = response.propertyOwner.id
        obj.propertyOwner.name = response.propertyOwner.name
        obj.propertyOwner.wholeName = response.propertyOwner.wholeName
        obj.propertyOwner.type = response.propertyOwner.type
        obj.propertyOwner.birthDate = response.propertyOwner.birthDate
        obj.propertyOwner.identificationNumber = response.propertyOwner.identificationNumber
        obj.propertyOwner.contacts = response.propertyOwner.contacts.map(contact => SubjectContactResponse.from(contact))

        obj.nearestRepairDate = response.nearestRepairDate
        obj.createdAt = response.createdAt
        obj.lastUpdateAt = response.lastUpdateAt
        obj.ignoreUntilDate = response.ignoreUntilDate

        obj.appliances = response.appliances.map(appliance => PlanningApplianceEntity.from(appliance))

        return obj
    }

}
