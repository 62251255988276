<template>

    <div>

        <div class="mb-4">
            <div class="d-flex justify-content-between text-align-center">
                <BButtonGroup>
                    <BButton variant="primary" @click="filterActive = !filterActive">
                        <i class="fad fa-filter mr-2"/> <span class="font-weight-500">Filtr</span>
                    </BButton>
                    <BButton variant="danger" @click="resetFilters" v-if="isFilterUsed">
                        <i class="far fa-times"/>
                    </BButton>
                </BButtonGroup>
            </div>

            <BCollapse :visible="filterActive || isFilterUsed" class="bg-little-gray p-4 mt-4">
                <BRow class="mb-3">
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Odběrné místo:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-supplyPoint"
                        >
                            <BFormInput
                                id="filter-supplyPoint"
                                size="sm"
                                v-model="filterRequest.filter.supplyPoint"
                                v-bind:class="{'filter-used': filterRequest.filter.supplyPoint !== ''}"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Zákazník:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-customer"
                        >
                            <BFormInput
                                id="filter-customer"
                                v-model="filterRequest.filter.customer"
                                size="sm"
                                v-bind:class="{'filter-used': filterRequest.filter.customer !== ''}"
                                @change="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                </BRow>
                <BRow>
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Účinnost:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-effective"
                        >
                            <DatePicker
                                id="filter-effective"
                                type="date"
                                format="DD.MM.YYYY"
                                value-type="YYYY-MM-DD"
                                placeholder="Vybrat datum"
                                input-class="form-control form-control-sm"
                                v-model="filterRequest.filter.effectiveFrom"
                                range
                                :show-second="false"
                                @input="filterUpdated"
                            />
                        </BFormGroup>
                    </BCol>
                    <BCol>
                        <BFormGroup
                            class="mb-0"
                            label-size="sm"
                            label-cols-sm="5"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label="Typ:"
                            label-align-sm="right"
                            label-class="font-weight-700"
                            label-for="filter-type"
                        >
                            <BFormSelect
                                v-model="filterRequest.filter.type"
                                name="type"
                                id="input-type"
                                @input="filterUpdated"
                                size="sm"
                            >
                                <BFormSelectOption :value="null">Vybrat</BFormSelectOption>
                                <BFormSelectOption :value="'individual'">Individuální</BFormSelectOption>
                                <BFormSelectOption :value="'fixed_contract'">Fixovaná smlouva</BFormSelectOption>
                                <BFormSelectOption :value="'service_contract'">Servisní smlouva</BFormSelectOption>
                            </BFormSelect>
                        </BFormGroup>
                    </BCol>
                </BRow>
            </BCollapse>

        </div>

        <div>

            <BTable
                ref="table"
                :fields="fields"
                :busy="isLoading"
                :current-page.sync="filterRequest.currentPage"
                :per-page.sync="filterRequest.perPage"
                :sort-by.sync="filterRequest.sortBy"
                :sort-desc.sync="filterRequest.sortDesc"
                :items="myProvider"
                tbody-tr-class="border-bottom"
                thead-tr-class="border-bottom"
                hover
                sort-icon-left
                borderless
                class="mb-0"
                no-sort-reset
                show-empty
            >
                <template v-slot:table-busy>
                    <TableLoading/>
                </template>
                <template v-slot:empty>
                    <TableWithoutRecords/>
                </template>
                <template v-slot:cell(type)="data">
                    <span v-if="data.item.type === 'individual'">Individuální</span>
                    <span v-if="data.item.type === 'fixed_contract'">Fixovaná smlouva</span>
                    <span v-if="data.item.type === 'service_contract'">Servisní smlouva</span>
                </template>
                <template v-slot:cell(action)="data">
                    <RouterLink
                        :to="'/discounts/' + data.item.id"
                        class="btn btn-primary btn-sm"
                    >
                        <i class="fal fa-eye"/>
                    </RouterLink>
                </template>
            </BTable>
            <div class="mt-3">
                <strong>Celkem záznamů:</strong> {{ totalRecords }}
            </div>

            <TablePaginator :total-records="totalRecords" :filter-request="filterRequest" hide-go-to-end-buttons/>

        </div>

    </div>

</template>

<script>
import {mapGetters} from "vuex"
import SimpleFormRequest from "../../../NewModel/Common/SimpleFormRequest"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TablePaginator from "@/Module/Common/UI/Table/TablePaginator.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"
import Vue from "vue"

export default {

    components: {
        TableLoading,
        TablePaginator,
        TableWithoutRecords,
    },

    data() {
        return {
            filterRequest: this.buildFilterRequest(),
            filterActive: false,
            fields: [
                {
                    key: 'supplyPoint',
                    label: 'Odběrné místo',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle',
                    formatter: value => {
                        return value.ean
                    },
                },
                {
                    key: 'customer',
                    label: 'Zákazník',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle',
                },
                {
                    key: 'effectiveFrom',
                    label: 'Účinnost OD',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle',
                    formatter: value => {
                        return Vue.filter('prettyDate')(value)
                    },
                },
                {
                    key: 'effectiveTo',
                    label: 'Účinnost DO',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle',
                    formatter: value => {
                        return Vue.filter('prettyDate')(value)
                    },
                },
                {
                    key: 'type',
                    label: 'Typ slevy',
                    sortable: true,
                    thClass: 'font-weight-700',
                    class: 'align-middle',
                },
                {
                    key: 'action',
                    label: 'Akce',
                    thClass: 'font-weight-700',
                    class: 'text-right align-middle w-10',
                },
            ],
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isLoading: 'discount/isLoading',
            records: 'discount/getDiscounts',
            totalRecords: 'discount/getTotalDiscounts',
        }),
        gridName: () => '_discountGridFilter',
        isFilterUsed() {
            return this.filterRequest.filter.supplyPoint !== ''
                || this.filterRequest.filter.customer !== ''
                || this.filterRequest.filter.effectiveFrom.length !== 0
                || this.filterRequest.filter.type !== null
        },
    },

    created() {
        if (this.$cookies.isKey(this.gridName) && this.user.filterSave) {
            this.filterRequest = SimpleFormRequest.restore(this.$cookies.get(this.gridName))
        }
    },

    mounted() {
        this.$store.dispatch("discount/clearAll")
        this.filterUpdated()
    },


    methods: {

        buildFilterRequest() {
            return new SimpleFormRequest({
                supplyPoint: '',
                customer: '',
                effectiveFrom: [],
                type: null,
            }, "id", null)
        },

        gridUpdated(ctx) {
            if (ctx) {
                this.filterRequest.sortBy = ctx.sortBy
                this.filterRequest.sortDesc = ctx.sortDesc
                this.saveFilter()
            }
        },

        saveFilter() {
            if (this.user.filterSave) {
                this.$cookies.set(this.gridName, this.filterRequest.store())
            }
        },

        filterUpdated(resetPage = false) {
            if (resetPage) {
                this.filterRequest.currentPage = 1
            }
            this.saveFilter()
            this.$refs.table.refresh()
        },

        myProvider(ctx, callback) {
            let items = []
            this.$store.dispatch("discount/clearAll")
            this.$store.dispatch("discount/getFiltered", this.filterRequest)
                .then((response) => {
                    callback(response)
                })
                .catch(() => {
                    callback([])
                })
            return items || []
        },

        resetFilters() {
            this.filterActive = false
            this.filterRequest = this.buildFilterRequest()
            this.filterUpdated()
        },

    },

}
</script>
