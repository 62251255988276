import AuthService from "./AuthService"
import store from "../../store"
import AuthUser from "./AuthUser"

export default {

    namespaced: true,

    state: {
        isLoading: false,
        error: null,
        user: null,
        isLoggedIn: false,
        token: null
    },

    actions: {

        /**
         * @param commit
         * @param request : AuthRequest
         * @returns {Promise<*>}
         */
        async login({commit}, request) {
            try {
                commit("LOGIN")
                let response = await AuthService.login(request)
                commit("LOGIN_SUCCESS")
                store.dispatch("auth/attempt", response.data.token)
                return response.data
            } catch (error) {
                commit("LOGIN_ERROR", error)
                return Promise.reject(error)
            }
        },

        async attempt({commit, state}, token) {
            if (token) {
                commit("SET_TOKEN", token)
            }
            if (!state.token) {
                return
            }
            try {
                let response = await AuthService.getUser()
                commit("SET_USER", AuthUser.buildFromResponse(response.data))
            } catch (e) {
                commit("SET_TOKEN", null)
                commit("SET_USER", null)
            }
        },
        async getUser({commit}) {
            try {
                let response = await AuthService.getUser()
                commit("SET_USER", AuthUser.buildFromResponse(response.data))
            } catch (e) {
                commit("SET_TOKEN", null)
                commit("SET_USER", null)
            }
        },
        async logout({commit}) {
            return await AuthService.logout().then(() => {
                commit("SET_TOKEN", null)
                commit("SET_USER", null)
            })
        }
    },
    mutations: {
        ["SET_USER"](state, user) {
            state.user = user
            state.isLoggedIn = !!user
        },
        ["SET_TOKEN"](state, token) {
            state.token = token
            localStorage.setItem("token", token)
        },
        ["LOGIN"](state) {
            state.isLoading = true
            state.error = null
        },
        ["LOGIN_SUCCESS"](state) {
            state.isLoading = false
        },
        ["LOGIN_ERROR"](state, error) {
            state.isLoading = false
            state.error = error
        }
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        getUser(state) {
            return state.user
        }
    }

}
