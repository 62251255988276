<template>
    <button class="btn btn-primary">
        <slot name="default">
            <i class="mr-3" :class="icon" v-if="icon"/>
            <span v-if="text">{{ text }}</span>
        </slot>
    </button>
</template>

<script>
export default {

    props: {
        icon: {
            type: String,
            required: false,
            default: '',
        },
        text: {
            type: String,
            required: false,
            default: "",
        },
    },

}
</script>
