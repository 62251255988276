import AttachmentStore from "./Store/AttachmentStore"
import IssuedMaterialStore from "./Store/IssuedMaterialStore"
import MaterialStore from "./Store/IssuedMaterialStore"
import ItemStore from "./Store/ItemStore"
import WorkerService from "../WorkerService"
import WorkerStatusStore from "./Store/WorkerStatusStore"
import MapStore from "./Store/MapStore"

const
    FETCHING_ORDER = "FETCHING_ORDER",
    FETCHING_ORDER_SUCCESS = "FETCHING_ORDER_SUCCESS",
    FETCHING_ORDER_ERROR = "FETCHING_ORDER_ERROR",
    SET_ORDER = "SET_ORDER",
    CLEAR_ORDER = "CLEAR_ORDER"

const initialState = {
    isLoading: false,
    error: null,
    order: null
}

export const state = {...initialState}

export const actions = {

    async getOrder({commit}, id) {
        try {
            commit(FETCHING_ORDER)
            let response = await WorkerService.getOrder(id)
            commit(FETCHING_ORDER_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(FETCHING_ORDER_ERROR, error)
            return Promise.reject(error)
        }
    },

    setEntity({commit}, entity) {
        commit(SET_ORDER, entity)
    },

    clear({commit}) {
        commit(CLEAR_ORDER)
    },

}

export const mutations = {

    [FETCHING_ORDER](state) {
        state.isLoading = true
        state.error = null
        state.order = null
    },
    [FETCHING_ORDER_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.order = data
    },
    [FETCHING_ORDER_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.order = null
    },

    [CLEAR_ORDER](state) {
        state.isLoading = false
        state.error = null
        state.order = null
    },

    [SET_ORDER](state, entity) {
        state.order = entity
    },

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasOrder(state) {
        return state.order !== null
    },
    getOrder(state) {
        return state.order
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        attachment: AttachmentStore,
        issuedMaterial: IssuedMaterialStore,
        materials: MaterialStore,
        item: ItemStore,
        workerStatus: WorkerStatusStore,
        map: MapStore,
    }
}
