export default class ApplianceTypeRequest {

    constructor() {
        this.name = ""
        this.commodity = 1
        this.active = true
        this.repairTypes = []
    }

    /**
     * @param response : ApplianceTypeEntity
     * @returns ApplianceTypeRequest
     */
    static fromEntity(response) {

        let req = new this
        req.name = response.name
        req.commodity = response.commodity
        req.active = response.active
        req.repairTypes = response.repairTypes
        return req
    }

    toForm() {
        return this
    }

}
