export default class DiscountSimpleEntity {

    constructor() {
        this.id = null
        this.supplyPoint = {
            id: null, ean: '', commodity: null, address: '',
        }
        this.customer = ''
        this.propertyOwner = ''
        this.effectiveFrom = ''
        this.effectiveTo = null
        this.type = ''
        this.serviceContract = null
        this.contract = null
        this.generalAmount = 0
        this.createdAt = ''
        this.createdBy = ''
        this.updatedAt = ''
        this.updatedBy = ''
    }

    /**
     * @param response : object
     * @returns DiscountSimpleEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.supplyPoint.id = response.supplyPoint.id
        obj.supplyPoint.ean = response.supplyPoint.ean
        obj.supplyPoint.commodity = response.supplyPoint.commodity
        obj.supplyPoint.address = response.supplyPoint.address
        obj.customer = response.customer
        obj.propertyOwner = response.propertyOwner
        obj.effectiveFrom = response.effectiveFrom
        obj.effectiveTo = response.effectiveTo
        obj.type = response.type
        obj.serviceContract = response.serviceContract
        obj.contract = response.contract
        obj.generalAmount = response.generalAmount
        obj.createdAt = response.createdAt
        obj.createdBy = response.createdBy
        obj.updatedAt = response.updatedAt
        obj.updatedBy = response.updatedBy
        return obj
    }

}
