export default class ApplianceModelRequest {

    constructor() {
        this.name = ""
        this.manufacturer = null
        this.description = ""
        this.active = true
        this.commodity = 1
        this.attachments = []
    }

    /**
     * @param response : ApplianceModelEntity
     * @returns ApplianceModelRequest
     */
    static fromEntity(response) {

        let req = new this
        req.name = response.name
        req.manufacturer = response.manufacturer.id
        req.description = response.description
        req.active = response.active
        req.commodity = response.commodity
        return req
    }

    toForm() {
        return {
            name: this.name,
            manufacturer: this.manufacturer,
            description: this.description,
            active: this.active,
            commodity: this.commodity,
            attachments: this.attachments.map(attachment => attachment.id)
        }
    }

}
