export default class DashboardOrderReview {

    constructor() {
        this.numberOfConcepts = 0
        this.numberOfTodayOrders = 0
        this.numberOfThisWeekOrders = 0
        this.numberOfMyTodayOrders = 0
    }

    /**
     * @param response
     * @returns {DashboardOrderReview}
     */
    static buildFromResponse(response) {
        let entity = new this
        entity.numberOfConcepts = response.numberOfConcepts
        entity.numberOfTodayOrders = response.numberOfTodayOrders
        entity.numberOfThisWeekOrders = response.numberOfThisWeekOrders
        entity.numberOfMyTodayOrders = response.numberOfMyTodayOrders
        return entity
    }

}
