import OrderService from "../OrderService"

const
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    DELETING_RECORD = "DELETING_RECORD",
    DELETING_RECORD_SUCCESS = "DELETING_RECORD_SUCCESS",
    DELETING_RECORD_ERROR = "DELETING_RECORD_ERROR",
    CLEAR_RECORDS = "CLEAR_RECORDS",
    CLEAR_RECORD = "CLEAR_RECORD"

const initialState = {
    isLoading: false,
    isCreating: false,
    isDeleting: false,
    record: null,
    records: [],
    error: null
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<any>}
     */
    async getMaterials({commit}, id) {
        try {
            commit(FETCHING_RECORDS)
            let response = await OrderService.getOrderMaterials(id)
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param request : OrderMaterialPickupRequest
     * @returns {Promise<*>}
     */
    async createMaterial({commit}, {id, request}) {
        try {
            commit(CREATING_RECORD)
            let response = await OrderService.createOrderMaterials(id, request)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param order : string
     * @param orderMaterial : string
     * @returns {Promise<*>}
     */
    async cancelMaterial({commit}, {order, orderMaterial}) {
        try {
            commit(DELETING_RECORD)
            let response = await OrderService.cancelOrderMaterial(order, orderMaterial)
            commit(DELETING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(DELETING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

    async clear({commit}) {
        commit(CLEAR_RECORD)
    }

}

export const mutations = {

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.records = data
    },
    [FETCHING_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
    },

    [CREATING_RECORD](state) {
        state.isCreating = true
        state.error = null
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isCreating = false
        state.error = null
        state.record = data
    },
    [CREATING_RECORD_ERROR](state, error) {
        state.isCreating = false
        state.error = error
    },

    [DELETING_RECORD](state) {
        state.isCreating = true
        state.error = null
    },
    [DELETING_RECORD_SUCCESS](state) {
        state.isCreating = false
        state.error = null
    },
    [DELETING_RECORD_ERROR](state, error) {
        state.isCreating = false
        state.error = error
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.error = null
        state.records = []
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.error = null
        state.record = null
    },

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    isCreating(state) {
        return state.isCreating
    },
    isDeleting(state) {
        return state.isDeleting
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasMaterials(state) {
        return state.records.length > 0
    },
    getMaterials(state) {
        return state.records
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
