<template>
    <div class="py-5">
        <div class="text-center">
            <i class="fad fa-10x fa-check-circle text-success"/>
            <div class="mt-5">
                <h1 class="display-1 mb-3">Zakázka byla uzavřena.</h1>
                <div class="display-4">
                    Díky moc!
                </div>
                <div class="mt-5">
                    <PrimaryLinkButton :to="{ name: 'order_show'}" text="Zobrazit detail"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PrimaryLinkButton from "../../Module/Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        PrimaryLinkButton,
    },

    computed: {
        ...mapGetters({
            order: 'order/getOrder',
        }),
    },

}
</script>
