export default class RepairTypeRequest {

    constructor() {
        this.parent = null
        this.name = ""
        this.shortName = ""
        this.description = ""
        this.type = 1
        this.repeatAfterMonths = null
        this.commodity = 1
        this.active = true
    }

    static from(response) {

        let req = new this
        req.parent = response.parent
        req.name = response.name
        req.shortName = response.shortName
        req.description = response.description || ""
        req.type = response.type
        req.repeatAfterMonths = response.repeatAfterMonths
        req.commodity = response.commodity
        req.active = response.active
        return req
    }

    toForm() {
        return this
    }

}
