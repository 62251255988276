<template>
    <BModal
        id="modal-add-material"
        header-bg-variant="primary"
        header-text-variant="white"
        footer-bg-variant="light"
        footer-text-variant="dark"
        size="xl"
        body-class="p-4"
        no-stacking
    >
        <template #modal-header>
            <div class="p-3">
                <h4 class="display-4 mb-0">
                    <i class="fa-light fa-cog fa-fw mr-3"/> Přidat spotřebu materiálu
                </h4>
            </div>
        </template>
        <BOverlay :show="material_isLoading">
            <BForm ref="addMaterial" @submit.prevent="handleSubmit">

                <BFormGroup
                    id="input-group-1"
                    label-for="input-1"
                    description="Naskenujte čárový kód"
                    label-class="font-weight-600"
                >
                    <template v-slot:label>
                        EAN kód: <span class="text-danger ml-1">*</span>
                    </template>

                    <BInputGroup>
                        <BFormInput
                            id="input-1"
                            v-model="request.eanCode"
                            type="text"
                            required
                            @change="loadMaterial"
                        />
                        <BInputGroupAppend>
                            <BButton
                                variant="primary"
                                @click="scannerEnabled = !scannerEnabled"
                            >
                                <i class="fad fa-camera-alt fa-fw text-warning"/>
                            </BButton>
                        </BInputGroupAppend>
                    </BInputGroup>
                </BFormGroup>

                <StreamBarcodeReader @decode="onDecode" v-if="scannerEnabled" class="mb-3"/>

                <div v-if="!materialExists">

                    <div class="table-info p-2 border-radius-sm text-left mb-3">
                        Materiál s tímto EAN kódem neexistuje. Vyfotografujte prosím štítek produktu.
                    </div>

                    <BFormGroup
                        class="mb-0"
                        id="input-group-2"
                        label="Štítek:"
                        label-for="attachments"
                        label-class="font-weight-600"
                    >
                        <AttachmentUpload :is-multiple="false" v-on:uploaded="attachmentUploaded"/>
                    </BFormGroup>

                    <ul>
                        <li v-for="attachment in request.attachments" v-bind:key="attachment.id">
                            {{ attachment.name }}
                        </li>
                    </ul>

                </div>

                <BFormGroup
                    id="input-group-2"
                    label="Název:"
                    label-for="input-2"
                    label-class="font-weight-600"
                    v-if="materialExists"
                >
                    <BFormInput
                        id="input-2"
                        v-model="request.itemName"
                        type="text"
                        readonly
                    />
                </BFormGroup>

                <BFormGroup
                    id="input-group-3"
                    label="Specifikace:"
                    label-for="input-3"
                    label-class="font-weight-600"
                >
                    <BFormTextarea
                        id="input-3"
                        v-model="request.specification"
                    />
                </BFormGroup>

                <BFormGroup
                    id="input-group-4"
                    label-for="input-4"
                    label-class="font-weight-600"
                >
                    <template v-slot:label>
                        Množství: <span class="text-danger ml-1">*</span>
                    </template>
                    <BFormInput
                        type="number"
                        min="1"
                        max="999"
                        id="input-4"
                        v-model="request.quantity"
                    />
                </BFormGroup>

            </BForm>
        </BOverlay>
        <template #modal-footer>
            <BButton
                type="submit"
                variant="secondary"
                :disabled="isCreating"
                @click="handleSubmit"
            >
                <i class="fa-light fa-floppy-disk fa-fw mr-3"/>Uložit
            </BButton>
        </template>
    </BModal>
</template>

<script>
import {mapGetters} from "vuex"
import {StreamBarcodeReader} from "vue-barcode-reader"
import AttachmentUpload from "../../../../Components/General/AttachmentUpload.vue"
import OrderMaterialPickupRequest from "@/NewModel/Order/Request/OrderMaterialPickupRequest";

export default {

    components: {
        AttachmentUpload,
        StreamBarcodeReader,
    },

    props: {
        order: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isCreating: "order/material/material/isCreating",
            material_isLoading: "material/search/isLoading",
        }),
    },

    data() {
        return {
            scannerEnabled: false,
            request: new OrderMaterialPickupRequest(),
            form: {
                eanCode: null,
                name: "",
                specification: "",
                quantity: 1,
                attachments: [],
            },
            materialExists: false,
        }
    },

    methods: {

        onDecode(result) {
            if (result && result.length > 3) {
                this.request.eanCode = result
                this.scannerEnabled = false
                this.loadMaterial()
            }
        },

        checkFormValidity() {
            return this.$refs.addMaterial.checkValidity()
        },

        handleSubmit() {
            let self = this
            this.$store.dispatch("order/material/material/bookMaterial", {
                id: this.order,
                request: this.request,
            })
                .then((response) => {
                    self.$emit("created", response)
                    self.request = new OrderMaterialPickupRequest()
                    this.successMessage("Úspěšně zpracováno.")
                    this.$root.$emit("bv::hide::modal", "modal-add-material")
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        loadMaterial() {
            if (this.request.eanCode.length >= 3) {
                this.materialExists = false
                let self = this
                this.$store.dispatch("material/search/findByEanCode", {code: this.request.eanCode})
                    .then((response) => {
                        self.materialExists = true
                        self.request.itemName = response.name
                    })
            }
        },

        attachmentUploaded(files) {
            let self = this
            files.forEach(function (file) {
                self.request.attachments.push(file)
            })
        },

    },

}
</script>
