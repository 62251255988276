<template>
    <div>

        <Header icon="fad fa-user-circle" title="Vyhledání klienta"/>

        <SearchClientForm @typed="typed" class="mb-4"/>

        <div class="p-3 bg-white border-radius-sm mb-4">
            <h5 class="font-weight-600 text-dark mb-3">
                <i class="fad fa-search fa-fw mr-2"/>
                Výsledky vyhledávání
                <span class="font-weight-500" v-if="searchText.length !== 0">: {{ searchText }}</span>
            </h5>

            <SearchClientTable v-bind:clients="clients"/>

            <div class="p-4 d-flex align-content-center justify-content-center"
                 v-if="!haveClients && searchText.length === 0">
                <span class="font-weight-light h4">Začněte tím, že do formuláře identifikujete klienta ...</span>
            </div>

            <div class="p-4 d-flex align-content-center justify-content-center" v-if="isLoading">
                <i class="fad fa-spinner fa-spin fa-4x text-primary"/>
            </div>

            <div class="p-3 d-flex align-content-center justify-content-center font-weight-light h5"
                 v-if="searchText.length !== 0 && !loading"
            >
                <div class="text-center">
                    <span v-if="!haveClients" class="font-weight-light h5">
                          Výskyt <strong>{{ searchText }}</strong> se nám nepodařilo dohledat...
                    </span>
                    <br/><br/><br/>
                    <PrimaryLinkButton
                        to="/supply_points/create?return_type=order"
                        icon="fad fa-user-secret"
                        text="Vytvořit OM"
                        class="btn-lg"
                    />
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import {debounce} from "../../NewModel/Common/Helpers/Debounce"
import {mapGetters} from "vuex"
import Header from "../../Module/Common/UI/Header.vue"
import PrimaryLinkButton from "../../Module/Common/UI/Button/PrimaryLinkButton.vue"
import SearchClientForm from "../../Components/Order/SearchClientForm"
import SearchClientTable from "../../Components/Order/SearchClientTable"

export default {

    components: {
        Header,
        PrimaryLinkButton,
        SearchClientForm,
        SearchClientTable,
    },

    data() {
        return {
            searchText: "",
            loading: false,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: "supplyPoint/searchClients/isLoading",
            haveClients: "supplyPoint/searchClients/hasClients",
            clients: "supplyPoint/searchClients/getClients",
        }),
    },

    watch: {
        searchText: debounce(function (txt) {
            this.$store.dispatch("supplyPoint/searchClients/searchByQuery", txt)
                .finally(() => {
                    this.loading = false
                })
        }, 750),
    },

    methods: {

        typed: function (text) {
            if (text.length > 2) {
                this.loading = true
                this.searchText = text
            }
        },

    },

}
</script>
