<template>
    <nav class="d-flex justify-content-center mt-3" v-if="totalRecords > filterRequest.perPage">
        <BPagination
            v-model="filterRequest.currentPage"
            :total-rows="totalRecords"
            :per-page="filterRequest.perPage"
            prev-text="Předchozí"
            next-text="Následující"
            :hide-goto-end-buttons="hideGoToEndButtons"
            class="mb-0"
        />
    </nav>
</template>

<script>
import SimpleFilterRequest from "../../../../NewModel/Common/SimpleFormRequest"

export default {

    props: {
        filterRequest: {
            type: SimpleFilterRequest,
            required: true,
        },
        totalRecords: {
            type: Number,
            required: true,
        },
        hideGoToEndButtons: {
            type: Boolean,
            default: false,
        },
    },

}
</script>
