<template>
    <div class="container">

        <Header title="Nové odběrné místo">
            <template #buttons>
                <BackLinkButton :to="{name: 'supply_point_list'}"/>
            </template>
        </Header>

        <SupplyPointForm
            :isProcessing="isProcessing"
            v-on:submitted="onSubmitted"
        />

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../Common/UI/Button/BackLinkButton.vue"
import Header from "../Common/UI/Header.vue"
import SupplyPointForm from "./Component/SupplyPointForm.vue"

export default {

    components: {
        BackLinkButton,
        Header,
        SupplyPointForm,
    },

    computed: {
        ...mapGetters({
            isProcessing: 'supplyPoint/isProcessing',
        }),
    },

    created() {
        this.$store.dispatch("supplyPoint/clear")
    },

    methods: {

        /**
         * @param request : SupplyPointRequest
         */
        onSubmitted(request) {
            this.$store.dispatch("supplyPoint/createSupplyPoint", request)
                .then((supplyPoint) => {
                    this.successMessage('Úspěšně uloženo!')
                    if (this.$route.query.return_type === "order") {
                        this.$router.push("/supply_points/" + supplyPoint.id + "/show")
                    } else {
                        this.$router.push("/supply_points/" + supplyPoint.id + "/show")
                    }
                })
                .catch((error) => {
                        this.warningMessage(error.response.data.message)
                    }
                )
        },

    },

}
</script>
