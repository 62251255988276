import ApplianceService from "./ApplianceService"
import ApplianceAttachmentsStore from "./Store/ApplianceAttachmentsStore"
import ApplianceIssuesStore from "./Store/ApplianceIssuesStore"
import ApplianceRepairsMapStore from "./Store/ApplianceRepairsMapStore"
import ApplianceRepairsStore from "./Store/ApplianceRepairsStore"
import ApplianceTypeStore from "./Type/ApplianceTypeStore"
import ApplianceModelStore from "./Model/ApplianceModelStore"
import ApplianceModelTypeStore from "./ModelType/ApplianceModelTypeStore"
import ApplianceManufacturerStore from "./Manufacturer/ApplianceManufacturerStore"
import ApplianceEntity from "@/NewModel/Appliance/Entity/ApplianceEntity"
import ApplianceFilterEntity from "@/NewModel/Appliance/Entity/ApplianceFilterEntity"

const
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    FETCHING_FILTERED_RECORDS = "FETCHING_FILTERED_RECORDS",
    FETCHING_FILTERED_RECORDS_SUCCESS = "FETCHING_FILTERED_RECORDS_SUCCESS",
    FETCHING_FILTERED_RECORDS_ERROR = "FETCHING_FILTERED_RECORDS_ERROR",
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    FETCHING_RECORDS = "FETCHING_RECORDS",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    UPDATING_RECORD = "CREATING_RECORD",
    UPDATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    DELETING_RECORD = "DELETING_RECORD",
    DELETING_RECORD_SUCCESS = "DELETING_RECORD_SUCCESS",
    DELETING_RECORD_ERROR = "DELETING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isLoading: false,
    isProcessing: false,
    record: null,
    records: [],
    totalRecords: 0,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param request : SimpleFormRequest
     * @returns {Promise<*>}
     */
    async getFilteredRecords({commit}, request) {
        try {
            commit(FETCHING_FILTERED_RECORDS)
            let response = await ApplianceService.filterAppliances(request)
            commit(FETCHING_FILTERED_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_FILTERED_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @returns {Promise<[]>}
     */
    async findAllActiveAppliances({commit}) {
        try {
            commit(FETCHING_RECORDS)
            let response = await ApplianceService.findAllActiveAppliances()
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },
    /**
     * @param commit
     * @param params
     * @returns {Promise<[]>}
     */
    async filter({commit}, params) {
        try {
            commit(FETCHING_FILTERED_RECORDS)
            let response = await ApplianceService.filter(params)
            commit(FETCHING_FILTERED_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_FILTERED_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @returns {Promise<*>}
     */
    async getAppliance({commit}, id) {

        try {
            commit(FETCHING_RECORD)
            let response = await ApplianceService.getAppliance(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @param request : ApplianceRequest
     * @returns {Promise<*>}
     */
    async updateAppliance({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await ApplianceService.updateAppliance(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @returns {Promise<*>}
     */
    async deleteAppliance({commit}, id) {
        try {
            commit(DELETING_RECORD)
            let response = await ApplianceService.deleteAppliance(id)
            commit(DELETING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(DELETING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @returns {Promise<*>}
     */
    async dismountAppliance({commit}, id) {
        try {
            commit(UPDATING_RECORD)
            let response = await ApplianceService.dismountAppliance(id)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    async clear({commit}) {
        commit(CLEAR_RECORD)
    },

    async clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

}

export const mutations = {

    [FETCHING_FILTERED_RECORDS](state) {
        state.isLoading = true
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_FILTERED_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.records.map(appliance => ApplianceFilterEntity.from(appliance))
        state.totalRecords = data.totalRecords
    },
    [FETCHING_FILTERED_RECORDS_ERROR](state) {
        state.isLoading = false
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.map(record => ApplianceEntity.from(record))
        state.totalRecords = data.length
    },
    [FETCHING_RECORDS_ERROR](state) {
        state.isLoading = false
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_RECORD](state) {
        state.isLoading = true
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.record = ApplianceEntity.from(data)
    },
    [FETCHING_RECORD_ERROR](state) {
        state.isLoading = false
    },

    [CREATING_RECORD](state) {
        state.isProcessing = true
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isProcessing = false
        state.record = ApplianceEntity.from(data)
    },
    [CREATING_RECORD_ERROR](state) {
        state.isProcessing = false
    },

    [UPDATING_RECORD](state) {
        state.isProcessing = true
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isProcessing = false
        state.record = ApplianceEntity.from(data)
    },
    [UPDATING_RECORD_ERROR](state) {
        state.isProcessing = false
    },

    [DELETING_RECORD](state) {
        state.isProcessing = true
    },
    [DELETING_RECORD_SUCCESS](state) {
        state.isProcessing = false
    },
    [DELETING_RECORD_ERROR](state) {
        state.isProcessing = false
    },

    [CLEAR_RECORD](state) {
        state.isProcessing = false
        state.isLoading = false
        state.record = null
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.records = []
        state.totalRecords = 0
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },

    isProcessing(state) {
        return state.isProcessing
    },

    hasAppliance(state) {
        return state.record !== null
    },

    getAppliance(state) {
        return state.record
    },

    hasAppliances(state) {
        return state.records.length > 0
    },

    getAppliances(state) {
        return state.records
    },

    getTotalAppliances(state) {
        return state.totalRecords
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        attachments: ApplianceAttachmentsStore,
        issues: ApplianceIssuesStore,
        repairs: ApplianceRepairsStore,
        repairsMap: ApplianceRepairsMapStore,
        types: ApplianceTypeStore,
        manufacturers: ApplianceManufacturerStore,
        models: ApplianceModelStore,
        modelTypes: ApplianceModelTypeStore,
    }
}
