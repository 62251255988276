<template>

    <div class="bg-little-gray border-radius-sm px-3 mb-4">
        <div class="d-flex justify-content-between align-items-center border-light border-bottom py-3 pointer">
            <div>
                <i class="fad fa-clock fa-fw mr-2 text-primary"/>
                {{ {startTime: order.scheduledStartTime, endTime: order.scheduledEndTime}|prettyDateRange }}
            </div>
            <i class="fa fa-check fa-fw"
               :class="{
                    'text-success': order.workerStatus === 2,
                    'text-warning': order.workerStatus === 1,
                    'text-primary': order.workerStatus === 0,
                    'text-danger': order.workerStatus === 7
                }"
               style="font-size: 1.5em;"
               v-b-tooltip:hover
            />
        </div>
        <div class="d-flex justify-content-between align-items-center border-light border-bottom py-3 pointer">
            <div>
                <i class="fad fa-barcode fa-fw mr-2 text-primary"/>
                {{ order.ean }}
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center border-light border-bottom py-3 pointer">
            <div>
                <i class="fad fa-ballot fa-fw mr-2 text-primary"/>
                {{ order.orderNumber }}
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center border-light border-bottom py-3 pointer">
            <div>
                <i class="fad fa-map-marked fa-fw mr-2 text-primary"/>
                {{ order.address }}
            </div>
        </div>
        <div
            class="d-flex justify-content-between align-items-center border-light border-bottom py-3 pointer"
            v-if="order.customer"
        >
            <div>
                <i class="fad fa-user-circle fa-fw mr-2 text-primary"/>
                {{ order.customerName }}
            </div>
        </div>
    </div>

</template>

<script>
import NewOrderEntity from "../../../NewModel/Order/Entity/NewOrderEntity"

export default {

    props: {
        order: {
            type: NewOrderEntity,
            required: true,
        },
    },

}

</script>


