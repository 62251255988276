<template>
    <table class="table table-borderless mb-0 table-responsive">
        <thead>
        <tr>
            <th class="align-middle w-10 text-center">
                Datum
            </th>
            <th class="align-middle text-center w-20">
                Typ opravy
            </th>
            <th class="align-middle text-center w-10">
                Zakázka
            </th>
            <th class="align-middle text-center w-20">
                Pracovník
            </th>
            <th class="align-middle">
                Popis
            </th>
            <th class="align-middle">
                Soubory
            </th>
            <th class="align-middle text-right w-5"/>
        </tr>
        </thead>
        <tbody>
        <template v-if="hasRepairs && !isLoading">
            <tr
                class="border-top"
                v-for="(repair, repairIndex) in repairs"
                :key="'repair_' + repair.id"
            >
                <td class="align-middle text-center">
                    {{ repair.dateOfRepair|prettyDate }}
                </td>
                <td class="align-middle text-center">
                    {{ repair.repairType }}
                </td>
                <td class="align-middle text-center">
                    {{ repair.orderNumber }}
                </td>
                <td class="align-middle text-center">
                    {{ repair.worker }}
                </td>
                <td class="align-middle">
                    {{ repair.description }}
                </td>
                <td class="align-middle">
                    <div v-for="attachment in repair.attachments" v-bind:key="'attachment_' + attachment.id">
                        <AttachmentDownloadLink :id="attachment.id" :name="attachment.name" class="mr-2"/>
                    </div>
                </td>
                <td class="align-middle text-right w-5">
                    <BButton variant="danger" size="sm" v-on:click="deleteRepair(repair.id, repairIndex)">
                        <i class="fad fa-trash fa-fw"/>
                    </BButton>
                </td>
            </tr>
        </template>
        <tr class="border-top" v-else-if="isLoading && !hasRepairs">
            <td colspan="7">
                <TableLoading/>
            </td>
        </tr>
        <tr class="border-top" v-else>
            <td colspan="7">
                <TableWithoutRecords/>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceEntity from "../../../NewModel/Appliance/Entity/ApplianceEntity"
import AttachmentDownloadLink from "../../Common/Attachment/AttachmentDownloadLink.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        AttachmentDownloadLink,
        TableLoading,
        TableWithoutRecords,
    },

    props: {
        entity: {
            type: ApplianceEntity,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isLoading: 'appliance/repairs/isLoading',
            isDeleting: 'appliance/repairs/isDeleting',
            hasRepairs: 'appliance/repairs/hasRepairs',
            repairs: 'appliance/repairs/getRepairs',
        }),
    },

    created() {
        this.loadApplianceRepairs()
    },

    methods: {

        loadApplianceRepairs() {
            this.$store.dispatch("appliance/repairs/findRepairs", this.entity.id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        deleteRepair(repair, index) {
            let self = this
            this.$bvModal.msgBoxConfirm('Opravdu chcete odstranit tuto opravu?', {
                title: 'Potvrzení',
                okVariant: 'primary',
                cancelVariant: 'default',
                centered: true,
                okTitle: "Ano, odstranit.",
                cancelTitle: "Ne"
            }).then(value => {
                if (value === true) {
                    this.$store.dispatch("appliance/repairs/deleteRepair", {
                        appliance: this.entity.id,
                        repair: repair
                    })
                        .then((result) => {
                            if (result) {
                                this.successMessage('Úspěšně odstraněno!')
                                self.repairs.splice(index, 1)
                            } else {
                                this.warningMessage('Při odstranění opravy nastala chyba.')
                            }
                        })
                        .catch((error) => {
                            this.errorMessage(error.response.data.message)
                        })
                }
            })
        },

    },

}
</script>
