<template>
    <div class="vh-100 vw-100">
        <div class="h-100 w-100 d-flex justify-content-center align-items-center">
            <div class="bg-little-gray p-5">
                <div class="text-center mb-4">
                    <div class="px-3">
                        <img src="../../../Assets/logo.svg" class="img-fluid" style="max-width: 280px;">
                    </div>
                </div>
                <div class="p-3">
                    <LoginForm @onLoginSuccessfully="onLoginSuccessfully"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import LoginForm from "./Component/LoginForm.vue"

export default {

    components: {
        LoginForm,
    },

    computed: {
        ...mapGetters({
            loggedIn: 'auth/isLoggedIn',
        }),
    },

    created() {
        if (this.loggedIn) {
            this.$router.push({name: 'dashboard'})
        }
    },

    methods: {

        onLoginSuccessfully(data) {
            if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect)
            } else if (data.mobileVersion) {
                this.$router.push({name: 'new_mobile_home'})
            } else {
                this.$router.push({name: 'dashboard'})
            }
        },

    },

}
</script>
