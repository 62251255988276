<template>
    <div>
        <form ref="form" @submit.prevent="processForm()">

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="name" class="bold col-form-label">
                        Název druhu spotřebiče: <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormInput
                        v-model="request.name"
                        id="name"
                        type="text"
                        required
                        autocomplete="off"
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="commodity" class="bold col-form-label">
                        Komodita: <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormRadioGroup
                        v-model="request.commodity"
                        :options="list.commodity"
                        @change="loadRepairTypesItems()"
                        id="commodity"
                        name="commodity-select"
                        value-field="id"
                        text-field="text"
                        stacked
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="repair-type" class="bold col-form-label">
                        Přidružené typy oprav: <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <div class="alert alert-info p-4" v-if="isLoading">
                        <i class="fa fa-spinner fa-spin mr-4"/>Nahrávám typy oprav ...
                    </div>
                    <BFormCheckboxGroup
                        v-model="request.repairTypes"
                        :options="list.repairTypes"
                        value-field="id"
                        text-field="name"
                        stacked
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12"/>
                <BCol lg="9" md="12">
                    <BFormCheckbox v-model="request.active">
                        Aktivní
                    </BFormCheckbox>
                </BCol>
            </BRow>

            <BRow>
                <BCol lg="3" md="12"/>
                <BCol lg="9" md="12">
                    <BButton type="submit" variant="primary" :disabled="isProcessing">
                        <i class="fad fa-save mr-3"/>Uložit
                    </BButton>
                </BCol>
            </BRow>

        </form>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceTypeRequest from "../../../../NewModel/Appliance/Type/Request/ApplianceTypeRequest"
import Commodity from "../../../../Model/Enum/commodity"

export default {

    props: {
        request: {
            type: ApplianceTypeRequest,
            required: true,
        },
        isProcessing: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            selected: [],
            list: {
                commodity: Commodity.list(),
                repairTypes: [],
            },
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'repairType/isLoading',
        }),
    },

    created() {
        this.loadRepairTypesItems()
    },

    methods: {

        loadRepairTypesItems() {
            let self = this
            self.list.repairTypes = []
            this.$store.dispatch("repairType/clearAll")
            this.$store.dispatch("repairType/findAllActiveRepairTypes")
                .then((records) => {
                    self.list.repairTypes = records.filter(
                        repairType => repairType.type === 2 && repairType.commodity === self.request.commodity
                    )
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        processForm() {
            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Selhala validace. Byla všechna požadovaná pole vyplněna?')
                return
            }
            this.$emit('submitted', this.request)
        },

    },

}
</script>
