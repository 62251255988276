export default class OrderApplianceRepairRequest {

    constructor() {
        this.id = null
        this.repairType = null
        this.worker = null
        this.paid = true
        this.comment = ""
    }

    /**
     * @param response : object
     * @returns {OrderApplianceRepairRequest}
     */
    static from(response) {

        let request = new this
        request.id = response.id
        request.repairType = response.repairType
        request.worker = response.worker
        request.paid = response.paid
        request.comment = response.comment
        return request
    }

    toForm() {
        return {
            id: this.id,
            repairType: this.repairType,
            worker: this.worker,
            paid: this.paid,
            comment: this.comment,
        }
    }

}
