<template>
    <div>
        <BForm ref="form" class="mb-3" v-on:submit.prevent="processForm">
            <BInputGroup>
                <BFormTextarea
                    v-model="request.message"
                    id="communication-message"
                    placeholder="Nová poznámka"
                    required
                    rows="3"
                    no-resize
                />
                <BInputGroupAppend>
                    <BButton type="submit" variant="primary" :disabled="isCreating">
                        <i class="fad fa-save fa-fw mr-2"/>Uložit
                    </BButton>
                </BInputGroupAppend>
            </BInputGroup>
        </BForm>

        <div>

            <table class="table mb-0" v-if="hasNotes && !isLoading">
                <tbody>
                <tr
                    v-for="(communication, communicationIndex) in notes"
                    v-bind:key="'communication_' + communication.id"
                    v-bind:class="{'border-bottom pb-2 mb-2': communicationIndex + 1 !== notes.length }"
                >
                    <td class="w-25 font-weight-600">
                        {{ communication.createdAt|prettyDateTime }}<span
                        v-if="communication.createdBy">, {{ communication.createdBy.name }}</span>
                    </td>
                    <td>
                        {{ communication.message }}
                    </td>
                </tr>
                </tbody>
            </table>

            <TableLoading v-if="!hasNotes && isLoading"/>
            <TableWithoutRecords v-if="!hasNotes && !isLoading"/>

        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import SupplyPointNoteRequest from "../../../NewModel/SupplyPoint/Request/SupplyPointNoteRequest"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TableWithoutRecords,
    },

    props: {
        supplyPoint: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            request: new SupplyPointNoteRequest()
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'supplyPoint/notes/isLoading',
            isCreating: 'supplyPoint/notes/isCreating',
            hasNotes: 'supplyPoint/notes/hasNotes',
            notes: 'supplyPoint/notes/getNotes',
        })
    },

    created() {
        this.loadSupplyPointNotes()
    },

    methods: {

        loadSupplyPointNotes() {
            this.$store.dispatch("supplyPoint/notes/clearAll")
            this.$store.dispatch("supplyPoint/notes/getNotes", this.supplyPoint)
        },

        checkFormValidity() {
            return this.$refs.form.checkValidity()
        },

        processForm() {
            let self = this
            this.$store.dispatch("supplyPoint/notes/createNote", {
                id: this.supplyPoint,
                request: this.request
            })
                .then((communication) => {
                    self.notes.unshift(communication)
                    self.request.message = ""
                })
        },

    },

}
</script>
