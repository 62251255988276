<template>
    <table class="table table-borderless mb-0">
        <tbody>
        <tr>
            <td class="w-20 align-middle font-weight-600">
                Výrobce spotřebiče:
            </td>
            <td>
                {{ entity.manufacturer.name }}
            </td>
        </tr>
        <tr class="border-top border-light">
            <td class="w-20 align-middle font-weight-600">
                Model:
            </td>
            <td>
                {{ entity.name }}
            </td>
        </tr>
        <tr class="border-top border-light">
            <td class="w-20 align-middle font-weight-600">
                Popis:
            </td>
            <td>
                {{ entity.description }}
            </td>
        </tr>
        <tr class="border-top border-light">
            <td class="w-20 align-middle font-weight-600">
                Stav:
            </td>
            <td>
                {{ entity.active ? 'Aktivní' : 'Neaktivní' }}
            </td>
        </tr>
        <tr class="border-top border-light">
            <td class="w-20 font-weight-600">
                Dokumenty:
            </td>
            <td>
                <em>Bude dořešeno, zatím použijte přílohy pod tlačítkem "Upravit"</em>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import ApplianceModelEntity from "../../../../NewModel/Appliance/Model/Entity/ApplianceModelEntity"

export default {

    props: {
        entity: {
            type: ApplianceModelEntity,
            required: false,
            default: null,
        },
    },

}
</script>
