<template>
    <BTable
        borderless
        :busy="isLoading"
        :fields="fields"
        show-empty
        :items="myProvider"
        tbody-tr-class="border-top"
        thead-tr-class="border-bottom"
        table-class="mb-0 table-fit"
        small
    >

        <template v-slot:table-busy>
            <TableLoading/>
        </template>

        <template v-slot:empty>
            <TableWithoutRecords/>
        </template>

        <template #cell(customId)="row">
            <BLink :to="{ name: 'order_show', params: { id: row.item.id }}" target="_blank">
                {{ row.value }}
            </BLink>
        </template>

        <template #cell(status)="row">
            {{ orderStatus.getItem(row.value).text }}
        </template>

        <template #cell(workerStatus)="row">
            {{ row.value > 0 ? workerStatus.getItem(row.value).text : "" }}
            <span v-if="workerStatus.getItem(row.value).cancelReasonRequired">
                ({{ row.value > 0 ? cancelStatus.getItem(row.item.cancelReasonStatus).text : "" }})
            </span>
        </template>

    </BTable>
</template>

<script>
import {mapGetters} from "vuex"
import CancelStatus from "../../../../Model/Enum/cancel_status"
import ServiceOrderStatus from "../../../../Model/Enum/service_order_status"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"
import Vue from "vue"
import WorkerStatus from "../../../../Model/Enum/worker_status"

export default {

    components: {
        TableLoading,
        TableWithoutRecords,
    },

    data() {
        return {
            orderStatus: ServiceOrderStatus,
            workerStatus: WorkerStatus,
            cancelStatus: CancelStatus,
            fields: [
                {
                    key: "customId",
                    label: "Ev. číslo",
                    sortable: false,
                    tdClass: "align-middle",
                },
                {
                    key: "address",
                    label: "Adresa",
                    sortable: false,
                    tdClass: "align-middle",
                },
                {
                    key: "worker",
                    label: "Technik",
                    sortable: false,
                    tdClass: "align-middle",
                },
                {
                    key: "scheduledStartTime",
                    label: "Čas zahájení",
                    tdClass: "align-middle",
                    sortable: false,
                    formatter: function (a) {
                        return Vue.filter("prettyDateTime")(a)
                    },
                },
                {
                    key: "status",
                    label: "Stav zak.",
                    tdClass: "align-middle",
                    sortable: false,
                },
                {
                    key: "workerStatus",
                    label: "Stav technika",
                    sortable: false,
                    tdClass: "align-middle",
                },
            ],
        }
    },

    computed: {
        ...mapGetters({
            isLoading: "dashboard/todayOrders/isLoading",
            hasRecords: "dashboard/todayOrders/hasRecords",
            records: "dashboard/todayOrders/getRecords",
        }),
    },

    methods: {

        myProvider(ctx, callback) {
            let items = []
            this.$store.dispatch("dashboard/todayOrders/clearAll")
            this.$store.dispatch("dashboard/todayOrders/load")
                .then((response) => {
                    callback(response)
                })
                .catch(() => {
                    callback([])
                })
            return items || []
        },

    },

}
</script>
