<template>
    <RouterLink class="btn btn-primary" :to="to">
        <slot name="default">
            <i class="mr-3" :class="icon" v-if="icon"/>
            <span v-if="text">{{ text }}</span>
        </slot>
    </RouterLink>
</template>

<script>
export default {

    props: {
        to: {
            type: [Object, String],
            required: true,
            default: () => {
            },
        },
        icon: {
            type: String,
            required: false,
            default: '',
        },
        text: {
            type: String,
            required: false,
            default: "",
        },
    },

}
</script>
