import PlanningService from "./PlanningService"
import PlanningFilterEntity from "./Entity/PlanningFilterEntity"
import PlanningEntity from "./Entity/PlanningEntity"

const
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    FETCHING_FILTERED_RECORDS = "FETCHING_FILTERED_RECORDS",
    FETCHING_FILTERED_RECORDS_SUCCESS = "FETCHING_FILTERED_RECORDS_SUCCESS",
    FETCHING_FILTERED_RECORDS_ERROR = "FETCHING_FILTERED_RECORDS_ERROR",
    UPDATING_RECORD = "UPDATING_RECORD",
    UPDATING_RECORD_SUCCESS = "UPDATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "UPDATING_RECORD_ERROR",
    CLEAR_RECORDS = "CLEAR_RECORDS",
    CLEAR_RECORD = "CLEAR_RECORD"

const initialState = {
    isLoading: false,
    isProcessing: false,
    record: null,
    records: [],
    totalRecords: 0,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<*>}
     */
    async getPlan({commit}, id) {
        try {
            commit(FETCHING_RECORD)
            let response = await PlanningService.getPlan(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param request : PlanningFilterRequest
     * @returns {Promise<*>}
     */
    async getFilteredRecords({commit}, request) {
        try {
            commit(FETCHING_FILTERED_RECORDS)
            let response = await PlanningService.filterPlans(request)
            commit(FETCHING_FILTERED_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_FILTERED_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param length : string
     * @param message : string
     * @returns {Promise<any>}
     */
    async putToSleep({commit}, {id, length, message}) {
        try {
            commit(UPDATING_RECORD)
            let response = await PlanningService.putToSleep(id, length, message)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

    clear({commit}) {
        commit(CLEAR_RECORD)
    }

}

export const mutations = {

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.record = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.record = PlanningEntity.from(data)
    },
    [FETCHING_RECORD_ERROR](state) {
        state.isLoading = false
        state.record = null
    },

    [FETCHING_FILTERED_RECORDS](state) {
        state.isLoading = true
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_FILTERED_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.records.map(record => PlanningFilterEntity.from(record))
        state.totalRecords = data.totalRecords
    },
    [FETCHING_FILTERED_RECORDS_ERROR](state) {
        state.isLoading = false
        state.records = []
        state.totalRecords = 0
    },

    [UPDATING_RECORD](state) {
        state.isProcessing = true
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isProcessing = false
        state.record = PlanningEntity.from(data)
    },
    [UPDATING_RECORD_ERROR](state) {
        state.isProcessing = false
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.records = []
        state.totalRecords = 0
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.isProcessing = false
        state.record = null
    },

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },

    isProcessing(state) {
        return state.isProcessing
    },

    hasPlans(state) {
        return state.records.length > 0
    },

    getPlans(state) {
        return state.records
    },

    hasPlan(state) {
        return state.record !== null
    },

    getPlan(state) {
        return state.record
    },

    getTotalPlans(state) {
        return state.totalRecords
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
