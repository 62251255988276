import AddressEntity from "../../Address/AddressEntity"
import SubjectContactBasicEntity from "./SubjectContactBasicEntity"

export default class SubjectBasicEntity {

    constructor() {
        this.id = ''
        this.type = 1
        this.fullName = ''
        this.firstName = ''
        this.lastName = ''
        this.birthDate = null
        this.academicDegree = ''
        this.companyName = ''
        this.wholeName = ''
        this.identificationNumber = null
        this.vatNumber = null
        this.address = new AddressEntity()
        this.contacts = []
        this.editable = false
        this.unreliableVatPayer = false
    }

    /**
     * @param response : object
     * @returns SubjectBasicEntity
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.type = response.type
        obj.fullName = response.fullName ?? ''
        obj.firstName = response.firstName ?? ''
        obj.lastName = response.lastName ?? ''
        obj.birthDate = response.birthDate
        obj.academicDegree = response.academicDegree
        obj.companyName = response.companyName
        obj.wholeName = response.wholeName
        obj.identificationNumber = response.identificationNumber
        obj.vatNumber = response.vatNumber
        obj.address = AddressEntity.from(response.address)
        obj.contacts = response.contacts.map(contact => SubjectContactBasicEntity.from(contact))
        obj.editable = response.editable
        obj.unreliableVatPayer = response.unreliableVatPayer
        return obj
    }

}
