import _ from "lodash"

const WorkerStatusList = [
    {id: 0, text: "Nevyplněno", active: false, disabled: true, cancelReasonRequired: false},
    {id: 1, text: "Jsem na místě", active: true, disabled: false, cancelReasonRequired: false},
    {id: 2, text: "Vyřízeno", active: true, disabled: false, cancelReasonRequired: false},
    {id: 7, text: "Zrušeno", active: true, disabled: false, cancelReasonRequired: true},
]

export default class WorkerStatus {

    constructor(id) {
        this.selected = id !== null ? id : null
        this.items = {...WorkerStatusList}
    }

    toString() {
        let o = _.find(this.items, {id: this.selected})
        return o === undefined ? "N/A" : o.text
    }

    static print(id) {
        let o = _.find({...WorkerStatusList}, {id: parseInt(id)})
        return o === undefined ? "N/A" : o.text
    }

    static list() {
        return {...WorkerStatusList}
    }

    static getItem(id) {
        let o = _.find({...WorkerStatusList}, {id: parseInt(id)})
        return o === undefined ? null : o
    }

}
