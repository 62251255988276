import AttachmentRequest from "@/NewModel/Attachment/Request/AttachmentRequest"

export default class OrderAttachmentsRequest {

    constructor() {
        this.attachments = []
        this.assemblySheet = null
    }

    /**
     * @param order : NewOrderEntity
     */
    static fromOrder(order) {
        let request = new this
        request.assemblySheet = order.list
        request.attachments = order.attachments.map(attachment => new AttachmentRequest(attachment.id, attachment.name))
        return request
    }

    toForm() {
        return {
            attachments: this.attachments.map(attachment => attachment.id),
            assemblySheet: this.assemblySheet,
        }
    }

}
