import DiscountService from "./DiscountService"
import DiscountSimpleEntity from "./Entity/DiscountSimpleEntity"
import DiscountEntity from "./Entity/DiscountEntity"

const
    FETCHING_FILTERED_RECORDS = "FETCHING_FILTERED_RECORDS",
    FETCHING_FILTERED_RECORDS_SUCCESS = "FETCHING_FILTERED_RECORDS_SUCCESS",
    FETCHING_FILTERED_RECORDS_ERROR = "FETCHING_FILTERED_RECORDS_ERROR",
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    UPDATING_RECORD = "UPDATING_RECORD",
    UPDATING_RECORD_SUCCESS = "UPDATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "UPDATING_RECORD_ERROR",
    DELETING_RECORD = "DELETING_RECORD",
    DELETING_RECORD_SUCCESS = "DELETING_RECORD_SUCCESS",
    DELETING_RECORD_ERROR = "DELETING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isLoading: false,
    isProcessing: false,
    error: null,
    record: null,
    records: [],
    totalRecords: 0
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param request : SimpleFormRequest
     * @returns {Promise<*>}
     */
    async getFiltered({commit}, request) {
        try {
            commit(FETCHING_FILTERED_RECORDS)
            let response = await DiscountService.filterDiscounts(request)
            commit(FETCHING_FILTERED_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_FILTERED_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @returns {Promise<DiscountEntity>}
     */
    async getDiscount({commit}, id) {
        try {
            commit(FETCHING_RECORD)
            let response = await DiscountService.getDiscount(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param request : DiscountRequest
     * @returns {Promise<DiscountEntity>}
     */
    async createDiscount({commit}, request) {
        try {
            commit(CREATING_RECORD)
            let response = await DiscountService.createDiscount(request)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @param request : DiscountRequest
     * @returns {Promise<DiscountEntity>}
     */
    async updateDiscount({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await DiscountService.updateDiscount(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : Number
     * @returns {Promise<DiscountEntity>}
     */
    async deleteDiscount({commit}, id) {
        try {
            commit(DELETING_RECORD)
            let response = await DiscountService.deleteDiscount(id)
            commit(DELETING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(DELETING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    clear({commit}) {
        commit(CLEAR_RECORD)
    },

    clearAll({commit}) {
        commit(CLEAR_RECORDS)
    }

}

export const mutations = {

    [FETCHING_FILTERED_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_FILTERED_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.records = data.records.map(record => DiscountSimpleEntity.from(record))
        state.totalRecords = data.totalRecords
    },
    [FETCHING_FILTERED_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
        state.record = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = DiscountEntity.from(data)
    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.record = null
    },

    [CREATING_RECORD](state) {
        state.isProcessing = true
        state.error = null
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isProcessing = false
        state.error = null
        state.record = DiscountEntity.from(data)
    },
    [CREATING_RECORD_ERROR](state, error) {
        state.isProcessing = false
        state.error = error
    },

    [UPDATING_RECORD](state) {
        state.isProcessing = true
        state.error = null
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isProcessing = false
        state.error = null
        state.record = DiscountEntity.from(data)
    },
    [UPDATING_RECORD_ERROR](state, error) {
        state.isProcessing = false
        state.error = error
    },

    [DELETING_RECORD](state) {
        state.isProcessing = true
        state.error = null
    },
    [DELETING_RECORD_SUCCESS](state) {
        state.isProcessing = false
        state.error = null
    },
    [DELETING_RECORD_ERROR](state, error) {
        state.isProcessing = false
        state.error = error
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.isProcessing = false
        state.error = null
        state.record = null
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.isProcessing = false
        state.error = null
        state.records = []
        state.totalRecords = 0
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    isCreating(state) {
        return state.isCreating
    },
    isProcessing(state) {
        return state.isProcessing
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasDiscount(state) {
        return state.record !== null
    },
    getDiscount(state) {
        return state.record
    },
    hasDiscounts(state) {
        return state.records.length > 0
    },
    getDiscounts(state) {
        return state.records
    },
    getTotalDiscounts(state) {
        return state.totalRecords
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
