<template>
    <div>

        <TableWithoutRecords v-if="!isLoading && !hasContracts" />

        <div
            class="bg-little-gray p-4 mb-4"
            v-for="(contract, contractIndex) in contracts"
            v-bind:key="'contract_master' + contractIndex"
        >

            <h5 class="font-weight-600 text-dark mb-3">
                <i class="fad fa-file-contract text-primary fa-fw mr-3"/>
                #<strong>{{ contract.contractNumber }}</strong> s datem účinnosti od
                <strong>{{ contract.contractDate|prettyDate }}</strong>
            </h5>

            <table class="table table-sm table-borderless mb-0">
                <thead>
                <tr class="border-bottom">
                    <th class="align-middle w-5">
                        Smlouva
                    </th>
                    <th class="align-middle w-20">
                        OM - EAN/EIC
                    </th>
                    <th class="align-middle w-30">
                        OM - Adresa
                    </th>
                    <th class="align-middle w-15">
                        Platnost od
                    </th>
                    <th class="align-middle w-15">
                        Platnost do
                    </th>
                    <th class="align-middle w-15">
                        Benefity
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    :class="{'border-bottom': connectionContractIndex + 1 !== contract.connectionContracts.length}"
                    v-for="(connectionContract, connectionContractIndex) in contract.connectionContracts"
                    v-bind:key="'contract_connection_' + contractIndex + '_' + connectionContractIndex"
                >
                    <td class="align-middle">
                        #{{ connectionContract.contractNumber }}
                    </td>
                    <td class="align-middle">
                        <i class="mr-2" :class="commodity.getItem(connectionContract.commodity).icon"/>
                        <RouterLink
                            :to="{ name: 'supply_point_show', params: { id: connectionContract.supplyPoint.id }}">
                            {{ connectionContract.supplyPoint.ean }}
                        </RouterLink>
                    </td>
                    <td class="align-middle">
                        {{ connectionContract.supplyPoint.address }}
                    </td>
                    <td class="align-middle">
                        {{ connectionContract.validFromDate|prettyDate }}
                    </td>
                    <td class="align-middle">
                        {{ connectionContract.validToDate|prettyDate }}
                    </td>
                    <td class="align-middle">
                        {{ connectionContract.benefits.join(", ") }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Commodity from "@/Model/Enum/commodity"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        subject: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            commodity: Commodity,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'subject/contracts/isLoading',
            hasContracts: 'subject/contracts/hasContracts',
            contracts: 'subject/contracts/getContracts',
        }),
    },

    created() {
        this.loadSubjectContracts(this.subject)
    },

    methods: {

        loadSubjectContracts(id) {
            this.$store.dispatch("subject/contracts/loadContracts", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
