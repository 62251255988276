<template>
    <div class="container-fluid p-4">
        <div class="d-flex justify-content-between align-items-center mb-5">
            <h3 class="font-weight-600 text-dark mb-0">
                <i class="fad fa-car-mechanic text-primary fa-fw mr-3"/>Mé zakázky
            </h3>
            <div class="buttons">
                <DateSwitcher v-on:changed="dateChanged"/>
            </div>
        </div>

        <div v-if="isLoading === false && markers.length > 0" style="height: calc(100vh - 20rem);">
            <GmapMap :center="center" :zoom="11.25" map-type-id="roadmap" class="w-100 h-100">
                <Cluster :maxZoom="22" @click="clickOnCluster">
                    <GmapMarker
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :options="m.options"
                        :clickable="true"
                        :draggable="true"
                        @click="clickOnMarker(m.options.data)"
                        :key="index"
                        :title="m.options.data.orderNumber + ' - ' + m.options.data.customerName"
                    />

                </Cluster>
            </GmapMap>
            <BModal
                size="lg"
                id="order-modal"
                :title="'Zakázka #' + selected.orderNumber"
                body-class="p-0"
                v-if="selected"
                ok-title="Zavřít"
                header-bg-variant="white"
                no-stacking
                centered
                ok-only
            >
                <table class="table mb-0">
                    <tr>
                        <td class="bg-little-gray align-middle font-weight-600">
                            Zakázka:
                        </td>
                        <td>
                            {{ selected.orderNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-little-gray align-middle font-weight-600">
                            Čas realizace:
                        </td>
                        <td>
                            {{ {startTime: selected.startTime, endTime: selected.endTime}|prettyDateRange }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-little-gray align-middle font-weight-600">
                            Odběrné místo:
                        </td>
                        <td>
                            {{ selected.ean }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-little-gray align-middle font-weight-600">
                            Zákazník:
                        </td>
                        <td>
                            {{ selected.customerName }}
                        </td>
                    </tr>
                    <tr v-if="selected.propertyOwnerName">
                        <td class="bg-little-gray align-middle font-weight-600">
                            Vlastník nemovitosti:
                        </td>
                        <td>
                            {{ selected.propertyOwnerName }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-little-gray align-middle font-weight-600">
                            Adresa:
                        </td>
                        <td>
                            {{ selected.addressName }}
                        </td>
                    </tr>
                </table>
            </BModal>
        </div>

        <TableLoading v-else-if="isLoading === true && records.length === 0"/>
        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import DateSwitcher from "./Component/DateSwitcher.vue"
import WorkerService from "../../../NewModel/Worker/WorkerService"
import Cluster from "vue2-google-maps/dist/components/cluster"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"

export default {

    components: {
        Cluster,
        DateSwitcher,
        TableLoading,
        TableWithoutRecords,
    },

    data() {
        return {
            isLoading: false,
            records: [],
            selected: null,
            center: {
                lat: 50.226725,
                lng: 12.798262
            },
            markers: [],
            clusteredMarkers: [],
            orders: [],
        }
    },

    methods: {

        clickOnMarker(data) {
            this.selected = data
            this.$bvModal.show("order-modal")
        },

        clickOnCluster(cluster) {
            let self = this
            self.clusteredMarkers = cluster.markers_.map((marker) => marker.data)
            if (self.clusteredMarkers.length > 0) {
                this.$bvModal.show("supply-point-clustered")
            }
        },

        dateChanged(requiredDate) {
            this.loadOrders(requiredDate)
        },

        loadOrders(requiredDate) {

            let self = this
            self.isLoading = true
            self.markers = []
            self.orders = []


            WorkerService.getMapOfOrders(requiredDate)
                .then((response) => {
                    response.data.forEach((order) => {
                        self.markers.push({
                            position: {
                                lat: order.gpsLat,
                                lng: order.gpsLng,
                            },
                            options: {
                                id: order.id,
                                data: order,
                                icon: {
                                    url: order.status === 5
                                        ? "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                                        : "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
                                },
                            },
                            clickable: true,
                        })
                        self.orders.push(order)
                    })

                })
                .catch((error) => {
                    self.errorMessage(error.response.data.message)
                })
                .finally(() => {
                    self.isLoading = false
                })
        },

    },

}
</script>
