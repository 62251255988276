<template>
    <div>
        <table class="table table-borderless mb-0">
            <thead>
            <tr>
                <th class="text-gray-700 align-middle">
                    Poznámka
                </th>
                <th class="text-gray-700 align-middle w-10 text-center">
                    Typ
                </th>
                <th class="text-gray-700 align-middle w-10 text-center">
                    Autor
                </th>
                <th class="text-gray-700 align-middle w-10 text-center">
                    Datum
                </th>
                <th class="text-gray-700 align-middle w-10">
                    Spotřebič
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                class="border-top"
                v-for="note in notes"
                v-bind:key="'note_' + note.id"
            >
                <td class="align-middle">
                    {{ note.message }}
                </td>
                <td class="align-middle text-center">
                    <span v-if="note.type === 1">Komunikace</span>
                    <span v-if="note.type === 2">Technická</span>
                </td>
                <td class="align-middle text-center">
                    <BAvatar
                        variant="primary"
                        :src="note.createdBy.picture"
                        :text="note.createdBy.acronym"
                        :title="note.createdBy.name"
                        size="sm"
                    />
                </td>
                <td class="align-middle text-center">
                    {{ note.createdAt|prettyDate }}
                </td>
                <td class="align-middle">
                    <BLink
                        :to="{ name: 'appliance_show', params: { appliance_id: note.appliance.id }}"
                        v-if="note.appliance"
                    >
                        {{ note.appliance.name|shortify(12) }}
                    </BLink>
                </td>
            </tr>
            <tr class="border-top" v-if="!isLoading && !hasNotes">
                <td colspan="7">
                    <TableWithoutRecords/>
                </td>
            </tr>
            <tr class="border-top" v-if="isLoading && !hasNotes">
                <td colspan="7">
                    <TableLoading/>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr class="border-top">
                <td colspan="5">
                    <AddNote :supplyPoint="supplyPoint" @onCreate="noteCreated"/>
                </td>
            </tr>
            </tfoot>
        </table>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import AddNote from "./AddNote"
import SupplyPointNoteRequest from "../../../NewModel/SupplyPoint/Request/SupplyPointNoteRequest"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"

export default {

    components: {
        AddNote,
        TableLoading,
        TableWithoutRecords,
    },

    props: {
        supplyPoint: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            form: new SupplyPointNoteRequest(),
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'supplyPoint/notes/isLoading',
            hasNotes: 'supplyPoint/notes/hasNotes',
            notes: 'supplyPoint/notes/getNotes',
        })
    },

    created() {
        this.loadSupplyPointNotes()
    },

    methods: {

        loadSupplyPointNotes() {
            this.$store.dispatch("supplyPoint/notes/clearAll")
            this.$store.dispatch("supplyPoint/notes/getNotes", this.supplyPoint)
        },

        noteCreated(note) {
            this.notes.unshift(note)
        },

    },

}
</script>
