import Planning from "./Planning.vue"
import Show from "./Show.vue"
import WhiteMain from "../General/WhiteMain.vue"

const planningRoutes = [
    {
        path: "/planning",
        component: WhiteMain,
        children: [
            {
                path: "",
                name: "planning",
                component: Planning,
            },
            {
                path: ":plan_id/show",
                name: "planning_show",
                component: Show,
            },
        ],
    },
]

export default planningRoutes
