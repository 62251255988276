<template>
    <div>

        <div class="py-2 px-3 mb-3 text-white" style="background-color: #2a7dc4;">
            <strong>Ostatní materiál:</strong>
        </div>

        <div class="mb-3 px-3">
            <table class="table table-sm">
                <tbody>
                <tr class="bg-light">
                    <th colspan="6" class="text-left">
                        Materiál
                    </th>
                </tr>
                <tr>
                    <th class="text-left">
                        Označení
                    </th>
                    <th class="text-center w-10">
                        Počet m.j.
                    </th>
                    <th class="text-right w-15">
                        Cena / m.j.
                    </th>
                    <th class="text-right w-5">
                        Sleva
                    </th>
                    <th class="text-right w-15">
                        Cena po slevě
                    </th>
                    <th class="text-right w-15">
                        Celkem
                    </th>
                </tr>
                <tr v-for="material in assemblySheet.getFreeMaterials()" :key="material.id">
                    <td>
                        {{ material.itemName }}
                    </td>
                    <td class="text-center">
                        {{ material.quantity }}
                    </td>
                    <td class="text-right">
                        {{ material.pricePerItem|moneyFormat("CZK_LONG") }}
                    </td>
                    <td class="text-right">
                        {{ material.discount }} %
                    </td>
                    <td class="text-right">
                        {{ material.pricePerItemWithDiscount|moneyFormat("CZK_LONG") }}
                    </td>
                    <td class="text-right">
                        {{ material.totalAmount|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import AssemblySheetEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetEntity"

export default {

    props: {
        assemblySheet: {
            type: AssemblySheetEntity,
            required: true,
        },
    },

}
</script>
