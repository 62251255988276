<template>
    <table class="table table-fit table-borderless mb-0">
        <thead>
        <tr>
            <th class="w-10 text-gray-700 align-middle">
                Datum a čas
            </th>
            <th class="w-10 text-gray-700 align-middle">
                # ID
            </th>
            <th class="w-15 text-gray-700 align-middle">
                Položky v zak.
            </th>
            <th class="w-20 text-gray-700 align-middle">
                Technik
            </th>
            <th class="w-10 text-gray-700 align-middle">
                Stav
            </th>
            <th class="w-5 text-gray-700 align-middle text-right"/>
        </tr>
        </thead>
        <tbody>
        <tr
            class="border-top" v-for="order in orders"
            :key="order.id"
        >
            <td class="align-middle">
                {{ order.scheduledStartTime|prettyDateTime }}
            </td>
            <td class="align-middle">
                {{ order.customId }}
            </td>
            <td class="align-middle">
                {{ order.includedRepairs.join(", ") }}
            </td>
            <td class="align-middle">
                {{ order.worker }}
            </td>
            <td class="align-middle">
                {{ orderStatus.getItem(order.status).text }}
            </td>
            <td class="align-middle text-right">
                <BButton
                    :to="'/orders/' + order.id + '/show'"
                    variant="primary"
                    size="sm"
                >
                    <i class="fad fa-eye"/>
                </BButton>
            </td>
        </tr>
        <tr class="border-top" v-if="!isLoading && !hasOrders">
            <td colspan="7">
                <TableWithoutRecords/>
            </td>
        </tr>
        <tr class="border-top" v-if="isLoading && !hasOrders">
            <td colspan="7">
                <TableLoading/>
            </td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="border-top">
            <td colspan="4" class="text-gray-700">
                <strong>Celkem zakázek:</strong> {{ orders.length }}
            </td>
        </tr>
        </tfoot>
    </table>
</template>

<script>
import {mapGetters} from "vuex"
import ServiceOrderStatus from "@/Model/Enum/service_order_status"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TableWithoutRecords,
    },

    props: {
        supplyPoint: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            orderStatus: ServiceOrderStatus,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'supplyPoint/orders/isLoading',
            hasOrders: 'supplyPoint/orders/hasOrders',
            orders: 'supplyPoint/orders/getOrders',
        }),
    },

    created() {
        this.loadSupplyPointOrders(this.supplyPoint)
    },

    methods: {

        loadSupplyPointOrders(id) {
            this.$store.dispatch("supplyPoint/orders/getOrders", id)
        },

    },

}
</script>
