<template>
    <div>

        <Header icon="fad fa-satellite" :title="appliance.name">
            <template #buttons>
                <PrimaryLinkButton
                    :to="{ name: 'appliance_edit', params: { appliance_id: appliance.id } }"
                    v-if="appliance.status !== -1"
                    icon="fad fa-edit"
                    text="Upravit"
                />
                <BackLinkButton
                    class="ml-3"
                    :to="{ name: 'supply_point_show', params: { id: appliance.supplyPoint.id }}"
                    text="Zpět do OM"
                />
            </template>
        </Header>

        <BTabs content-class="mt-4 p-0" active-nav-item-class="btn-primary" pills lazy>

            <BTab title="Informace" class="p-0" title-item-class="bg-light" active>
                <BRow>
                    <BCol cols="8">
                        <ApplianceInfo :entity="appliance" class="bg-little-gray p-4 mb-4"/>
                    </BCol>
                    <BCol cols="4">

                        <div class="table-danger p-4 mb-4" v-if="appliance.status === -1">
                            <i class="fad text-danger fa-exclamation-triangle fa-fw mr-3"/>
                            <strong class="font-weight-600">Upozornění!</strong><br/>
                            Tento spotřebič byl demontován!
                        </div>

                        <div class="mb-4">

                            <div v-if="appliance.status !== -1">
                                <DismountAppliance :appliance="appliance"/>
                                <hr class="border-light"/>
                            </div>

                            <div v-if="appliance.status === 0">
                                <DeleteAppliance :appliance="appliance"/>
                                <hr class="border-light"/>
                            </div>

                        </div>

                    </BCol>
                </BRow>
            </BTab>

            <BTab title="Opravy" class="p-0" title-item-class="bg-light ml-3" lazy>
                <ApplianceRepairs :entity="appliance"/>
            </BTab>

            <BTab title="Dokumenty" class="p-0" title-item-class="bg-light ml-3" lazy>
                <ApplianceAttachments :entity="appliance"/>
            </BTab>

        </BTabs>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ApplianceAttachments from './Component/ApplianceAttachments.vue'
import ApplianceRepairs from './Component/ApplianceRepairs.vue'
import ApplianceInfo from './Component/ApplianceInfo.vue'
import BackLinkButton from '../Common/UI/Button/BackLinkButton.vue'
import DeleteAppliance from "@/Module/Appliance/Component/DeleteAppliance.vue"
import DismountAppliance from "@/Module/Appliance/Component/DismountAppliance.vue"
import Fuel from '../../Model/Enum/fuel'
import Header from '../Common/UI/Header.vue'
import PrimaryLinkButton from "@/Module/Common/UI/Button/PrimaryLinkButton.vue"

export default {

    components: {
        ApplianceAttachments,
        ApplianceRepairs,
        ApplianceInfo,
        BackLinkButton,
        DeleteAppliance,
        DismountAppliance,
        Header,
        PrimaryLinkButton,
    },

    computed: {
        ...mapGetters({
            appliance: 'appliance/getAppliance',
        }),
    },

    data() {
        return {
            showIssueForm: false,
            showFileForm: false,
            issueCreating: true,
            list: {
                fuel: Fuel,
            },
        }
    }

}
</script>
