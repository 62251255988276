import authHeader from "../../../Model/Common/AuthHeader"
import {httpClient} from "../../../Model/Common"

const ApplianceTypeService = {

    findAllActiveTypes() {
        return httpClient.get("v3/appliance_types", {
            headers: authHeader()
        })
    },

    /**
     * @param request : SimpleFormRequest
     */
    filterTypes(request) {
        return httpClient.post("v3/appliance_types/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getType(id) {
        return httpClient.get("v3/appliance_types/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param request : ApplianceTypeRequest
     */
    createType(request) {
        return httpClient.post("v3/appliance_types", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @param request : ApplianceTypeRequest
     */
    updateType(id, request) {
        return httpClient.put("v3/appliance_types/" + id, request.toForm(), {
            headers: authHeader()
        })
    },

}

export default ApplianceTypeService
