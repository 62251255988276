<template>
    <div>
        <BLink v-b-modal.orderAppliancePicker variant="dark" size="sm">
            <i class="fad fa-plus mr-2"/>Přidat spotřebič
        </BLink>
        <BModal
            id="orderAppliancePicker"
            ref="orderAppliancePicker"
            title="Výběr spotřebiče"
            header-bg-variant="light"
            header-class="text-dark"
            hide-footer
            centered
            size="xl"
            lazy
            body-class="p-0"
        >
            <BTabs nav-wrapper-class="px-4 pt-4" content-class="p-4" pills>
                <BTab title="Stávající spotřebič" active lazy>
                    <table class="table table-fit table-sm table-borderless mb-0">
                        <thead>
                        <tr>
                            <th class="align-middle w-10">
                                Identifikátor
                            </th>
                            <th class="align-middle w-40">
                                Druh spotřebiče
                            </th>
                            <th class="align-middle w-30">
                                Spotřebič
                            </th>
                            <th class="align-middle w-25">
                                Výrobní číslo
                            </th>
                            <th class="align-middle w-5"/>
                        </tr>
                        </thead>
                        <tbody v-if="availableAppliances.length > 0">
                        <tr v-for="appliance in availableAppliances" :key="appliance.id" class="border-top">
                            <td class="align-middle">
                                #{{ String(appliance.id).slice(-5) }}
                            </td>
                            <td class="align-middle">
                                {{ appliance.name }}
                            </td>
                            <td class="align-middle">
                                {{ appliance.applianceType }}
                            </td>
                            <td class="align-middle">
                                {{ appliance.serialNumber }}
                            </td>
                            <td class="text-right align-middle">
                                <BButton
                                    variant="success"
                                    size="sm"
                                    v-on:click="useCurrentAppliance(appliance)"
                                    :disabled="appliances.some(r=> r.appliance === appliance.id)"
                                >
                                    <i class="fad fa-check-circle"/>
                                </BButton>
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr v-if="!availableAppliances" class="border-top">
                            <td colspan="3">
                                <i class="fad fa-times fa-fw text-danger mr-3"/>U tohoto odběrného místa nebyly dosud
                                vytvořeny žádné spotřebiče.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </BTab>

                <b-tab title="Nový spotřebič" lazy>
                    <ApplianceForm
                        :is-processing="isProcessing"
                        :request="request"
                        @submitted="onSubmitted"
                    />
                </b-tab>
            </BTabs>

        </BModal>
    </div>
</template>

<script>
import OrderApplianceRequest from "../../../../NewModel/Order/Request/OrderApplianceRequest"
import ApplianceForm from "../../../Appliance/Component/ApplianceForm.vue"
import ApplianceRequest from "../../../../NewModel/Appliance/Request/ApplianceRequest"
import NewOrderEntity from "@/NewModel/Order/Entity/NewOrderEntity"
import OrderMetadataApplianceEntity from "@/NewModel/Order/Entity/OrderMetadataApplianceEntity"

export default {

    props: {

        order: {
            type: NewOrderEntity,
            required: true,
        },

        appliances: {
            type: Array,
            default: () => [],
            required: true,
        },

        availableAppliances: {
            type: Array,
            default: () => [],
            required: true,
        },
    },

    components: {
        ApplianceForm,
    },

    data() {
        return {
            newAppliance: {
                serialNumber: "",
                boilerManufacturer: {
                    id: null,
                    name: "",
                },
                boilerModel: {
                    id: null,
                    name: "",
                },
                boilerType: {
                    id: null,
                    name: "",
                },
                applianceType: null,
                applianceTypeName: "",
            },
            isProcessing: false,
            request: new ApplianceRequest(),
        }
    },

    methods: {

        useCurrentAppliance(availableAppliance) {
            let appliance = new OrderApplianceRequest()
            appliance.id = null
            appliance.appliance = availableAppliance.id
            appliance.name = availableAppliance.name
            appliance.appplianceType = availableAppliance.applianceType
            appliance.serialNumber = availableAppliance.serialNumber
            this.appliances.push(appliance)
            this.$bvModal.hide('orderAppliancePicker')
        },

        /**
         * @param request : ApplianceRequest
         */
        onSubmitted(request) {
            this.$store.dispatch("supplyPoint/appliances/createAppliance", {
                id: this.order.supplyPoint,
                request: request,
            })
                .then((response) => {
                    this.successMessage('Úspěšně uloženo!')

                    let appliance = new OrderApplianceRequest()
                    appliance.id = null
                    appliance.appliance = response.id
                    appliance.name = response.name
                    appliance.appplianceType = response.applianceType.name
                    appliance.serialNumber = response.serialNumber
                    this.appliances.push(appliance)
                    this.request = new ApplianceRequest()

                    appliance = new OrderMetadataApplianceEntity()
                    appliance.id = response.id
                    appliance.name = response.name
                    appliance.applianceType = response.applianceType.name
                    appliance.serialNumber = response.serialNumber
                    this.availableAppliances.push(appliance)
                    this.$bvModal.hide('orderAppliancePicker')
                })
                .catch((error) => {
                    this.warningMessage(error.response.data.message)
                })
        },

    },

}
</script>
