<template>
    <div>

        <Header icon="fad fa-pencil-ruler" title="Upravit typ opravy">
            <template #buttons>
                <BackLinkButton to="/setup/repair_types"/>
            </template>
        </Header>

        <RepairTypeForm
            :request="request"
            :is-processing="isUpdating"
            v-on:submitted="onSubmitted"
        />

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../../Common/UI/Button/BackLinkButton.vue"
import Header from "../../Common/UI/Header.vue"
import RepairTypeForm from "./Component/RepairTypeForm.vue"
import RepairTypeRequest from "../../../NewModel/RepairType/Request/RepairTypeRequest"

export default {

    components: {
        BackLinkButton,
        Header,
        RepairTypeForm,
    },

    computed: {
        ...mapGetters({
            repairType: 'repairType/getRepairType',
            isUpdating: 'repairType/isUpdating',
        }),
    },

    data() {
        return {
            request: new RepairTypeRequest(),
        }
    },

    mounted() {
        this.request = RepairTypeRequest.from(this.repairType)
    },

    methods: {

        onSubmitted(request) {
            this.$store.dispatch("repairType/updateRepairType", {
                id: this.repairType.id,
                request: request,
            })
                .then(() => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'setup_repair_type_list'})
                })
                .catch((err) => {
                    this.warningMessage(err.response.message)
                })
        },

    },

}
</script>
