import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const UserService = {

    findAllActiveUsers() {
        return httpClient.get("v3/users", {
            headers: authHeader()
        })
    },

    /**
     * @param request : SimpleFormRequest
     */
    filterUsers(request) {
        return httpClient.post("v3/users/filter", request.toForm(), {
            headers: authHeader()
        })
    },

    findWorkers() {
        return httpClient.get("v3/users/workers", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getUser(id) {
        return httpClient.get("v3/users/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param request : UserRequest
     */
    createUser(request) {
        return httpClient.post("v3/users", request.toForm(), {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @param request : UserRequest
     */
    updateUser(id, request) {
        return httpClient.put("v3/users/" + id, request.toForm(), {
            headers: authHeader()
        })
    },

}

export default UserService
