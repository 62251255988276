<template>
    <div class="container-fluid">

        <div class="d-flex justify-content-between align-items-center mb-4 bg-white border-radius-sm p-3">
            <h3 class="font-weight-700 text-dark mb-0">
                <i class="fad fa-screwdriver-wrench text-primary fa-fw mr-3"/>Montážní list
            </h3>
            <div class="text-right">
                <div class="font-weight-900">Číslo zakázky:</div>
                <div>{{ assemblySheet.assemblySheetId }}</div>
            </div>
        </div>

        <BForm ref="assemblySheetForm">

            <BTabs
                v-model="tabIndex"
                active-nav-item-class="text-white bg-primary border-radius-sm"
                content-class="mt-4"
                pills
                lazy
                justified
            >
                <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase" active>
                    <template #title>
                        <div class="m-1">
                            Základní
                        </div>
                    </template>

                    <div class="mb-4">
                        <div class="row">
                            <div class="col">
                                <div class="font-weight-900 mb-2 h5">Objednavatel:</div>
                                <div>{{ assemblySheet.customer.name }}, {{ assemblySheet.customer.address }}</div>
                                <div v-if="assemblySheet.customer.type === 2"
                                     class="d-flex justify-content-between align-items-top">
                                    <div v-if="assemblySheet.customer.identificationNumber" class="mr-3">
                                        <span class="font-weight-600">IČO:</span>
                                        {{ assemblySheet.customer.identificationNumber }}
                                    </div>
                                    <div v-if="assemblySheet.customer.vatNumber">
                                        <span class="font-weight-600">DIČ:</span> {{ assemblySheet.customer.vatNumber }}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="font-weight-900 mb-1 h5">Pracoviště:</div>
                                <div>{{ assemblySheet.address.name }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="font-weight-900 mb-2 h5">Realizace:</div>
                    <BRow class="mb-4 py-1 border-radius-sm">
                        <BCol>
                            <div>
                                <span class="font-weight-900">Zahájení:</span>
                                {{ assemblySheet.scheduledStartTime|prettyDateTime }}
                            </div>
                        </BCol>
                        <BCol>
                            <div>
                                <span class="font-weight-900">Ukončení:</span>
                                {{ assemblySheet.scheduledEndTime|prettyDateTime }}
                            </div>
                        </BCol>
                    </BRow>

                    <div class="font-weight-900 mb-2 h5">Doprava:</div>
                    <BRow class="mb-4 bg-white py-3 border-radius-sm">
                        <BCol>
                            <div>
                                <span class="font-weight-900">Druh dopravy:</span>
                                <span class="text-danger font-weight-900 ml-2">*</span>
                                <BFormRadioGroup
                                    id="transportType"
                                    v-model="assemblySheet.pricing.transportType"
                                    name="transportType"
                                    @change="saveForm()"
                                    required
                                >
                                    <BFormRadio :value="1">Sazba za kilometr</BFormRadio>
                                    <BFormRadio :value="2">Paušální sazba</BFormRadio>
                                </BFormRadioGroup>
                            </div>
                        </BCol>
                        <BCol v-if="assemblySheet.pricing.transportType === 1">
                            <div>
                                <span class="font-weight-900">Vzdálenost:</span>
                                <span class="text-danger font-weight-900 ml-2">*</span>
                                <BInputGroup append="km">
                                    <BFormInput
                                        type="number"
                                        v-model="assemblySheet.pricing.transportDistance"
                                        @change="saveForm()"
                                        min="0"
                                        required
                                    />
                                </BInputGroup>
                            </div>
                        </BCol>
                    </BRow>

                    <div class="font-weight-900 mb-2 h5">Finanční zatížení:</div>
                    <BRow class="mb-4 bg-white py-3 border-radius-sm">
                        <BCol>
                            <div>
                                <span class="font-weight-900">Výše daně:</span>
                                <span class="text-danger font-weight-900 ml-2">*</span>
                                <BFormRadioGroup
                                    id="vatTax"
                                    v-model="assemblySheet.pricing.vatTax"
                                    name="vatTax"
                                    @change="saveForm()"
                                    required
                                >
                                    <BFormRadio :value="15">15%</BFormRadio>
                                    <BFormRadio :value="21">21%</BFormRadio>
                                </BFormRadioGroup>
                            </div>
                        </BCol>
                        <BCol>
                            <div>
                                <span class="font-weight-900">Expresní příplatek:</span>
                                <span class="text-danger font-weight-900 ml-2">*</span>
                                <BFormRadioGroup
                                    id="expressSurcharge"
                                    v-model="assemblySheet.pricing.hasExpressSurcharge"
                                    name="expressSurcharge"
                                    @change="saveForm()"
                                    required
                                >
                                    <BFormRadio :value="true">Ano</BFormRadio>
                                    <BFormRadio :value="false">Ne</BFormRadio>
                                </BFormRadioGroup>
                            </div>
                        </BCol>
                        <BCol>
                            <div>
                                <span class="font-weight-900">Režijní materiál:</span>
                                <span class="text-danger font-weight-900 ml-2">*</span>
                                <BInputGroup append="Kč">
                                    <BFormInput
                                        id="priceForOverheadMaterial"
                                        v-model="assemblySheet.pricing.priceForOverheadMaterial"
                                        name="priceForOverheadMaterial" type="number"
                                        step="0.1"
                                        min="0"
                                        @change="saveForm()"
                                        required
                                    />
                                </BInputGroup>
                            </div>
                        </BCol>
                    </BRow>

                </BTab>

                <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                    <template #title>
                        <div class="m-1">
                            Úkony
                        </div>
                    </template>

                    <div v-for="appliance in assemblySheet.appliances" :key="appliance.id"
                         class="mb-4 bg-white p-3 border-radius-sm">
                        <div
                            class="d-flex align-items-center justify-content-between mb-3 border-bottom border-light pb-3"
                        >
                            <h5 class="font-weight-900 mb-0">
                                <strong class="text-danger">#{{ String(appliance.appliance.id).slice(-5) }}</strong>
                                / {{ appliance.appliance.name }}
                            </h5>
                            <div>
                                <BButton
                                    variant="light"
                                    size="sm"
                                    class="ml-3"
                                    @click="applianceAddRepair(appliance)"
                                >
                                    <i class="fad fa-plus-circle fa-fw mr-2"/>Přidat práci
                                </BButton>
                            </div>
                        </div>

                        <div class="pb-3 border-bottom border-light mb-3" v-if="appliance.hasSerialNumber">
                            <strong class="font-weight-600 text-primary">Sériové číslo:</strong>
                            {{ appliance.serialNumber }}
                        </div>


                        <div v-if="appliance.repairs.length > 0">

                            <h5 class="font-weight-900 mb-3">
                                Provedená práce:
                            </h5>

                            <table class="table table-sm table-borderless mb-0">
                                <thead>
                                <tr class="bg-light">
                                    <th class="align-middle">
                                        Označení
                                    </th>
                                    <th class="align-middle text-center w-5">
                                        Počet
                                    </th>
                                    <th class="text-right align-middle w-15">
                                        Cena / m.j.
                                    </th>
                                    <th class="text-right align-middle w-10">
                                        Sleva
                                    </th>
                                    <th class="text-right align-middle w-15">
                                        Akce
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <AssemblySheetApplianceRepairFormRow
                                    v-for="(repair, repairIndex) in appliance.repairs"
                                    :key="repair + repairIndex"
                                    :assembly-sheet-id="assemblySheet.id"
                                    :appliance-id="appliance.id"
                                    :repair="repair"
                                    :index="repairIndex"
                                    @updated="applianceRepairProcessed"
                                    @created="applianceRepairProcessed"
                                    @cancelled="applianceRepairCancelled"
                                />
                                </tbody>
                            </table>

                        </div>
                        <div v-else>
                            <div class="text-center text-muted">
                                <i class="fad fa-exclamation-circle fa-fw mr-2"/>Žádné práce nebyly přidány
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center align-items-center">
                        <AssemblySheetApplianceForm @created="onApplianceAdded"/>
                    </div>

                </BTab>

                <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                    <template #title>
                        <div class="m-1">
                            Materiál
                        </div>
                    </template>
                    <div class="p-3 border-radius-sm bg-white">
                        <div>
                            <table class="table table-sm table-borderless mb-0">
                                <thead>
                                <tr class="bg-light">
                                    <th class="align-middle">
                                        Označení
                                    </th>
                                    <th class="align-middle text-center w-15">
                                        Spotřebič
                                    </th>
                                    <th class="align-middle text-center w-5">
                                        Počet
                                    </th>
                                    <th class="text-right align-middle w-15">
                                        Cena / m.j.
                                    </th>
                                    <th class="text-right align-middle w-10">
                                        Sleva
                                    </th>
                                    <th class="text-right align-middle w-15">
                                        Akce
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <AssemblySheetMaterialFormRow
                                    v-for="(material, materialIndex) in assemblySheet.materials"
                                    :key="material + materialIndex"
                                    :assembly-sheet="assemblySheet"
                                    :material="material"
                                    :index="materialIndex"
                                    @updated="materialProcessed"
                                    @created="materialProcessed"
                                    @cancelled="materialCancelled"
                                />
                                </tbody>
                                <tbody v-if="assemblySheet.materials.length === 0">
                                <tr>
                                    <td colspan="5">
                                        <div class="d-flex justify-content-center align-items-center">
                                            Žádná položka nebyla dosud přidána.
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr class="border-top">
                                    <td colspan="5">
                                        <BButton variant="light" size="sm" @click="addMaterial()">
                                            <i class="fad fa-plus-circle fa-fw mr-2"/>Přidat ostatní materiál
                                        </BButton>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </BTab>

                <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                    <template #title>
                        <div class="m-1">
                            Jiné položky
                        </div>
                    </template>
                    <div class="p-3 border-radius-sm bg-white">
                        <div>
                            <table class="table table-sm table-borderless mb-0">
                                <thead>
                                <tr class="bg-light">
                                    <th class="align-middle">
                                        Označení
                                    </th>
                                    <th class="align-middle text-center w-5">
                                        Počet
                                    </th>
                                    <th class="text-right align-middle w-15">
                                        Cena / m.j.
                                    </th>
                                    <th class="text-right align-middle w-10">
                                        Sleva
                                    </th>
                                    <th class="text-right align-middle w-15">
                                        Akce
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <AssemblySheetItemFormRow
                                    v-for="(item, itemIndex) in assemblySheet.items"
                                    :key="item + itemIndex"
                                    :assembly-sheet-id="assemblySheet.id"
                                    :item="item"
                                    :index="itemIndex"
                                    @updated="itemProcessed"
                                    @created="itemProcessed"
                                    @cancelled="itemCancelled"
                                />
                                </tbody>
                                <tbody v-if="assemblySheet.items.length === 0">
                                <tr>
                                    <td colspan="5">
                                        <div class="d-flex justify-content-center align-items-center">
                                            Žádná položka nebyla dosud přidána.
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr class="border-top">
                                    <td colspan="5">
                                        <BButton variant="light" size="sm" @click="addItem()">
                                            <i class="fad fa-plus-circle fa-fw mr-2"/>Přidat položku
                                        </BButton>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </BTab>

                <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3"
                      @click="recalculationClicked">
                    <template #title>
                        <div class="m-1">
                            Kalkulace
                        </div>
                    </template>
                    <div class="p-3 border-radius-sm bg-white mb-4">
                        <table class="table table-borderless mb-0" v-if="!isRecalculating">
                            <thead>
                            <tr>
                                <th class="align-middle">
                                    Položka kalkulace
                                </th>
                                <th class="align-middle w-15"/>
                                <th class="w-20 text-right font-weight-600 align-middle">
                                    Cena / Kč bez DPH
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="border-top">
                                <td class="align-middle" colspan="2">
                                    1. Provedené práce
                                </td>
                                <td class="align-middle text-right">
                                    {{ assemblySheet.pricing.priceForWork|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="align-middle" colspan="2">
                                    2. Použitý materiál a náhradní díly
                                </td>
                                <td class="align-middle text-right">
                                    {{ assemblySheet.pricing.priceForMaterial|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="align-middle" colspan="2">
                                    3. Ostatní položky
                                </td>
                                <td class="align-middle text-right">
                                    {{ assemblySheet.pricing.priceForItems|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="align-middle" colspan="2">
                                    4. Režijní materiál
                                </td>
                                <td class="align-middle text-right">
                                    {{ assemblySheet.pricing.priceForOverheadMaterial|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="align-middle" colspan="2">
                                    5. Doprava
                                </td>
                                <td class="align-middle text-right">
                                    {{ assemblySheet.pricing.priceForTransport|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="align-middle">
                                    6. Expresní příplatek
                                </td>
                                <td class="align-middle w-15">{{
                                        assemblySheet.pricing.hasExpressSurcharge ? "Ano" : "Ne"
                                    }}
                                </td>
                                <td class="align-middle text-right">
                                    {{ assemblySheet.pricing.expressSurcharge|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="align-middle" colspan="2">7. Sleva</td>
                                <td class="align-middle text-right text-danger">
                                    - {{ assemblySheet.pricing.totalDiscountAmount|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="p-3 border-radius-sm bg-white">
                        <table class="table table-borderless mb-0">
                            <tbody>
                            <tr>
                                <td class="align-middle" colspan="2">
                                    Cena celkem bez DPH
                                </td>
                                <td class="w-20 align-middle text-right">
                                    {{ assemblySheet.pricing.totalAmount|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="align-middle">
                                    DPH
                                </td>
                                <td class="w-15 align-middle">
                                    {{ assemblySheet.pricing.vatTax }} %
                                </td>
                                <td class="w-20 align-middle text-right">
                                    {{ assemblySheet.pricing.taxAmount|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="align-middle" colspan="2">
                                    Haléřové vyrovnání
                                </td>
                                <td class="w-20 align-middle text-right">
                                    {{ assemblySheet.pricing.pennyRounding|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="align-middle font-weight-900" colspan="2">
                                    Částka k úhradě včetně DPH
                                </td>
                                <td class="w-20 align-middle text-right font-weight-900">
                                    {{ assemblySheet.pricing.totalAmountIncludingVat|moneyFormat("CZK_LONG") }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </BTab>

                <BTab title-item-class="bg-success border-radius-sm text-uppercase ml-3">
                    <template #title>
                        <div class="m-1 text-white">
                            Dokončení
                        </div>
                    </template>

                    <div class="font-weight-900 mb-2 h5">Zpráva zákazníkovi:</div>

                    <BTextarea
                        class="mb-4 bg-white py-3"
                        v-model="assemblySheet.messageForCustomer"
                        placeholder="Zpráva zákazníkovi"
                        rows="4"
                        @change="saveForm()"
                    />

                    <div class="mb-4">
                        <div class="row">
                            <div class="col">
                                <div class="font-weight-900 mb-2 h5">Email:</div>
                                <div class="mb-2">
                                    <BFormInput
                                        type="email"
                                        placeholder="Odeslat na jinou e-mailovou adresu"
                                        v-model="assemblySheet.invoicingEmail"
                                        @change="saveForm()"
                                    />
                                </div>
                                <div>
                                    Z kontaktu: {{ assemblySheet.contact.email || '' }}
                                </div>
                            </div>
                            <div class="col">
                                <div class="font-weight-900 mb-1 h5">SMS:</div>
                                <div class="mb-2">
                                    <BFormInput
                                        type="text"
                                        placeholder="Odeslat na jiné telefonní číslo"
                                        v-model="assemblySheet.invoicingPhone"
                                        @change="saveForm()"
                                    />
                                </div>
                                <div>Z kontaktu: {{ assemblySheet.contact.phone }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">

                    </div>

                    <BButton variant="primary" class="py-3" block @click.prevent="processToCustomer()">
                        <i class="fad fa-signature fa-fw mr-3"/>Přejít k podpisu
                    </BButton>
                </BTab>

            </BTabs>

        </BForm>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import AssemblySheetApplianceForm from "./Form/AssemblySheetApplianceForm.vue"
import AssemblySheetApplianceRepairEntity
    from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetApplianceRepairEntity"
import AssemblySheetApplianceRepairFormRow from "./Form/AssemblySheetApplianceRepairFormRow.vue"
import AssemblySheetEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetEntity"
import AssemblySheetItemEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetItemEntity"
import AssemblySheetItemFormRow from "./Form/AssemblySheetItemFormRow.vue"
import AssemblySheetMaterialEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetMaterialEntity"
import AssemblySheetMaterialFormRow from "./Form/AssemblySheetMaterialFormRow.vue"

export default {

    components: {
        AssemblySheetApplianceForm,
        AssemblySheetApplianceRepairFormRow,
        AssemblySheetItemFormRow,
        AssemblySheetMaterialFormRow,
    },

    props: {
        assemblySheet: {
            type: AssemblySheetEntity,
            required: true,
        },
        readonlyMode: {
            type: Boolean,
            required: true,
            default: false,
        },
    },

    computed: {
        ...mapGetters({
            isUpdating: "assemblySheet/process/isUpdating",
            isRecalculating: "assemblySheet/process/isRecalculating",
        }),
    },

    data() {
        return {
            tabIndex: 1,
        }
    },

    methods: {

        saveForm(redirectToCustomer = false) {
            this.$store.dispatch("assemblySheet/process/updateAssemblySheet", {
                id: this.assemblySheet.id,
                formData: this.assemblySheet.to(),
            })
                .then(() => {
                    if (redirectToCustomer) {
                        this.$router.push({path: "/worker/assembly_sheets/" + this.assemblySheet.id + "/customer"})
                            .catch(() => {
                            })
                    } else {
                        this.infoMessage("Formulář montážního listu byl aktualizován.", 1200)
                    }
                })
        },

        processToCustomer() {
            try {
                if (!this.$refs.assemblySheetForm.checkValidity()) {
                    throw "Vyplňte prosím všechny povinné údaje."
                }
            } catch (err) {
                this.warningMessage(err)
                return false
            }
            this.saveForm(true)
        },

        applianceAddRepair(appliance) {
            // Check if opened repair already exists
            let l = appliance.repairs.find((a) => a.action === "create")
            if (typeof l !== "undefined") {
                return
            }

            let repair = new AssemblySheetApplianceRepairEntity()
            repair.action = "create"
            appliance.repairs.push(repair)
        },

        applianceRepairProcessed(data) {
            // Search assembly sheet appliance repair by id and replace then
            let appliance = this.assemblySheet.appliances.find((a) => a.id === data.applianceId)
            if (typeof appliance.repairs[data.index] !== "undefined") {
                appliance.repairs.splice(data.index, 1, data.entity)
            }
        },

        applianceRepairCancelled(data) {
            // Search assembly sheet appliance repair by id and replace then
            let appliance = this.assemblySheet.appliances.find((a) => a.id === data.applianceId)
            if (typeof appliance.repairs[data.index] !== "undefined") {
                appliance.repairs.splice(data.index, 1)
            }
        },

        addMaterial() {
            // Check if opened material already exists
            let l = this.assemblySheet.materials.find((a) => a.action === "create")
            if (typeof l !== "undefined") {
                return
            }

            let material = new AssemblySheetMaterialEntity()
            material.action = "create"
            this.assemblySheet.materials.push(material)
        },

        materialProcessed(data) {
            // Search assembly sheet appliance repair by id and replace then
            if (typeof this.assemblySheet.materials[data.index] !== "undefined") {
                this.assemblySheet.materials.splice(data.index, 1, data.entity)
            }
        },

        materialCancelled(data) {
            // Search assembly sheet appliance material by id and replace then
            if (typeof this.assemblySheet.materials[data.index] !== "undefined") {
                this.assemblySheet.materials.splice(data.index, 1)
            }
        },

        addItem() {
            // Check if opened item already exists
            let l = this.assemblySheet.items.find((a) => a.action === "create")
            if (typeof l !== "undefined") {
                return
            }

            let item = new AssemblySheetItemEntity()
            item.action = "create"
            this.assemblySheet.items.push(item)
        },

        itemProcessed(data) {
            // Search assembly sheet other item by id and replace then
            if (typeof this.assemblySheet.items[data.index] !== "undefined") {
                this.assemblySheet.items.splice(data.index, 1, data.entity)
            }
        },

        itemCancelled(data) {
            // Search assembly sheet other item by id and replace then
            if (typeof this.assemblySheet.items[data.index] !== "undefined") {
                this.assemblySheet.items.splice(data.index, 1)
            }
        },

        onApplianceAdded(appliance) {
            this.assemblySheet.appliances.push(appliance)
        },

        recalculationClicked() {
            this.doRecalculation()
        },

        doRecalculation() {
            this.$store.dispatch("assemblySheet/process/recalculationAssemblySheet", this.assemblySheet.id)
                .catch(() => {
                })
        },

    },

}
</script>
