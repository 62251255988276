<template>

  <tr class="border-bottom" v-bind:class="{'table-warning': client.orders.length > 0}" style="cursor: pointer"
      v-on:click.prevent="$router.push({name: 'supply_point_show', params : {id: client.supplyPoint.id}})">
    <td>
      {{ client.name }} <small>({{ subjectType.getItem(client.type).short_text }})</small>
    </td>
    <td>
      <i :class="commodity.getItem(client.commodity).icon"></i><span class="ml-3">{{ client.supplyPoint.ean }}</span>
    </td>
    <td>
      {{ client.address }}
    </td>
    <td>
        <small>
          <span class="mr-2" v-if="client.supplyPoint.name"><strong class="font-weight-600">Název OM:</strong> {{client.supplyPoint.name}}</span>
          <span class="mr-2" v-if="client.supplyPoint.floor_number"><strong class="font-weight-600">Patro:</strong> {{client.supplyPoint.floor_number}}</span>
          <span v-if="client.supplyPoint.apartment_number"><strong class="font-weight-600">Byt:</strong> {{client.supplyPoint.apartment_number}}</span>
        </small>
    </td>
    <td class="text-right w-10 align-middle">
      <div v-if="client.orders.length !== 0">
        {{ client.orders.length }}
        <span class="badge badge-primary p-1">
          <i class="fad fa-user-hard-hat"></i>
        </span>
      </div>
    </td>
  </tr>

</template>

<script>
import SubjectType from "@/Model/Enum/subject_type"
import Commodity from "@/Model/Enum/commodity"

export default {
  props: ['client'],
  data() {
    return {
      subjectType: SubjectType,
      commodity: Commodity,
    }
  }
}
</script>
