import AssemblySheetService from "../AssemblySheetService"
import AssemblySheetApplianceEntity from "../Entity/AssemblySheetApplianceEntity"

const
    CREATE_RECORD = "CREATE_RECORD",
    CREATE_RECORD_SUCCESS = "CREATE_RECORD_SUCCESS",
    CREATE_RECORD_ERROR = "CREATE_RECORD_ERROR"

const initialState = {
    isCreating: false,
    record: null
}

export const state = {...initialState}

export const actions = {

    async createAppliance({commit}, {id, formData}) {
        try {
            commit(CREATE_RECORD)
            let response = await AssemblySheetService.createAppliance(id, formData)
            commit(CREATE_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATE_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    }

}

export const mutations = {

    [CREATE_RECORD](state) {
        state.isCreating = true
        state.error = null
        state.record = null
    },
    [CREATE_RECORD_SUCCESS](state, data) {
        state.isCreating = false
        state.error = null
        state.record = AssemblySheetApplianceEntity.from(data)
    },
    [CREATE_RECORD_ERROR](state, error) {
        state.isCreating = false
        state.error = error
        state.record = null
    }

}

export const getters = {

    isCreating(state) {
        return state.isCreating
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasRecord(state) {
        return state.record !== null
    },
    getRecord(state) {
        return state.record
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
