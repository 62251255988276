export default class SupplyPointConnectionContractEntity {

    constructor() {
        this.id = null
        this.contractNumber = ''
        this.masterContract = null
        this.benefits = ''
    }

    /**
     * @param response : object
     * @returns SupplyPointConnectionContractEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.contractNumber = response.contractNumber
        obj.masterContract = response.masterContract
        obj.benefits = response.benefits
        return obj
    }

}
