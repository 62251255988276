export default class PlanningFilterRequest {

    constructor() {
        this.commodity = 1
        this.onlyBenefits = false
        this.onlyFree = false
        this.includeOnlyRepairTypes = []
        this.perPage = 10
        this.page = 1
        this.gpsLat = null
        this.gpsLng = null
        this.radius = 10
        this.address = ""
        this.serviceLevel = null
        this.sortBy = "plannedDateOfRepair"
        this.sortDesc = false
        this.startDate = null
        this.endDate = null
    }

    toForm() {
        return this
    }

}
