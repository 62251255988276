export default class SupplyPointRequest {

    constructor() {
        this.ean = ''
        this.customer = {
            id: null,
            name: '',
            address: ''
        }
        this.propertyOwner = {
            id: null,
            name: '',
            address: ''
        }
        this.address = {
            id: null,
            name: ''
        }
        this.commodity = 1
        this.serviceContract = 0
        this.floorNumber = ''
        this.apartmentNumber = ''
        this.name = ''
    }

    static from(response) {

        let req = new this
        req.ean = response.ean
        if (response.customer) {
            req.customer = {
                id: response.customer.id,
                name: response.customer.wholeName,
                address: response.customer.primaryAddress ? response.customer.primaryAddress.wholeName : '',
            }
        }
        if (response.propertyOwner) {
            req.propertyOwner = {
                id: response.propertyOwner.id,
                name: response.propertyOwner.wholeName,
                address: response.propertyOwner.primaryAddress ? response.propertyOwner.primaryAddress.wholeName : '',
            }
        }
        if (response.address) {
            req.address = {
                id: response.address.id,
                wholeName: response.address.wholeName,
            }
        }
        req.commodity = response.commodity
        req.serviceContract = response.serviceContract
        req.floorNumber = response.floorNumber
        req.apartmentNumber = response.apartmentNumber
        req.name = response.name
        return req
    }

    toForm() {
        return {
            ean: this.ean || '',
            customer: this.customer.id,
            propertyOwner: this.propertyOwner.id || null,
            address: this.address.id,
            commodity: this.commodity,
            serviceContract: this.serviceContract,
            floorNumber: this.floorNumber,
            apartmentNumber: this.apartmentNumber,
            name: this.name
        }
    }

    resetAddress() {
        this.address = {
            id: null,
            wholeName: ''
        }
    }

    resetCustomer() {
        this.customer = {
            id: null,
            name: '',
            address: ''
        }
    }

    resetPropertyOwner() {
        this.propertyOwner = {
            id: null,
            name: '',
            address: ''
        }
    }

}
