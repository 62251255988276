import SubjectList from "./SubjectList.vue"
import SubjectUpdate from "./SubjectUpdate.vue"
import SubjectWrapper from "./SubjectWrapper.vue"
import SubjectShow from "./SubjectShow.vue"
import WhiteMain from "../General/WhiteMain.vue"

const subjectRoutes = [
    {
        path: "/subjects",
        component: WhiteMain,
        children: [
            {
                path: "",
                name: "subject_list",
                component: SubjectList,
            },
            {
                path: ":id",
                name: "subject_update_wrapper",
                component: SubjectWrapper,
                children: [
                    {
                        path: "",
                        name: "subject_show",
                        component: SubjectShow,
                    },
                    {
                        path: "update",
                        name: "subject_update",
                        component: SubjectUpdate,
                    },
                ],
            },
        ],
    },
]

export default subjectRoutes
