<template>
    <tr class="border-top">
        <template v-if="contact._action !== null">
            <td class="align-middle">
                <BFormInput
                    v-model="request.name"
                    :disabled="isProcessing"
                    type="text"
                    size="sm"
                    placeholder="Název kontaktu"
                    required
                />
            </td>
            <td class="align-middle">
                <BFormInput
                    v-model="request.phone"
                    :disabled="isProcessing"
                    type="text"
                    size="sm"
                    placeholder="Telefonní číslo"
                    required
                />
            </td>
            <td class="align-middle px-4">
                <BFormInput
                    v-model="request.email"
                    :disabled="isProcessing"
                    type="email"
                    size="sm"
                    placeholder="E-mailová adresa"
                />
            </td>
            <td class="align-middle text-right">
                <BButton size="sm" variant="danger" @click="cancelRow()">
                    <i class="fa fa-times fa-fw"/>
                </BButton>
                <BButton
                    :disabled="isProcessing"
                    @click="saveRow()"
                    size="sm"
                    variant="success"
                    class="ml-3"
                >
                    <i class="fad fa-spinner-third fa-spin fa-fw" v-if="isProcessing"/>
                    <i class="fad fa-check fa-fw" v-else/>
                </BButton>
            </td>
        </template>
        <template v-else>
            <td class="align-middle">
                <span v-if="contact.name">{{ contact.name }}</span>
                <em v-else>nevyplněno</em>
            </td>
            <td class="align-middle">
                {{ contact.phone }}
            </td>
            <td class="align-middle">
                {{ contact.email }}
            </td>
            <td class="align-middle text-right">
                <BButton size="sm" variant="danger" @click="deleteRow()">
                    <i class="fad fa-trash fa-fw"/>
                </BButton>
                <BButton size="sm" variant="primary" @click="openEditFormMode()" class="ml-3">
                    <i class="fad fa-edit fa-fw"/>
                </BButton>
            </td>
        </template>
    </tr>
</template>

<script>
import {mapGetters} from "vuex"
import SubjectContactBasicEntity from "../../../NewModel/Subject/Entity/SubjectContactBasicEntity"
import SubjectContactRequest from "../../../NewModel/Subject/Request/SubjectContactRequest"

export default {

    props: {
        subjectId: {
            type: String,
            required: true,
        },
        contact: {
            type: SubjectContactBasicEntity,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isProcessing: "subject/contacts/isProcessing",
        }),
    },

    data() {
        return {
            request: new SubjectContactRequest
        }
    },

    mounted() {
        if (this.contact._action === "create") {
            this.openCreateFormMode()
        }
    },

    methods: {

        openFormMode(mode) {
            this.contact._action = mode
        },

        openCreateFormMode() {
            this.openFormMode("create")
        },

        openEditFormMode() {
            this.openFormMode("edit")
            this.fillFormFromEntity(this.contact)
        },

        fillFormFromEntity(entity) {
            this.request = SubjectContactRequest.from(entity)
        },

        cancelRow() {
            if (this.contact._action === "edit") {
                this.fillFormFromEntity(this.contact)
                this.contact._action = null
            } else {
                this.$emit("cancelled", {
                    subjectId: this.subjectId,
                    index: this.index
                })
            }
        },

        deleteRow() {
            let self = this
            this.$bvModal.msgBoxConfirm("Opravdu chcete odstranit tento kontakt?", {
                title: "Potvrzení",
                okVariant: "primary",
                cancelVariant: "default",
                centered: true,
                okTitle: "Ano",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("subject/contacts/deleteSubjectContact", {
                            subject: this.subjectId,
                            id: this.contact.id,
                        })
                            .then(() => {
                                self.$emit("cancelled", {
                                    subjectId: this.subjectId,
                                    index: this.index
                                })
                                self.$emit("deleted", this.contact.id)
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

        saveRow() {
            try {
                this.checkForm()
                if (this.contact._action === "edit") {
                    this.$store.dispatch("subject/contacts/updateSubjectContact", {
                        subject: this.subjectId,
                        id: this.contact.id,
                        request: this.request
                    })
                        .then((response) => {
                            this.contact.action = null
                            this.$emit("updated", {
                                subjectId: this.subjectId,
                                index: this.index,
                                entity: response
                            })
                        })
                } else if (this.contact._action === "create") {
                    this.$store.dispatch("subject/contacts/createSubjectContact", {
                        id: this.subjectId,
                        request: this.request
                    })
                        .then((response) => {
                            this.contact.action = null
                            this.$emit("created", {
                                subjectId: this.subjectId,
                                index: this.index,
                                entity: response
                            })
                        })
                }

            } catch (err) {
                this.warningMessage(err)
                return false
            }
        },

        checkForm() {
            if (this.request.name === "") {
                throw "Vyplňte jméno!"
            }
            if (this.request.phone === "") {
                throw "Vyplňte telefonní číslo!"
            }
        },

    },

}
</script>
