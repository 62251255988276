<template>
    <div class="container">
        <LoadingBlock v-if="!hasRepairType && isLoading"/>
        <RouterView v-if="hasRepairType"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import LoadingBlock from "../../Common/UI/LoadingBlock.vue"

export default {

    components: {
        LoadingBlock,
    },

    computed: {
        ...mapGetters({
            isLoading: 'repairType/isLoading',
            hasRepairType: 'repairType/hasRepairType',
            repairType: 'repairType/getRepairType',
        }),
    },

    created() {
        this.$store.dispatch("appliance/repairs/clear")
        this.loadRepairType(this.$route.params.id)
    },

    methods: {

        loadRepairType(id) {
            this.$store.dispatch("repairType/getRepairType", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
