<template>
    <BButton :size="size" :variant="variant" @click.prevent="downloadFile" class="mr-2">
        <i :class="icon" v-if="icon"/>
        <span :class="{'ml-3': icon !== null}" v-if="text">{{ text }}</span>
    </BButton>
</template>

<script>
export default {

    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
            default: '',
        },
        text: {
            type: String,
            required: false,
            default: "",
        },
        variant: {
            type: String,
            required: false,
            default: 'primary',
        },
        size: {
            type: String,
            required: false,
            default: '',
        },
    },

    methods: {

        downloadFile() {
            this.$store.dispatch('attachment/downloadAttachment', {
                id: this.id,
                name: this.name
            })
                .catch((error) => {
                    this.errorMessage(error.response.status === 404 ? 'Soubor nebyl nalezen.' : 'Nepodařilo se stáhnout soubor')
                })
        },

    },

}
</script>
