import WhiteMain from "../General/WhiteMain.vue"
import OrderList from "./OrderList.vue"
import DailyOverview from "./DailyOverview.vue"
import Order from "./Order.vue"
import NewOrderProcess from "./OrderProcess.vue"
import OrderShow from "./OrderShow.vue"
import OrderSuccessfullyUpdated from "./OrderSuccessfullyUpdated.vue"
import OrderSuccessfullyFinished from "./OrderSuccessfullyFinished.vue"
import OrderCancelled from "./OrderCancelled.vue"

const orderRoutes = [

    {
        path: "/orders",
        component: WhiteMain,
        children: [
            {
                path: "",
                redirect: {name: "order_list"},
            },
            {
                path: "list",
                name: "order_list",
                component: OrderList,
            },
            {
                path: "daily/:date?",
                name: "order_daily",
                component: DailyOverview,
            },
            {
                path: ":id",
                name: "order",
                component: Order,
                props: true,
                children: [
                    {
                        path: "show",
                        name: "order_show",
                        component: OrderShow,
                    },
                    {
                        path: "form",
                        name: "order_form",
                        component: NewOrderProcess,
                    },
                    {
                        path: "updated",
                        name: "order_successfully_updated",
                        component: OrderSuccessfullyUpdated,
                    },
                    {
                        path: "finished",
                        name: "order_successfully_finished",
                        component: OrderSuccessfullyFinished,
                    },
                    {
                        path: "cancelled",
                        name: "order_cancelled",
                        component: OrderCancelled,
                    },
                ],
            },
        ],
    },
]

export default orderRoutes
