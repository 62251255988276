import WorkerService from "../../WorkerService"

const
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR"

const initialState = {
    isLoading: false,
    record: null,
    error: null
}

export const state = {...initialState}

export const actions = {

    async getDetailOfSupplyPoint({commit}, id) {
        try {
            commit(FETCHING_RECORD)
            let response = await WorkerService.getDetailOfSupplyPoint(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return response.data
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    }

}

export const mutations = {

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
        state.record = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = data
    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.record = null
    }

}

export const getters = {

    isLoading(state) {
        return state.isCreating
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasRecord(state) {
        return state.record !== null
    },
    getRecord(state) {
        return state.record
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
