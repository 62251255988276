<template>
    <BFormSelect
        v-model="selectedType"
        :options="types"
        value-field="id"
        text-field="name"
        required
        :disabled="isLoading"
        @change="onChange"
    >
        <template #first>
            <option :value="null" disabled>--- Vyberte druh spotřebiče ---</option>
        </template>
    </BFormSelect>
</template>
<script>
import {mapGetters} from "vuex"

export default {

    model: {
        prop: "value",
        event: "change",
    },

    props: {
        value: {
            validator: function (value) {
                return value === null || value === undefined || typeof value === 'number';
            },
            required: true,
        },
    },

    data() {
        return {
            selectedType: this.value,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'appliance/types/isLoading',
            hasTypes: 'appliance/types/hasTypes',
            types: 'appliance/types/getTypes',
        }),
    },

    mounted() {
        this.loadApplianceTypes()
    },

    watch: {
        value(newValue) {
            this.selectedType = newValue;
        },
    },

    methods: {

        loadApplianceTypes() {
            this.$store.dispatch("appliance/types/findAllActiveTypes")
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        onChange(value) {
            this.$emit('change', value)
        },

    },

}
</script>
