<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-5">
            <div class="d-flex align-items-center">
                <h3 class="font-weight-600 text-dark mb-0">
                    <i class="fad fa-car-mechanic text-primary fa-fw mr-3"/>Zakázka #{{ order.sequenceNumber }}
                    <i class="fad fa-lock-alt text-white fa-fw ml-3 text-warning" v-if="order.locked"/>
                </h3>
                <AddWindow :order="order" class="ml-4"/>
            </div>
            <div class="d-flex align-items-center">
                <!--                <CreateAssemblySheet :order="order.id" class="btn btn-success" />-->
                <ChangeStatusModalForm :order="order"/>
                <PrimaryLinkButton
                    to="/worker/dashboard"
                    class="ml-4"
                    icon="fad fa-chevron-left"
                    text="Zpět"
                />
            </div>
        </div>

        <BTabs
            content-class="mt-5"
            active-nav-item-class="text-white bg-primary border-radius-sm"
            pills
            lazy
            justified
        >
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase" active>
                <template #title>
                    <div class="m-1">
                        Informace
                    </div>
                </template>

                <BRow>
                    <BCol class="mb-4">
                        <h5>Zakázka</h5>
                        <div class="p-4 bg-little-gray">
                            <table class="table table-sm table-borderless mb-0">
                                <tr>
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Identifikátor zakázky">
                                        Zakázka:
                                    </td>
                                    <td>
                                        #{{ order.sequenceNumber }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Datum a čas realizace">
                                        Datum:
                                    </td>
                                    <td>
                                        {{
                                            {
                                                startTime: order.startTime,
                                                endTime: order.endTime
                                            }|prettyDateRange(false, false, false)
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Adresa">
                                        Adresa:
                                    </td>
                                    <td>
                                        {{ order.addressName }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Vzdálenost k místu">
                                        Cesta:
                                    </td>
                                    <td>
                                        <div v-if="distance_hasResponse">
                                            <em>
                                                {{
                                                    distance_response.distance || "- km"
                                                }}({{ distance_response.durationInTraffic || "- min." }})
                                            </em>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="border-top border-light" v-if="order.note">
                                    <td
                                        class="font-weight-600 w-30 text-danger text-uppercase"
                                        v-b-tooltip.hover
                                        title="Poznámka k zakázce"
                                    >
                                        Poznámka:
                                    </td>
                                    <td v-html="formatBreakLines(order.note)"/>
                                </tr>
                                <tr class="border-top border-light" v-if="order.assemblySheet !== null">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover
                                        title="Zobrazení montážního listu">
                                        Montáž. list:
                                    </td>
                                    <td>
                                        <BLink
                                            :to="'/worker/assembly_sheets/' + order.assemblySheet"
                                            v-if="order.assemblySheetFinished === true"
                                        >
                                            Zobrazit
                                        </BLink>
                                        <BLink
                                            :to="'/worker/assembly_sheets/' + order.assemblySheet+ '/edit'"
                                            v-if="order.assemblySheetFinished === false"
                                        >
                                            Dokončit
                                        </BLink>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </BCol>
                    <BCol class="mb-4">
                        <h5>Subjekt</h5>
                        <div class="p-4 bg-little-gray">
                            <table class="table table-sm table-borderless mb-0">
                                <tr>
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Jméno zákazníka">
                                        Zákazník:
                                    </td>
                                    <td>
                                        {{ order.customerName }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Jméno vlastníka">
                                        Vlastník:
                                    </td>
                                    <td>
                                        {{ order.propertyOwnerName }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Kontaktní osoba">
                                        Kontakt:
                                    </td>
                                    <td>
                                        {{ order.contact.name }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover
                                        title="Telefon na kontaktní osobu">
                                        Telefon:
                                    </td>
                                    <td>
                                        {{ order.contact.phone }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </BCol>
                </BRow>

                <BRow>
                    <BCol>
                        <h5>Odběrné místo</h5>
                        <div class="p-4 bg-little-gray">
                            <table class="table table-sm table-borderless mb-0">
                                <tr>
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="EAN / EIC kód">
                                        EAN:
                                    </td>
                                    <td>
                                        <BLink
                                            class="border-dashed"
                                            :to="'/worker/supply_points/' + order.supplyPoint.id + '?order=' + order.id"
                                        >
                                            {{ order.supplyPoint.ean }}
                                        </BLink>
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Patro">
                                        Patro:
                                    </td>
                                    <td>
                                        {{ order.supplyPoint.floorNumber }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Číslo bytu">
                                        Byt:
                                    </td>
                                    <td>
                                        {{ order.supplyPoint.apartmentNumber }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-30" v-b-tooltip.hover title="Název odběrného místa">
                                        Název:
                                    </td>
                                    <td>
                                        {{ order.supplyPoint.name|shortify(35) }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </BCol>
                    <BCol>
                        <h5>Úhrada</h5>
                        <div class="p-4 bg-little-gray">
                            <table class="table table-sm table-borderless mb-0">
                                <tr>
                                    <td class="font-weight-600 w-40" v-b-tooltip.hover title="Forma úhrady">
                                        Forma:
                                    </td>
                                    <td>
                                        <span v-if="order.payment.formOfPayment === 'invoice'"> fakturou</span>
                                        <span v-if="order.payment.formOfPayment === 'cash'"> hotově</span>
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-40" v-b-tooltip.hover title="Předběžná smluvní cena">
                                        Před. cena:
                                    </td>
                                    <td>
                                        {{ order.payment.estimatedAmount|moneyFormat("CZK") }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-40" v-b-tooltip.hover title="Plátce zakázky">
                                        Plátce:
                                    </td>
                                    <td>
                                        {{ payer.print(order.payment.payer) }}
                                    </td>
                                </tr>
                                <tr class="border-top border-light">
                                    <td class="font-weight-600 w-40" v-b-tooltip.hover title="Servisní úroveň">
                                        Serv. úroveň:
                                    </td>
                                    <td>
                                        {{ order.supplyPoint.serviceContract }}
                                        <span v-if="order.supplyPoint.serviceContract"> (zákazník KVPLYN)</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </BCol>
                </BRow>

                <div class="mt-4 text-center" v-if="order.assemblySheet === null">
                    <CreateAssemblySheet
                        :order="order.id"
                        link-text="Vytvořit montážní list (nepoužívat)"
                    />
                </div>

            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Položky
                        <BBadge variant="danger" class="ml-1" v-if="order.metadata.numberOfRepairs > 0">
                            {{ order.metadata.numberOfRepairs }}
                        </BBadge>
                    </div>
                </template>
                <Items :order="order"/>
            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Dokumenty
                        <BBadge variant="danger" class="ml-1" v-if="order.metadata.numberOfAttachments > 0">
                            {{ order.metadata.numberOfAttachments }}
                        </BBadge>
                    </div>
                </template>
                <Attachments :order="order"/>
            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Materiál
                        <BBadge variant="danger" class="ml-1" v-if="order.metadata.numberOfMaterials > 0">
                            {{ order.metadata.numberOfMaterials }}
                        </BBadge>
                    </div>
                </template>
                <IssuedMaterial :order="order"/>
            </BTab>
            <BTab title-item-class="bg-gray-300 border-radius-sm text-uppercase ml-3">
                <template #title>
                    <div class="m-1">
                        Poznámky
                        <BBadge variant="danger" class="ml-1" v-if="order.metadata.numberOfNotes > 0">
                            {{ order.metadata.numberOfNotes }}
                        </BBadge>
                    </div>
                </template>
                <TechnicalNotes :supply-point="order.supplyPoint.id"/>
            </BTab>
        </BTabs>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import AddWindow from "./Component/AddWindow.vue"
import Attachments from "./Component/Attachments.vue"
import ChangeStatusModalForm from "./Component/ChangeStatusModalForm.vue"
import CreateAssemblySheet from "../AssemblySheet/Component/CreateAssemblySheet.vue"
import IssuedMaterial from "./Component/IssuedMaterial.vue"
import Items from "./Component/Items.vue"
import payer from "../../../Model/Enum/payer"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"
import TechnicalNotes from "../SupplyPoint/Component/TechnicalNotes.vue"

export default {

    components: {
        AddWindow,
        Attachments,
        ChangeStatusModalForm,
        CreateAssemblySheet,
        IssuedMaterial,
        Items,
        PrimaryLinkButton,
        TechnicalNotes,
    },

    data() {
        return {
            payer: payer,
        }
    },

    computed: {
        ...mapGetters({
            order: "worker/order/getOrder",
            distance_isLoading: "address/distance/isLoading",
            distance_hasResponse: "address/distance/hasResponse",
            distance_response: "address/distance/getResponse",
        })
    },

    mounted() {
        if (this.order.address) {
            let self = this
            navigator.geolocation.getCurrentPosition(
                position => {
                    self.loadDistance(position.coords.latitude, position.coords.longitude)
                }
            )
        }
    },

    methods: {

        loadDistance(gpsLat, gpsLon) {
            this.$store.dispatch("address/distance/getDistance", {
                id: this.order.address,
                gpsLat: gpsLat,
                gpsLon: gpsLon,
            })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        formatBreakLines(text) {
            return text.replace(/\n/g, "<br />")
        },

    },

}
</script>
