<template>
    <div>

        <Header icon="fad fa-tools" title="Typy oprav">
            <template #buttons>
                <PrimaryLinkButton
                    to="/setup/repair_types/create"
                    icon="fad fa-plus-circle"
                    text="Přidat typ opravy"
                />
            </template>
        </Header>

        <RepairTypesList/>

    </div>
</template>

<script>
import Header from "../../Common/UI/Header.vue"
import PrimaryLinkButton from "../../Common/UI/Button/PrimaryLinkButton.vue"
import RepairTypesList from "./Component/RepairTypesList.vue"

export default {

    components: {
        Header,
        PrimaryLinkButton,
        RepairTypesList,
    },

}
</script>
