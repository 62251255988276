<template>
    <div>
        <form ref="form" @submit.prevent="processForm()">

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="name" class="bold col-form-label">
                        Název opravy: <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormInput
                        v-model="request.name"
                        id="name"
                        type="text"
                        required
                        autocomplete="off"
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="shortName" class="bold col-form-label">
                        Zkratka:
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormInput
                        v-model="request.shortName"
                        id="shortName"
                        type="text"
                        autocomplete="off"
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="description" class="bold col-form-label">
                        Popis opravy:
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormTextarea
                        v-model="request.description"
                        id="description"
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="repairType" class="bold col-form-label">
                        Typ opravy: <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormRadioGroup
                        v-model="request.type"
                        :options="list.typeOfRepairType"
                        id="repairType"
                        name="type-select"
                        value-field="id"
                        text-field="text"
                        stacked
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4" v-if="request.type === 2">
                <BCol lg="3" md="12">
                    <label for="repeatAfterMonths" class="bold col-form-label">
                        Perioda (v měsících): <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormInput
                        v-model.number="request.repeatAfterMonths"
                        id="repeatAfterMonths"
                        type="number"
                        min="0"
                        max="96"
                        step="1"
                        required
                        autocomplete="off"
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label
                        for="commodity"
                        class="bold col-form-label"
                    >
                        Komodita: <span class="text-danger">*</span>
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <BFormRadioGroup
                        v-model="request.commodity"
                        :options="list.commodity"
                        @change="commodityChanged"
                        id="commodity"
                        name="commodity-select"
                        value-field="id"
                        text-field="text"
                        stacked
                    />
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12">
                    <label for="parent" class="bold col-form-label">
                        Nadřazený typ opravy:
                    </label>
                </BCol>
                <BCol lg="9" md="12">
                    <div class="alert alert-info p-4" v-if="isLoading">
                        <i class="fa fa-spinner fa-spin mr-4"/>Nahrávám typy oprav ...
                    </div>
                    <BSelect
                        v-model="request.parent"
                        :options="list.rootRepairTypes"
                        id="parent"
                        value-field="id"
                        text-field="name"
                    >
                        <template #first>
                            <BFormSelectOption :value="null">
                                -- Bez nadřazeného typu opravy --
                            </BFormSelectOption>
                        </template>
                    </BSelect>
                </BCol>
            </BRow>

            <BRow class="mb-4">
                <BCol lg="3" md="12"/>
                <BCol lg="9" md="12">
                    <BFormCheckbox v-model="request.active">
                        Aktivní
                    </BFormCheckbox>
                </BCol>
            </BRow>

            <BRow>
                <BCol lg="3" md="12"/>
                <BCol lg="9" md="12">
                    <BButton type="submit" variant="primary" :disabled="isProcessing">
                        <i class="fad fa-save mr-3"/>Uložit
                    </BButton>
                </BCol>
            </BRow>

        </form>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Commodity from "../../../../Model/Enum/commodity"
import RepairTypeRequest from "../../../../NewModel/RepairType/Request/RepairTypeRequest"
import TypeOfRepairType from "../../../../Model/Enum/type_of_repair_type"

export default {

    props: {
        request: {
            type: RepairTypeRequest,
            required: true,
        },
        isProcessing: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isLoading: 'repairType/isLoading',
        }),
    },

    data() {
        return {
            list: {
                commodity: Commodity.list(),
                rootRepairTypes: [],
                typeOfRepairType: TypeOfRepairType.list(),
            },
        }
    },

    mounted() {
        this.loadRepairTypesItems()
    },

    methods: {

        commodityChanged() {
            this.repairType.parent = null
            this.loadRepairTypesItems()
        },

        loadRepairTypesItems() {
            let self = this
            self.list.repairTypes = []
            this.$store.dispatch("repairType/clearAll")
            this.$store.dispatch("repairType/findRootsRepairTypes")
                .then((records) => {
                    self.list.repairTypes = records.filter(
                        repairType => repairType.commodity === self.request.commodity
                    )
                })
        },

        processForm() {
            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Selhala validace. Byla všechna požadovaná pole vyplněna?')
                return
            }
            this.$emit('submitted', this.request)
        },

    },

}
</script>
