<template>
    <div>
        <div class="py-2 px-3 mb-3 text-white" style="background-color: #2a7dc4;">
            <strong>Doprava:</strong>
        </div>

        <table class="table table-sm table-stripped mb-4">
            <tbody>
            <tr style="background: #e3e5e8;">
                <th>
                    Druh dopravy
                </th>
                <th class="text-center w-15">
                    Kč / km
                </th>
                <th class="text-center w-15">
                    Počet km
                </th>
                <th class="text-right w-15">
                    Celkem / Kč
                </th>
            </tr>
            <tr>
                <td>
                    <span v-if="assemblySheet.pricing.transportType === 1">Sazba za km</span>
                    <span v-if="assemblySheet.pricing.transportType === 2">Paušál</span>
                </td>
                <td class="text-center">
                    {{ assemblySheet.pricing.transportPricePerKm|moneyFormat("CZK_LONG") }}
                </td>
                <td class="text-center">
                    {{ assemblySheet.pricing.transportDistance }}
                </td>
                <td class="text-right">
                    {{ assemblySheet.pricing.priceForTransport|moneyFormat("CZK_LONG") }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import AssemblySheetEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetEntity"

export default {

    props: {
        assemblySheet: {
            type: AssemblySheetEntity,
            required: true,
        },
    },

}
</script>
