export default class ApplianceTypeFilterEntity {

    constructor() {
        this.id = null
        this.name = ""
        this.commodity = 1
        this.active = false
    }

    /**
     * @param response : object
     * @returns ApplianceTypeFilterEntity
     */
    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.commodity = response.commodity
        obj.active = response.active
        return obj
    }

    to() {
        return this.id
    }

}
