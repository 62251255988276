<template>
    <div class="container-fluid p-4">
        <div class="d-flex justify-content-between align-items-center mb-5">
            <h3 class="font-weight-600 text-dark mb-0">
                <i class="fad fa-car-mechanic text-primary fa-fw mr-3"/>Mé zakázky
            </h3>
            <div class="buttons">
                <DateSwitcher v-on:changed="dateChanged"/>
            </div>
        </div>

        <div v-if="isLoading === false && records.length > 0">
            <OrderRow :record="record" v-for="record in records" :key="record.id"/>
        </div>

        <TableLoading v-else-if="isLoading === true && records.length === 0"/>
        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import DateSwitcher from "./Component/DateSwitcher.vue"
import OrderRow from "./Component/OrderRow.vue"
import WorkerService from "../../../NewModel/Worker/WorkerService"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        DateSwitcher,
        OrderRow,
        TableLoading,
        TableWithoutRecords,
    },

    data() {
        return {
            isLoading: false,
            records: [],
        }
    },

    methods: {

        dateChanged(requiredDate) {
            this.loadOrders(requiredDate)
        },

        loadOrders(requiredDate) {

            let self = this
            self.isLoading = true
            self.records = []

            WorkerService.findOrdersOfWorkerOfRequiredDate(requiredDate)
                .then((response) => {
                    self.records = response.data
                })
                .catch((error) => {
                    self.errorMessage(error.response.data.message)
                })
                .finally(() => {
                    self.isLoading = false
                })
        },

    },

}
</script>
