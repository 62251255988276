<template>
    <div class="container">

        <Header icon="fad fa-piggy-bank" title="Nová sleva">
            <template #buttons>
                <BackLinkButton to="/discounts"/>
            </template>
        </Header>

        <div class="bg-white p-3 border-radius-sm">
            <DiscountForm
                :request="request"
                :is-processing="isProcessing"
                v-on:submitted="onSubmitted"
            />
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../Common/UI/Button/BackLinkButton.vue"
import DiscountForm from "./Component/DiscountForm.vue"
import DiscountRequest from "../../NewModel/Discount/Request/DiscountRequest"
import Header from "../Common/UI/Header.vue"

export default {

    components: {
        BackLinkButton,
        DiscountForm,
        Header,
    },

    data() {
        return {
            request: new DiscountRequest(),
        }
    },

    computed: {
        ...mapGetters({
            isProcessing: "discount/isProcessing",
        }),
    },

    created() {
        this.$store.dispatch("discount/clear")
    },

    methods: {

        /**
         * @param request : DiscountRequest
         */
        onSubmitted(request) {
            this.$store.dispatch("discount/createDiscount", request)
                .then(() => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'discounts_list'})
                })
                .catch((error) => {
                    this.warningMessage(error.response.data.message)
                })
        },

    },

}
</script>
