import TrackingService from "./TrackingService"

const
    PROCESSING = "PROCESSING",
    PROCESSING_SUCCESS = "PROCESSING_SUCCESS",
    PROCESSING_ERROR = "PROCESSING_ERROR"

const initialState = {
    isProcessing: false,
}

export const state = {...initialState}

export const actions = {

    async doLog({commit}, {latitude, longitude, order}) {
        try {
            commit(PROCESSING)
            let response = await TrackingService.doLog(latitude, longitude, order)
            commit(PROCESSING_SUCCESS, response.data)
            return response.data
        } catch (error) {
            console.log(error.response)
            commit(PROCESSING_ERROR, error)
            return Promise.reject(error)
        }
    },

}

export const mutations = {

    [PROCESSING](state) {
        state.isProcessing = true
    },
    [PROCESSING_SUCCESS](state) {
        state.isProcessing = false
    },
    [PROCESSING_ERROR](state) {
        state.isProcessing = false
    },

}

export const getters = {
    isProcessing(state) {
        return state.isProcessing
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
