export default class OrderMetadataApplianceEntity {

    constructor() {
        this.id = null
        this.name = ''
        this.applianceType = ''
        this.serialNumber = ''
    }

    /**
     * @param response : object
     * @returns {OrderMetadataApplianceEntity}
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.applianceType = response.applianceType
        obj.serialNumber = response.serialNumber
        return obj
    }

}
