<template>
    <div>
        <BFormFile
            :class="inputClass"
            :browse-text="inputBrowse"
            id="attachments"
            :placeholder="inputPlaceholder"
            drop-placeholder="Zde je pusťte"
            :multiple="isMultiple" @input="addFiles"
            ref="manualFiles"
            :size="inputSize"

        />
        <div class="p-3 d-flex align-items-center border-radius-sm table-warning mb-3" v-if="isUploading">
            <i class="fa-duotone fa-spinner fa-fw fa-2x fa-spin text-danger mr-3"/>
            Nahrávám dokumenty na server ...
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    props: {
        isMultiple: {
            default: true,
            type: Boolean,
            required: false,
        },
        inputClass: {
            default: "mb-3",
            type: String,
            required: false,
        },
        inputSize: {
            default: "md",
            type: String,
            required: false,
        },
        inputPlaceholder: {
            default: "Vyberte soubory v počítači nebo je přetáhněte do tohoto políčka.",
            type: String,
            required: false,
        },
        inputBrowse: {
            default: "Najít",
            type: String,
            required: false,
        },
    },

    computed: {
        ...mapGetters({
            isUploading: "attachment/isUploading",
        }),
    },

    methods: {

        addFiles(files) {
            let upload = []
            if (files instanceof File) {
                upload.push(files)
            } else {
                if (files.length === 0) {
                    return
                }
                upload = upload.concat(files)
            }

            this.$store.dispatch("attachment/uploadAttachments", upload)
                .then((uploadedAttachments) => {
                    if (uploadedAttachments.length === upload.length) {
                        this.successMessage("Soubory byly úspěšně uloženy na server!")
                    } else if (uploadedAttachments.length > 0) {
                        this.warningMessage("Ne všechny soubory byly nahrány na server")
                    } else if (uploadedAttachments.length === 0) {
                        this.errorMessage("Soubor se nepodařilo nahrát na server.")
                        return
                    }
                    this.$emit("uploaded", uploadedAttachments)
                })
        },

    },

}
</script>
