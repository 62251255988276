<template>
    <div
        class="d-flex flex-column flex-lg-row justify-content-start align-items-center bg-white py-3 border-radius-sm mb-4">

        <div class="w-15 d-flex align-items-center px-3">
            <i class="fa-duotone fa-circle fa-fw text-success fa-2x mr-3" v-if="record.solved === true"/>
            <span class="font-weight-300" v-if="record.solved === true">
                Vyřízeno
            </span>
            <i class="fa-duotone fa-circle fa-fw text-warning fa-2x mr-3" v-if="record.solved === false"/>
            <span class="font-weight-300" v-if="record.solved === false">
                K vyřízení
            </span>
        </div>

        <div class="w-10 text-center font-weight-600 pr-2">
            {{ record.createdAt|prettyDateTimeShort }}
        </div>

        <div class="font-weight-300 pr-2">
            {{ record.customer }}:
        </div>

        <div class="flex-grow-1 font-weight-300">
            {{ record.description }}
        </div>

        <div class="w-15 text-right mx-3" v-if="record.solved === false">
            <BButton variant="light" :disabled="isUpdating" @click="resolveIncident">
                <div>
                    <i class="fa-duotone fa-check text-success fa-fw mr-3" v-if="!isUpdating"/>
                    <i class="fa-duotone fa-spinner-third fa-spin-pulse fa-fw mr-3" v-else/>
                    Vyřešit
                </div>
            </BButton>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    props: {
        record: {
            type: Object,
            required: false,
        },
    },

    computed: {
        ...mapGetters({
            isUpdating: "incident/isUpdating",
        })
    },

    methods: {

        resolveIncident() {
            let self = this
            this.$bvModal.msgBoxConfirm("Opravu označit tento incident jako vyřešený?", {
                title: "Potvrzení",
                okVariant: "primary",
                cancelVariant: "default",
                centered: true,
                okTitle: "Ano",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("incident/resolveIncident", self.record.id)
                            .then((response) => {
                                self.record.solved = response.solved
                                this.$emit("updated")
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

    },

}
</script>
