<template>
    <BRow>

        <BCol class="mb-4">
            <DashboardOverviewNumberOfConceptsTile :value="data ? data.numberOfConcepts : 0"/>
        </BCol>

        <BCol class="mb-4">
            <DashboardOverviewNumberOfThisWeekOrdersTile :value="data ? data.numberOfThisWeekOrders : 0"/>
        </BCol>

        <BCol class="mb-4">
            <DashboardOverviewNumberOfTodayOrdersTile :value="data ? data.numberOfTodayOrders : 0"/>
        </BCol>

        <BCol class="mb-4">
            <DashboardOverviewNumberOfMyTodayOrdersTile :value="data ? data.numberOfMyTodayOrders : 0"/>
        </BCol>

    </BRow>
</template>

<script>
import {mapGetters} from "vuex"
import DashboardOverviewNumberOfConceptsTile from "./DashboardOverviewNumberOfConceptsTile.vue"
import DashboardOverviewNumberOfMyTodayOrdersTile from "./DashboardOverviewNumberOfMyTodayOrdersTile.vue"
import DashboardOverviewNumberOfThisWeekOrdersTile from "./DashboardOverviewNumberOfThisWeekOrdersTile.vue"
import DashboardOverviewNumberOfTodayOrdersTile from "./DashboardOverviewNumberOfTodayOrdersTile.vue"

export default {

    components: {
        DashboardOverviewNumberOfConceptsTile,
        DashboardOverviewNumberOfMyTodayOrdersTile,
        DashboardOverviewNumberOfThisWeekOrdersTile,
        DashboardOverviewNumberOfTodayOrdersTile,
    },

    computed: {
        ...mapGetters({
            data: "dashboard/orderReviews/getData",
        }),
    },

    mounted() {
        this.$store.dispatch("dashboard/orderReviews/getQuickOrdersReview")
    },

}
</script>
