<template>
    <div>
        <div class="py-2 px-3 mb-3 text-white" style="background-color: #39af5e;">
            <strong>Cenová kalkulace:</strong>
        </div>

        <div class="mb-4 px-0">

            <table class="table table-sm table-borderless mb-4">
                <tbody>
                <tr>
                    <th colspan="3"/>
                    <th class="text-right w-20">
                        Cena / Kč bez DPH
                    </th>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="3">
                        1. Provedené práce
                    </td>
                    <td class="text-right w-20">
                        {{ assemblySheet.pricing.priceForWork|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="3">
                        2. Použitý materiál a náhradní díly
                    </td>
                    <td class="text-right w-20">
                        {{ assemblySheet.pricing.priceForMaterial|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="3">
                        3. Ostatní položky
                    </td>
                    <td class="text-right w-20">
                        {{ assemblySheet.pricing.priceForItems|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="3">
                        4. Režijní materiál
                    </td>
                    <td class="text-right w-20">
                        {{ assemblySheet.pricing.priceForOverheadMaterial|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="3">
                        5. Doprava
                    </td>
                    <td class="text-right w-20">
                        {{ assemblySheet.pricing.priceForTransport|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="2">
                        6. Expresní příplatek
                    </td>
                    <td class="text-center w-15">
                        {{ assemblySheet.pricing.hasExpressSurcharge ? "Ano" : "Ne" }}
                    </td>
                    <td class="text-right w-20">{{ assemblySheet.pricing.expressSurcharge|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="3">
                        7. Sleva
                    </td>
                    <td class="text-right text-danger w-20">-
                        {{ assemblySheet.pricing.totalDiscountAmount|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                </tbody>
            </table>

            <hr class="mb-4 border-bottom border-light">

            <table class="table table-sm table-borderless">
                <tbody>
                <tr>
                    <td colspan="3">
                        Cena celkem bez DPH
                    </td>
                    <td class="text-right w-20">
                        {{ assemblySheet.pricing.totalAmount|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="2">
                        DPH
                    </td>
                    <td class="text-center w-15">
                        {{ assemblySheet.pricing.vatTax }} %
                    </td>
                    <td class="text-right w-20">
                        {{ assemblySheet.pricing.taxAmount|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="3">
                        Haléřové vyrovnání
                    </td>
                    <td class="text-right w-20">
                        {{ assemblySheet.pricing.pennyRounding|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                <tr class="border-top border-light">
                    <td colspan="3">
                        <strong>Částka k úhradě včetně DPH</strong>
                    </td>
                    <td class="text-right font-weight-900">
                        {{ assemblySheet.pricing.totalAmountIncludingVat|moneyFormat("CZK_LONG") }}
                    </td>
                </tr>
                </tbody>
            </table>

        </div>

        <div class="py-2 px-3 mb-4">
            <strong>Forma úhrady:</strong>
            <span v-if="assemblySheet.order.formOfPayment === 'cash'"> Hotovost</span>
            <span v-if="assemblySheet.order.formOfPayment === 'invoice'"> Faktura</span>
        </div>

    </div>
</template>

<script>
import AssemblySheetEntity from "../../../../NewModel/AssemblySheet/Entity/AssemblySheetEntity"

export default {

    props: {
        assemblySheet: {
            type: AssemblySheetEntity,
            required: true,
        },
    },

}
</script>
