<template>

    <div class="d-flex align-items-center">
        <div class="px-4 font-weight-600">
            <span class="mr-4">{{ this.dateFormat }}</span>
            <BFormDatepicker
                button-only
                right
                locale="cs-CZ"
                aria-controls="example-input"
                @context="onContext"
                button-variant="primary"
                :start-weekday="1"
                today-button
                label-today-button="Zvolit dnešní datum"
                today-variant="danger"
            />
        </div>

        <div>
            <button class="btn btn-primary" v-on:click="changeDate(-1)">
                <i class="fad fa-chevron-left"/><span class="ml-3">Předchozí</span>
            </button>
            <button class="btn btn-primary ml-4" v-on:click="changeDate(+1)">
                <span class="mr-3">Následující</span><i class="fad fa-chevron-right"/>
            </button>
        </div>
    </div>

</template>

<script>
import moment from "moment"

const LOCAL_DATE_KEY_NAME = "worker_date"

export default {

    data() {
        return {
            date: null,
            dateFormat: null,
        }
    },

    mounted() {
        let _localDate = this.getLocalDate()
        this.updateDate(_localDate)
    },

    methods: {

        onContext(ctx) {
            if (ctx.selectedYMD !== '') {
                console.log(ctx.selectedYMD)
                this.updateDate(moment(ctx.selectedYMD))
            }
        },

        changeDate(modifier) {
            let _date
            if (typeof modifier === "number" && !isNaN(modifier)) {
                _date = this.date.clone().add(modifier, "d")
            } else {
                _date = moment()
            }
            this.updateDate(_date)
        },

        updateDate(_requiredDate) {
            let isDateChanged = false
            if (this.date === null || this.date.isSame(_requiredDate, "day") === false) {
                isDateChanged = true
            }

            this.date = _requiredDate
            this.dateFormat = _requiredDate.format("DD.MM.YYYY")
            if (isDateChanged) {
                this.setLocalDate(_requiredDate)
                this.$emit("changed", _requiredDate.format("YYYY-MM-DD"))
            }
        },

        getLocalDate() {
            let _requiredDate
            if (this.$route.params.date) {
                _requiredDate = moment(String(this.$route.params.date), "YYYY-MM-DD")
            } else if (this.$cookies.isKey(LOCAL_DATE_KEY_NAME)) {
                _requiredDate = moment(String(this.$cookies.get(LOCAL_DATE_KEY_NAME)), "YYYY-MM-DD")
            }

            return _requiredDate instanceof moment && _requiredDate.isValid() ? _requiredDate : moment()
        },

        setLocalDate(_date) {
            this.$cookies.set(LOCAL_DATE_KEY_NAME, _date.format("YYYY-MM-DD"), "2h")
        },

    },

}
</script>


