export default class SubjectContactRequest {

    constructor() {
        this.id = null
        this.name = ''
        this.phone = ''
        this.email = ''
    }

    static from(response) {

        let req = new this
        req.id = response.id || null
        req.name = response.name || ''
        req.phone = response.phone || ''
        req.email = response.email || ''
        return req
    }

    toForm() {
        return {
            id: this.id,
            name: this.name,
            phone: this.phone,
            email: this.email,
        }
    }

}
