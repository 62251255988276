export default class AssemblySheetRepairTypeEntity {

    constructor() {
        this.id = null
        this.name = ""
        this.shortName = ""
        this.description = ""
        this.price = 0
    }

    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.shortName = response.shortName
        obj.description = response.description
        obj.price = response.price

        return obj
    }

    to() {
        return {
            id: this.id,
        }
    }

}
