<template>
    <div class="text-center text-dark m-0 p-3 d-flex justify-content-center align-items-center">
        <i class="text-primary fa-fw mr-3" :class="icon" v-if="showIcon"/>
        <slot name="default">
            <div v-if="title" class="font-weight-400">{{ title }}</div>
        </slot>
    </div>
</template>


<script>
export default {

    props: {
        showIcon: {
            type: Boolean,
            required: false,
            default: true,
        },
        icon: {
            type: String,
            required: false,
            default: 'fa-solid fa-empty-set fa-xl text-primary',
        },
        title: {
            type: String,
            required: false,
            default: "Nebyly nazelezeny žádné záznamy",
        },
    },

}
</script>
