export default class SubjectFilterEntity {

    constructor() {
        this.id = ''
        this.type = 1
        this.name = ''
        this.identifier = ''
        this.address = ''
        this.secondaryAddress = null
    }

    /**
     * @param response : object
     * @returns SubjectFilterEntity
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.type = response.type
        obj.name = response.name || ''
        obj.identifier = response.identifier || ''
        obj.address = response.address || ''
        obj.secondaryAddress = response.secondaryAddress || ''
        return obj
    }

}
