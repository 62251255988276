import DiscountsList from "./DiscountsList.vue"
import Discount from "./Discount.vue"
import DiscountShow from "./DiscountShow.vue"
import DiscountEdit from "./DiscountEdit.vue"
import DiscountCreate from "./DiscountCreate.vue"
import WhiteMain from "../General/WhiteMain.vue"

const routes = [
    {
        path: "/discounts",
        component: WhiteMain,
        children: [
            {
                path: "",
                name: "discounts_list",
                component: DiscountsList,
            },
            {
                path: "create",
                name: "discounts_create",
                component: DiscountCreate,
            },
            {
                path: ":id",
                component: Discount,
                children: [
                    {
                        path: "",
                        name: "discounts_show",
                        component: DiscountShow,
                    },
                    {
                        path: "edit",
                        name: "discounts_edit",
                        component: DiscountEdit,
                    },
                ],
            },
            {
                path: "",
                redirect: {name: "discounts_list"},
            },
        ],
    },
]

export default routes
