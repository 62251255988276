<template>
    <div>

        <Header icon="fad fa-pencil-ruler" title="Upravit výrobce spotřebiče">
            <template #buttons>
                <BackLinkButton to="/setup/boilers/manufacturers"/>
            </template>
        </Header>

        <BoilerManufacturerForm
            :request="request"
            :is-processing="isUpdating"
            v-on:submitted="onSubmitted"
        />

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ApplianceManufacturerRequest from "../../../NewModel/Appliance/Manufacturer/Request/ApplianceManufacturerRequest"
import BackLinkButton from "../../Common/UI/Button/BackLinkButton.vue"
import BoilerManufacturerForm from "./Component/BoilerManufacturerForm.vue"
import Header from "../../Common/UI/Header.vue"

export default {

    components: {
        BackLinkButton,
        BoilerManufacturerForm,
        Header,
    },

    computed: {
        ...mapGetters({
            isUpdating: 'appliance/manufacturers/isUpdating',
            boilerManufacturer: 'appliance/manufacturers/getManufacturer',
        }),
    },

    data() {
        return {
            request: new ApplianceManufacturerRequest(),
        }
    },

    mounted() {
        this.request = ApplianceManufacturerRequest.fromEntity(this.boilerManufacturer)
    },

    methods: {

        onSubmitted(request) {
            this.$store.dispatch("appliance/manufacturers/updateManufacturer", {
                id: this.boilerManufacturer.id,
                request: request,
            })
                .then(() => {
                    this.successMessage('Úspěšně uloženo!')
                    this.$router.push({name: 'setup_boiler_manufacturer_list'})
                })
                .catch((err) => {
                    this.warningMessage(err.response.message)
                })
        },

    },

}
</script>
