import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const AddressService = {

    /**
     * @param request : AddressRequest
     * @returns {Promise<any>}
     */
    createByAddress(request) {
        return httpClient.post("v3/addresses/address", request.toForm(), {
            headers: authHeader(),
        })
    },

    /**
     * @param request : AddressParcelRequest
     * @returns {Promise<any>}
     */
    createByParcel(request) {
        return httpClient.post("v3/addresses/parcel", request.toForm(), {
            headers: authHeader(),
        })
    },

    getDistance({id, gpsLat, gpsLon}) {
        return httpClient.get("v3/addresses/" + id + "/distance", {
            headers: authHeader(),
            params: {
                gpsLat: gpsLat,
                gpsLon: gpsLon,
            }
        })
    },

    whisperAddress(query) {
        return httpClient.get("v3/addresses/whisperer", {
            headers: authHeader(),
            params: {
                query: query,
            }
        })
    },

    validateWhisperedAddress(addressText, addressHash) {
        return httpClient.post("v3/addresses/whisperer", {}, {
            headers: authHeader(),
            params: {
                query: addressText,
                hash: addressHash,
            }
        })
    },


}

export default AddressService
