<template>
    <div>

        <TableWithoutRecords v-if="!isLoading && !hasSupplyPoints"/>

        <div class="bg-little-gray p-4 border-radius-sm mb-4" v-for="(supplyPoint, supplyPointIndex) in supplyPoints"
             v-bind:key="'supply_point' + supplyPointIndex">

            <h5 class="font-weight-600 text-dark mb-3">
                <i class="mr-2 fa-fw" :class="commodity.getItem(supplyPoint.commodity).icon"/>
                <strong>{{ supplyPoint.ean }}</strong>,
                {{ supplyPoint.address }}
                <BLink
                    :to="{name: 'supply_point_show', params: {id: supplyPoint.id}}"
                    target="_blank" class="text-info"
                >
                    <i class="fad fa-external-link fa-fw ml-3"/>
                </BLink>
            </h5>

            <div class="bg-white p-2 border-radius-sm d-flex mb-2">
                <div class="mr-4">
                    <strong>Smlouva:</strong>
                    <span v-if="supplyPoint.contract">
                        #<a
                        :href="'https://is.kvplyn.cz/#subsite=History/masterContractId=_MASTER_ID_/st=KvPlyn.Sites.Contract.Detail'.replace('_MASTER_ID_', supplyPoint.contract)"
                        target="_blank"
                    >
                            {{ supplyPoint.contractNumber }}
                        </a>
                    </span>
                    <span v-else>
                        Není
                    </span>
                </div>
                <div class="mr-4">
                    <strong>Benefity:</strong>
                    <span v-if="supplyPoint.benefits.length > 0">
                        {{ supplyPoint.benefits.join(", ") }}
                    </span>
                    <span v-else>
                        Nesjednány
                    </span>
                </div>
                <div>
                    <strong>Servisní smlouva:</strong>
                    {{ serviceContractLevel.getItem(supplyPoint.serviceContract).text }}
                </div>
            </div>

            <table class="table table-borderless mb-0">
                <thead>
                <tr class="border-bottom">
                    <th class="align-middle w-15">
                        Druh spotřebiče
                    </th>
                    <th class="align-middle w-35">
                        Název
                    </th>
                    <th class="align-middle w-15">
                        Sériové číslo
                    </th>
                    <th class="align-middle text-center w-15">
                        Datum instalace
                    </th>
                    <th class="align-middle text-center w-15">
                        Záruka do
                    </th>
                    <th class="align-middle text-right">
                        Akce
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    :class="{'border-bottom': applianceIndex + 1 !== supplyPoint.appliances.length}"
                    v-for="(appliance, applianceIndex) in supplyPoint.appliances"
                    v-bind:key="'appliance_' + supplyPointIndex + '_' + applianceIndex"
                >
                    <td class="align-middle">
                        {{ appliance.applianceType }}
                    </td>
                    <td class="align-middle">
                        {{ appliance.name }}
                    </td>
                    <td class="align-middle">
                        {{ appliance.serialNumber }}
                    </td>
                    <td class="align-middle text-center">
                        {{ appliance.dateOfInstallation|prettyDate }}
                    </td>
                    <td class="align-middle text-center">
                        {{ appliance.warrantyToDate|prettyDate }}
                    </td>
                    <td class="text-right">
                        <BButton
                            variant="primary"
                            :to="{ name: 'appliance_show', params: { appliance_id: appliance.id }}"
                            target="_blank"
                            size="sm"
                        >
                            <i class="fad fa-eye"/>
                        </BButton>
                    </td>
                </tr>
                <tr class="border-top" v-if="supplyPoint.appliances.length === 0">
                    <td colspan="6">
                        <TableWithoutRecords>
                            Na tomto místě nejsou evidovány žádné spotřebiče.
                        </TableWithoutRecords>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Commodity from "@/Model/Enum/commodity"
import ServiceContractLevel from "@/Model/Enum/service_contract_level"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        subject: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            commodity: Commodity,
            serviceContractLevel: ServiceContractLevel,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'subject/supplyPoints/isLoading',
            hasSupplyPoints: 'subject/supplyPoints/hasSupplyPoints',
            supplyPoints: 'subject/supplyPoints/getSupplyPoints',
        }),
    },

    created() {
        this.loadSubjectSupplyPoints(this.subject)
    },

    methods: {

        loadSubjectSupplyPoints(id) {
            this.$store.dispatch("subject/supplyPoints/loadSupplyPoints", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
