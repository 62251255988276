<template>

    <BForm ref="form" @submit.prevent="processForm">

        <BFormGroup label-for="input-supplyPoint" label-class="font-weight-600" class="mb-4" v-if="!disableSupplyPoint">
            <template v-slot:label>
                Odběrné místo: <span class="text-danger ml-1">*</span>
            </template>
            <BFormInput
                v-model="request.ean"
                @change="checkSupplyPoint"
                :disabled="disableSupplyPoint"
                id="input-supplyPoint"
                type="text" required
            />
        </BFormGroup>

        <div class="table-success border-radius-sm p-3 mb-4" v-if="selectedSupplyPoint && !disableSupplyPoint">
            <strong>EAN:</strong> {{ selectedSupplyPoint.ean }} | <strong>Zákazník:</strong>
            {{ selectedSupplyPoint.customer.wholeName }} | <strong>Adresa:</strong>
            {{ selectedSupplyPoint.address.wholeName }}
        </div>

        <div class="table-warning p-3 mb-4" v-if="selectedSupplyPoint === null && !disableSupplyPoint">
            Musíte vyplnit existující EAN/EIC kód!
        </div>

        <div class="table-info p-3 mb-4" v-if="request.createdAutomatically">
            <i class="fad fa-info-circle mr-2 ml-2"/>
            Tato sleva byla vytvořena automaticky synchronizací z ISIKu! Některá políčka nelze změnit.
        </div>

        <BFormGroup label-for="input-type" label-class="font-weight-600" class="mb-4">
            <template v-slot:label>
                Typ: <span class="text-danger ml-1">*</span>
            </template>
            <BFormRadioGroup
                v-model="request.type"
                :disabled="request.createdAutomatically"
                name="type"
                id="input-type"
            >
                <BFormRadio :value="'individual'">Individuální</BFormRadio>
                <BFormRadio :value="'fixed_contract'">Fixovaná smlouva</BFormRadio>
                <BFormRadio :value="'service_contract'">Servisní smlouva</BFormRadio>
            </BFormRadioGroup>
        </BFormGroup>

        <BFormGroup
            label-for="input-serviceContract"
            label-class="font-weight-600"
            v-if="request.type === 'service_contract'"
            class="mb-4"
        >
            <template v-slot:label>
                Číslo serv. smlouvy: <span class="text-danger ml-1">*</span>
            </template>
            <BFormInput id="input-serviceContract" v-model="request.serviceContract" type="text" required/>
        </BFormGroup>

        <BFormRow>
            <BCol>
                <BFormGroup
                    id="effectiveFrom"
                    label-for="input-effectiveFrom"
                    label-class="font-weight-600"
                    :disabled="request.createdAutomatically"
                    class="mb-4"
                >
                    <template v-slot:label>
                        Platnost OD: <span class="text-danger ml-1">*</span>
                    </template>
                    <DatePicker
                        v-model="request.effectiveFrom"
                        :disabled="request.createdAutomatically"
                        type="date"
                        format="DD.MM.YYYY"
                        value-type="YYYY-MM-DD"
                        placeholder="Vybrat datum"
                        input-class="form-control"
                    />
                </BFormGroup>
            </BCol>
            <BCol>
                <BFormGroup
                    id="effectiveFrom"
                    label-for="input-effectiveFrom"
                    label-class="font-weight-600"
                    :disabled="request.createdAutomatically"
                    class="mb-4"
                >
                    <template v-slot:label>
                        Platnost DO:
                        <BLink
                            v-on:click.prevent="request.effectiveTo = '2100-12-31'"
                            style="text-decoration-style: dashed; text-decoration-line: underline; text-decoration-thickness: 1px;"
                            class="ml-3"
                        >
                            neomezeně
                        </BLink>
                    </template>
                    <DatePicker
                        v-model="request.effectiveTo"
                        type="date"
                        format="DD.MM.YYYY"
                        value-type="YYYY-MM-DD"
                        placeholder="Vybrat datum"
                        input-class="form-control"
                        :disabled="request.createdAutomatically"
                    />
                </BFormGroup>
            </BCol>
        </BFormRow>

        <BFormGroup
            label-for="input-generalAmount"
            label-class="font-weight-600"
            description="Pokud nebude sleva přepsána pro jednotlivý servisní úkon níže, uplatní se tato výše slevy."
            class="mb-4"
        >
            <template v-slot:label>
                Výše obecné slevy: <span class="text-danger ml-1">*</span>
            </template>
            <BInputGroup prepend="%">
                <BFormInput
                    id="input-generalAmount"
                    v-model.number="request.generalAmount"
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    required
                />
            </BInputGroup>
        </BFormGroup>

        <BFormGroup label-for="input-note" label-class="font-weight-600" class="mb-4">
            <template v-slot:label>
                Poznámka:
            </template>
            <BFormInput id="input-note" v-model="request.note" type="text"/>
        </BFormGroup>

        <BFormGroup label-for="input-items" label-class="font-weight-600" class="mb-4">
            <template v-slot:label>
                Jednotlivé slevy:
            </template>
            <table class="table table-borderless mb-0">
                <thead>
                <tr class="border-light border-bottom">
                    <th class="font-weight-700">
                        Servisní úkon
                    </th>
                    <th class="font-weight-700 w-20">
                        Výše slevy [%]
                    </th>
                    <th class="font-weight-700 w-20 text-right"/>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, itemIndex) in request.items"
                    :key="'item_' + itemIndex"
                    :class="{'border-light border-bottom': (request.items.length - 1) !== itemIndex}"
                >
                    <td>
                        <BFormSelect
                            v-model="item.repairType"
                            :options="filteredRepairTypes"
                            value-field="id"
                            text-field="name"
                        />
                    </td>
                    <td>
                        <BInputGroup prepend="%">
                            <BFormInput
                                v-model.number="item.amount"
                                type="number"
                                min="0"
                                max="100"
                                step="1"
                                required
                            />
                        </BInputGroup>
                    </td>
                    <td class="text-right">
                        <BButton variant="danger" size="sm">
                            <i class="fad fa-minus fa-fw" @click.prevent="removeItem(itemIndex)"/>
                        </BButton>
                    </td>
                </tr>
                <tr v-if="request.items.length === 0">
                    <td colspan="3">
                        <TableWithoutRecords>
                            Žádné individuální slevy. Ve všech případech bude použita obecná sleva.
                        </TableWithoutRecords>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="border-top border-light">
                    <td colspan="3">
                        <BLink @click.prevent="addItem">Přidat individuální slevu</BLink>
                    </td>
                </tr>
                </tfoot>
            </table>
        </BFormGroup>
        <BButton
            type="submit"
            variant="primary"
            size="lg"
            block
            :disabled="!this.request.supplyPoint || isProcessing"
        >
            <i class="fal fa-paper-plane mr-3"/>Uložit
        </BButton>

    </BForm>
</template>

<script>
import DiscountRequest from "../../../NewModel/Discount/Request/DiscountRequest"
import DiscountItemRequest from "../../../NewModel/Discount/Request/DiscountItemRequest"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableWithoutRecords,
    },

    props: {
        request: {
            type: DiscountRequest,
            required: true,
        },
        isProcessing: {
            type: Boolean,
            required: true,
        },
        disableSupplyPoint: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        filteredRepairTypes: function () {
            let items = this.list.repairTypes.filter(r => (r.commodity === this.request.commodity && r.type === 2))
            items.unshift({id: null, name: "* Pro všechny typy oprav"})
            return items
        },
    },

    data() {
        return {
            selectedSupplyPoint: null,
            list: {
                repairTypes: [],
            },
        }
    },

    mounted() {
        this.loadRepairTypes()
    },

    methods: {

        processForm() {

            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Selhala validace. Byla všechna požadovaná pole vyplněna?')
                return
            }

            if (!this.request.effectiveFrom) {
                this.warningMessage('Vyplňte datum účinnosti!')
                return
            }

            this.$emit('submitted', this.request)
        },

        loadRepairTypes() {
            let self = this
            self.list.repairTypes = []

            this.$store.dispatch("repairType/clearAll")
            this.$store.dispatch("repairType/findAllActiveRepairTypes")
                .then((records) => {
                    self.list.repairTypes = records.map(r => {
                        return {id: r.id, name: r.name, commodity: r.commodity, type: r.type}
                    })
                })
        },

        checkSupplyPoint(val) {
            if (String(val).length < 4) {
                return
            }
            let self = this
            this.$store.dispatch("supplyPoint/clear")
            this.$store.dispatch("supplyPoint/getSupplyPoint", val)
                .then((data) => {
                    self.selectedSupplyPoint = data
                    self.request.supplyPoint = data.id
                    self.request.ean = data.ean
                    self.request.commodity = data.commodity
                })
        },

        removeItem(index) {
            this.request.items.splice(index, 1)
        },

        addItem() {
            this.request.items.push(new DiscountItemRequest())
        },

    },

}
</script>
