<template>
    <div>

        <Header title="Moje zakázky"/>

        <div v-if="haveOrders && !isLoading" class="mb-3">
            <div
                v-for="order in orders"
                v-bind:key="order.id"
                class="mb-3 cursor-pointer border-radius-sm"
                v-on:click="$router.push({name: 'new_mobile_order_show', params: { 'id': order.id }})"
            >
                <OrderListItem v-bind:order="order"/>
            </div>
        </div>

        <TableLoading class="mb-3" v-if="isLoading"/>

        <TableWithoutRecords class="mb-3" v-if="!isLoading && !haveOrders"/>

        <div class="text-center mt-4 mb-5">
            <div class="mb-3">
                <button class="btn btn-info" v-on:click="changeDate(-1)">
                    <i class="fad fa-chevron-left"/>
                </button>
                <span class="mr-4 ml-4">
                    {{ this.dateFormat }}
                </span>
                <button class="btn btn-info" v-on:click="changeDate(+1)">
                    <i class="fad fa-chevron-right"/>
                </button>
            </div>
            <div v-if="!todaySelected" class="mr-3">
                <button class="btn btn-info" v-on:click="changeDate('today')">
                    <i class="fad fa-calendar-day mr-4"/>Dnes
                </button>
            </div>
        </div>

        <StatusLegend class="mb-4" v-if="haveOrders && !isLoading"/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Header from "../Common/UI/Header.vue"
import moment from "moment"
import OrderListItem from "./Component/OrderListItem.vue"
import StatusLegend from "./Component/StatusLegend.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        Header,
        OrderListItem,
        StatusLegend,
        TableLoading,
        TableWithoutRecords,
    },

    data() {
        return {
            date: null,
            dateFormat: null,
            todaySelected: false,
            user: null,
            numberOfOrders: 0,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: "order/isLoading",
            haveOrders: "order/hasOrders",
            orders: "order/getOrders",
        }),
    },

    mounted() {
        this.today = moment()

        const loggedUser = JSON.parse(localStorage.getItem("user"))
        if (loggedUser) {
            this.user = loggedUser.id
        }

        this.loadDate()
    },

    methods: {

        loadOrders(date) {
            let self = this
            this.$store.dispatch("order/findWorkerOrdersByDate", date.format("YYYY-MM-DD"))
                .then((data) => {
                    self.numberOfOrders = 0
                    for (const [i, order] of data) {
                        if (order.worker.id !== self.user) {
                            self.numberOfOrders++
                            self.orders.splice(i, 1)
                        }
                    }
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        changeDate(modifier) {
            let _date
            if (typeof modifier === "number" && !isNaN(modifier)) {
                _date = this.date.add(modifier, "d")
            } else {
                _date = moment()
            }

            this.updateDate(_date)
        },

        loadDate() {
            let orderListDate
            if (this.$cookies.isKey("orderListDate")) {
                orderListDate = this.$cookies.get("orderListDate")
            } else {
                orderListDate = null
            }

            let _date = moment()

            if (this.$route.params.date) {
                _date = moment(String(this.$route.params.date), "DD.MM.YYYY")
            } else if (orderListDate) {
                _date = moment(String(orderListDate), "DD.MM.YYYY")
            }

            this.updateDate(_date)
        },

        updateDate(_date) {
            this.date = _date
            this.dateFormat = _date.format("DD.MM.YYYY")
            this.todaySelected = moment(_date).isSame(moment(), "day")
            this.$cookies.set("orderListDate", _date.format("DD.MM.YYYY"), "8h")
            this.$router.replace({
                name: "new_mobile_order_my_list",
                params: {date: _date.format("DD.MM.YYYY")}
            })
                .catch(error => {
                    if (error.name !== "NavigationDuplicated") {
                        throw error
                    }
                })
            return this.loadOrders(this.date)
        },

    },

}
</script>


