<template>
    <div class="container-fluid p-4">

        <RouterView v-if="!isLoading && hasAssemblySheet"/>

        <div v-if="isLoading === true" class="p-5 text-center">
            <i class="fad fa-spinner fa-fw fa-spin text-primary mr-4"/> Nahrávám montážní list
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    computed: {
        ...mapGetters({
            isLoading: "assemblySheet/process/isLoading",
            hasAssemblySheet: "assemblySheet/process/hasRecord",
            assemblySheet: "assemblySheet/process/getRecord",
        })
    },

    mounted() {
        this.loadAssemblySheet(this.$route.params.id)
    },

    methods: {

        loadAssemblySheet(id) {
            this.$store.dispatch("assemblySheet/process/getAssemblySheet", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
