import authHeader from "../../Model/Common/AuthHeader"
import {httpClient} from "../../Model/Common"

const IssueService = {

    findAllActiveIssues() {
        return httpClient.get("v3/issues", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     */
    getIssue(id) {
        return httpClient.get("v3/issues/" + id, {
            headers: authHeader()
        })
    },

    /**
     * @param request : IssueRequest
     * @returns {Promise<any>}
     */
    createIssue(request) {
        return httpClient.post("v3/issues", request.toForm(), {headers: authHeader()})
    },

    /**
     * @param id : Number
     * @param request : IssueRequest
     * @returns {Promise<any>}
     */
    updateIssue(id, request) {
        return httpClient.put("v3/issues/" + id, request.toForm(), {headers: authHeader()})
    },

    getMyIssues() {
        return httpClient.get("v3/issues/my", {
            headers: authHeader()
        })
    },

    /**
     * @param id : Number
     * @returns {Promise<any>}
     */
    closeIssue(id) {
        return httpClient.put("v3/issues/" + id + "/close", {}, {headers: authHeader()})
    },

    /**
     * @param id : Number
     * @returns {Promise<any>}
     */
    deleteIssue(id) {
        return httpClient.delete("v3/issues/" + id, {headers: authHeader()})
    },

}

export default IssueService
