import SubjectConnectionContractEntity from "../Entity/SubjectConnectionContractEntity"

export default class SubjectContractEntity {

    constructor() {
        this.id = null
        this.contractNumber = ""
        this.contractDate = null
        this.debt = false
        this.connectionContracts = []
    }

    /**
     * @param response : object
     * @returns {SubjectContractEntity}
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.contractNumber = response.contractNumber
        obj.contractDate = response.contractDate
        obj.debt = response.debt
        obj.connectionContracts = response.connectionContracts.map(
            contract => SubjectConnectionContractEntity.from(contract)
        )
        return obj
    }

}
