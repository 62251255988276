import SupplyPointAppliancesStore from "./Store/SupplyPointAppliancesStore"
import SupplyPointService from "./SupplyPointService"
import SupplyPointMapStore from "./Store/SupplyPointMapStore"
import SupplyPointOrdersStore from "./Store/SupplyPointOrdersStore"
import SupplyPointNotesStore from "./Store/SupplyPointNotesStore"
import SupplyPointSearchClientsStore from "./Store/SupplyPointSearchClientsStore"
import SupplyPointMetaStore from "./Store/SupplyPointMetaStore"
import SupplyPointEntity from "./Entity/SupplyPointEntity"

const
    FETCHING_RECORD = "FETCHING_RECORD",
    FETCHING_RECORD_SUCCESS = "FETCHING_RECORD_SUCCESS",
    FETCHING_RECORD_ERROR = "FETCHING_RECORD_ERROR",
    FETCHING_FILTERED_RECORDS = "FETCHING_FILTERED_RECORDS",
    FETCHING_FILTERED_RECORDS_SUCCESS = "FETCHING_FILTERED_RECORDS_SUCCESS",
    FETCHING_FILTERED_RECORDS_ERROR = "FETCHING_FILTERED_RECORDS_ERROR",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    UPDATING_RECORD = "CREATING_RECORD",
    UPDATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    UPDATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    record: null,
    records: [],
    totalRecords: 0,
    error: null,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param request : SimpleFormRequest
     * @returns {Promise<*>}
     */
    async getFilteredRecords({commit}, request) {
        try {
            commit(FETCHING_FILTERED_RECORDS)
            let response = await SupplyPointService.filterSupplyPoints(request)
            commit(FETCHING_FILTERED_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_FILTERED_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @returns {Promise<[]>}
     */
    async getSupplyPoint({commit}, id) {
        try {
            commit(FETCHING_RECORD)
            let response = await SupplyPointService.getSupplyPoint(id)
            commit(FETCHING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(FETCHING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param request : SupplyPointRequest
     * @returns {Promise<*>}
     */
    async createSupplyPoint({commit}, request) {
        try {
            commit(CREATING_RECORD)
            let response = await SupplyPointService.createSupplyPoint(request)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param request : SupplyPointRequest
     * @returns {Promise<*>}
     */
    async updateSupplyPoint({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await SupplyPointService.updateSupplyPoint(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    /**
     * @param commit
     * @param id : string
     * @param request : SupplyPointBlockRequest
     * @returns {Promise<*>}
     */
    async blockSupplyPoint({commit}, {id, request}) {
        try {
            commit(UPDATING_RECORD)
            let response = await SupplyPointService.blockSupplyPoint(id, request)
            commit(UPDATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(UPDATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

    clear({commit}) {
        commit(CLEAR_RECORD)
    },

    clearAll({commit}) {
        commit(CLEAR_RECORDS)
    },

}

export const mutations = {

    [FETCHING_FILTERED_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_FILTERED_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data.records
        state.totalRecords = data.totalRecords
    },
    [FETCHING_FILTERED_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
        state.totalRecords = 0
    },

    [FETCHING_RECORD](state) {
        state.isLoading = true
        state.error = null
    },
    [FETCHING_RECORD_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.record = SupplyPointEntity.from(data)
    },
    [FETCHING_RECORD_ERROR](state, error) {
        state.isLoading = false
        state.error = error
    },

    [CREATING_RECORD](state) {
        state.isCreating = true
        state.error = null
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isCreating = false
        state.error = null
        state.record = SupplyPointEntity.from(data)
    },
    [CREATING_RECORD_ERROR](state, error) {
        state.isCreating = false
        state.error = error
    },

    [UPDATING_RECORD](state) {
        state.isUpdating = true
        state.error = null
    },
    [UPDATING_RECORD_SUCCESS](state, data) {
        state.isUpdating = false
        state.error = null
        state.record = SupplyPointEntity.from(data)
    },
    [UPDATING_RECORD_ERROR](state, error) {
        state.isUpdating = false
        state.error = error
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.error = null
        state.record = null
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.error = null
        state.records = []
        state.totalRecords = 0
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },

    isCreating(state) {
        return state.isCreating
    },

    isUpdating(state) {
        return state.isUpdating
    },

    isProcessing(state) {
        return state.isUpdating || state.isCreating
    },

    hasError(state) {
        return state.error !== null
    },

    error(state) {
        return state.error
    },

    hasSupplyPoint(state) {
        return state.record !== null
    },

    getSupplyPoint(state) {
        return state.record
    },

    hasSupplyPoints(state) {
        return state.records.length > 0
    },

    getSupplyPoints(state) {
        return state.records
    },

    getTotalSupplyPoints(state) {
        return state.totalRecords
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        appliances: SupplyPointAppliancesStore,
        map: SupplyPointMapStore,
        meta: SupplyPointMetaStore,
        notes: SupplyPointNotesStore,
        orders: SupplyPointOrdersStore,
        searchClients: SupplyPointSearchClientsStore,
    }
}
