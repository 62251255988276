<template>

    <div>

        <Header icon="fad fa-car-mechanic" :title="'#' + order.orderNumber">
            <template #buttons>
                <button
                    v-on:click.prevent="$router.go(-1)"
                    class="btn btn-info ml-3"
                >
                    <i class="fad fa-chevron-left mr-3"/>Zpět
                </button>
            </template>
        </Header>

        <div class="mb-4 px-2 border-radius-sm">

            <h5 class="font-weight-700 text-dark mb-3">Nová poznámka</h5>

            <BOverlay :show="isCreating">
                <BForm ref="form" @submit.prevent="handleSubmit">
                    <BFormGroup
                        id="input-group-1"
                        label-for="input-1"
                        label-class="font-weight-700"
                    >
                        <template v-slot:label>
                            Umístění poznámky: <span class="text-danger ml-1">*</span>
                        </template>
                        <BFormSelect v-model="request.target" id="input-1" class="border-radius-sm">
                            <BFormSelectOption :value="'supplyPoint'">K odběrnému místu</BFormSelectOption>
                            <BFormSelectOption :value="'appliance'">Ke spotřebiči</BFormSelectOption>
                        </BFormSelect>
                    </BFormGroup>

                    <BFormGroup
                        id="input-group-2"
                        label-for="input-2"
                        label-class="font-weight-700"
                        v-if="request.target === 'appliance'"
                    >
                        <template v-slot:label>
                            Spotřebič: <span class="text-danger ml-1">*</span>
                        </template>
                        <BFormRadioGroup
                            v-model="request.appliance"
                            id="input-2"
                            class="bg-white p-2 border-radius-sm"
                        >
                            <BFormRadio
                                name="appliance"
                                :value="appliance.appliance"
                                v-for="(appliance, applianceIndex) in order.appliances"
                                :key="'app_' + appliance.id"
                                v-bind:class="(order.appliances.length - 1) !== applianceIndex ? 'mb-3': ''"
                            >
                                <div class="font-weight-600">{{ appliance.name }}</div>
                                <div v-if="appliance.serialNumber">S/N: {{ appliance.serialNumber }}</div>
                            </BFormRadio>
                        </BFormRadioGroup>
                    </BFormGroup>

                    <BFormGroup
                        id="input-group-3"
                        label-for="input-3"
                        label-class="font-weight-600"
                    >
                        <template v-slot:label>
                            Obsah zprávy: <span class="text-danger ml-1">*</span>
                        </template>
                        <BFormTextarea id="input-3" v-model="request.message" rows="5"/>
                    </BFormGroup>

                    <BButton type="submit" variant="primary" block :disabled="isCreating">
                        <i class="fad fa-paper-plane fa-fw mr-3"/>Uložit poznámku
                    </BButton>

                </BForm>
            </BOverlay>

        </div>

    </div>

</template>

<script>
import {mapGetters} from "vuex"
import Header from "../Common/UI/Header.vue"
import SupplyPointTechnicalNoteRequest from "@/NewModel/SupplyPoint/Request/SupplyPointTechnicalNoteRequest";

export default {

    components: {
        Header,
    },

    computed: {
        ...mapGetters({
            isCreating: 'supplyPoint/notes/isCreating',
            order: 'order/getOrder',
        }),
    },

    data() {
        return {
            scannerEnabled: false,
            request: new SupplyPointTechnicalNoteRequest(),
        }
    },

    methods: {


        checkFormValidity() {
            return this.$refs.form.checkValidity()
        },

        handleSubmit() {
            this.$store.dispatch("supplyPoint/notes/createTechnicalNote", {
                id: this.order.supplyPoint.id,
                request: this.request,
            })
                .then(() => {
                    this.$router.push({name: 'new_mobile_order_show', params: {'id': this.order.id}})
                    this.successMessage("Úspěšně zpracováno.")
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
