import AttachmentEntity from "../../Attachment/Entity/AttachmentEntity"
import SubjectContactBasicEntity from "../../Subject/Entity/SubjectContactBasicEntity"
import NewOrderApplianceEntity from "./NewOrderApplianceEntity"

export default class NewOrderEntity {

    constructor() {
        this.id = null
        this.parent = null
        this.orderNumber = ''
        this.ean = ''
        this.supplyPoint = ''
        this.customer = null
        this.customerName = ''
        this.propertyOwner = null
        this.propertyOwnerName = ''
        this.contact = null
        this.payer = 1
        this.address = ''
        this.worker = null
        this.workerName = ''
        this.workerStatus = 0
        this.scheduledStartTime = null
        this.scheduledEndTime = null
        this.scheduledRepairTime = 0
        this.status = 0
        this.note = ''
        this.cancelNote = ''
        this.list = ''
        this.formOfPayment = 'cash'
        this.estimatedAmount = null
        this.muteNotifications = false
        this.sendSms = false
        this.appliances = []
        this.attachments = []
        this.children = []
    }

    /**
     * @param response : object
     * @returns {NewOrderEntity}
     */
    static from(response) {
        let obj = new this

        obj.id = response.id
        obj.parent = response.parent
        obj.orderNumber = response.orderNumber
        obj.ean = response.ean
        obj.supplyPoint = response.supplyPoint
        obj.customer = response.customer
        obj.customerName = response.customerName
        obj.propertyOwner = response.propertyOwner
        obj.propertyOwnerName = response.propertyOwnerName
        obj.contact = response.contact ? SubjectContactBasicEntity.from(response.contact) : null
        obj.payer = response.payer
        obj.address = response.address
        obj.worker = response.worker
        obj.workerName = response.workerName
        obj.workerStatus = response.workerStatus
        obj.scheduledStartTime = response.scheduledStartTime
        obj.scheduledEndTime = response.scheduledEndTime
        obj.scheduledRepairTime = response.scheduledRepairTime
        obj.status = response.status
        obj.note = response.note
        obj.cancelNote = response.cancelNote
        obj.list = response.list
        obj.formOfPayment = response.formOfPayment
        obj.estimatedAmount = response.estimatedAmount
        obj.muteNotifications = response.muteNotifications
        obj.sendSms = response.sendSms
        obj.appliances = response.appliances.map(appliance => NewOrderApplianceEntity.from(appliance))
        obj.attachments = response.attachments.map(attachment => AttachmentEntity.from(attachment))
        obj.children = response.children

        return obj
    }

}
