import AssemblySheetApplianceEntity from "./AssemblySheetApplianceEntity"
import AssemblySheetPricingEntity from "./AssemblySheetPricingEntity"
import UserEntity from "../../User/Entity/UserEntity"
import AssemblySheetMaterialEntity from "./AssemblySheetMaterialEntity"
import AssemblySheetItemEntity from "./AssemblySheetItemEntity"

export default class AssemblySheetEntity {

    constructor() {
        this.id = null
        this.assemblySheetId = null
        this.order = {
            id: "",
            customId: "",
            estimatedAmount: null,
            formOfPayment: null,
            scheduledStartTime: null,
            scheduledEndTime: null,
        }
        this.supplyPoint = {
            id: "",
            ean: "",
            appliances: [],
        }
        this.address = {
            id: "",
            name: "",
        }
        this.customer = null
        this.propertyOwner = null
        this.contact = null
        this.payer = 0
        this.scheduledStartTime = null
        this.scheduledEndTime = null
        this.createdAt = ""
        this.finishedAt = null
        this.finishedBy = null
        this.signingTime = null
        this.signingPlace = null
        this.signingFile = null
        this.invoicingEmail = null
        this.invoicingPhone = null
        this.pricing = new AssemblySheetPricingEntity()
        this.worker = null
        this.appliances = []
        this.materials = []
        this.items = []
        this.messageForCustomer = ""
    }

    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.assemblySheetId = response.assemblySheetId
        obj.order = {
            id: response.order.id,
            customId: response.order.customId,
            estimatedAmount: response.order.estimatedAmount,
            formOfPayment: response.order.formOfPayment,
            scheduledStartTime: response.order.scheduledStartTime,
            scheduledEndTime: response.order.scheduledEndTime,
        }
        obj.supplyPoint = {
            id: response.supplyPoint.id,
            ean: response.supplyPoint.ean,
            appliances: response.supplyPoint.appliances,
        }
        obj.address = {
            id: response.address.id,
            name: response.address.name,
        }
        obj.customer = response.customer ? {
            id: response.customer.id,
            name: response.customer.name,
            address: response.customer.address,
            type: response.customer.type,
            identificationNumber: response.customer.identificationNumber,
            vatNumber: response.customer.vatNumber,
        } : null
        obj.propertyOwner = response.propertyOwner ? {
            id: response.propertyOwner.id,
            name: response.propertyOwner.name,
            address: response.propertyOwner.address,
            type: response.propertyOwner.type,
            identificationNumber: response.propertyOwner.identificationNumber,
            vatNumber: response.propertyOwner.vatNumber,
        } : null
        obj.contact = response.contact ? {
            id: response.contact.id,
            name: response.contact.name,
            email: response.contact.email,
            phone: response.contact.phone,
        } : null
        obj.payer = response.payer
        obj.scheduledStartTime = response.scheduledStartTime
        obj.scheduledEndTime = response.scheduledEndTime
        obj.createdAt = response.createdAt
        obj.finishedAt = response.finishedAt
        obj.finishedBy = response.finishedBy ? UserEntity.from(response.finishedBy) : null
        obj.signingTime = response.signingTime
        obj.signingPlace = response.signingPlace
        obj.signingFile = response.signingFile
        obj.invoicingEmail = response.invoicingEmail
        obj.invoicingPhone = response.invoicingPhone
        obj.pricing = AssemblySheetPricingEntity.from(response.pricing)
        obj.worker = response.worker ? UserEntity.from(response.worker) : null
        obj.appliances = response.appliances.map(appliance => {
            return AssemblySheetApplianceEntity.from(appliance)
        })
        obj.materials = response.materials.map(material => {
            return AssemblySheetMaterialEntity.from(material)
        })
        obj.items = response.items.map(item => {
            return AssemblySheetItemEntity.from(item)
        })
        obj.messageForCustomer = response.messageForCustomer
        return obj
    }

    getApplianceMaterials(id) {
        return this.materials.filter(el => el.assemblySheetAppliance === id);
    }

    getFreeMaterials() {
        return this.materials.filter(el => el.assemblySheetAppliance === null);
    }

    to() {
        return {
            id: this.id,
            scheduledStartTime: this.scheduledStartTime,
            scheduledEndTime: this.scheduledEndTime,
            invoicingEmail: this.invoicingEmail || null,
            invoicingPhone: this.invoicingPhone || null,
            messageForCustomer: this.messageForCustomer || "",
            hasExpressSurcharge: this.pricing.hasExpressSurcharge,
            priceForOverheadMaterial: this.pricing.priceForOverheadMaterial ? parseFloat(this.pricing.priceForOverheadMaterial) : 0,
            transportType: this.pricing.transportType,
            transportDistance: this.pricing.transportDistance ? parseInt(this.pricing.transportDistance) : 0,
            vatTax: this.pricing.vatTax,
        }
    }

}
