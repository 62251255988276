export default class AssemblySheetSupplyPointApplianceEntity {

    constructor() {
        this.id = null
        this.name = ""
        this.serialNumber = ""
        this.photo = null
        this.note = ""
    }

    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.serialNumber = response.serialNumber
        obj.photo = response.photo
        obj.note = response.note
        return obj
    }

    to() {
        return {
            id: this.id,
        }
    }

}
