<template>
    <div>

        <Header title="Nový incident">
            <template #buttons>
                <RouterLink :to="{name: 'mobile_incidents'}">
                    <i class="fad fa-chevron-circle-left fa-fw fa-2x"/>
                </RouterLink>
            </template>
        </Header>

        <BForm @submit="onSubmit">
            <BFormGroup
                id="input-customer"
                label-for="customer"
                description="Pro budoucí zpracování doporučujeme zadávat pouze jméno zákazníka (např. Bakanová Markéta)"
                label-class="font-weight-600"
            >
                <template v-slot:label>
                    Zákazník: <span class="text-danger ml-1">*</span>
                </template>
                <BFormInput
                    id="customer"
                    v-model="request.customer"
                    type="text"
                    placeholder=""
                    required
                    @change="formUpdated"
                />
            </BFormGroup>

            <BFormGroup
                id="input-description"
                label-for="description"
                label-class="font-weight-600"
                description="Co zákazník požaduje, co je slíbeno, kontakt, ..."
            >
                <template v-slot:label>
                    Popis / Poznámka: <span class="text-danger ml-1">*</span>
                </template>
                <BFormTextarea
                    id="description"
                    v-model="request.description"
                    placeholder=""
                    required
                    rows="5"
                    @change="formUpdated"
                />
            </BFormGroup>

            <BFormGroup
                id="input-eventTime"
                label="Datum a čas:"
                label-for="eventTime"
                label-class="font-weight-600"
                class="mb-4"
            >
                <DatePicker
                    v-model="request.eventTime"
                    type="datetime"
                    format="DD.MM.YYYY HH:mm"
                    value-type="YYYY-MM-DD HH:mm:ss"
                    time-title-format="DD.MM.YYYY HH:mm"
                    :show-second="false"
                    placeholder=""
                    input-class="form-control"
                    id="eventTime"
                    class="mb-3"
                    @change="formUpdated"
                />

                <BFormCheckbox
                    id="create-calendar-event"
                    v-model="request.createCalendarEvent"
                    name="createCalendarEvent"
                    :value="true"
                    :unchecked-value="false"
                    @change="formUpdated"
                >
                    Přidat do mého kalendáře
                </BFormCheckbox>
            </BFormGroup>

            <BButton type="submit" variant="success" block :disabled="!submitEnabled || isCreating">
                <span v-if="isCreating">
                    <i class="fad fa-spinner fa-spin fa-fw mr-2"/> Odesílám
                </span>
                <span v-else>
                    <i class="fad fa-paper-plane fa-fw mr-2"/> Odeslat incident
                </span>
            </BButton>
        </BForm>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import Header from "../Common/UI/Header.vue"
import IncidentRequest from "../../NewModel/Incident/Request/IncidentRequest"

export default {

    components: {
        Header,
    },

    computed: {
        ...mapGetters({
            isCreating: 'incident/isCreating',
        }),
    },

    data() {
        return {
            submitEnabled: false,
            request: new IncidentRequest(),
        }
    },

    methods: {

        formUpdated() {
            this.submitEnabled = this.request.customer.length > 0
                && this.request.description.length > 0
                && (
                    this.request.createCalendarEvent === false
                    || (
                        this.request.createCalendarEvent === true
                        && this.request.eventTime !== null
                    )
                )
        },

        onSubmit(event) {
            event.preventDefault()
            this.$store.dispatch("incident/createIncident", this.request)
                .then(() => {
                    this.successMessage("Incident uložen")
                    this.$router.push({name: 'mobile_incidents'})
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        }
    }
}
</script>

<style scoped>
.mx-datepicker {
    position: relative;
    display: block !important;
    width: 100% !important;
}
</style>
