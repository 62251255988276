import ProcessStore from "./Store/AssemblySheetProcessStore"
import AvailableAppliancesStore from "./Store/AssemblySheetAvailableAppliancesStore"
import AvailableApplianceTypesStore from "./Store/AssemblySheetAvailableApplianceTypesStore"
import AvailableRepairTypesStore from "./Store/AssemblySheetAvailableRepairTypesStore"
import AppliancesStore from "./Store/AssemblySheetAppliancesStore"
import ItemsStore from "./Store/AssemblySheetItemStore"
import MaterialsStore from "./Store/AssemblySheetMaterialStore"
import ApplianceRepairStore from "./Store/AssemblySheetApplianceRepairStore"


export default {
    namespaced: true,
    modules: {
        process: ProcessStore,
        appliances: AppliancesStore,
        items: ItemsStore,
        materials: MaterialsStore,
        applianceRepairs: ApplianceRepairStore,
        availableAppliances: AvailableAppliancesStore,
        availableApplianceTypes: AvailableApplianceTypesStore,
        availableRepairTypes: AvailableRepairTypesStore,
    }
}
