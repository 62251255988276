import AssemblySheetService from "../AssemblySheetService"
import AssemblySheetSupplyPointApplianceEntity from "../Entity/AssemblySheetSupplyPointApplianceEntity"

const
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR"

const initialState = {
    isLoading: false,
    records: [],
    error: null
}

export const state = {...initialState}

export const actions = {

    async findAvailableAppliances({commit}, id) {
        try {
            commit(FETCHING_RECORDS)
            let response = await AssemblySheetService.findAvailableAppliances(id)
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    }

}

export const mutations = {

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.records = data.map(record => {
            return AssemblySheetSupplyPointApplianceEntity.from(record)
        })
    },
    [FETCHING_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.record = []
    }

}

export const getters = {

    isLoading(state) {
        return state.isCreating
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },
    hasRecords(state) {
        return state.records.length > 0
    },
    getRecords(state) {
        return state.records
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
