import Vue from "vue"

Vue.filter("moneyFormat", function (value, iso, nbsp) {

    let currencyList = {
        NONE: {
            code: "NONE",
            precision: 2,
            thousand: " ",
            decimal: ",",
            symbol: "zł",
            format: "{{value}}"
        },
        EUR: {
            code: "EUR",
            precision: 2,
            thousand: " ",
            decimal: ".",
            symbol: "€",
            format: "{{value}} {{symbol}}"
        },
        USD: {
            code: "USD",
            precision: 2,
            decimal: ".",
            thousand: ",",
            symbol: "$",
            format: "{{symbol}}{{value}}"
        },
        GBP: {
            code: "GBP",
            precision: 2,
            decimal: ".",
            thousand: ",",
            symbol: "£",
            format: "{{symbol}}{{value}}"
        },
        CZK: {
            code: "CZK",
            precision: 0,
            decimal: ".",
            thousand: " ",
            symbol: "Kč",
            format: "{{value}} {{symbol}}"
        },
        CZK_LONG: {
            code: "CZK_LONG",
            precision: 2,
            decimal: ".",
            thousand: " ",
            symbol: "Kč",
            format: "{{value}} {{symbol}}"
        }
    }

    iso = iso || "NONE"
    nbsp = nbsp || false

    var currency = currencyList[iso] || currencyList.NONE,
        number = parseFloat(String(value).replace(",", ".")) || 0,
        places = currency.precision,
        symbol = currency.symbol,
        format = currency.format,
        decimal = currency.decimal,
        thousand = currency.thousand,
        negative = number < 0 ? "-" : "",
        i, j

    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + ""
    j = (j = i.length) > 3 ? j % 3 : 0

    value = negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j)
        .replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i)
        .toFixed(places).slice(2) : "")

    value = format
        .replace("{{symbol}}", symbol)
        .replace("{{value}}", value)

    return nbsp ? value.split(" ").join("&nbsp;") : value
})
