<template>
    <div class="container-fluid p-4">
        <div class="d-flex justify-content-between align-items-center mb-5">
            <h3 class="font-weight-600 text-dark mb-0">
                <i class="fad fa-dashboard text-primary fa-fw mr-3"/>Dashboard
            </h3>
            <div class="buttons">
                <ApplianceNavigator/>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="font-weight-600 text-dark mb-0">
                <i class="fad fa-calendar-day text-primary fa-fw mr-3"/>Dnešní zakázky
            </h5>
            <div class="buttons"/>
        </div>

        <div v-if="isLoading === false && records.length > 0">
            <div v-if="isLoading === false && records.length > 0">
                <OrderRow :record="record" v-for="record in records" :key="record.id"/>
            </div>
        </div>

        <TableLoading v-else-if="isLoading === true && records.length === 0"/>

        <TableWithoutRecords v-else>
            Dnes nejsou k dispozici žádné zakázky.
        </TableWithoutRecords>

        <div v-if="1===2">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h5 class="font-weight-600 text-dark mb-0">
                    <i class="fad fa-dashboard text-primary fa-fw mr-3"/>KPI - Zakázky tento týden
                </h5>
            </div>
            <div class="bg-white p-3 border-radius-sm">
                <PerformanceOrders/>
            </div>
        </div>

    </div>
</template>

<script>
import ApplianceNavigator from "../Component/ApplianceNavigator.vue"
import moment from "moment"
import OrderRow from "../Order/Component/OrderRow.vue"
import PerformanceOrders from "../Component/PerformanceOrders.vue"
import WorkerService from "../../../NewModel/Worker/WorkerService"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        ApplianceNavigator,
        OrderRow,
        PerformanceOrders,
        TableLoading,
        TableWithoutRecords,
    },

    data() {
        return {
            isLoading: false,
            records: [],
        }
    },

    mounted() {
        this.loadTodayOrders()
    },

    methods: {

        loadTodayOrders() {

            let self = this
            self.isLoading = true
            self.records = []

            WorkerService.findOrdersOfWorkerOfRequiredDate(moment().format("YYYY-MM-DD"))
                .then((response) => {
                    self.records = response.data
                })
                .catch((error) => {
                    self.errorMessage(error.response.data.message)
                })
                .finally(() => {
                    self.isLoading = false
                })
        },

    },

}
</script>
