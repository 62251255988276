import AddressService from "../AddressService"

const
    FETCHING_RECORDS = "FETCHING_RECORDS",
    FETCHING_RECORDS_SUCCESS = "FETCHING_RECORDS_SUCCESS",
    FETCHING_RECORDS_ERROR = "FETCHING_RECORDS_ERROR",
    CREATING_RECORD = "CREATING_RECORD",
    CREATING_RECORD_SUCCESS = "CREATING_RECORD_SUCCESS",
    CREATING_RECORD_ERROR = "CREATING_RECORD_ERROR",
    CLEAR_RECORD = "CLEAR_RECORD",
    CLEAR_RECORDS = "CLEAR_RECORDS"

const initialState = {
    isLoading: false,
    isCreating: false,
    record: null,
    records: [],
    totalRecords: 0,
    error: null,
}

export const state = {...initialState}

export const actions = {

    /**
     * @param commit
     * @param query : string
     * @returns {Promise<[]>}
     */
    async whisperAddress({commit}, query) {
        try {
            commit(FETCHING_RECORDS)
            let response = await AddressService.whisperAddress(query)
            commit(FETCHING_RECORDS_SUCCESS, response.data)
            return state.records
        } catch (error) {
            commit(FETCHING_RECORDS_ERROR, error)
            return Promise.reject(error)
        }
    },


    /**
     * @param commit
     * @param addressText : string
     * @param addressHash : string
     * @returns {Promise<*>}
     */
    async validateWhisperedAddress({commit}, {addressText, addressHash}) {
        try {
            commit(CREATING_RECORD)
            let response = await AddressService.validateWhisperedAddress(addressText, addressHash)
            commit(CREATING_RECORD_SUCCESS, response.data)
            return state.record
        } catch (error) {
            commit(CREATING_RECORD_ERROR, error)
            return Promise.reject(error)
        }
    },

}

export const mutations = {

    [FETCHING_RECORDS](state) {
        state.isLoading = true
        state.error = null
        state.records = []
        state.totalRecords = 0
    },
    [FETCHING_RECORDS_SUCCESS](state, data) {
        state.isLoading = false
        state.records = data
        state.totalRecords = data.length
    },
    [FETCHING_RECORDS_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.records = []
        state.totalRecords = 0
    },

    [CREATING_RECORD](state) {
        state.isCreating = true
        state.error = null
    },
    [CREATING_RECORD_SUCCESS](state, data) {
        state.isCreating = false
        state.error = null
        state.record = data
    },
    [CREATING_RECORD_ERROR](state, error) {
        state.isCreating = false
        state.error = error
    },

    [CLEAR_RECORD](state) {
        state.isLoading = false
        state.error = null
        state.record = null
    },

    [CLEAR_RECORDS](state) {
        state.isLoading = false
        state.error = null
        state.records = []
        state.totalRecords = 0
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },

    isCreating(state) {
        return state.isCreating
    },

    isUpdating(state) {
        return state.isUpdating
    },

    hasError(state) {
        return state.error !== null
    },

    error(state) {
        return state.error
    },

    hasAddress(state) {
        return state.record !== null
    },

    getAddress(state) {
        return state.record
    },

    hasAddresses(state) {
        return state.records.length > 0
    },

    getAddresses(state) {
        return state.records
    },

    getTotalAddresses(state) {
        return state.totalRecords
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
