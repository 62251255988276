import AssemblySheetApplianceRepairEntity from "./AssemblySheetApplianceRepairEntity"

export default class AssemblySheetApplianceEntity {

    constructor() {
        this.id = null
        this.appliance = null
        this.manufacturer = null
        this.model = null
        this.modelType = null
        this.recordOrder = 0
        this.hasSerialNumber = false
        this.serialNumber = ""
        this.hasPhoto = false
        this.photo = ""
        this.repairs = []
    }

    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.appliance = response.appliance ? {
            id: response.appliance.id,
            name: response.appliance.name,
        } : null
        obj.manufacturer = response.manufacturer ? {
            id: response.manufacturer.id,
            name: response.manufacturer.name,
        } : null
        obj.model = response.model ? {
            id: response.model.id,
            name: response.model.name,
        } : null
        obj.modelType = response.modelType ? {
            id: response.modelType.id,
            name: response.modelType.name,
        } : null
        obj.recordOrder = response.recordOrder
        obj.hasSerialNumber = response.hasSerialNumber
        obj.serialNumber = response.serialNumber
        obj.hasPhoto = response.hasPhoto
        obj.photo = response.photo
        obj.repairs = response.repairs.map(repair => AssemblySheetApplianceRepairEntity.from(repair))
        return obj
    }

    to() {
        return {
            id: this.id,
            appliance: this.appliance ? this.appliance.id : null,
            manufacturer: this.manufacturer ? this.manufacturer.id : null,
            model: this.model ? this.model.id : null,
            modelType: this.modelType ? this.modelType.id : null,
            recordOrder: this.recordOrder,
            hasSerialNumber: this.hasSerialNumber,
            serialNumber: this.serialNumber,
            hasPhoto: this.hasPhoto,
            photo: this.photo,
            repairs: this.repairs.map(repair => {
                return repair.id
            })
        }
    }

}
