<template>

    <BForm ref="form" @submit.stop.prevent="processForm">

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="boiler_manufacturer" class="font-weight-700">
                    Výrobce spotřebiče:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <Multiselect
                    v-model="request.manufacturer"
                    :options="manufacturer_records"
                    placeholder="Vybrat"
                    label="name"
                    trackBy="name"
                    id="boiler_manufacturer"
                    :loading="manufacturer_isSearching"
                    :options-limit="300"
                    :internal-search="false"
                    @search-change="asyncFindManufacturer"
                    @input="manufacturerSelected"
                >
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title">
                               <strong class="font-weight-600">{{ props.option.name }}</strong>
                            </span>
                        </div>
                    </template>
                </Multiselect>
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="boiler_type" class="font-weight-700">
                    Model spotřebiče:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <Multiselect
                    v-model="request.model"
                    :options="model_records"
                    placeholder="Vybrat"
                    label="name"
                    trackBy="name"
                    id="boiler_model"
                    :loading="model_isSearching"
                    :options-limit="300"
                    :internal-search="false"
                    @search-change="asyncFindModel"
                    @input="modelSelected"
                >
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title">
                                <strong class="font-weight-600">{{ props.option.name }}</strong>
                            </span>
                        </div>
                    </template>
                </Multiselect>
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="boiler_type" class="font-weight-700">
                    Provedení spotřebiče:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <Multiselect
                    v-model="request.modelType"
                    :options="modelType_records"
                    placeholder="Vybrat"
                    label="name"
                    trackBy="name"
                    id="boiler_type"
                    :loading="modelType_isSearching"
                    :options-limit="300"
                    :internal-search="false"
                    @search-change="asyncFindModelType"
                    @input="modelTypeSelected"
                >
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title">
                                <strong class="font-weight-600">{{ props.option.name }}</strong>
                            </span>
                        </div>
                    </template>
                </Multiselect>
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="appliance_type" class="font-weight-700">
                    Druh spotřebiče: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <ApplianceTypeSelect v-model="request.type"/>
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="serial_number" class="font-weight-700">
                    Sériové číslo:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    autocomplete="off"
                    v-model="request.serialNumber"
                    id="serial_number"
                    type="text"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="date_of_installation" class="font-weight-700">
                    Datum instalace:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    autocomplete="off"
                    v-model="request.dateOfInstallation"
                    id="date_of_installation"
                    type="date"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="warranty_to_date" class="font-weight-700">
                    Záruka platná do:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    autocomplete="off"
                    v-model="request.warrantyToDate"
                    id="warranty_to_date"
                    type="date"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="regulation" class="font-weight-700">
                    Regulace:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    autocomplete="off"
                    v-model="request.regulation"
                    id="regulation"
                    type="text"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="fuel" class="font-weight-700">
                    Palivo: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormSelect
                    v-model.number="request.fuel"
                    :options="list.fuel"
                    value-field="id"
                    text-field="text"
                    disabled-field="notEnabled"
                    required
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="detail_place_of_installation" class="font-weight-700">
                    Popis místa instalace:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    autocomplete="off"
                    v-model="request.detailPlaceOfInstallation"
                    id="detail_place_of_installation"
                    type="text"
                />
            </BCol>
        </BRow>

        <BRow class="mb-3">
            <BCol lg="3" md="12">
                <label for="note" class="font-weight-700">
                    Poznámka:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormTextarea
                    v-model="request.note"
                    id="note"
                />
            </BCol>
        </BRow>

        <BRow class="mb-0">
            <BCol lg="3" md="12"/>
            <BCol lg="9" md="12">
                <BButton type="submit" variant="primary" :disabled="isProcessing">
                    <i class="fad fa-spinner-third fa-spin mr-2" v-if="isProcessing"/>Uložit
                </BButton>
            </BCol>
        </BRow>

    </BForm>

</template>

<script>
import {mapGetters} from "vuex"
import ApplianceRequest from "../../../NewModel/Appliance/Request/ApplianceRequest"
import ApplianceTypeSelect from "../../Common/Form/ApplianceTypeSelect.vue"
import Fuel from "../../../Model/Enum/fuel"
import Multiselect from 'vue-multiselect'

export default {

    components: {
        ApplianceTypeSelect,
        Multiselect,
    },

    props: {
        request: {
            type: ApplianceRequest,
            required: true,
        },
        isProcessing: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            list: {
                fuel: Fuel.arrayList(),
            },
        }
    },

    computed: {
        ...mapGetters({
            manufacturer_isSearching: 'appliance/manufacturers/search/isLoading',
            manufacturer_haveRecords: 'appliance/manufacturers/search/hasRecords',
            manufacturer_records: 'appliance/manufacturers/search/getRecords',
            model_isSearching: 'appliance/models/search/isLoading',
            model_haveRecords: 'appliance/models/search/hasRecords',
            model_records: 'appliance/models/search/getRecords',
            modelType_isSearching: 'appliance/modelTypes/search/isLoading',
            modelType_haveRecords: 'appliance/modelTypes/search/hasRecords',
            modelType_records: 'appliance/modelTypes/search/getRecords',
        }),
    },

    methods: {

        asyncFindManufacturer(query) {
            if (query.length >= 2) {
                this.$store.dispatch("appliance/manufacturers/search/searchManufacturer", {query})
                    .catch(() => {
                    })
            }
        },

        asyncFindModel(query) {
            if (query.length >= 2) {
                this.$store.dispatch("appliance/models/search/searchModel", {
                    query,
                    manufacturer: this.request.manufacturer.id || null
                }).catch(() => {
                })
            }
        },

        asyncFindModelType(query) {
            if (query.length >= 2) {
                this.$store.dispatch("appliance/modelTypes/search/searchModelType", {
                    query,
                    model: this.request.model.id || null,
                    manufacturer: this.request.manufacturer.id || null
                })
                    .catch(() => {
                    })
            }
        },

        /**
         * @param selectedOption : ApplianceSuggestionEntity
         */
        manufacturerSelected(selectedOption) {
            if (selectedOption !== null) {
                this.request.manufacturer = selectedOption.manufacturer
                this.request.model = {
                    id: null,
                    name: "Nepřiřazený model spotřebiče",
                }
                this.request.modelType = {
                    id: null,
                    name: "Nepřiřazené provedení spotřebiče",
                }
            } else {
                this.request.manufacturer = {
                    id: null,
                    name: ""
                }
            }
        },

        /**
         * @param selectedOption : ApplianceSuggestionEntity
         */
        modelSelected(selectedOption) {
            if (selectedOption !== null) {
                this.request.manufacturer = selectedOption.manufacturer
                this.request.model = selectedOption.model
                this.request.modelType = {
                    id: null,
                    name: "Nepřiřazené provedení spotřebiče",
                }
            } else {
                this.request.model = {
                    id: null,
                    name: ""
                }
            }
        },

        /**
         * @param selectedOption : ApplianceSuggestionEntity
         */
        modelTypeSelected(selectedOption) {
            if (selectedOption !== null) {
                this.request.manufacturer = selectedOption.manufacturer
                this.request.model = selectedOption.model
                this.request.modelType = {
                    id: selectedOption.modelType.id,
                    name: selectedOption.modelType.name
                }

                if (selectedOption.recordType === "type") {
                    this.request.type = selectedOption.applianceType.id
                }
            } else {
                this.request.modelType = {
                    id: null,
                    name: ""
                }
            }
        },

        processForm() {
            if (!this.$refs.form.checkValidity()) {
                this.warningMessage('Selhala validace. Byly všechny požadované pole vyplněny?')
                return false
            }
            this.$emit("submitted", this.request)

        },

    },

}
</script>
