<template>
    <div class="min-vh-100 h-100 d-flex">

        <Sidebar/>

        <div id="content-wrapper" class="d-flex flex-column w-100">

            <div id="content" class="flex-grow-1">
                <div v-if="applicationEnabled">
                    <RouterView/>
                </div>
                <div class="d-flex justify-content-center align-items-center h-100" v-else>
                    <div class="text-center">
                        <i class="fa-thin fa-cloud-exclamation fa-4x mb-5 text-danger"/>
                        <div class="mb-5 display-4">
                            Aplikace vyžaduje pro současné i připravované funkcionality oprávnění pro fotoaparát a
                            polohu.
                            <br/>
                            <br/>
                            Udělte patřičná oprávnění a obnovte stránku.
                        </div>
                        <div>
                            <PrimaryButton @click="reloadWindow()" text="Obnovit stránku"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import PrimaryButton from "../Common/UI/Button/PrimaryButton.vue"
import Sidebar from "./Component/Sidebar.vue"

export default {

    components: {
        PrimaryButton,
        Sidebar,
    },

    data() {
        return {
            development: (process.env.NODE_ENV !== "production"),
            applicationEnabled: true,
        }
    },

    methods: {

        checkPermissions() {
            navigator.permissions.query({name: "geolocation"}).then((result) => {
                if (result.state !== "granted") {
                    this.applicationEnabled = false
                    this.tryGeolocation()
                }
            })
        },

        tryGeolocation() {
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log("Latitude: " + position.coords.latitude)
                    console.log("Longitude:" + position.coords.longitude)
                },
                error => {
                    if (error.code === error.PERMISSION_DENIED) {
                        this.applicationEnabled = false
                    }
                },
            )
        },

        reloadWindow() {
            window.location.reload()
        },

    },

    mounted() {
        this.checkPermissions()
    },

}
</script>
