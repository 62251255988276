import AddressEntity from "../../../NewModel/Address/AddressEntity"

export default class AresEntity {

    constructor() {
        this.name = null
        this.identificationNumber = null
        this.vatNumber = null
        this.address = new AddressEntity()
    }

    static from(response) {
        let obj = new this()
        obj.name = response.name
        obj.identificationNumber = response.identificationNumber
        obj.vatNumber = response.vatNumber
        obj.address = AddressEntity.from(response.address)
        return obj
    }

}
