<template>
    <BLink
        v-on:click.prevent="process"
        variant="primary"
    >PZ
    </BLink>
</template>

<script>
import {mapGetters} from "vuex"

export default {

    computed: {
        ...mapGetters({
            order: 'order/getOrder',
            isProcessing: 'order/isProcessing',
        }),
    },

    methods: {

        process() {
            this.$bvModal.msgBoxConfirm('Opravdu chcete vytvořit podřízenou zakázku?', {
                title: 'Potvrzení',
                okVariant: 'primary',
                cancelVariant: 'default',
                centered: true,
                okTitle: "Ano, vytvořit.",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("order/createChildOrder", this.order.id)
                            .then((response) => {
                                this.$router.push({name: 'order_show', params: {id: response.id}})
                                this.successMessage('Podřízená zakázka byla úspěšně vytvořena.')
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

    },

}
</script>
