export default class OrderCancelRequest {

    constructor() {
        this.note = ""
    }

    toForm() {
        return this
    }

}
