<template>
    <BForm ref="form" @submit.stop.prevent="processForm">

        <!-- Typ subjektu = FO (fyzická osoba nepodnikatel) / PO (fyzická osoba podnikatel nebo obchodní společnost) -->
        <BRow class="mb-4">
            <BCol lg="3" md="12">
                <label for="subjectType" class="bold col-form-label">
                    Typ subjektu: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormRadioGroup
                    v-model="request.type"
                    :options="subjectType.list()"
                    id="subjectType"
                    value-field="id"
                    text-field="text"
                    stacked
                />
            </BCol>
        </BRow>

        <BRow class="mb-4" v-if="request.isNaturalPerson()">
            <BCol lg="3" md="12">
                <label class="bold col-form-label" for="lastName">
                    Příjmení a jméno: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BRow>
                    <BCol>
                        <!-- Příjmení -->
                        <BFormInput
                            v-model="request.lastName"
                            :required="request.isNaturalPerson()"
                            autocomplete="off"
                            id="lastName"
                            type="text"
                            placeholder="Příjmení"
                        />
                    </BCol>
                    <BCol>
                        <!-- Jméno -->
                        <BFormInput
                            v-model="request.firstName"
                            :required="request.isNaturalPerson()"
                            id="firstName"
                            type="text"
                            autocomplete="off"
                            placeholder="Jméno"
                        />
                    </BCol>
                    <BCol>
                        <!-- Datum narození -->
                        <BFormInput
                            v-model="request.birthDate"
                            id="birthDate"
                            type="date"
                            autocomplete="off"
                            placeholder="Datum narození"
                        />
                    </BCol>
                </BRow>
                <small class="text-muted">
                    Datum narození není povinné. Slouží pro odlišení a je přeneseno z IS.
                </small>
            </BCol>
        </BRow>

        <BRow class="mb-4" v-if="request.isCompany()">
            <BCol lg="3" md="12">
                <label class="bold col-form-label">
                    IČO: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BInputGroup>
                    <BFormInput
                        v-model="request.identificationNumber"
                        :required="request.isCompany()"
                        type="text"
                        autocomplete="off"
                        placeholder="Identifikační číslo subjektu"
                    />
                    <BInputGroupAppend>
                        <LoadFromEconomicRegistry
                            :identificationNumber="request.identificationNumber"
                            @obtained="companyInformationObtained"
                        />
                    </BInputGroupAppend>
                </BInputGroup>
                <div
                    class="text-muted small mt-2"
                    v-if="request.identificationNumber && request.identificationNumber.length < 8"
                >
                    Pro předvyplnění formuláře z registru ekonomických subjektů je nutné zadat platné identifikační
                    číslo subjektu.
                </div>
            </BCol>
        </BRow>

        <BRow class="mb-4" v-if="request.isCompany()">
            <BCol lg="3" md="12">
                <label class="bold col-form-label">
                    DIČ:
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.vatNumber"
                    type="text"
                    autocomplete="off"
                    placeholder="Daňové identifikační číslo subjektu"
                />
            </BCol>
        </BRow>

        <BRow class="mb-4" v-if="request.isCompany()">
            <BCol lg="3" md="12">
                <label class="bold col-form-label">
                    Obchodní název: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BFormInput
                    v-model="request.companyName"
                    :required="request.isCompany()"
                    type="text"
                    autocomplete="off"
                />
            </BCol>
        </BRow>

        <BRow class="mb-4">
            <BCol lg="3" md="12">
                <label class="bold col-form-label">
                    Adresa: <span class="text-danger">*</span>
                </label>
            </BCol>
            <BCol lg="9" md="12">
                <BInputGroup v-if="request.address.id">
                    <BFormInput v-model="request.address.wholeName" disabled/>
                    <BInputGroupAppend>
                        <BButton variant="primary" v-on:click="request.resetAddress()">
                            X
                        </BButton>
                    </BInputGroupAppend>
                </BInputGroup>
                <AddressSuggestion @picked="addressSelected" v-else/>
                <div class="small text-muted mt-1 pointer">
                    <BLink
                        to="#"
                        v-b-modal.subject-address-modal-form
                        class="text-muted"
                        style="text-decoration: none;"
                    >
                        Vytvořit adresu ručně
                    </BLink>
                </div>
            </BCol>
        </BRow>

        <div class="d-flex justify-content-between align-items-center bg-light p-3">
            <h5 class="h5 mb-0"><i class="fad fa-phone-office mr-4"/>Kontakty</h5>
            <div class="buttons">
                <button type="button" class="btn btn-success btn-sm" v-on:click.prevent="addContact">
                    <i class="fad fa-user-plus mr-3"/>Přidat kontakt
                </button>
            </div>
        </div>

        <table class="table  table-borderless table-hover border-top" v-if="request.contacts.length > 0">
            <tbody>
            <tr v-for="(contact, contactIndex) in request.contacts" v-bind:key="contactIndex" class="border-bottom">
                <td class="align-middle">
                    <BFormInput
                        v-model="contact.name"
                        type="text"
                        placeholder="Název kontaktu"
                        autocomplete="off"
                    />
                </td>
                <td class="align-middle">
                    <BFormInput
                        v-model="contact.phone"
                        type="text"
                        placeholder="Telefon (+420)"
                        autocomplete="off"
                    />
                </td>
                <td class="align-middle">
                    <BFormInput
                        v-model="contact.email"
                        type="email"
                        placeholder="E-mail"
                        autocomplete="off"
                    />
                </td>
                <td class="align-middle">
                    <a href="" class="btn btn-danger btn-sm" v-on:click.prevent="removeContact(contactIndex)">
                        <i class="fad fa-minus"/>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="p-3" v-else>
            <span>Dosud nevložen žádný kontakt ...</span>
        </div>

        <hr class="border-0 border-bottom">

        <BButton type="submit" variant="primary">
            <i class="fad fa-save mr-3"/>Zpracovat
        </BButton>

        <BModal
            size="lg"
            centered
            id="subject-address-modal-form"
            title="Vytvoření nové adresy subjektu"
            hide-footer
            content-class="border-0"
            header-bg-variant="primary"
            header-text-variant="white"
            body-class="p-4"
        >
            <AddressFormComponent @picked="addressSelected"/>
        </BModal>

    </BForm>

</template>

<script>
import AddressFormComponent from "@/Module/Address/Component/AddressFormComponent.vue"
import AddressSuggestion from "../../Address/Component/AddressSuggestion.vue"
import LoadFromEconomicRegistry from "../../Subject/Component/LoadFromEconomicRegistry.vue"
import SubjectContactRequest from "../../../NewModel/Subject/Request/SubjectContactRequest"
import SubjectRequest from "../../../NewModel/Subject/Request/SubjectRequest"
import SubjectType from "@/Model/Enum/subject_type"
import Sweetalert2 from "sweetalert2"

export default {

    components: {
        AddressFormComponent,
        AddressSuggestion,
        LoadFromEconomicRegistry,
    },

    props: {
        request: {
            type: SubjectRequest,
            required: false,
            default: () => new SubjectRequest,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            subjectType: SubjectType,
        }
    },

    methods: {

        processForm() {

            if (!this.$refs.form.checkValidity()) {
                return
            }

            try {
                this.request.contacts.forEach((contact) => {
                    if (contact.name === "") {
                        throw new Error("Vyplňte jméno u kontaktu!")
                    }
                    if (contact.phone === "") {
                        throw new Error("Vyplňte telefonní číslo u kontaktu!")
                    }
                })
            } catch (err) {
                this.warningMessage(err.message)
                return false
            }

            if (this.id === null) {
                this.createSubject()
            } else {
                this.updateSubject()
            }
        },

        createSubject(override = false) {
            this.request.force = override

            // Vytvoříme subjekt
            this.$store.dispatch("subject/createSubject", this.request)
                .then((data) => {
                    this.successMessage("Subjekt byl vytvořen.")
                    this.$emit("submitted", data)
                })
                .catch((error) => {
                        if (error.response.status === 409) {
                            this.subjectExistsAskForOverride()
                        } else {
                            this.errorMessage(error.response.data.message)
                        }
                    }
                )
        },

        updateSubject() {
            this.$store.dispatch("subject/updateSubject", {
                id: this.id,
                request: this.request,
            })
                .then((data) => {
                    this.successMessage("Subjekt byl aktualizován.")
                    this.$emit("submitted", data)
                })
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        addressSelected(address) {
            this.request.address.id = address.id
            this.request.address.wholeName = address.wholeName
        },

        removeContact(index) {
            this.request.contacts.splice(index, 1)
        },

        addContact() {
            let contact = new SubjectContactRequest()
            this.request.contacts.push(contact)
        },

        /**
         * Callback pro načtení informací o společnosti z ekonomického registru
         * @param data : AresEntity
         */
        companyInformationObtained(data) {
            this.request.companyName = data.name
            this.request.identificationNumber = data.identificationNumber
            this.request.vatNumber = data.vatNumber
            this.request.address.id = data.address.id
            this.request.address.wholeName = data.address.wholeName
        },

        subjectExistsAskForOverride() {
            let self = this
            Sweetalert2.fire({
                title: "UPOZORNĚNÍ",
                text: "V systému existuje shoda (příjmení nebo IČO) na této adrese. Chcete jej i tak vytvořit?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#439e55",
                cancelButtonColor: "#db2f36",
                confirmButtonText: "Ano",
                cancelButtonText: "Ne"
            })
                .then((result) => {
                    if (result.value) {
                        self.createSubject(true)
                    }
                })
        },

    },

}
</script>
