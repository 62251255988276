export default class SubjectSupplyPointApplianceEntity {

    constructor() {
        this.id = null
        this.applianceType = ''
        this.name = ''
        this.serialNumber = ''
        this.dateOfInstallation = null
        this.warrantyToDate = null
    }

    /**
     * @param response : object
     * @returns SubjectSupplyPointEntity
     */
    static from(response) {
        let obj = new this
        obj.id = response.id
        obj.applianceType = response.applianceType
        obj.name = response.name || ''
        obj.serialNumber = response.serialNumber || ''
        obj.dateOfInstallation = response.dateOfInstallation
        obj.warrantyToDate = response.warrantyToDate
        return obj
    }

}
