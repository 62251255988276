<template>
    <div>

        <Header icon="fad fa-calendar-alt" title="Plánování - EAN">
            <template #buttons>
                <PrimaryButton v-b-modal.plan-put-to-sleep icon="fad fa-snooze" text="Uspat"/>
                <BButton
                    variant="primary"
                    v-on:click="createOrder"
                    v-if="hasPlan && plan.supplyPoint.activeOrder === null"
                    class="ml-4"
                >
                    <i class="fad fa-plus-circle fa-fw mr-3"/>Vytvořit zakázku
                </BButton>
                <BackLinkButton :to="{name: 'planning'}" class="ml-4"/>
            </template>
        </Header>

        <div v-if="hasPlan && !isLoading">

            <div
                class="table-warning px-3 py-2 border-radius-sm d-flex justify-content-between align-items-center mb-3"
                v-if="plan.supplyPoint.activeOrder !== null"
            >
                <div class="font-weight-700">
                    <i class="fad text-danger fa-exclamation-triangle fa-fw mr-3"/>Probíhající zakázka
                </div>
                <BButton
                    :to="{ name: 'order_show', params: {id: plan.supplyPoint.activeOrder}}"
                    variant="warning"
                    size="sm"
                >
                    <i class="fad fa-link mr-3"/>Přejít na zakázku
                </BButton>
            </div>

            <BFormRow>
                <BCol class="mb-4">
                    <div class="bg-little-gray p-4 h-100">

                        <h5 class="font-weight-600 text-dark mb-3">
                            <i class="fad fa-file-contract text-danger fa-fw mr-3"/>Odběrné místo
                        </h5>

                        <table class="table table-borderless table-sm mb-0">
                            <tr>
                                <td class="w-25 font-weight-600">
                                    EAN:
                                </td>
                                <td>
                                    <i class="mr-2" :class="commodity.getItem(plan.supplyPoint.commodity).icon"/>
                                    <BLink
                                        :to="{ name: 'supply_point_show', params: { id: plan.supplyPoint.id }}"
                                        target='_blank'
                                    >
                                        {{ plan.supplyPoint.ean }}
                                    </BLink>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-25 font-weight-600">
                                    Adresa:
                                </td>
                                <td>
                                    <a
                                        :href="'https://www.google.cz/maps/place/' + encodeURIComponent(plan.supplyPoint.address)"
                                        target="_blank"
                                    >
                                        {{ plan.supplyPoint.address }}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-25 font-weight-600">
                                    Servisní sml.:
                                </td>
                                <td>
                                    {{ serviceContractLevel.getItem(plan.supplyPoint.serviceContract).text }}
                                </td>
                            </tr>
                            <tr>
                                <td class="w-25 font-weight-600">
                                    KV Plyn:
                                </td>
                                <td v-if="plan.supplyPoint.activeContract">
                                    <a
                                        :href="'https://is.kvplyn.cz/#subsite=History/masterContractId=_MASTER_ID_/st=KvPlyn.Sites.Contract.Detail'.replace('_MASTER_ID_', plan.supplyPoint.contractMasterId)"
                                        target="_blank"
                                    >
                                        {{ plan.supplyPoint.contractCustomId }}
                                    </a>
                                </td>
                                <td v-else>
                                    Ne
                                </td>
                            </tr>
                            <tr v-if="plan.ignoreUntilDate">
                                <td class="w-25 font-weight-600">
                                    Uspání:
                                </td>
                                <td class="text-danger font-weight-900">
                                    do {{ plan.ignoreUntilDate|prettyDate }}
                                </td>
                            </tr>
                        </table>

                        <div
                            class="table-info border-radius-sm p-3 text-dark d-flex justify-content-between align-items-center"
                            v-if="plan.supplyPoint.validDiscount"
                        >
                            <strong class="font-weight-600">
                                <i class="fad fa-exclamation-triangle mr-2 fa-fw"/>
                                K tomuto odběrnému místu existuje SLEVA
                            </strong>
                            <BLink
                                :to="{name: 'discounts_show', params: {id: plan.supplyPoint.validDiscount}}"
                                target="_blank">Odkaz do slevy
                            </BLink>
                        </div>

                    </div>
                </BCol>

                <BCol class="mb-4">
                    <div class="bg-little-gray p-4 h-100">
                        <h5 class="font-weight-600 text-dark mb-3">
                            <i class="fad fa-user-circle text-danger fa-fw mr-3"/>Zákazník
                        </h5>
                        <div class="text-uppercase font-weight-700 mb-2">
                            {{ plan.customer.name }}
                        </div>
                        <div v-if="plan.customer.type === 1" class="mb-2">
                            <span class="font-weight-700">IČO:</span> {{ plan.customer.identificationNumber }}
                        </div>
                        <div v-if="plan.customer.type === 2" class="mb-2">
                            <span class="font-weight-700">Datum nar.:</span> {{ plan.customer.birthDate|prettyDate }}
                        </div>
                        <div>
                            <strong>Kontakty:</strong><br/>
                            <ul>
                                <li
                                    v-for="contact in plan.customer.contacts"
                                    v-bind:key="'propertyOwner_' + contact.id"
                                >
                                    <span v-bind:class="{'font-weight-600': contact.contractMain}">
                                        {{ contact.name || "Beze jména" }}
                                        - {{ contact.email || "Bez emailu" }}
                                        - {{ contact.phone || "Bez telefonu" }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </BCol>
                <BCol class="mb-4" v-if="plan.propertyOwner.id && plan.propertyOwner.id !== plan.customer.id">
                    <div class="bg-little-gray p-4 h-100">
                        <h5 class="font-weight-600 text-dark mb-3">
                            <i class="fad fa-building text-danger fa-fw mr-3"/>Vlastník
                        </h5>
                        <div class="text-uppercase font-weight-700 mb-2">
                            {{ plan.propertyOwner.name }}
                        </div>
                        <div v-if="plan.propertyOwner.type === 1" class="mb-2">
                            <span class="font-weight-700">IČO:</span>
                            {{ plan.propertyOwner.identificationNumber }}
                        </div>
                        <div v-if="plan.propertyOwner.type === 2" class="mb-2">
                            <span class="font-weight-700">Datum nar.:</span>
                            {{ plan.propertyOwner.birthDate|prettyDate }}
                        </div>
                        <div>
                            <strong>Kontakty:</strong>
                            <br/>
                            <ul>
                                <li
                                    v-for="contact in plan.propertyOwner.contacts"
                                    v-bind:key="'propertyOwner_' + contact.id"
                                >
                                    <span v-bind:class="{'font-weight-600': contact.contractMain}">
                                        {{ contact.name || "Beze jména" }}
                                        - {{ contact.email || "Bez emailu" }}
                                        - {{ contact.phone || "Bez telefonu" }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </BCol>
            </BFormRow>
        </div>

        <BFormRow v-if="hasPlan && !isLoading">
            <BCol>
                <div class="bg-little-gray p-4 h-100">
                    <h5 class="font-weight-600 text-dark mb-3">
                        <i class="fad fa-comment text-danger fa-fw mr-3"/>Komunikace
                    </h5>
                    <SupplyPointCommunicationsComponent :supply-point="plan.supplyPoint.id"/>
                </div>
            </BCol>
            <BCol>
                <div class="bg-little-gray p-4 h-100">
                    <h5 class="font-weight-600 text-dark mb-3">
                        <i class="fad fa-satellite-dish text-danger fa-fw mr-3"/>Spotřebiče & opravy
                    </h5>

                    <div
                        v-for="(appliance, applianceIndex) in plan.appliances"
                        v-bind:key="'appliance:' + appliance.id"
                        v-bind:class="{'mb-3': applianceIndex + 1 !== plan.appliances.length }"
                    >
                        <div class="p-2 font-weight-600 bg-light border-radius-sm">
                            <i
                                class="fa fa-circle mr-2"
                                :class="appliance.validWarranty() ? 'text-success' : 'text-danger'"
                                v-b-tooltip.hover
                                :title="'Záruka do: ' + appliance.formatWarranty()"
                            />
                            {{ appliance.name }}
                            <BLink
                                class="text-dark"
                                :to="{ name: 'appliance_show', params: { appliance_id: appliance.applianceId }}"
                                target="_blank"
                            >
                                <i class="fad fa-external-link ml-2"/>
                            </BLink>
                        </div>
                        <div>
                            <table class="table table-sm table-borderless mb-0">
                                <thead>
                                <tr>
                                    <th>
                                        Druh opravy
                                    </th>
                                    <th>
                                        Následné datum opravy
                                    </th>
                                    <th>
                                        Poslední oprava
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="repair in appliance.repairs"
                                    v-bind:key="'repair:' + repair.id"
                                    class="border-top"
                                >
                                    <td>
                                        {{ repair.repairType }}
                                        <i
                                            class="fab fa-bitcoin fa-fw ml-1 text-warning"
                                            v-if="repair.isBenefit"
                                            title="Benefit"
                                        />
                                        <i class="fad fa-badge-dollar fa-fw ml-1 text-success"
                                           v-if="!repair.isPaid"
                                           title="Zdarma"
                                        />
                                    </td>
                                    <td>
                                        {{ repair.plannedDateOfRepair|prettyDate }}
                                    </td>
                                    <td>
                                        {{ repair.dateOfLastRepair|prettyDate }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </BCol>
        </BFormRow>

        <BModal
            id="plan-put-to-sleep"
            ref="modal"
            title="Uspat"
            ok-title="Nastavit"
            @ok.prevent="sleepPlan"
        >
            <form ref="form">
                <BFormGroup label-for="duration">
                    <template #label>
                        Uspat na dobu: <span class="text-danger">*</span>
                    </template>
                    <BFormSelect
                        id="duration"
                        v-model="form.putToSleep.duration"
                        :options="list.duration" required
                    />
                </BFormGroup>
                <BFormGroup label-for="message">
                    <template #label>
                        Poznámka: <span class="text-danger">*</span>
                    </template>
                    <BTextarea id="message" v-model="form.putToSleep.message" required/>
                </BFormGroup>
            </form>
        </BModal>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import BackLinkButton from "../Common/UI/Button/BackLinkButton.vue"
import Commodity from "../../Model/Enum/commodity"
import Header from "../Common/UI/Header.vue"
import PrimaryButton from "../Common/UI/Button/PrimaryButton.vue"
import ServiceContractLevel from "../../Model/Enum/service_contract_level"
import SupplyPointCommunicationsComponent from "../SupplyPoint/Component/SupplyPointCommunicationsComponent.vue"
import Sweetalert2 from "sweetalert2"

export default {

    components: {
        BackLinkButton,
        Header,
        PrimaryButton,
        SupplyPointCommunicationsComponent,
    },

    computed: {
        ...mapGetters({
            isLoading: "planning/isLoading",
            hasPlan: "planning/hasPlan",
            plan: "planning/getPlan",
        }),
    },

    data() {
        return {
            commodity: Commodity,
            serviceContractLevel: ServiceContractLevel,
            form: {
                putToSleep: {
                    message: "",
                    duration: "1M",
                },
            },
            list: {
                duration: [
                    {value: "1W", text: "1 týden"},
                    {value: "2W", text: "2 týdny"},
                    {value: "1M", text: "1 měsíc"},
                    {value: "2M", text: "2 měsíce"},
                    {value: "3M", text: "3 měsíce"},
                    {value: "6M", text: "6 měsíců"},
                    {value: "1Y", text: "1 rok"},
                ],
            },
        }
    },

    created() {
        this.$store.dispatch("planning/clear")
        this.loadPlan(this.$route.params.plan_id)
    },

    methods: {

        createOrder() {
            let self = this
            Sweetalert2.fire({
                title: "Nová zakázka",
                text: "Potvrďte vytvoření zakázky",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#437e9e",
                cancelButtonColor: "#db2f36",
                confirmButtonText: "Ano",
                cancelButtonText: "Ne"
            })
                .then((result) => {
                    if (result.value) {
                        this.$store.dispatch("supplyPoint/orders/createOrder", self.plan.supplyPoint.id)
                            .then((order) => {
                                this.successMessage("Zakázka vytvořena, za okamžik budete přesměrováni.")
                                this.$router.push({name: "order_show", params: {id: order.id}})
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

        loadPlan(id) {
            this.$store.dispatch("planning/getPlan", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        checkFormValidity() {
            return this.$refs.form.checkValidity()
        },

        sleepPlan() {

            let self = this

            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                this.warningMessage("Vyplňte všechna povinná políčka formuláře!")
                return
            }

            this.$store.dispatch("planning/putToSleep", {
                id: this.$route.params.plan_id,
                length: this.form.putToSleep.duration,
                message: this.form.putToSleep.message,
            })
                .then(() => {
                    this.successMessage("Úspěšně uloženo!")
                    self.$root.$emit("bv::hide::modal", "plan-put-to-sleep")
                })
        },

    },

}
</script>
