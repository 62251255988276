export default class UserEntity {

    constructor() {
        this.id = null
        this.name = ""
        this.picture = ""
        this.acronym = ""
    }

    static from(response) {

        let obj = new this
        obj.id = response.id
        obj.name = response.name
        obj.picture = response.picture
        obj.acronym = response.acronym
        return obj
    }

    to() {
        return this.id
    }

}
