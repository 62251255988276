<template>
    <div>

        <table class="table table-borderless mb-0">
            <tbody>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Odběrné místo:
                </td>
                <td class="align-middle">
                    <RouterLink
                        :to="{ name: 'supply_point_show', params: { id: entity.supplyPoint.id }}"
                    >
                        {{ entity.supplyPoint.ean }}
                    </RouterLink>
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Adresa odběrného místa:
                </td>
                <td class="align-middle">
                    {{ entity.address.name }}
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Výrobce a typ spotřebiče:
                </td>
                <td class="align-middle">{{ entity.name }}</td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Druh spotřebiče:
                </td>
                <td class="align-middle">{{ entity.applianceType.name }}</td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Identifikátor spotřebiče:
                </td>
                <td class="align-middle">
                    #{{ String(entity.id).slice(-5) }}
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Sériové číslo:
                </td>
                <td class="align-middle">
                    {{ entity.serialNumber }}
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Datum instalace:
                </td>
                <td class="align-middle">
                    {{ entity.dateOfInstallation|prettyDate }}
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Záruka do:
                </td>
                <td class="align-middle">
                    {{ entity.warrantyToDate|prettyDate }}
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Regulace:
                </td>
                <td class="align-middle">
                    {{ entity.regulation }}
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Palivo:
                </td>
                <td class="align-middle">
                    {{ this.entity.fuel ? this.list.fuel.getItem(this.entity.fuel).text : "N/A" }}
                </td>
            </tr>
            <tr class="border-bottom">
                <td class="font-weight-700 align-middle w-40">
                    Upřesnění místa instalace:
                </td>
                <td class="align-middle">
                    {{ entity.detailPlaceOfInstallation }}
                </td>
            </tr>
            <tr>
                <td class="font-weight-700 align-middle w-40">
                    Poznámka:
                </td>
                <td class="align-middle">
                    {{ entity.note }}
                </td>
            </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import Fuel from "../../../Model/Enum/fuel"
import ApplianceEntity from "../../../NewModel/Appliance/Entity/ApplianceEntity"

export default {

    props: {
        entity: {
            type: ApplianceEntity,
            required: true,
        },
    },

    data() {
        return {
            list: {
                fuel: Fuel,
            },
        }
    }
}
</script>
