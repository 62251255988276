<template>
    <div>
        <div v-if="isLoading === false && hasRecords">
            <div class="bg-little-gray p-4">
                <table class="table table-borderless mb-0">
                    <thead>
                    <tr>
                        <th class="align-middle text-left w-12">
                            EAN
                        </th>
                        <th class="align-middle">
                            Název položky
                        </th>
                        <th class="align-middle">
                            Specifikace
                        </th>
                        <th class="text-center align-middle w-10">
                            Množství
                        </th>
                        <th class="text-center align-middle w-10">
                            Vydal
                        </th>
                        <th class="text-right align-middle w-10">
                            Akce
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(record, recordIndex) in records" v-bind:key="record.id"
                        class="border-top">
                        <td class="align-middle text-left">
                            {{ record.ean }}
                        </td>
                        <td class="align-middle">
                            {{ record.itemName || "Neidentifikováno" }}
                        </td>
                        <td class="align-middle">
                            {{ record.specification }}
                        </td>
                        <td class="align-middle">
                            {{ record.quantity }}
                        </td>
                        <td class="align-middle text-center">
                            <BAvatar
                                size="md"
                                :src="record.createdBy.picture || null"
                                :text="record.createdBy.acronym"
                                v-if="record.createdBy"
                            />
                            <BAvatar size="md" variant="light" v-else/>
                        </td>
                        <td class="align-middle text-right">
                            <BButton
                                variant="danger"
                                v-on:click.prevent="cancelMaterial(record.id, recordIndex)"
                                size="sm"
                                :disabled="order.locked"
                            >
                                <i class="fa-duotone fa-trash-alt fa-fw"/>
                            </BButton>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <TableLoading v-else-if="isLoading === true && hasRecords === false"/>
        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TableWithoutRecords,
    },

    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isLoading: "worker/order/materials/isLoading",
            hasRecords: "worker/order/materials/hasRecords",
            records: "worker/order/materials/getRecords",
        })
    },

    created() {
        this.$eventBus.$on("order-updated", () => {
            this.loadOrder(this.order.id)
        })
    },

    beforeDestroy() {
        this.$eventBus.$off("order-updated")
    },

    mounted() {
        this.loadOrder(this.order.id)
    },

    methods: {

        loadOrder(id) {
            this.$store.dispatch("worker/order/materials/findOrderMaterials", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

        cancelMaterial(id, index) {
            this.$bvModal.msgBoxConfirm("Opravdu chcete stornovat tuto materiálovou položku?", {
                title: "Potvrzení",
                okVariant: "primary",
                cancelVariant: "default",
                centered: true,
                okTitle: "Ano, stornovat.",
                cancelTitle: "Ne"
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch("order/material/material/cancelMaterial", {
                            order: this.order.id,
                            material: id
                        })
                            .then(() => {
                                this.records.splice(index, 1)
                            })
                            .catch((error) => {
                                this.errorMessage(error.response.data.message)
                            })
                    }
                })
        },

    },

}

</script>
