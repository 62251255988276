<template>
    <form ref="loginForm" @submit.prevent="onSubmit">

        <div class="form-group mb-3">
            <label for="accountName" class="font-weight-900">E-mailová adresa:</label>
            <input
                v-model="request.username"
                type="email"
                class="form-control rounded-0"
                id="accountName"
                aria-describedby="accountNameHelp"
                placeholder="Vložte uživatelské jméno"
                required
            />
        </div>

        <div class="form-group mb-3">
            <label for="password" class="font-weight-900">Heslo:</label>
            <input
                v-model="request.password"
                type="password"
                name="password"
                class="form-control rounded-0"
                id="password"
                placeholder="Vložte heslo"
                required
            />
        </div>

        <b-form-group class="mb-3">
            <b-form-checkbox
                v-model="request.rememberMe"
                button-variant="primary"
                :unchecked-value="false"
                :value="true"
            >
                Zapamatovat
            </b-form-checkbox>
        </b-form-group>

        <div class="bg-danger text-white p-3 mb-3" v-if="error">
            <span><i class="fa fa-exclamation-triangle mr-2"/>{{ error }}</span>
        </div>

        <button type="submit" class="btn btn-primary btn-block rounded-0" :disabled="isProcessing">
            <span class="spinner-border spinner-border-sm mr-3" v-if="isProcessing"/>
            <span v-if="isProcessing">Ověřuji Vaši identitu…</span>
            <span v-else>Přihlásit</span>
        </button>
    </form>
</template>

<script>
import AuthRequest from "../../../../NewModel/Auth/AuthRequest"

export default {

    data() {
        return {
            request: new AuthRequest(),
            isProcessing: false,
            error: '',
        }
    },

    methods: {

        async onSubmit() {
            this.error = ''
            if (!this.$refs.loginForm.checkValidity()) {
                this.error = 'Pro přihlášení je nutné vyplňit uživatelské jméno a heslo.'
            }
            this.isProcessing = true;
            await this.$store.dispatch('auth/login', this.request)
                .then((data) => {
                    this.$emit('onLoginSuccessfully', data)
                })
                .catch((err) => {
                    this.error = err.response.data.message
                })
                .finally(() => {
                    this.isProcessing = false
                })
        },

    },

}

</script>
