<template>
    <div>
        <div v-if="isLoading === false && hasRecords">
            <div v-for="(record, recordIndex) in records" :key="record.id">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <h5 class="font-weight-900">
                        {{ recordIndex + 1 }})
                        <strong class="text-danger">#{{ String(record.appliance).slice(-5) }}</strong> -
                        {{ record.name }}
                    </h5>
                    <BButton variant="light" :to="'/worker/appliances/' + record.appliance + '?order=' + order.id">
                        Detail spotřebiče <i class="fa-duotone fa-eye fa-fw ml-2"/>
                    </BButton>
                </div>
                <div class="bg-little-gray p-4 mb-4">
                    <div class="pb-3 border-bottom border-light">
                        <span v-if="record.serialNumber">
                            <strong class="font-weight-600 text-primary">
                                Sériové číslo:
                            </strong> {{ record.serialNumber }}
                        </span>
                        <span class="mx-3" v-if="record.serialNumber && record.type">/</span>
                        <span v-if="record.type">
                            <strong class="font-weight-600 text-primary">
                                Druh spotřebiče:
                            </strong>
                            {{ record.type }}
                        </span>
                        <span class="mx-3" v-if="(record.type || record.serialNumber) && record.location">/</span>
                        <span v-if="record.location">
                            <strong class="font-weight-600 text-primary">
                                Detail umístění:
                            </strong>
                            {{ record.location }}
                        </span>
                    </div>

                    <table class="table table-fit table-borderless mb-0">
                        <thead>
                        <tr>
                            <th class="align-middle w-30">
                                Typ opravy
                            </th>
                            <th class="align-middle w-40">
                                Komentář
                            </th>
                            <th class="text-center align-middle w-10">
                                Technik
                            </th>
                            <th class="text-center align-middle w-10">
                                Sleva
                            </th>
                            <th class="text-center align-middle w-10">
                                Zdarma
                            </th>
                        </tr>
                        </thead>
                        <tbody v-if="record.repairs.length > 0">
                        <tr
                            v-for="repair in record.repairs"
                            v-bind:key="'repair_' + repair.id"
                            class="border-top"
                        >
                            <td class="align-middle">
                                {{ repair.repairType.shortName }} - {{ repair.repairType.name }}
                            </td>
                            <td class="align-middle">
                                {{ repair.description }}
                            </td>
                            <td class="text-center align-middle">
                                <BAvatar
                                    size="md"
                                    :src="repair.worker.picture || null"
                                    :text="repair.worker.acronym"
                                    v-if="repair.worker"
                                />
                                <BAvatar
                                    size="md"
                                    :src="order.worker.picture || null"
                                    :text="order.worker.acronym"
                                    v-else-if="order.worker"
                                />
                                <BAvatar
                                    size="md"
                                    variant="light"
                                    v-else
                                />
                            </td>
                            <td class="text-center align-middle">
                                {{ repair.discount ? (repair.discount + "%") : "Ne" }}
                            </td>
                            <td class="text-center align-middle">
                                {{ repair.paid ? "Ne" : "Ano" }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <TableLoading v-else-if="isLoading === true && hasRecords === false"/>
        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"

export default {

    components: {
        TableLoading,
        TableWithoutRecords,
    },

    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isLoading: "worker/order/item/isLoading",
            hasRecords: "worker/order/item/hasRecords",
            records: "worker/order/item/getRecords",
        })
    },

    mounted() {
        this.loadOrder(this.order.id)
    },

    methods: {

        loadOrder(id) {
            this.$store.dispatch("worker/order/item/findOrderItems", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
