import DashboardService from "../DashboardService"
import DashboardOrderReview from "../Entity/DashboardOrderReview"

const
    FETCHING_DATA = "FETCHING_DATA",
    FETCHING_DATA_SUCCESS = "FETCHING_DATA_SUCCESS",
    FETCHING_DATA_ERROR = "FETCHING_DATA_ERROR",
    CLEAR_DATA = "CLEAR_DATA"

const initialState = {
    isLoading: false,
    error: null,
    data: null
}

export const state = {...initialState}

export const actions = {

    async getQuickOrdersReview({commit}) {
        try {
            commit(FETCHING_DATA)
            let response = await DashboardService.getQuickOrdersReview()
            commit(FETCHING_DATA_SUCCESS, response.data)
            return state.data
        } catch (error) {
            commit(FETCHING_DATA_ERROR, error)
            return Promise.reject(error)
        }
    },

    clear({commit}) {
        commit(CLEAR_DATA)
    },

}

export const mutations = {

    [FETCHING_DATA](state) {
        state.isLoading = true
        state.error = null
        state.data = null
    },
    [FETCHING_DATA_SUCCESS](state, data) {
        state.isLoading = false
        state.error = null
        state.data = DashboardOrderReview.buildFromResponse(data)
    },
    [FETCHING_DATA_ERROR](state, error) {
        state.isLoading = false
        state.error = error
        state.data = null
    },

    [CLEAR_DATA](state) {
        state.isLoading = false
        state.error = null
        state.data = null
    }

}

export const getters = {

    isLoading(state) {
        return state.isLoading
    },
    hasError(state) {
        return state.error !== null
    },
    error(state) {
        return state.error
    },

    /**
     * @param state
     * @returns {boolean}
     */
    hasData(state) {
        return state.data !== null
    },

    /**
     * @param state
     * @returns {DashboardOrderReview|null}
     */
    getData(state) {
        return state.data
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
