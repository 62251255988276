<template>
    <div>
        <div v-if="isLoading === false && hasRecords">
            <div class="bg-little-gray p-4">
                <table class="table table-fit table-borderless mb-0">
                    <thead>
                    <tr>
                        <th class="text-center align-middle w-10">
                            Datum
                        </th>
                        <th class="text-center align-middle w-10">
                            Autor
                        </th>
                        <th class="align-middle w-30">
                            Spotřebič
                        </th>
                        <th class="align-middle text-left">
                            Zpráva
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="record in records" v-bind:key="record.id" class="border-top">
                        <td class="align-middle text-center">
                            {{ record.createdAt|prettyDate }}
                        </td>
                        <td class="align-middle text-center">
                            <BAvatar
                                size="md"
                                :src="record.createdBy.picture || null"
                                :text="record.createdBy.acronym"
                                v-if="record.createdBy"
                            />
                            <BAvatar
                                size="md"
                                variant="light"
                                v-else
                            />
                        </td>
                        <td class="align-middle">
                            <span v-if="record.appliance">
                                #{{ String(record.appliance.id).slice(-5) }} / {{ record.appliance.fullName|shortify }}
                            </span>
                        </td>
                        <td class="align-middle text-left">
                            {{ record.message }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <TableLoading v-else-if="isLoading === true && hasRecords === true"/>
        <TableWithoutRecords v-else/>

    </div>
</template>

<script>
import {mapGetters} from "vuex"
import TableLoading from "@/Module/Common/UI/Table/TableLoading.vue"
import TableWithoutRecords from "@/Module/Common/UI/Table/TableWithoutRecords.vue"

export default {

    components: {
        TableLoading,
        TableWithoutRecords,
    },

    props: {
        supplyPoint: {
            type: String,
            required: true,
        }
    },

    computed: {
        ...mapGetters({
            isLoading: "worker/supplyPoint/note/isLoading",
            hasRecords: "worker/supplyPoint/note/hasRecords",
            records: "worker/supplyPoint/note/getRecords",
        }),
    },

    created() {
        this.$eventBus.$on("order-updated", () => {
            this.loadRecords(this.supplyPoint)
        })
    },

    beforeDestroy() {
        this.$eventBus.$off("order-updated")
    },

    mounted() {
        this.loadRecords(this.supplyPoint)
    },

    methods: {

        loadRecords(id) {
            this.$store.dispatch("worker/supplyPoint/note/clearRecords")
            this.$store.dispatch("worker/supplyPoint/note/findSupplyPointTechnicalNotes", id)
                .catch((error) => {
                    this.errorMessage(error.response.data.message)
                })
        },

    },

}
</script>
